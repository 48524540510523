import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { SendgridEmailEventListItemModel } from "../../apiclient/models";
import Util from "../../js/util";
import GridTable from "../../components/GridTable";
import { AuthContext } from "../../contexts/AccountContext";
import _ from "lodash";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

interface GiftCardEmailEventsModalComponentProps {
  giftCardId: string;
  onHide: any;
  show: boolean;
  onRefresh: any;
}

const GiftCardEmailEventsModalComponent = (
  props: GiftCardEmailEventsModalComponentProps
) => {
    const [data, setData] = useState([] as SendgridEmailEventListItemModel[]);
    const [pageCount, setPageCount] = React.useState(0);
    const [refreshCount, setRefreshCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);
    const [loading, setLoading] = useState(true as boolean);
    const [searchTerm, setSearchTerm] = useState("");
    const [eventTypeFilter, setEventTypeFilter] = useState("")
    const authContext = useContext(AuthContext);

  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  const tableColumns = [
    {
      Header: "Recipient email address",
      accessor: "emailAddress",
      Cell: ({ value, row }: any) => {
        var item = row.original as SendgridEmailEventListItemModel;
        return (
          <React.Fragment>
            <span>{item.emailAddress}</span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Event",
      accessor: "eventName",
      Cell: ({ value, row }: any) => {
        var item = row.original as SendgridEmailEventListItemModel;
        var badgeColor = "bg-secondary text-dark";
        if (item.eventName === "bounce" || item.eventName == "dropped" || item.eventName == "spamreport" || item.eventName == "unsubscribe") {
          badgeColor = "bg-danger text-danger";
        } else if (item.eventName == "delivered") {
          badgeColor = "bg-success text-success";
        }

        return (
          <React.Fragment>
            <span
              className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
            >
              {item.eventName}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Subject Line",
      accessor: "subjectLine",
      Cell: ({ value, row }: any) => {
        var item = row.original as SendgridEmailEventListItemModel;
        return (
          <React.Fragment>
            <span>{item.subjectLine}</span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Event date",
      accessor: "eventAt",
      Cell: ({ value, row }: any) => {
        var item = row.original as SendgridEmailEventListItemModel;
        return (
          <React.Fragment>
            <span>
              {dayjs(item.eventAt)
                .utc()
                .tz(authContext?.account?.ianaTimezone)
                .format("YYYY-MM-DD hh:mm a")}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Reason",
      Cell: ({ value, row }: any) => {
        var item = row.original as SendgridEmailEventListItemModel;
        return (
          <React.Fragment>
            <span className="font-semibold text-heading">
              {item.reason}
            </span>
          </React.Fragment>
        );
      },
    },
    
  ];

  const modalLoaded = () => {


  };


//   useEffect(() => {
//     setRefreshCount(refreshCount + 1);
//   }, [filterStatus]);

  const fetchData = ({ pageSize, pageIndex, search, sortBy, status }: any) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      var isDesc = sortBy[0] ? sortBy[0].desc ?? "true" : "true";
      var sortField = sortBy[0] ? sortBy[0].id ?? "eventAt" : "eventAt";

      fetchAPIData({
        limit: pageSize,
        pageIndex: pageIndex,
        skip: pageSize * pageIndex,
        search: search,
        sortField: sortField,
        isDesc: isDesc,
        status,
      });
    }
  };

  const fetchAPIData = async ({
    limit,
    pageIndex,
    skip,
    search,
    sortField,
    isDesc,
    status,
  }: any) => {
    try {
      setLoading(true);
      if (!sortField) {
        sortField = "eventAt";
      }
      const serverSortField = sortField[0].toUpperCase() + sortField.slice(1); //capitalize first letter to match server field name
      giftcardApi
        .giftcardGetEmailEventsPageByGiftCardGet(
          props.giftCardId,
          eventTypeFilter,
          pageIndex,
          skip,
          limit,
          serverSortField,
          isDesc,
          searchTerm,
        )
        .then((response) => {
          const list = response.data.result;
          var pageSize = limit;
          var TotalCount = response.data.totalCount ?? 0;
          var pageCount = ((TotalCount + pageSize - 1) / pageSize) | 0;

          setData(list ?? ([] as SendgridEmailEventListItemModel[]));
          setPageCount(pageCount);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  const _handleSearch = _.debounce(
    (search: string) => {
      setSearchTerm(search);
    },
    1500,
    {
      maxWait: 1500,
    }
  );


  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide} onEntered={modalLoaded} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Gift card email events for past month</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>

          <div className="p-5 pt-0">
                <GridTable
                  columns={tableColumns}
                  data={data}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  search={searchTerm}
                  goToFirstPage={undefined}
                  status={refreshCount} //hack to support other filters
                />
              </div>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default GiftCardEmailEventsModalComponent;

import React, { useEffect } from "react";
import { ProfileApi } from "../apiclient";
import { apiConfig } from "../config";
import { axiosInstance } from "../ShopifyAxiosInterceptor";

const AuthorizedAdminOrOwnerCheckComponent = (props: any) => {
  let profileApi = new ProfileApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  useEffect(() => {
    profileApi.profileIsAdminOrOwnerGet().then((response) => {}).catch((error: any) => {});
  }, []);
  return <React.Fragment></React.Fragment>;
};

export default AuthorizedAdminOrOwnerCheckComponent;

import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Button, Row, Modal, Form, Container, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AssetApi, GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "./PublicGiftToFriend.css";
import {
  GiftToFriendPageSettingsViewModel,
  GiftCardDetailViewModel,
  SendGiftCardToFriendViewModel,
  UploadGiftCardsVideoMessageResultPayload,
  GiftCardImageAssetViewModel,
} from "../../apiclient/models";
import Util from "../../js/util";
import ReactQuill from "react-quill";
import TimezoneSelect from "react-timezone-select";
import { toLocaleString } from "../../data/admins";
import VideoRecorderModal from "./VideoRecorderModal";
import { escape, unescape } from "lodash";
import { Helmet } from "react-helmet";
import PoweredByFooter from "../../components/PoweredByFooter";

//import "./assets.css";
const PublicGiftToFriendAdvancedPage = () => {
  let { id } = useParams();
  const [isLoading, setLoading] = useState(false as boolean);
  const [model, setModel] = useState({} as SendGiftCardToFriendViewModel);
  const [themeModel, setThemeModel] = useState(
    {} as GiftToFriendPageSettingsViewModel
  );
  const [giftMessage, setGiftMessage] = useState("");
  const [giftEmailHtml, setGiftEmailHtml] = useState("");
  const [giftEmailTemplateHtml, setGiftEmailTemplateHtml] = useState("");
  const [customHeadScript, setCustomHeadScript] = useState("");
  const [validated, setValidated] = useState(false);
  const [giftSent, setGiftSent] = useState(false);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  let cardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  const [images, setImages] = useState([] as GiftCardImageAssetViewModel[]);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    {} as GiftCardImageAssetViewModel
  );

  useEffect(() => {
    fetchData();
    fetchEmailHtml();
  }, []);

  useEffect(() => {
    if (model && giftEmailTemplateHtml) {
      updateEmailPreviewHtml(giftEmailTemplateHtml);
    }
  }, [model, giftMessage, selectedImage, giftEmailTemplateHtml]);

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]);
    }
  }, [images]);

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument;
      if (iframeDocument) {
        iframeDocument.open();
        iframeDocument.write(giftEmailHtml);
        iframeDocument.close();
        console.log("giftEmailHtml");
      }
    }
  }, [giftEmailHtml]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const timezoneOnChange = (selectedTimezone: any) => {
    console.log("selectedTimezone", selectedTimezone);
    setTimezone(selectedTimezone.value);
  };

  const fetchThemeData = (aId: string) => {
    assetApi
      .assetGiftToFriendPagePublicIdGet(aId!)
      .then((response) => {
        var data = response.data;
        if (data.useLegacy) {
          location.href = `/public/gift-to-friend-legacy/${id}`;
        }
        setThemeModel(data);
      })
      .catch((error: any) => { });
  };

  const fetchCustomScript = (aId: string) => {
    assetApi
      .assetGetCustomEmbedScriptByTypeGet(aId!, "GiftToFriendPageHeadScript")
      .then((response) => {
        var data = response.data;
        if (data) {
          setCustomHeadScript(data!);
        }
      })
      .catch((error: any) => { });
  };

  const fetchAssetImages = (id: string) => {
    assetApi
      .assetActiveImageAssetsIdGet(id!)
      .then((response) => {
        var data = response.data;

        setImages(data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log("get survey error", error);
        setLoading(false);
      });
  };

  const fetchEmailHtml = () => {
    cardApi
      .giftcardGiftToFriendEmailHtmlGet(id!)
      .then((response) => {
        var data = response.data;
        setGiftEmailTemplateHtml(data.html!);
        //updateEmailPreviewHtml(data.html!);
      })
      .catch((error: any) => { });
  };

  const uploadComplete = (upload: UploadGiftCardsVideoMessageResultPayload) => {
    if (upload) {
      setModel((values: SendGiftCardToFriendViewModel) => ({
        ...values,
        videoMessageUrl: upload.videoMessageUrl,
        videoMessageThumbnailUrl: upload.videoMessageThumbnailUrl,
      }));
      fetchEmailHtml();
    }
  };

  const deleteVideoMessage = () => {
    cardApi
      .giftcardRemoveVideoMessageIdGet(id!)
      .then((response) => {
        var data = response.data;
        if (data) {
          setModel((values: SendGiftCardToFriendViewModel) => ({
            ...values,
            videoMessageUrl: "",
            videoMessageThumbnailUrl: "",
          }));
          fetchEmailHtml();
        }
      })
      .catch((error: any) => { });
  };

  const updateEmailPreviewHtml = (previewHtml: string) => {
    //let previewHtml = giftEmailTemplateHtml;
    var videoImage = "";
    if (model.videoMessageThumbnailUrl) {
      videoImage = `<a href='${model.videoMessageUrl}' target='_blank' rel='noopener noreferrer'><div style='position: relative; width: 100%; max-width: 400px; margin: 0 auto; text-align: center;'>
        <img src='${model.videoMessageThumbnailUrl}' alt='Play Button' style='width: 350px;>
    </div></a>`;
    }
    previewHtml = previewHtml
      .replace(
        "{{gift_card_message}}",
        `${giftMessage!}<div style='padding-top:20px;'>${videoImage}</div>`
      )
      .replace("{{buyer_full_name}}", model.giftedFromName!)
      .replace("{{gift_image_url}}", selectedImage.imageUrl!);

    console.log("previewHtml", selectedImage.imageUrl);
    setGiftEmailHtml(previewHtml);
  };

  const getLocalDate = (dateStr: string) => {
    const currentDateTime = new Date(dateStr);
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const hours = String(currentDateTime.getHours()).padStart(2, "0");
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");

    var localDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return localDate;
  };

  const fetchData = () => {
    //setModel({});
    setGiftMessage(
      "<p>Best wishes for your birthday! Hope you enjoy this gift card!.</p>"
    );

    cardApi
      .giftcardGiftCardToFriendIdGet(id!)
      .then((response) => {
        var data = response.data;
        if (data.giftScheduledFor) {
          data.sendLater = true;
          data.giftScheduledFor = getLocalDate(data.giftScheduledFor);
        } else {
          data.sendLater = false;
          data.giftScheduledFor = getLocalDate(new Date().toString());
        }
        setModel(data);
        setGiftMessage(data.giftMessage!);
        fetchThemeData(data.accountId!);
        fetchAssetImages(data.accountId!);
        fetchCustomScript(data.accountId!);
      })
      .catch((error: any) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "sendLater") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }

    if (name === "giftMessage") {
      setGiftMessage(value);
    }
    setModel((values: SendGiftCardToFriendViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      //setIsSubmitting(false);
      model.giftMessage = giftMessage;
      model.timezone = timezone;
      if (selectedImage) {
        model.giftImageUrl = selectedImage.imageUrl;
      }
      cardApi
        .giftcardSendGiftCardToFriendPost("1", model)
        .then((response) => {
          if (response.data) {
            toast.success("Gift card has been sent to friend.", {
              duration: 10000,
            });
            setGiftSent(true);
          } else {
            toast.error("Count not send gift card to friend.");
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred.");
        });
    }
  };

  const handleImageSelect = (image: GiftCardImageAssetViewModel) => {
    setSelectedImage(image);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <LoaderIcon />
        </>
      ) : (
        <div className="d-xl-flex row">
          <div
            className="col-md-12 flex-xl-none border-start-xl h-xl-calc position-sticky top-xl-18"
          >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="">
                <div className="container py-10">
                  <div className="row mb-10">
                    <div className="mx-auto">
                      <div className="row align-items-center">
                        <div className="text-center">
                          {themeModel.pageHeaderImageUrl ? (
                            <img
                              src={themeModel.pageHeaderImageUrl}
                              alt={themeModel.pageHeaderTitle?.replace(
                                "{{companyName}}",
                                model.companyName!
                              )}
                            />
                          ) : (
                            <h1
                              className="h1 ls-tight mb-10"
                            >
                              {themeModel.pageHeaderTitle?.replace(
                                "{{companyName}}",
                                model.companyName!
                              )}
                            </h1>
                          )}

                          <h1
                            className="h2 ls-tight mb-5"
                          >
                            {themeModel.giftAmountHeaderTitle?.replace(
                              "{{amount}}",
                              model.giftCardBalance!
                            )}
                          </h1>
                        </div>
                      </div>
                      {!giftSent ? (
                        <Row>
                          <div className="col-md-6">
                            {images.length > 1 && (
                              <div
                                className="card mb-10"
                              >
                                <div className="card-header h5">
                                  {themeModel.imageSectionHeaderLabel}
                                </div>
                                <Row>
                                  {images.map((image) => (
                                    <Col key={image.id} xs={6} sm={6} md={4}>
                                      <label
                                        className={`radio-image ${selectedImage.id === image.id
                                            ? "selected"
                                            : ""
                                          }`}
                                        onClick={() => handleImageSelect(image)}
                                      >
                                        <img
                                          src={image.imageUrl}
                                          alt={`Image ${image.id}`}
                                        />
                                      </label>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            )}
                            <div
                              className="card mb-10"
                            >
                              <div className="card-header">
                                <h5
                                >
                                  {themeModel.giftCardSectionHeaderLabel}
                                </h5>
                              </div>
                              <div className="card-body">
                                <Row>
                                  <Form.Group className="">
                                    <Form.Label
                                    >
                                      {themeModel.giftCardFromNameFormLabel}
                                    </Form.Label>
                                    <Form.Control
                                      name="giftedFromName"
                                      value={model.giftedFromName || ""}
                                      onChange={handleChange}
                                      required
                                    />
                                  </Form.Group>
                                  <Form.Group className="mt-5">
                                    <Form.Label
                                    >
                                      {themeModel.giftCardFriendNameFormLabel}
                                    </Form.Label>
                                    <Form.Control
                                      name="recipientName"
                                      value={model.recipientName || ""}
                                      onChange={handleChange}
                                      required
                                    />
                                  </Form.Group>
                                  <Form.Group className="mt-5">
                                    <Form.Label
                                    >
                                      {themeModel.giftCardFriendEmailFormLabel}
                                    </Form.Label>
                                    <Form.Control
                                      name="recipientEmailAddress"
                                      value={model.recipientEmailAddress || ""}
                                      onChange={handleChange}
                                      required
                                    />
                                  </Form.Group>
                                  <Form.Group className="mt-5">
                                    <Form.Label
                                    >
                                      {themeModel.giftCardMessageFormLabel}
                                    </Form.Label>
                                    {/*<ReactQuill*/}
                                    {/*  value={giftMessage}*/}
                                    {/*  onChange={setGiftMessage}*/}
                                    {/*/>*/}
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      placeholder="e.g. Happy birthday"
                                      name="giftMessage"
                                      value={model.giftMessage || ""}
                                      onChange={handleChange}
                                    />
                                    {themeModel.isVideoMessageEnabled && (
                                      <>
                                        {!model.videoMessageUrl ? (
                                          <div className="mt-5">
                                            <button
                                              onClick={openModal}
                                              type="button"
                                              className="btn btn-secondary w-full"
                                            >
                                              <span className="bi-camera-video mr-10"></span>
                                              {
                                                themeModel.giftCardRecordVideoButtonText
                                              }
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="mt-5">
                                            <button
                                              onClick={openModal}
                                              type="button"
                                              className="btn btn-secondary w-full"
                                            >
                                              <span className="bi-camera-video mr-10"></span>
                                              Edit video message
                                            </button>
                                            <button
                                              onClick={deleteVideoMessage}
                                              type="button"
                                              className="btn btn-danger w-full mt-5"
                                            >
                                              <span className="bi-trash mr-10"></span>
                                              Remove video
                                            </button>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </Form.Group>

                                  <Form.Group className="mt-5">
                                    <div
                                      className="btn-group"
                                      role="group"
                                      aria-label="Basic radio toggle button group"
                                    >
                                      <input
                                        type="radio"
                                        className="btn-check"
                                        name="sendLater"
                                        id="btnradio1"
                                        value="false"
                                        checked={model.sendLater === false}
                                        onChange={handleChange}
                                      />
                                      <label
                                        className="btn btn-outline-primary"
                                        htmlFor="btnradio1"
                                      >
                                        Send now
                                      </label>

                                      <input
                                        type="radio"
                                        className="btn-check"
                                        name="sendLater"
                                        id="btnradio2"
                                        value="true"
                                        checked={model.sendLater === true}
                                        onChange={handleChange}
                                      />
                                      <label
                                        className="btn btn-outline-primary"
                                        htmlFor="btnradio2"
                                      >
                                        Send later
                                      </label>
                                    </div>
                                  </Form.Group>
                                  {model.sendLater && (
                                    <>
                                      <Form.Group className="mt-5">
                                        <Form.Label
                                        >
                                          {themeModel.giftCardSendAtFormLabel}
                                        </Form.Label>
                                        <Form.Control
                                          name="giftScheduledFor"
                                          type="datetime-local"
                                          value={model.giftScheduledFor || ""}
                                          onChange={handleChange}
                                          required
                                        />
                                      </Form.Group>
                                      <Form.Group className="mt-5">
                                        <Form.Label
                                        >
                                          {themeModel.giftCardTimezoneFormLabel}
                                        </Form.Label>
                                        <TimezoneSelect
                                          value={timezone}
                                          onChange={timezoneOnChange}
                                        />
                                      </Form.Group>
                                    </>
                                  )}
                                </Row>

                                <div className="mt-10">
                                  <button
                                    className="btn btn-primary w-full btn-custom"
                                    type="submit"
                                  >
                                    {themeModel.giftCardSendAsGiftButtonText}
                                  </button>
                                </div>
                              </div>
                            </div>
                            {themeModel && !themeModel.hideWrappedBranding && (
                              <PoweredByFooter />
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="card">
                              <div className="card-header h5 text-center">
                                <span className="bi-envelope mr-10"></span>
                                {themeModel.emailPreviewHeaderLabel}
                              </div>
                              <div className="card-body">
                                <iframe
                                  ref={iframeRef}
                                  title="Email content"
                                  style={{
                                    width: "100%",
                                    maxHeight: "100%",
                                    height: "1350px",
                                    border: "none",
                                    outline: "none",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Row>
                      ) : (
                        <Row>
                          <div
                            className="card p-20 mb-10 text-center"
                          >
                            {model.sendLater ? (
                              <div className="h3 ls-tight">
                                ✅ Gift card has been scheduled to be sent.
                              </div>
                            ) : (
                              <div className="h3 ls-tight">
                                ✅ Gift card has been sent to friend.
                              </div>
                            )}
                          </div>
                        </Row>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
      <VideoRecorderModal
        translation={themeModel}
        showModal={showModal}
        onClose={closeModal}
        uploadComplete={uploadComplete}
      />
      <Helmet>
        {model && model.accountId && (
          <link rel="stylesheet" href={`Asset/gifttofriendstyle.css?accountId=${model.accountId}`} />
        )}
        {customHeadScript && (
          <script>{`
            document.head.innerHTML += \`${customHeadScript}\`;
          `}</script>
        )}
      </Helmet>
    </React.Fragment>
  );
};

export default PublicGiftToFriendAdvancedPage;

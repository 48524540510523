import React, { useState, useEffect } from "react";
import { AccountApi } from "../../apiclient";
import {
  ConfirmMagicLoginLinkModel,
  SendMagicLoginLinkModel,
} from "../../apiclient/models";
import { Button, Row, Col, Form } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import "./SignInPage.css";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { useNavigate, useSearchParams } from "react-router-dom";

const MagicLinkConfirm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token") ?? "";
  const userId = searchParams.get("userId") ?? "";
  const navigate = useNavigate();
  let accountApi = new AccountApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as ConfirmMagicLoginLinkModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginButtonText, setLoginButtonText] = useState("Confirm and login");
  useEffect(() => {
    getTokenUser();
  }, []);

  const getTokenUser = () => {
    accountApi
      .accountConfirmMagicLoginLinkPost(userId, token)
        .then((response) => {
            var result = response.data as ConfirmMagicLoginLinkModel;
            if (result.isValid) {
                setModel(response.data);
            } else {
                toast.error("Token is invalid. Please log in using your email address", { duration: 5000 });
                setLoginButtonText("Confirm and login");
                navigate("/account/login");
            }
      })
      .catch((error: any) => {
          toast.error("Token is invalid. Please log in using your email address", { duration: 5000 });
          setLoginButtonText("Confirm and login");
          navigate("/account/login");
      });
  };

  const handleSubmit = (event: any) => {
    setIsSubmitting(false);
    setLoginButtonText("Logging in..");
    window.location.href = `/MagicLink/MagicLogin?userId=${userId}&token=${token}`;
  };

  return (
    <React.Fragment>
    
        <div className="px-5 py-5 p-lg-0 min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-dark d-none d-lg-flex flex-column">
              {/* <!-- Logo --> */}
              <a className="d-block" href="https://wrappedgiftcards.com">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="..."
                />
              </a>
              {/* <!-- Title --> */}
              <div className="mt-32 mb-20">
                <h1 className="ls-tight font-bolder display-7 text-white mb-5">
                  Create and manage omnichannel gift cards
                </h1>
                <p className="text-white text-opacity-80">
                  Deliver a better customer experience with gift cards that can
                  be redeemed instore or online.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-screen d-flex flex-column justify-content-center position-relative">
              <div className="py-lg-16 px-lg-20">
                <div className="row">
                  <div className="col-lg-10 col-md-9 col-xl-7 mx-auto ms-xl-0">
                    <div className="mt-10 mt-lg-5 mb-6 d-lg-block">
                      <span className="d-inline-block d-lg-block h1 mb-4 mb-lg-6 me-3">
                        👋
                      </span>
                      <div className="ls-tight font-bolder h4">
                        Confirm login for {model?.emailAddress}
                      </div>
                    </div>
                    <div>
                      <div className="hstack gap-4 box-content">
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        type="button"
                      >
                        {loginButtonText}
                      </Button>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </React.Fragment>
  );
};

export default MagicLinkConfirm;

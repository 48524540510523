/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AccountFeatureType = {
    MarselloIntegration: 'MarselloIntegration',
    StoreCredit: 'StoreCredit',
    Automations: 'Automations'
} as const;

export type AccountFeatureType = typeof AccountFeatureType[keyof typeof AccountFeatureType];




import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { EmailApi, RegisterDomainPayload, ProcessResultModel } from "../../apiclient";
import { apiConfig } from "../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";

const VerifyDomainModalComponent = (props : any) => {
    let settingsApi = new EmailApi(apiConfig.configuration,apiConfig.baseUrl);
    const [validated, setValidated] = useState(false);
    const [value, setValue] = useState();
    const [model, setModel] = useState(
        {} as RegisterDomainPayload
    );

    const handleChange = (event: any) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = event.target.name;
            setModel((values: RegisterDomainPayload) => ({
            ...values,
            [name]: value,
        }));
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {

            settingsApi.emailRegisterDomainPost('1',{
                verifyEmailAddress: model.verifyEmailAddress
            })
                .then(function (response) {
                    var data = response.data as ProcessResultModel;
                    if(data.isSuccessful){
                        toast.success(data.message!);
                        props.onHide(); 
                        props.refreshDomains();
                    }else{
                        toast.error(data.message!);
                    }
                    
                })
                .catch(function (error) {
                    toast.error("Verify email could not be sent");
                });
        } else {
            setValidated(true);
        }
    };

    return (
        <React.Fragment>
            <Modal show={props.isOpen} onHide={props.onHide}>
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Verify Domain</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <p className="pb-3">We need to verify that you have access to an email address at the domain you plan to send emails from. Enter an address, and we'll send you a verification email. <a target="_blank" href="#" className="text-link">Learn more.</a></p>
                            <Form.Label>Email Address:</Form.Label>
                            <Form.Control
                                    name="verifyEmailAddress"
                                    type="email"
                                    required
                                    placeholder="test@example.com"
                                    onChange={handleChange}
                                    value={model.verifyEmailAddress}
                                />
                            <Form.Control.Feedback type="invalid">
                                Please provide a vaild email.
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                            Enter an address that contains the domain you want to verify.
                            </Form.Text>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="sm" variant="secondary" onClick={props.onHide}>
                            Cancel
                        </Button>
                        {model.verifyEmailAddress ? (
                            <Button size="sm" variant="primary" type="submit">
                                Send Verification Email
                            </Button>
                        ) : (
                            <Button size="sm" variant="primary" disabled>
                                Send Verification Email
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default VerifyDomainModalComponent;

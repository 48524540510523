import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
    FormCheck,
    Button,
    Spinner,
    Form,
    Col,
    Row,
    Dropdown,
    ButtonGroup,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { AnalyticsApi } from "../../apiclient";
import { DashboardGiftCardsAnalyticsModel } from "../../apiclient/models";
import _ from "lodash";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const AnalyticsPanelComponent = () => {
    let analyticsApi = new AnalyticsApi(
        apiConfig.configuration,
        apiConfig.baseUrl,
        axiosInstance
    );
    const [loading, setLoading] = useState(true as boolean);
    const authContext = useContext(AuthContext);

    const [model, setModel] = useState({} as DashboardGiftCardsAnalyticsModel);

    useEffect(() => {
        fetchInitialAnalyticsData();
    }, []);

    const fetchInitialAnalyticsData = () => {
        setLoading(true);

        analyticsApi
            .analyticsGetTopLevelStatsForAccountGet()
            .then((response) => {
                var data = response.data as DashboardGiftCardsAnalyticsModel;
                setModel(data);
                setLoading(false);
            })
            .catch((error: any) => {
                console.log("get analytics error", error);
                setLoading(false);
            });
    }

    const handleDatesCallback = (start: any, end: any) => {
        setLoading(true);
        var startDate = start?.format("YYYY/MM/DD");
        var endDate = end?.format("YYYY/MM/DD");

        setModel({
            ...model,
            startDate: startDate,
            endDate: endDate,
        });

        analyticsApi
            .analyticsGetAnalyticsForAccountByDateRangeGet(startDate, endDate)
            .then((response) => {
                var data = response.data as DashboardGiftCardsAnalyticsModel;
                setModel(data);
                setLoading(false);
            })
            .catch((error: any) => {
                console.log("get analytics error", error);
                setLoading(false);
            });
    };

    return (
        <React.Fragment>

            <div className="card">
                <div className="card-body">
                    <div className="card-title d-flex align-items-center border-bottom pb-5">
                                   
                        <h5 className="mb-0">Overview </h5>  {loading && <><Spinner animation="border" style={{ marginLeft: "20px" }} /></>}
                        <div className="ms-auto text-end">
                           
                            {model.startDate &&
                                <>
                                <div className="calendar-input-icons">
                                        <i className="bi bi-calendar icon"></i>
                                        <DateRangePicker
                                            initialSettings={{
                                                startDate: dayjs(model.startDate).toDate(),
                                                endDate: dayjs().toDate(),
                                                locale: {
                                                    format: "DD MMM, yyyy",
                                                },
                                                ranges: {
                                                    "All Time": [
                                                        dayjs(model.startDate).toDate(),
                                                        dayjs().toDate(),
                                                    ],
                                                    Today: [dayjs().toDate(), dayjs().toDate()],
                                                    Yesterday: [
                                                        dayjs().subtract(1, "days").toDate(),
                                                        dayjs().subtract(1, "days").toDate(),
                                                    ],
                                                    "Last 7 Days": [
                                                        dayjs().subtract(6, "days").toDate(),
                                                        dayjs().toDate(),
                                                    ],
                                                    "Last 30 Days": [
                                                        dayjs().subtract(29, "days").toDate(),
                                                        dayjs().toDate(),
                                                    ],
                                                    "This Month": [
                                                        dayjs().startOf("month").toDate(),
                                                        dayjs().endOf("month").toDate(),
                                                    ],
                                                    "Last Month": [
                                                        dayjs().subtract(1, "month").startOf("month").toDate(),
                                                        dayjs().subtract(1, "month").endOf("month").toDate(),
                                                    ],
                                                },
                                            }}
                                            onCallback={handleDatesCallback}
                                        >
                                            <input
                                                type="text"
                                                className="form-control input-field"
                                                placeholder="Select date range"
                                            />
                                        </DateRangePicker>
                                    </div>
                                </>}
                        </div>
                    </div>
                    <div className="row g-6 mb-6">
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card bg-surface-secondary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                                Revenue from orders with gift cards
                                            </span>
                                            <span className="h3 font-bold mb-0">{model.totalGiftCardRevenueFormatted}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card bg-surface-secondary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                                Upsell on gift cards
                                            </span>
                                          
                                                <span className="h3 font-bold mb-0">
                                                        {model.upsellOnGiftCardsFormatted}</span>
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card bg-surface-secondary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                                Redeemed gift cards value
                                            </span>
                                            <span className="h3 font-bold mb-0">{model.redeemedGiftCardsValueFormatted}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card bg-surface-secondary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                                Outstanding balances
                                            </span>
                                            <span className="h3 font-bold mb-0">{model.outstandingRedeemableBalanceFormatted}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card bg-surface-secondary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                                Gift card sales
                                            </span>
                                            <span className="h3 font-bold mb-0">{model.totalGiftCardSalesFormatted}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card bg-surface-secondary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                                Number of gift cards sold
                                            </span>
                                            <span className="h3 font-bold mb-0">{model.numGiftCardsSold}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card bg-surface-secondary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                                Avg. gift card sold value
                                            </span>
                                            <span className="h3 font-bold mb-0">{model.averageGiftCardValueFormatted}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card bg-surface-secondary">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                                Number of active gift cards
                                            </span>
                                            <span className="h3 font-bold mb-0">{model.numActiveGiftCards}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AnalyticsPanelComponent;

/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { WCAuthPayload } from '../models';
/**
 * WooCommerceApi - axios parameter creator
 * @export
 */
export const WooCommerceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wooCommerceApiKeyGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WooCommerce/ApiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {WCAuthPayload} [wCAuthPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wooCommerceAuthCallbackPost: async (apiVersion?: string, wCAuthPayload?: WCAuthPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WooCommerce/AuthCallback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wCAuthPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [storeUrl] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wooCommerceAuthGet: async (storeUrl?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WooCommerce/Auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeUrl !== undefined) {
                localVarQueryParameter['storeUrl'] = storeUrl;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WooCommerceApi - functional programming interface
 * @export
 */
export const WooCommerceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WooCommerceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wooCommerceApiKeyGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wooCommerceApiKeyGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {WCAuthPayload} [wCAuthPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wooCommerceAuthCallbackPost(apiVersion?: string, wCAuthPayload?: WCAuthPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wooCommerceAuthCallbackPost(apiVersion, wCAuthPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [storeUrl] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wooCommerceAuthGet(storeUrl?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wooCommerceAuthGet(storeUrl, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WooCommerceApi - factory interface
 * @export
 */
export const WooCommerceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WooCommerceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wooCommerceApiKeyGet(apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.wooCommerceApiKeyGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {WCAuthPayload} [wCAuthPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wooCommerceAuthCallbackPost(apiVersion?: string, wCAuthPayload?: WCAuthPayload, options?: any): AxiosPromise<boolean> {
            return localVarFp.wooCommerceAuthCallbackPost(apiVersion, wCAuthPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [storeUrl] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wooCommerceAuthGet(storeUrl?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.wooCommerceAuthGet(storeUrl, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WooCommerceApi - object-oriented interface
 * @export
 * @class WooCommerceApi
 * @extends {BaseAPI}
 */
export class WooCommerceApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WooCommerceApi
     */
    public wooCommerceApiKeyGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return WooCommerceApiFp(this.configuration).wooCommerceApiKeyGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {WCAuthPayload} [wCAuthPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WooCommerceApi
     */
    public wooCommerceAuthCallbackPost(apiVersion?: string, wCAuthPayload?: WCAuthPayload, options?: AxiosRequestConfig) {
        return WooCommerceApiFp(this.configuration).wooCommerceAuthCallbackPost(apiVersion, wCAuthPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [storeUrl] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WooCommerceApi
     */
    public wooCommerceAuthGet(storeUrl?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return WooCommerceApiFp(this.configuration).wooCommerceAuthGet(storeUrl, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}

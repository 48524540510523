import "./WorkflowBuilder.css";
import { AuthContext } from "../../contexts/AccountContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import {
  FlowsApi,
  Workflow,
  WorkflowAction,
  WorkflowCase,
  WorkflowCaseDelaySettings,
  WorkflowCondition,
  WorkflowConditions
} from "../../apiclient";
import Util from "../../js/util";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { Link, useParams } from "react-router-dom";
import React from "react";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";

//import "./assets.css";
const WorkflowDesignPage = () => {
  let flowApi = new FlowsApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as Workflow);
  const [isLoading, setLoading] = useState(false as boolean);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const authContext = useContext(AuthContext);
  const [selectedCase, setSelectedCase] = useState({} as WorkflowCase);
  const [selectedAction, setSelectedAction] = useState({} as WorkflowAction);
  const [showConditionPanel, setShowConditionPanel] = useState(false);
  const [selectedBoxOutlineId, setSelectedBoxOutlineId] = useState(
    "" as string
  );
  const [showActionPanel, setShowActionPanel] = useState(false);

  const saveChanges = () => {
    flowApi
      .flowsSavePost("1", model)
      .then((response) => {
        toast.success("Workflow saved!");
        setUnsavedChanges(false);
      })
      .catch((error: any) => {
        toast.error("An error occurred when saving workflow.");
        setUnsavedChanges(false);
      });
  };

  let { id } = useParams();
  useEffect(() => {
    fetchWorkflow();
  }, []);

  const appendDefaultBlock = () => {
    var newCase = {
      id: Util.generateRandomString(16),
    } as WorkflowCase;
    setModel((prevState) => ({
      ...prevState,
      cases: [...prevState.cases!, newCase],
    }));
  };

  const fetchWorkflow = () => {
    flowApi
      .flowsGetByIdIdGet(id!)
      .then((response) => {
        var flow = response.data;
        setModel(flow);
        if (flow.cases?.length === 0) {
          appendDefaultBlock();
        }
        console.log("fetchWorkflow", flow);
      })
      .catch((error: any) => {
        console.log("fetchWorkflow error", error);
      });
  };

  const appendCaseBlock = (caseToUpdate: WorkflowCase) => {
    var newCase = {
      id: caseToUpdate.id,
      conditions: {
        all: [
          {
            id: Util.generateRandomString(16),
            name: "CustomerTotalSpent",
            operator: "greaterThanInclusive",
            numberValue: 125,
          } as WorkflowCondition,
        ],
      } as WorkflowConditions,
    } as WorkflowCase;

    const updatedUseCaseData = model.cases?.map((useCase) => {
      if (useCase.id === caseToUpdate.id) {
        return { ...useCase, ...newCase };
      }
      return useCase;
    });

    setModel({ ...model, cases: updatedUseCaseData });

    appendDefaultBlock();

    loadConditionsPanel(newCase);
  };

  const appendConditionBlock = (caseToUpdate: WorkflowCase) => {
    var newCondition = {
      id: Util.generateRandomString(16),
      name: "CustomerTotalSpent",
      operator: "greaterThanInclusive",
      numberValue: 125,
    } as WorkflowCondition;

    const updatedUseCaseData = model.cases?.map((useCase) => {
      if (useCase.id === caseToUpdate.id) {
        if (!useCase.conditions) {
          useCase.conditions = {};
        }
        if (!useCase.conditions.all) {
          useCase.conditions.all = [];
        }
        useCase.conditions.all.push(newCondition);
      }
      return useCase;
    });

    setModel({ ...model, cases: updatedUseCaseData });
  };

  const appendActionBlock = (channel: WorkflowCase, index: number) => {
    console.log("appendActionBlock", index);
    var newAction = {
      id: Util.generateRandomString(16),
      name: "NotSet",
    } as WorkflowAction;

    const updatedUseCaseData = model.cases?.map((useCase) => {
      if (useCase.id === channel.id) {
        if (!useCase.actions) {
          useCase.actions = [];
        }
        useCase.actions.push(newAction);
      }
      return useCase;
    });

    setModel({ ...model, cases: updatedUseCaseData });
    loadActionsPanel(newAction);
  };

  const deleteCaseBlock = (index: number) => {
    console.log("deleteConditionBlock", index);
    setModel((prevState) => {
      const newList = prevState.cases!.filter((_, i) => i !== index);
      return {
        ...prevState,
        cases: newList,
      };
    });
  };

  const deleteCondition = (conditionToDelete: WorkflowCondition) => {
    const updatedUseCaseData = model.cases?.map((useCase) => {
      if (useCase.conditions && useCase.conditions.all) {
        useCase.conditions.all = useCase.conditions.all.filter(
          (condition) => condition.id !== conditionToDelete.id
        );
      }
      return useCase;
    });

    setModel({ ...model, cases: updatedUseCaseData });
  };

  const deleteActionById = (useCaseId: string, actionId: string) => {
    const updatedUseCaseData = model.cases!.map((useCase) => {
      if (useCase.id === useCaseId) {
        if (useCase.actions) {
          useCase.actions = useCase.actions.filter(
            (action) => action.id !== actionId
          );
        }
      }
      return useCase;
    });

    setModel({ ...model, cases: updatedUseCaseData });
  };

  const loadConditionsPanel = (useCase: WorkflowCase) => {
    setSelectedCase(useCase);
    setShowConditionPanel(true);
    setShowActionPanel(false);
    setSelectedBoxOutlineId(useCase.id!);
  };

  const loadActionsPanel = (action: WorkflowAction) => {
    setSelectedAction(action);
    setShowActionPanel(true);
    setShowConditionPanel(false);
    setSelectedBoxOutlineId(action.id!);
  };

  const setActionToIssueGiftCardType = (actionToUpdate: WorkflowAction) => {
    actionToUpdate.name = "IssueGiftCard";
    setSelectedAction((prevState) => ({
      ...prevState,
      name: "IssueGiftCard",
    }));

    const updatedUseCaseData = model.cases?.map((useCase) => {
      if (useCase.actions) {
        useCase.actions = useCase.actions.map((action) => {
          if (action.id === actionToUpdate.id) {
            return { ...action, ...actionToUpdate };
          }
          return action;
        });
      }
      return useCase;
    });

    setModel({ ...model, cases: updatedUseCaseData });
  };

  const setActionToDelayType = (actionToUpdate: WorkflowAction) => {
    actionToUpdate.name = "Delay";
    setSelectedAction((prevState) => ({
      ...prevState,
      name: "Delay",
    }));

    const updatedUseCaseData = model.cases?.map((useCase) => {
      if (useCase.actions) {
        useCase.actions = useCase.actions.map((action) => {
          if (action.id === actionToUpdate.id) {
            return { ...action, ...actionToUpdate };
          }
          return action;
        });
      }
      return useCase;
    });

    setModel({ ...model, cases: updatedUseCaseData });
  };

  const renderAction = (
    useCase: WorkflowCase,
    channel: WorkflowAction,
    i: number
  ) => {
    if (channel.name === "NotSet") {
      return (
        <div key={"flow-action" + i} className="list-group">
          <div
            className={`Flow-Box-Button ${
              channel.id === selectedBoxOutlineId
                ? "Flow-Box-Button__button-selected"
                : ""
            }`}
          >
            <i
              onClick={() => deleteActionById(useCase.id!, channel.id!)}
              className="bi bi-x"
            ></i>
            <button
              type="button"
              className="shop-remove w-full"
              onClick={() => loadActionsPanel(channel)}
            >
              <span className="button_content">
                <span>
                  <div className="notifications-icons"></div>
                  <span className="flow-box-button-item error">
                    <i>Select action</i>
                  </span>
                </span>
              </span>
            </button>
          </div>
        </div>
      );
    } else if (channel.name === "Delay") {
      return (
        <div
          key={"flow-action" + i}
          className={`Flow-Box-Button ${
            channel.id === selectedBoxOutlineId
              ? "Flow-Box-Button__button-selected"
              : ""
          }`}
        >
          <i
            onClick={() => deleteActionById(useCase.id!, channel.id!)}
            className="bi bi-x"
          ></i>
          <button
            type="button"
            className="shop-remove w-full"
            onClick={() => loadActionsPanel(channel)}
          >
            <span className="button_content">
              <span>
                <span className="flow-box-button-item">Wait 30 days</span>
              </span>
            </span>
          </button>
        </div>
      );
    } else {
      return (
        <div
          key={"flow-action" + i}
          className={`Flow-Box-Button ${
            channel.id === selectedBoxOutlineId
              ? "Flow-Box-Button__button-selected"
              : ""
          }`}
        >
          <i
            onClick={() => deleteActionById(useCase.id!, channel.id!)}
            className="bi bi-x"
          ></i>
          <button
            type="button"
            className="shop-remove w-full"
            onClick={() => loadActionsPanel(channel)}
          >
            <span className="button_content">
              <span>
                <span className="flow-box-button-item">Issue gift card</span>
              </span>
            </span>
          </button>
        </div>
      );
    }
  };

  const renderActionSettings = (action: WorkflowAction) => {
    console.log("renderActionSettings", action);
    if (action.name === "NotSet") {
      return (
        <div className="list-group">
          <div className="list-group-item d-flex">
            <div>
              <div className="icon text-primary">
                <i className="bi bi-gift"></i>
              </div>
            </div>
            <div className="ms-4">
              <a
                onClick={() => setActionToIssueGiftCardType(selectedAction)}
                className="d-block h5 font-semibold mb-1 stretched-link cursor-pointer"
              >
                Issue gift card
              </a>
              <p className="text-sm">
                Issue a gift card that can be redeemed in-store and online.
              </p>
            </div>
            <div className="ms-auto text-end align-self-center">
              <i className="bi bi-chevron-right"></i>
            </div>
          </div>
          <div className="list-group-item d-flex">
            <div>
              <div className="icon text-tertiary">
                <i className="bi bi-clock"></i>
              </div>
            </div>
            <div className="ms-4">
              <a
                onClick={() => setActionToDelayType(selectedAction)}
                className="d-block h5 font-semibold mb-1 stretched-link cursor-pointer"
              >
                Add delay
              </a>
              <p className="text-sm">Add a delay before action is executed.</p>
            </div>
            <div className="ms-auto text-end align-self-center">
              <i className="bi bi-chevron-right"></i>
            </div>
          </div>
        </div>
      );
    } else if (action.name === "IssueGiftCard") {
      return <><>Issue gift card</>{renderEmailAction(action.properties)}</>;
    } else {
      return (
        <>
          <>Delay</>
          {renderDelayAction()}
        </>
      );
    }
  };

  const renderEmailAction = (emailAction : any) => {
    if(!emailAction){
      emailAction = {};
    }
    return (
      <>
        <div className="">
              <div className="col-md-12">
                <div className="">
                <div className="d-flex align-items-center">
                <h5>Email Subject</h5>
              </div>
                  <Form.Control
                    name={"email_subject"}
                    className="form-control"
                    type="text"
                    value={emailAction.email_subject}
                    // onChange={(e) =>
                    //   handleDelayChange(
                    //     e,
                    //     selectedCase.id!,
                    //   )
                    // }
                    required
                  />
                </div>
              </div>
        </div>
      </>
    );
  };

  const renderDelayAction = () => {
    var delaySettings = selectedCase.delaySettings as WorkflowCaseDelaySettings;
    if (!delaySettings) {
      delaySettings = { delayAmount: 30, delayType: "Day" };
    }
    return (
      <>
        <div className="">
          {delaySettings && (
            <div className="row g-5 pb-5 condition all-conditions pt-3">
              <div className="d-flex align-items-center">
                <h5>Type</h5>
              </div>
              <div className="col-md-12">
                <Form.Select
                  name="delayType"
                  onChange={(e) =>
                    handleDelayChange(
                      e,
                      selectedCase.id!,
                    )
                  }
                  value={delaySettings.delayType}
                  required
                >
                  {delayOperatorOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="col-md-12">
                <div className="">
                  <Form.Control
                    name={"delayAmount"}
                    className="form-control"
                    type="number"
                    value={delaySettings.delayAmount?.toString()}
                    onChange={(e) =>
                      handleDelayChange(
                        e,
                        selectedCase.id!,
                      )
                    }
                    required
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderFlowCase = (channel: WorkflowCase, caseIndex: number) => {
    console.log("renderFlowCase", channel.conditions?.all);
    if (
      channel.conditions &&
      channel.conditions.all &&
      channel.conditions.all.length > 0
    ) {
      return (
        <div key={"flow-case" + caseIndex} className="list-group">
          <div className="Flow-Case">
            <div className="Flow-Box Flow-Box__Yes-Label Flow-Box__Bottom-Line">
              <div className="Flow-Box__Content Flow-Box__Yes-Label">
                <h1>if</h1>

                <div
                  className={`Flow-Box-Button ${
                    channel.id === selectedBoxOutlineId
                      ? "Flow-Box-Button__button-selected"
                      : ""
                  }`}
                >
                  <i
                    onClick={() => deleteCaseBlock(caseIndex)}
                    className="bi bi-x"
                  ></i>
                  <button
                    onClick={() => loadConditionsPanel(channel)}
                    type="button"
                    className="shop-remove w-full"
                  >
                    <span className="button_content">
                      <span>
                        {channel &&
                          channel.conditions?.all.map((condition, i) => (
                            <div className="conditions all-conditions">
                              <span className="flow-box-button-item">
                                {getConditionNameByValue(condition.name!)}{" "}
                                <span style={{ fontWeight: "600" }}>
                                  {getOperatorNameByValue(
                                    condition.operator!
                                  ).toLowerCase()}
                                </span>{" "}
                                <span
                                  style={{
                                    fontWeight: "600",
                                    color: "#5865c2",
                                  }}
                                >
                                  {condition.numberValue}
                                </span>
                              </span>
                            </div>
                          ))}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="Flow-Box__No-Label">No</div>
            </div>
            <div className="Flow-Box">
              <div className="Flow-Box__Content">
                <h1>then</h1>
                {channel &&
                  channel.actions?.map((a, i) =>
                    renderAction(channel, a, caseIndex)
                  )}
                <div className="Flow-Box-Button">
                  <button
                    type="button"
                    className="w-full"
                    onClick={() => appendActionBlock(channel, caseIndex)}
                  >
                    <span className="button_content">
                      <span>Add action</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div key={"flow-case" + caseIndex} className="list-group">
        <div className="Flow-Case">
          <div className="Flow-Box">
            <div className="Flow-Box__Content">
              <h1>then</h1>
              {(!channel.actions ||
                (channel.actions && channel.actions.length <= 0)) && (
                <div className="Flow-Box-Button">
                  <button
                    onClick={() => appendCaseBlock(channel)}
                    type="button"
                    className="w-full"
                  >
                    <span className="button_content">
                      <span>Add condition </span>
                    </span>
                  </button>
                </div>
              )}
              {channel &&
                channel.actions?.map((a, i) => renderAction(channel, a, i))}
              <div className="Flow-Box-Button">
                <button
                  onClick={() => appendActionBlock(channel, caseIndex)}
                  type="button"
                  className="w-full"
                >
                  <span className="button_content">
                    <span>Add action</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const delayOperatorOptions = [
    { value: "day", name: "Day(s)" },
    { value: "hour", name: "Hour(s)" },
    { value: "date", name: "Date" },
  ];

  const conditionOperatorOptions = [
    { value: "greaterThanInclusive", name: "Equal / greater than" },
    { value: "greaterThan", name: "Greater than" },
    { value: "lessThanInclusive", name: "Equal / less than" },
    { value: "lessThan", name: "Less than" },
    { value: "equal", name: "Is equal to" },
    { value: "notEqual", name: "Is not equal to" },
  ];

  const getOperatorNameByValue = (value: string) => {
    const option = conditionOperatorOptions.find(
      (option) => option.value === value
    );
    return option ? option.name : "";
  };

  const conditionNameOptions = [
    { value: "OrderTotalPrice", name: "Order total price" },
    { value: "OrderSubtotalPrice", name: "Order subtotal price" },
    { value: "CustomerTotalSpent", name: "Customer total spent" },
    { value: "CustomerOrdersCount", name: "Customer orders count" },
    { value: "ProductIds", name: "Product ids" },
  ];

  const getConditionNameByValue = (value: string) => {
    const option = conditionNameOptions.find(
      (option) => option.value === value
    );
    return option ? option.name : "";
  };

  let handleConditionChange = (
    event: any,
    i: number,
    useCaseId: string,
    conditionIdToUpdate: string
  ) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    // let newVariants = [...model.cases!];
    // newVariants[i][name] = value;

    // setModel((values: GiftCardProductViewModel) => ({
    //   ...values,
    //   variants: newVariants,
    // }));

    const updatedUseCaseData = model.cases?.map((useCase) => {
      if (useCase.id === useCaseId) {
        if (useCase.conditions && useCase.conditions.all) {
          useCase.conditions.all = useCase.conditions.all.map((condition) => {
            if (condition.id === conditionIdToUpdate) {
              return { ...condition, [name]: value };
            }
            return condition;
          });
        }
      }
      return useCase;
    });

    setModel({ ...model, cases: updatedUseCaseData });
  };

  let handleDelayChange = (
    event: any,
    useCaseId: string,
  ) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    // let newVariants = [...model.cases!];
    // newVariants[i][name] = value;

    // setModel((values: GiftCardProductViewModel) => ({
    //   ...values,
    //   variants: newVariants,
    // }));

    const updatedUseCaseData = model.cases?.map((useCase) => {
      if (useCase.id === useCaseId) {
        if(!useCase.delaySettings){
          useCase.delaySettings = {};
        }
          useCase.delaySettings!.delayAmount = value;
      }
      return useCase;
    });

    setModel({ ...model, cases: updatedUseCaseData });
  };

  return (
    <React.Fragment>
      <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
        <div className="container-fluid py-4">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-flex align-items-center gap-4">
                <div>
                  <Link
                    className="bi bi-chevron-left text-xs"
                    to={`/workflows`}
                  ></Link>
                </div>
                <div className="vr opacity-20 my-1"></div>
                <h1 className="h4 ls-tight">Workflow design: {model.title}</h1>
              </div>
            </div>
            <div className="col-auto">
              <div className="hstack gap-2 justify-content-end">
                {hasUnsavedChanges && (
                  <div className="text-sm text-muted font-semibold me-2 d-none d-md-block">
                    <span className="d-none d-sm-inline ms-2">
                      You have unsaved changes
                    </span>
                  </div>
                )}
                <a
                  type="button"
                  className="btn btn-neutral btn-sm"
                  target="_blank"
                  href={`https://manage.wrappedgiftcards.com/pc/check-balance/${authContext.account?.accountId}`}
                >
                  <span>Test workflow</span>
                </a>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={saveChanges}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="d-xl-flex row">
        <div className=" bg-surface-secondary col-md-3"></div>
        <div className="col-md-9 flex-xl-none h-xl-calc position-sticky top-xl-18 bg-surface-secondary">
          <aside className="p-8 h-full overflow-y-xl-auto">
            <div className="vstack gap-6">
              <div className="BuilderPageContainer">
                <div className="BuilderPageContent">
                  <div className="">
                    <div>
                      <div className="Flow-Box Flow-Box__Bottom-Line">
                        <div className="Flow-Box__Content">
                          <h1>start when</h1>
                          <div className="Flow-Box-Button">
                            {/* <i className="bi bi-x"></i> */}
                            <button
                              type="button"
                              className="shop-remove w-full"
                              style={{ cursor: "default;" }}
                            >
                              <span className="button_content">
                                <span>Order paid</span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      {model &&
                        model.cases?.map((casea, i) =>
                          renderFlowCase(casea, i)
                        )}
                    </div>
                  </div>
                </div>
                <Offcanvas
                  show={showConditionPanel}
                  onHide={() => setShowConditionPanel(false)}
                  scroll={true}
                  backdrop={false}
                >
                  <Offcanvas.Header className="border-bottom" closeButton>
                    <Offcanvas.Title>Select conditions:</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="conditions">
                      {selectedCase &&
                        selectedCase.conditions?.all?.map((condition, i) => (
                          <div
                            key={"condition" + condition.id}
                            className="row g-5 pb-5 condition all-conditions pt-3"
                          >
                            <div className="d-flex align-items-center">
                              <h5>If</h5>
                              {i > 0 && (
                                <button
                                  onClick={(e) => deleteCondition(condition)}
                                  type="button"
                                  className="btn btn-sm btn-neutral ms-auto"
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              )}
                            </div>
                            <div className="col-md-12">
                              <Form.Select
                                name="name"
                                onChange={(e) =>
                                  handleConditionChange(
                                    e,
                                    i,
                                    selectedCase.id!,
                                    condition.id!
                                  )
                                }
                                value={condition.name}
                                required
                              >
                                {/* <option value={""}>Choose...</option> */}
                                {conditionNameOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="col-md-12">
                              <Form.Select
                                name="operator"
                                onChange={(e) =>
                                  handleConditionChange(
                                    e,
                                    i,
                                    selectedCase.id!,
                                    condition.id!
                                  )
                                }
                                value={condition.operator}
                                required
                              >
                                {conditionOperatorOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="col-md-12">
                              <div className="">
                                <Form.Control
                                  name={"numberValue"}
                                  className="form-control"
                                  type="number"
                                  value={condition.numberValue?.toString()}
                                  onChange={(e) =>
                                    handleConditionChange(
                                      e,
                                      i,
                                      selectedCase.id!,
                                      condition.id!
                                    )
                                  }
                                  required
                                />
                              </div>
                            </div>
                            {i !==
                              selectedCase.conditions?.all?.length! - 1 && (
                              <div className="and-condition-divider" />
                            )}
                          </div>
                        ))}
                      <hr />
                      <div className="row g-5">
                        <div className="col-md-12">
                          <button
                            type="button"
                            onClick={() => appendConditionBlock(selectedCase)}
                            className="btn btn-neutral"
                          >
                            <i className="bi bi-plus pr-5"></i>Add another
                            condition
                          </button>
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas
                  show={showActionPanel}
                  onHide={() => setShowActionPanel(false)}
                  scroll={true}
                  backdrop={false}
                >
                  <Offcanvas.Header className="border-bottom" closeButton>
                    <Offcanvas.Title>
                      Action: ({selectedAction?.id})
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    {renderActionSettings(selectedAction)}
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WorkflowDesignPage;

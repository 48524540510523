/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MetabaseReportUrlModel } from '../models';
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [automationId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetAutomationReportsDashboardUrlGet: async (automationId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Reports/GetAutomationReportsDashboardUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (automationId !== undefined) {
                localVarQueryParameter['automationId'] = automationId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [giftCardBatchId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetGiftCardBatchReportsDashboardUrlGet: async (giftCardBatchId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Reports/GetGiftCardBatchReportsDashboardUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (giftCardBatchId !== undefined) {
                localVarQueryParameter['giftCardBatchId'] = giftCardBatchId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetGiftCardReportsDashboardUrlGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Reports/GetGiftCardReportsDashboardUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [automationId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetRevenueReportByAutomationIdDashboardUrlGet: async (automationId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Reports/GetRevenueReportByAutomationIdDashboardUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (automationId !== undefined) {
                localVarQueryParameter['automationId'] = automationId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [batchId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetRevenueReportByBatchIdDashboardUrlGet: async (batchId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Reports/GetRevenueReportByBatchIdDashboardUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [campaignId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetRevenueReportByCampaignIdDashboardUrlGet: async (campaignId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Reports/GetRevenueReportByCampaignIdDashboardUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [giftCardId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetRevenueReportByGiftCardIdDashboardUrlGet: async (giftCardId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Reports/GetRevenueReportByGiftCardIdDashboardUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (giftCardId !== undefined) {
                localVarQueryParameter['giftCardId'] = giftCardId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [automationId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetAutomationReportsDashboardUrlGet(automationId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetabaseReportUrlModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetAutomationReportsDashboardUrlGet(automationId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [giftCardBatchId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetGiftCardBatchReportsDashboardUrlGet(giftCardBatchId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetabaseReportUrlModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetGiftCardBatchReportsDashboardUrlGet(giftCardBatchId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetGiftCardReportsDashboardUrlGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetabaseReportUrlModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetGiftCardReportsDashboardUrlGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [automationId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetRevenueReportByAutomationIdDashboardUrlGet(automationId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetabaseReportUrlModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetRevenueReportByAutomationIdDashboardUrlGet(automationId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [batchId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetRevenueReportByBatchIdDashboardUrlGet(batchId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetabaseReportUrlModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetRevenueReportByBatchIdDashboardUrlGet(batchId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [campaignId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetRevenueReportByCampaignIdDashboardUrlGet(campaignId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetabaseReportUrlModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetRevenueReportByCampaignIdDashboardUrlGet(campaignId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [giftCardId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetRevenueReportByGiftCardIdDashboardUrlGet(giftCardId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetabaseReportUrlModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetRevenueReportByGiftCardIdDashboardUrlGet(giftCardId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [automationId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetAutomationReportsDashboardUrlGet(automationId?: string, apiVersion?: string, options?: any): AxiosPromise<MetabaseReportUrlModel> {
            return localVarFp.reportsGetAutomationReportsDashboardUrlGet(automationId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [giftCardBatchId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetGiftCardBatchReportsDashboardUrlGet(giftCardBatchId?: string, apiVersion?: string, options?: any): AxiosPromise<MetabaseReportUrlModel> {
            return localVarFp.reportsGetGiftCardBatchReportsDashboardUrlGet(giftCardBatchId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetGiftCardReportsDashboardUrlGet(apiVersion?: string, options?: any): AxiosPromise<MetabaseReportUrlModel> {
            return localVarFp.reportsGetGiftCardReportsDashboardUrlGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [automationId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetRevenueReportByAutomationIdDashboardUrlGet(automationId?: string, apiVersion?: string, options?: any): AxiosPromise<MetabaseReportUrlModel> {
            return localVarFp.reportsGetRevenueReportByAutomationIdDashboardUrlGet(automationId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [batchId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetRevenueReportByBatchIdDashboardUrlGet(batchId?: string, apiVersion?: string, options?: any): AxiosPromise<MetabaseReportUrlModel> {
            return localVarFp.reportsGetRevenueReportByBatchIdDashboardUrlGet(batchId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [campaignId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetRevenueReportByCampaignIdDashboardUrlGet(campaignId?: string, apiVersion?: string, options?: any): AxiosPromise<MetabaseReportUrlModel> {
            return localVarFp.reportsGetRevenueReportByCampaignIdDashboardUrlGet(campaignId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [giftCardId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetRevenueReportByGiftCardIdDashboardUrlGet(giftCardId?: string, apiVersion?: string, options?: any): AxiosPromise<MetabaseReportUrlModel> {
            return localVarFp.reportsGetRevenueReportByGiftCardIdDashboardUrlGet(giftCardId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @param {string} [automationId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetAutomationReportsDashboardUrlGet(automationId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsGetAutomationReportsDashboardUrlGet(automationId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [giftCardBatchId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetGiftCardBatchReportsDashboardUrlGet(giftCardBatchId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsGetGiftCardBatchReportsDashboardUrlGet(giftCardBatchId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetGiftCardReportsDashboardUrlGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsGetGiftCardReportsDashboardUrlGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [automationId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetRevenueReportByAutomationIdDashboardUrlGet(automationId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsGetRevenueReportByAutomationIdDashboardUrlGet(automationId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [batchId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetRevenueReportByBatchIdDashboardUrlGet(batchId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsGetRevenueReportByBatchIdDashboardUrlGet(batchId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [campaignId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetRevenueReportByCampaignIdDashboardUrlGet(campaignId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsGetRevenueReportByCampaignIdDashboardUrlGet(campaignId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [giftCardId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetRevenueReportByGiftCardIdDashboardUrlGet(giftCardId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsGetRevenueReportByGiftCardIdDashboardUrlGet(giftCardId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { useState, useEffect, useContext } from "react";
import { AccountApi } from "../../apiclient";
import { SwitchAccountListItem, SwitchAccountLoginResult } from "../../apiclient/models";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button, Row, Col, InputGroup, ListGroup } from "react-bootstrap";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { useNavigate } from "react-router-dom";

const SwitchAccountModal = (props: any) => {
  let navigate = useNavigate();
  let accountApi = new AccountApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
  const [model, setModel] = useState([] as SwitchAccountListItem[]);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    accountApi.accountGetSwitchAccountListGet().then((response) => {
      setModel(response.data);
    });
  };

  const LoginToAccount = (account: SwitchAccountListItem) => {
    accountApi
      .accountLoginToAccountGet(account.accountId)
      .then((response) => {
        var loginResult = response.data as SwitchAccountLoginResult;
        toast.success(`You have logged into account: ${account.companyName}`);
        authContext.refresh();
        navigate(loginResult.redirectUrl ?? "/dashboard");
      })
      .catch((error: any) => {
        toast.error(`Unable to login to account: ${account.companyName}`);
      });
  }

  return (

    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Switch account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {model && model?.map((account, i) => (
              <ListGroup.Item key={"account-" + i} action onClick={() => LoginToAccount(account)}>{account.companyName} <i className="bi bi-chevron-right float-end"></i></ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SwitchAccountModal;

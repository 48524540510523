import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Table,
  DropdownButton,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { AutomationApi } from "../../apiclient";
import {
  AutomationListItem,
  Automation
} from "../../apiclient/models";
import _ from "lodash";
import AnalyticsPanelComponent from "../analytics/AnalyticsPanelComponent";
import GiftCardCsvUploadModalComponent from "../giftcards/GiftCardCsvUploadModalComponent";
import GridTable from "../../components/GridTable";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
import * as Swal from "sweetalert2";

const AutomationListPage = () => {
  let navigate = useNavigate();

  let automationApi = new AutomationApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [loading, setLoading] = useState(true as boolean);
  const authContext = useContext(AuthContext);
  //const dayjs = require("dayjs");
  const [model, setModel] = useState([] as AutomationListItem[]);
  const [createButtonText, setCreateButtonText] = useState("Create automation" as string);

  useEffect(() => {
    refreshList();
  }, []);

  const createNewAutomation = () => {
    setCreateButtonText("Creating...");
    automationApi.automationCreateAutomationPost()
      .then((response) => {
        var data = response.data as Automation;
        navigate(`/automations/edit/${data.id}`);
        setCreateButtonText("Creating automation");
      }).catch((error) => {
        toast.error("Failed to create automation");
        setCreateButtonText("Creating automation");
      });
  }

  const refreshList = () => {
    automationApi.automationListAutomationsGet().then((response) => {
      var data = response.data as AutomationListItem[];
      setModel(data);
    });
  }

  const deleteAutomation = (automation: AutomationListItem) => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `This will delete the automation. Note: this will not delete any generated gift cards or credits.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete automation",

        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          automationApi.automationDeleteAutomationIdDelete(automation.automationId!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Automation deleted.");
                refreshList();
              } else {
                toast.error("Couldn't delete automation.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't delete automation.");
            });
        }
      });
  };

  const activateAutomation = (automation: AutomationListItem) => {
    Swal.default
      .fire({
        title: "Activate automation?",
        text: `This will activate the automation`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, activate automation",
      })
      .then((result) => {
        if (result.isConfirmed) {
          automationApi.automationEnableAutomationIdPut(automation.automationId!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Automation activated.");
                refreshList();
              } else {
                toast.error("Couldn't activate automation.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't activate automation.");
            });
        }
      });
  };

  const disableAutomation = (automation: AutomationListItem) => {
    Swal.default
      .fire({
        title: "Disable automation?",
        text: `This will disable the automation. Note: this will not delete any generated gift cards or credits.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, disable automation",

        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          automationApi.automationDisableAutomationIdPut(automation.automationId!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Automation disabled.");
                refreshList();
              } else {
                toast.error("Couldn't disable automation.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't disable automation.");
            });
        }
      });
  };

  const getStatusBadge = (automation: AutomationListItem) => {
    var cardStatus = automation.automationStatus;
    var badgeColor = "bg-secondary text-dark";
    if (cardStatus == "Active") {
      badgeColor = "bg-success text-success";
    }
    return (<span
      className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
    >
      {cardStatus}
    </span>)
  }

  return (
    <React.Fragment>
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  {(authContext.account?.userRole == "Admin" ||
                    authContext.account?.userRole == "Owner") && (
                      <>
                        <button
                          type="button"
                          className="float-end ms-2 btn btn-primary btn-sm"
                          onClick={createNewAutomation}
                        >
                          {createButtonText}
                        </button>

                      </>
                    )}
                  <h1 className="h2 ls-tight">Automations</h1>
                </div>
                <p className="text-sm pb-5">
                  Create and manage gift card automations
                </p>
              </div>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid vstack gap-3">
            <div className="bg-light">
              <div className="col-sm-12 col-12">

                {model && model.length == 0 &&
                  <>
                    <p className="text-semi-muted p-5 text-sm">No automations running. <a className="text-link cursor-pointer" onClick={createNewAutomation}>Create one?</a></p>
                  </>}
                {model && model.length > 0 &&
                  <>

                    <Table striped bordered hover>
                      <thead className="table-light">
                        <tr>
                          <th>Automation Name</th>
                          <th>Trigger</th>
                          <th>Credit Issued </th>
                          <th>Revenue</th>
                          <th>Created at</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {model &&
                          model.map((batch: AutomationListItem, i: number) => (
                            <>
                              <tr key={`row-batch-${batch.automationId}`}>
                                <td>
                                  <Link
                                    className="text-link active"
                                    to={`/automations/edit/${batch.automationId}`}
                                  >
                                    {batch.automationName}
                                  </Link>
                                </td>
                                <td>{batch.automationTrigger}</td>
                                <td>{batch.creditIssuedFormatted}</td>
                                <td>
                                <Link to={`/reporting/revenue/automation/${batch.automationId}`} className="text-link">
                                  {batch.revenueFormatted}
                                  </Link>
                                </td>
                                <td>
                                  <span>
                                    {dayjs(batch.createdAt)
                                      .utc()
                                      .tz(authContext?.account?.ianaTimezone)
                                      .format("YYYY-MM-DD hh:mm a")}
                                  </span>
                                </td>
                                <td>{getStatusBadge(batch)}</td>
                                <td>
                                  <DropdownButton
                                    key={`actions-menu-${batch.automationId}`}
                                    id="`actions-menu-${batch.automationId}`"
                                    title="Actions"
                                    variant="neutral"
                                    size="sm"
                                  >
                                    <Dropdown.Item onClick={() => navigate(`/automations/edit/${batch.automationId}`)}>
                                      Edit
                                    </Dropdown.Item>

                                    {batch.automationStatus == "Active" ?
                                      <>
                                        <Dropdown.Item onClick={() => disableAutomation(batch)}>
                                          Disable
                                        </Dropdown.Item>
                                      </> : <>
                                        <Dropdown.Item onClick={() => activateAutomation(batch)}>
                                          Activate
                                        </Dropdown.Item>
                                      </>}

                                    {/* <Dropdown.Item>
                                  View reporting
                                </Dropdown.Item> */}

                                    {/* <Dropdown.Item
                                  onClick={() =>
                                    window.open(
                                      `/Automation/ExportGiftCardBatchToCsv/${batch.automationId}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Export codes
                                </Dropdown.Item> */}

                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => deleteAutomation(batch)}>
                                      <span className="text-danger">Delete automation</span>
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </>
                }
              </div>
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default AutomationListPage;

/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const GiftCardReasonType = {
    Purchased: 'Purchased',
    Refund: 'Refund',
    Compensation: 'Compensation',
    Reward: 'Reward',
    Transfer: 'Transfer',
    Employee: 'Employee',
    Donation: 'Donation',
    Campaign: 'Campaign',
    Api: 'API',
    CsvImport: 'CsvImport',
    Import: 'Import',
    Other: 'Other',
    Workflow: 'Workflow',
    ConvertedInstoreOnly: 'ConvertedInstoreOnly',
    GeneratedFromBatch: 'GeneratedFromBatch',
    ImportedFromBatchCsv: 'ImportedFromBatchCsv',
    ExpiredGiftCard: 'ExpiredGiftCard',
    Automation: 'Automation',
    KlaviyoFlow: 'KlaviyoFlow'
} as const;

export type GiftCardReasonType = typeof GiftCardReasonType[keyof typeof GiftCardReasonType];




import React, { useState, FC } from 'react';

interface MaskedTextProps {
  text: string;
  maskChar?: string;
}

const MaskedText: FC<MaskedTextProps> = ({ text, maskChar = '*' }) => {
  const [isMasked, setIsMasked] = useState(true);

  const toggleMask = () => {
    setIsMasked(!isMasked);
  };

  const getMaskedText = (): string => {
    if (!isMasked) {
      return text;
    }
    const visiblePart = text.slice(0, 6);
    const maskedPart = maskChar.repeat(Math.max(0, text.length - 4));
    return visiblePart + maskedPart;
  };

  return (
    <span>
      <span>{getMaskedText()}</span>
      <button onClick={toggleMask} className="mx-2">
        {isMasked ? 'Show' : 'Hide'}
      </button>
    </span>
  );
};

export default MaskedText;
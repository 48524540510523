import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";

const GiftCardCsvUploadModalComponent = (props: any) => {
  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  const [validated, setValidated] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();

  const onFileInputChange = (event: any) => {
    let file = event.target.files[0];
    let extension = file.name.split(".").at(-1);
    let allowedFileExtensions = ["csv"];
    if (!allowedFileExtensions.includes(extension.toLowerCase())) {
      toast.error("Incorrect file format, failed to import.");
      // clear the file input
      event.target.value = null;
      return;
    }

    setUploadedFile(event.currentTarget.files[0]);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    giftcardApi
      .giftcardUploadGiftCardsCsvPost("1", uploadedFile)
      .then((response) => {
        toast.success("CSV file has been uploaded and is processing.", {duration: 5000});
        props.onHide();
      })
      .catch((error: any) => {
        toast.error("An error occurred uploading your CSV file.");
      });
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Upload gift cards csv file</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="">
                <label className="form-label" htmlFor="formInputExample">
                  Upload a CSV file with 2 columns: Code, Amount
                </label>
                <div className="card shadow-none border-2 border-dashed border-primary-hover position-relative">
                  <div className="d-flex justify-content-center px-5 py-5">
                    <label
                      htmlFor="file_upload"
                      className="position-absolute w-full h-full top-0 start-0 cursor-pointer"
                    >
                       <Form.Control
                            onChange={onFileInputChange}
                            type="file"
                            className=""
                            accept=".csv, text/csv"
                          />
                    </label>
                    {/* <div className="text-center">
                      <div className="text-2xl text-muted">
                        <i className="bi bi-upload"></i>
                      </div>
                      <div className="d-flex text-sm mt-3">
                        <p className="font-semibold">
                          Upload a file or drag and drop
                        </p>
                      </div>
                      <p className="text-xs text-gray-500">
                        CSV up to 3MB
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={props.onHide}>
              Close
            </Button>
            <Button size="sm" variant="primary" type="submit">
              Upload CSV file
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default GiftCardCsvUploadModalComponent;

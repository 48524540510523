import React, { useContext, useState, useEffect, useRef } from "react";
import TimezoneSelect from "react-timezone-select";
import { AssetApi, GiftcardApi } from "../../apiclient";
import {
  CheckBalancePageSettingsViewModel,
  GiftCardDetailViewModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Tabs,
  Tab,
  InputGroup,
} from "react-bootstrap";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import ContentEditable from "react-contenteditable";
import GiftCardMergeModalComponent from "../giftcards/GiftCardMergeModalComponent";
import GiftCardSendToFriendModalComponent from "../giftcards/GiftCardSendToFriendModalComponent";
import { Helmet } from "react-helmet";
import PoweredByFooter from "../../components/PoweredByFooter";
//import "./assets.css";
const PublicCheckBalancePage = () => {
  let { id } = useParams();
  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  let cardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [isLoading, setLoading] = useState(false as boolean);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showInvalidCodeError, setShowInvalidCodeError] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false as boolean);
  const [showGiftToFriendModal, setShowGiftToFriendModal] = useState(false as boolean);
  const [model, setModel] = useState({} as CheckBalancePageSettingsViewModel);
  const [giftCardModel, setGiftCardModel] = useState(
    {} as GiftCardDetailViewModel
  );
  const [showTab, setShowTab] = useState("main");
  const [searchParams, setSearchParams] = useSearchParams();
  const text = useRef("");
  const [giftCardCode, setGiftCardCode] = useState(
    searchParams.get("code") ?? ""
  );
  const [customHeadScript, setCustomHeadScript] = useState("");

  useEffect(() => {
    fetchData();
    fetchCustomScript();
  }, []);

  const fetchData = () => {
    assetApi
      .assetCheckBalancePagePublicIdGet(id!)
      .then((response) => {
        var data = response.data;
        setModel(data);
        setLoading(false);

        getGiftCardByCode();
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const fetchCustomScript = () => {
    assetApi
      .assetGetCustomEmbedScriptByTypeGet(id!, "CheckBalancePageHeadScript")
      .then((response) => {
        var data = response.data;
        if (data) {
          setCustomHeadScript(data!);
        }
      })
      .catch((error: any) => { });
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      getGiftCardByCode();
    }
  };

  const getGiftCardByCode = () => {
    if (giftCardCode) {
      cardApi
        .giftcardGetByCodeCodeGet(giftCardCode, id)
        .then((response) => {
          if (response.data) {
            setGiftCardModel(response.data);
          } else {
            toast.error("Invalid code.");
            setShowInvalidCodeError(true);
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred check code.");
        });
    }
  };

  const logout = () => {
    setGiftCardModel({});
    setGiftCardCode("");
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;
    setGiftCardCode(value);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <LoaderIcon />
        </>
      ) : (
        <div className="d-xl-flex row">
          <div className="col-md-12 flex-xl-none border-start-xl h-xl-calc position-sticky top-xl-18">
            <aside className="p-8 h-full overflow-y-xl-auto">
              <div className="vstack gap-6">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="">
                    <div className="container py-10">
                      <div className="row mb-10 text-center">
                        <div className="mx-auto">
                          {giftCardModel && giftCardModel.id ? (

                            <div className="card mb-5">
                              <div className="card-header d-flex align-items-center">
                                <div className="ms-auto">
                                  <button
                                    onClick={logout}
                                    className="btn btn-sm btn-neutral ms-2"
                                  >
                                    Check another card
                                  </button>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row g-5">
                                  <div className="col-12">
                                    <h5 className="font-semibold">

                                    </h5>
                                    <h2 className="font-semibold">
                                      {giftCardModel.formattedBalance}
                                    </h2>
                                    <h5 className="font-semibold mt-10">
                                      {model.codeInsertedPageExpirationText?.replace("{giftcard_expires_on}", "")}{" "}
                                      {giftCardModel.expiresOn ? giftCardModel.expiresOn : "never"}
                                    </h5>
                                    <h5 className="font-semibold mt-2">
                                      Code: {giftCardCode}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>

                          ) : (

                            <div className="card mb-5">
                              <div className="card-body">
                                <div className="row g-5">
                                  <div className="col-12">
                                    <h2 className="font-semibold">
                                      {model.mainPageTitleText}
                                    </h2>
                                    <div className="">
                                      <label className="form-label mt-5" dangerouslySetInnerHTML={{ __html: model.mainPageSubTitleText || '' }}>

                                      </label>
                                      <Form.Control
                                        name={"code"}
                                        className="form-control mt-5"
                                        type="text"
                                        placeholder={
                                          model.mainPagePlaceholderText
                                        }
                                        value={giftCardCode}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="d-grid gap-2 mx-auto">
                                    <button
                                      className="btn btn-primary btn-custom"
                                      type="submit"
                                    >
                                      {model.mainPageButtonText}
                                    </button>
                                  </div>
                                  {showInvalidCodeError && (
                                    <div className="font-semibold text-danger">
                                      {model.mainPageErrorText}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                          )}
                          {model && !model.hideWrappedBranding && (
                            <PoweredByFooter />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </aside>
          </div>
        </div>
      )}
      {model && <GiftCardMergeModalComponent show={showMergeModal} onHide={() => setShowMergeModal(false)} onRefresh={getGiftCardByCode} giftCard={giftCardModel} />}
      {model && <GiftCardSendToFriendModalComponent show={showGiftToFriendModal} onHide={() => setShowGiftToFriendModal(false)} onRefresh={getGiftCardByCode} giftCard={giftCardModel} />}
      <Helmet>
        {id && (
          <link rel="stylesheet" href={`Asset/checkbalancestyle.css?accountId=${id}`} />
        )}
        {customHeadScript && (
          <script>{`
            document.head.innerHTML += \`${customHeadScript}\`;
          `}</script>
        )}
      </Helmet>
    </React.Fragment>
  );
};

export default PublicCheckBalancePage;

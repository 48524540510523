import React, { useState, useEffect } from "react";
import { AccountApi } from "../../apiclient";
import {
  ForgotPasswordViewModel
} from "../../apiclient/models";
import { Button, Row, Col, Form } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import "./SignInPage.css";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { useNavigate } from "react-router";

const ForgotPasswordPage = () => {
  let accountApi = new AccountApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as ForgotPasswordViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [loginButtonText, setLoginButtonText] = useState("Get reset password link" as string);

  useEffect(() => {}, []);

  const handleSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }

    setValidated(true);
    if (form.checkValidity() === true) {
      setLoginButtonText("Sending..");
        accountApi
          .accountForgotPasswordPost("1", model)
          .then((response) => {
            toast.success("A link to reset your password has been emailed to you", {
              duration: 10000,
            });

            setIsSubmitting(false);
            setLoginButtonText("Sent");
            //setIsSubmitting(false);

            //reset form
            setTimeout(() => {
              //navigate("/account/login");
              setLoginButtonText("Get reset password link");
            }, 5000);

          })
          .catch((error: any) => {
            toast.error("Forgot password failed. Please try again", {
              duration: 10000,
            });
            setLoginButtonText("Get reset password link");
            setIsSubmitting(false);
          });
      }
    
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;

   
      setModel((values: ForgotPasswordViewModel) => ({
        ...values,
        [name]: value,
      }));
    
  };

  return (
    <React.Fragment>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="px-5 py-5 p-lg-0 min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-dark d-none d-lg-flex flex-column">
              {/* <!-- Logo --> */}
              <a className="d-block" href="https://wrappedgiftcards.com">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="..."
                />
              </a>
              {/* <!-- Title --> */}
              <div className="mt-32 mb-20">
                <h1 className="ls-tight font-bolder display-7 text-white mb-5">
                  Create and manage omnichannel gift cards
                </h1>
                <p className="text-white text-opacity-80">
                  Deliver a better customer experience with gift cards that can
                  be redeemed instore or online.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-screen d-flex flex-column justify-content-center position-relative">
              <div className="py-lg-16 px-lg-20">
                <div className="row">
                  <div className="col-lg-10 col-md-9 col-xl-7 mx-auto ms-xl-0">
                    <div className="mt-10 mt-lg-5 mb-6 d-lg-block">
                      <h1 className="ls-tight font-bolder h2">
                        Forgot Password
                      </h1>
                    </div>


                      <div className="login-box">
                        <div className="mb-5 mt-5">
                          {/* <label className="form-label" htmlFor="emailAddress">Email address</label>
                                        <input type="emailAddress" className="form-control" id="emailAddress" /> */}
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="emailAddress">
                              Enter email address to receive a link by email
                            </Form.Label>
                            <Form.Control
                              required
                              name={"emailAddress"}
                              value={model.emailAddress ?? ""}
                              className="form-control"
                              type="email"
                              placeholder="Email Address"
                              onChange={handleChange}
                            />
                          </Form.Group>
                          {/* <Form.Group className="mb-3">
                            <Form.Label htmlFor="password">Password </Form.Label> <span className="float-end form-label"><a href="" className="text-link">Forgot password?</a></span>
                            <Form.Control
                              required
                              name={"password"}
                              value={logInWithPasswordModel.password ?? ""}
                              className="form-control"
                              type="password"
                              onChange={handleChange}
                            />
                          </Form.Group> */}
                         
                        </div>
                        <div>
                          <Button
                            className="w-full"
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {loginButtonText}
                          </Button>
                        </div>
                      </div>
                    
                  </div>

                
                <div className="row mt-10">
                  <div className="col-12">
                    <a onClick={()=>navigate("/account/login?t=pw")} className="text-link cursor-pointer change-login-link">Back to log in</a>
                  </div>
                </div>
                </div>
    
              </div>
              <div className="position-lg-absolute bottom-0 end-0 my-8 mx-12 text-center text-lg-end">
                <small>Don't have an account?</small>
                <a
                  href="/account/signup"
                  className="text-sm font-semibold ml-5"
                >
                  Create a free account
                </a>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default ForgotPasswordPage;

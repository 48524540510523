import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { GiftCardBatchApi } from "../../apiclient";
import {
  CreateGiftCardsGenerateCodesBatchPostViewModel,
  CreateGiftCardsImportCsvBoxBatchPostViewModel,
  GiftCardBatchCsvBoxImportRecord,
} from "../../apiclient/models";
import { CSVBoxButton } from "@csvbox/react";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Util from "../../js/util";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const GiftCardBatchNewPage = () => {
  const navigate = useNavigate();
  let giftcardBatchApi = new GiftCardBatchApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const [generateGiftCardsModel, setGenerateGiftCardsModel] = useState(
    {} as CreateGiftCardsGenerateCodesBatchPostViewModel
  );
  const [uploadGiftCardsModel, setUploadGiftCardsModel] = useState(
    {} as CreateGiftCardsImportCsvBoxBatchPostViewModel
  );
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);

  const [generateType, setGenerateType] = useState("csv");
  const generateOptions = [
    { name: "Generate codes", value: "codes" },
    { name: "CSV file", value: "csv" },
  ];

  const [isCsvUpload, setIsCsvUpload] = useState<boolean>(false);

  useEffect(() => {
    if (uploadGiftCardsModel && uploadGiftCardsModel.records && uploadGiftCardsModel.records.length > 0) {
      console.log("uploadImporterCsvCodesToService");
      uploadImporterCsvCodesToService();
    }
  }, [uploadGiftCardsModel]);

  useEffect(() => {
    giftcardBatchApi
      .giftCardBatchInitCreateGiftCardGenerateCodesModelGet()
      .then((response) => {
        var data = response.data;
        setGenerateGiftCardsModel(data);
      });
  }, []);

  const handleGenerateCodesFormElementChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "hasExpiry") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }
    setGenerateGiftCardsModel(
      (values: CreateGiftCardsGenerateCodesBatchPostViewModel) => ({
        ...values,
        [name]: value,
      })
    );
  };

  const handleUploadCodesFormElementChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;

    setUploadGiftCardsModel(
      (values: CreateGiftCardsImportCsvBoxBatchPostViewModel) => ({
        ...values,
        [name]: value,
      })
    );

    if (value && value.length > 0) {
      setValidated(true);
    }
  };

  const handleUploadCodesSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    return true;
  };

  const uploadImporterCsvCodesToService = () => {
    setIsSubmitting(true);
    if (uploadGiftCardsModel && uploadGiftCardsModel.records && uploadGiftCardsModel.records.length > 0) {
      giftcardBatchApi
        .giftCardBatchCreateGiftCardsImportCsvBoxBatchPost(
          "1",
          uploadGiftCardsModel
        )
        .then((response) => {
          toast.success("Gift card batch created");
          setUnsavedChanges(false);
          setIsSubmitting(false);
          navigate("/bulk-gift-cards");
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving settings.");
          setIsSubmitting(false);
          setUnsavedChanges(false);
        });
    }
  }

  const handleGenerateCodesSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(true);

      console.log("generateGiftCardsModel", generateGiftCardsModel);

      giftcardBatchApi
        .giftCardBatchCreateGiftCardsGenerateCodesBatchPost(
          "1",
          generateGiftCardsModel
        )
        .then((response) => {
          toast.success("Gift card batch created");
          setUnsavedChanges(false);
          setIsSubmitting(false);
          navigate("/bulk-gift-cards");
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving settings.");
          setUnsavedChanges(false);
          setIsSubmitting(false);
        });
    }
  };

  const clearUploadedCsv = () => {
    setUploadGiftCardsModel(
      (values: CreateGiftCardsImportCsvBoxBatchPostViewModel) => ({
        ...values,
        ["records"]: [] as GiftCardBatchCsvBoxImportRecord[],
      })
    );
    setIsCsvUpload(false);
  };

  const handleUploadCsv = (data: GiftCardBatchCsvBoxImportRecord[]) => {
    setUploadGiftCardsModel(
      (values: CreateGiftCardsImportCsvBoxBatchPostViewModel) => ({
        ...values,
        ["records"]: data,
      })
    );
    setIsCsvUpload(true);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <Spinner animation="border" />
        </>
      ) : (
        <>
          <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
            <div className="container-fluid py-4">
              <div className="row align-items-center">
                <div className="col">
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <Link
                        className="bi bi-chevron-left text-xs"
                        to={`/bulk-gift-cards`}
                      ></Link>
                    </div>
                    <div className="vr opacity-20 my-1"></div>
                    <h1 className="h4 ls-tight">Create gift cards batch</h1>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="hstack gap-2 justify-content-end">
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="bg-surface-secondary">
            <div className="container py-10">
              <div className="row mb-10">
                <div className="col-lg-12">
                  <div className="card mb-5">
                    <div className="card-body">
                      <div className="row g-5">
                        <div className="col-12">
                          <h4 className="font-semibold mb-5">
                            How would you like to create your gift cards?
                          </h4>
                          <ButtonGroup>
                            {generateOptions.map((radio, idx) => (
                              <ToggleButton
                                key={idx}
                                size="sm"
                                id={`generateRadio-${idx}`}
                                type="radio"
                                variant={"outline-primary"}
                                name="radio"
                                value={radio.value}
                                checked={generateType === radio.value}
                                onChange={(e) =>
                                  setGenerateType(e.currentTarget.value)
                                }
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  {generateType === "codes" ? (
                    <div>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleGenerateCodesSubmit}
                      >
                        <div className="card mb-5">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-12">
                                <h4 className="font-semibold">
                                  Generate gift card settings:
                                </h4>
                                <div className="">
                                  <label
                                    className="form-label pt-3"
                                    htmlFor="batchName"
                                  >
                                    Batch name
                                  </label>
                                  <Form.Control
                                    name={"batchName"}
                                    className="form-control"
                                    type="text"
                                    value={generateGiftCardsModel.batchName}
                                    onChange={
                                      handleGenerateCodesFormElementChange
                                    }
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex mt-3">
                                  <div className="me-3">
                                    <Form.Check
                                      name="isPromotional"
                                      id="isPromotional"
                                      type={"checkbox"}
                                      checked={generateGiftCardsModel.isPromotional}
                                      onChange={handleGenerateCodesFormElementChange}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="isPromotional"
                                    >
                                      Used for promotional purposes?
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <label
                                    className="form-label pt-5"
                                    htmlFor="giftCardsValue"
                                  >
                                    Gift card value
                                  </label>
                                  <div className="input-group input-group-inline">
                                    <span className="input-group-text" id="">
                                      $
                                    </span>
                                    <Form.Control
                                      name={"giftCardsValue"}
                                      className="form-control"
                                      type="number"
                                      value={
                                        generateGiftCardsModel.giftCardsValue
                                      }
                                      onChange={
                                        handleGenerateCodesFormElementChange
                                      }
                                      placeholder="0.00"
                                      aria-label="0.00"
                                      required
                                    />
                                    <span className="input-group-text" id="">
                                      {authContext.account?.isoCurrencyCode}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <label
                                    className="form-label pt-5"
                                    htmlFor="numGiftCardsToGenerate"
                                  >
                                    Number of gift cards
                                  </label>
                                  <div className="input-group input-group-inline">
                                    <Form.Control
                                      name={"numGiftCardsToGenerate"}
                                      className="form-control"
                                      type="number"
                                      value={
                                        generateGiftCardsModel.numGiftCardsToGenerate
                                      }
                                      onChange={
                                        handleGenerateCodesFormElementChange
                                      }
                                      placeholder="0"
                                      aria-label="0"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row g-5  pt-5">
                              <div className="col-md-12">
                                <h5 className="font-semibold">
                                  Expiration date
                                </h5>
                                <p className="text-sm text-muted mb-5">
                                  Countries have different laws for gift card
                                  expiry dates. Check the laws for your country
                                  before changing this date.
                                </p>
                                <div className="d-flex mb-3">
                                  <div className="me-3">
                                    <Form.Check
                                      name="hasExpiry"
                                      type={"radio"}
                                      id={`hasExpiryNo`}
                                      checked={
                                        !generateGiftCardsModel.hasExpiry
                                      }
                                      onChange={
                                        handleGenerateCodesFormElementChange
                                      }
                                      value={"false"}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="hasExpiryNo"
                                    >
                                      No expiration date
                                    </label>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="me-3">
                                    <Form.Check
                                      name="hasExpiry"
                                      type={"radio"}
                                      id={`hasExpiryYes`}
                                      checked={generateGiftCardsModel.hasExpiry}
                                      onChange={
                                        handleGenerateCodesFormElementChange
                                      }
                                      value={"true"}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="hasExpiryYes"
                                    >
                                      Set expiration date
                                    </label>
                                  </div>
                                </div>
                                {generateGiftCardsModel.hasExpiry && (
                                  <div className="col-md-4">
                                    <label
                                      className="form-label pt-3"
                                      htmlFor="generatedGiftCardsExpiresAt"
                                    >
                                      Expires on
                                    </label>

                                    <Form.Control
                                      name={"generatedGiftCardsExpiresAt"}
                                      className="form-control"
                                      type="date"
                                      value={
                                        generateGiftCardsModel.generatedGiftCardsExpiresAt!
                                      }
                                      onChange={
                                        handleGenerateCodesFormElementChange
                                      }
                                      required
                                      disabled={
                                        !generateGiftCardsModel.hasExpiry
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              <hr className="mb-1" />
                              <div className="text-end">
                                <Link
                                  to={"/bulk-gift-cards"}
                                  className="btn btn-sm btn-neutral mr-10"
                                >
                                  Cancel
                                </Link>
                                <button
                                  type="submit"
                                  className="btn btn-sm btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Create batch
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  ) : (
                    <div>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleUploadCodesSubmit}
                      >
                        <div className="card mb-5">
                          <div className="card-body">
                            <div className="row g-5">
                              <div className="col-12">
                                <h4 className="font-semibold">
                                  Generate gift card settings:
                                </h4>
                                <div className="">
                                  <label
                                    className="form-label pt-3"
                                    htmlFor="batchName"
                                  >
                                    Batch name
                                  </label>
                                  <Form.Control
                                    name={"batchName"}
                                    className="form-control"
                                    type="text"
                                    value={uploadGiftCardsModel.batchName}
                                    onChange={
                                      handleUploadCodesFormElementChange
                                    }
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex mt-3">
                                  <div className="me-3">
                                    <Form.Check
                                      name="isPromotional"
                                      id="isPromotional"
                                      type={"checkbox"}
                                      checked={uploadGiftCardsModel.isPromotional}
                                      onChange={handleUploadCodesFormElementChange}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="isPromotional"
                                    >
                                      Used for promotional purposes?
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="mb-1" />
                            <div className="float-end pt-5">

                              <CSVBoxButton
                                key={"csvboxUpload"}
                                licenseKey={
                                  "jZuL1dKvfxbtFBID5v3t0QT9dKsxvj"
                                }
                                user={{
                                  company_name: `${authContext.account?.companyName}`,
                                  email: `${authContext.account?.emailAddress}`,
                                  name: `${authContext.account?.name}`,
                                  user_id: `${authContext.account?.userId}`,
                                  account_id: `${authContext.account?.accountId}`,
                                }}
                                render={(launch: any, isLoading: any) => {
                                  return (
                                    <>
                                      <Link
                                        to={"/bulk-gift-cards"}
                                        className="btn btn-sm btn-neutral mr-10"
                                      >
                                        Cancel
                                      </Link>
                                      <button
                                        type="submit"
                                        disabled={isLoading || isCsvUpload}
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                          if (validated) {
                                            launch();
                                          }
                                        }}
                                      >
                                        Upload CSV
                                      </button>
                                    </>
                                  );
                                }}
                                onImport={(result: any, data: any) => {
                                  if (result) {
                                    handleUploadCsv(data.rows);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default GiftCardBatchNewPage;

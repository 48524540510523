import React, { useContext, useState, useEffect } from "react";
import { RefundApi } from "../../apiclient";
import {
  ShopifyRefundViewModel,
  ShopifyRefundLineItemViewModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  InputGroup,
  FormCheck,
  Spinner,
} from "react-bootstrap";
import Gravatar from "react-gravatar";

const ShopifyRefundPage = () => {
  const [validated, setValidated] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const id = searchParams.get("id") ?? "";
  const shop = searchParams.get("shop") ?? "";
  let refundApi = new RefundApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as ShopifyRefundViewModel);
  useEffect(() => {
    getRefundDetails();
  }, []);

  useEffect(() => {
    recalcTotals();
  }, [model.lineItems, model.includeShipping, model.includeTax]);

  const recalcTotals = () => {
    var sum = 0;
    var quantity = 0 as number;
    if (model && model.lineItems) {
      model.lineItems.forEach((li) => {
        sum += Number(li.refundTotal!);
        quantity += Number(li.refundQuantity!);
      });

      var refundAmount = sum as number;
      if (model.includeTax) {
        refundAmount += model.totalTax!;
      }

      if (model.includeShipping) {
        refundAmount += model.totalShipping!;
      }

      setModel((values: ShopifyRefundViewModel) => ({
        ...values,
        refundSubTotal: sum,
        refundAmount: refundAmount,
        refundQuantity: quantity,
      }));
    }
  };

  const getRefundDetails = () => {
    setIsLoading(true);
    refundApi
      .refundShopifyRefundGet(id, shop)
      .then((response) => {
        setModel(response.data);
        setIsLoading(false);
      })
      .catch((error: any) => {});
  };

  const CurrencyFormatter = ({
    amount,
    currencyCode,
  }: {
    amount: number;
    currencyCode: string;
  }) => {
    if (currencyCode) {
      const formattedAmount = new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: currencyCode,
        currencyDisplay: "symbol",
      }).format(amount);

      return <span>{formattedAmount}</span>;
    } else {
      return <span>{amount}</span>;
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: ShopifyRefundViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  let handleRefundQuantityLineItemChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newVariants = [...model.lineItems!] as any;
    //recalc refund line total
    var lineItem = newVariants[i] as ShopifyRefundLineItemViewModel;
    newVariants[i]["refundTotal"] = lineItem.price! * value;
    newVariants[i]["refundQuantity"] = value;

    setModel((values: ShopifyRefundViewModel) => ({
      ...values,
      lineItems: newVariants,
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(true);

      refundApi
        .refundCreateShopifyRefundPost("1", model)
        .then((response) => {
          setIsSubmitting(false);
          toast.success("Refund created successfully!");
          //redirect to new gift card
          navigate(`/gift-cards/${response.data.giftCardId}?type=newrefund`);
        })
        .catch((error: any) => {
          toast.error("An error occurred when creating refund.");
        });
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-dark">
            <div className="flex-lg-1 h-screen overflow-y-lg-auto">
              <div className="py-6">
                <div className="col"></div>
                <div className="container-fluid vstack gap-6 col-9">
                  <div className="card">
                    <div className="card-body pb-0">
                      <div className="mb-3 align-items-center">
                        <h4 className="mb-0">
                          <Spinner /> Loading...
                        </h4>
                        <p className="mt-5">
                          Please ensure you are logged into Wrapped dashboard.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {model && (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div>
                <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-dark">
                  <div className="flex-lg-1 h-screen overflow-y-lg-auto">
                    <Toaster position={"top-right"} />
                    <div className="py-6">
                      <div className="col"></div>
                      <div className="container-fluid vstack gap-6 col-9">
                        <div className="card">
                          <div className="card-body pb-0">
                            <div className="mb-3 d-flex align-items-center">
                              <h4 className="mb-0">
                                Refund order {model.orderName}
                              </h4>
                              <a
                                href={`https://${shop}/admin/orders/${id}`}
                                className="text-link text-sm link-primary font-bold ms-auto text-end"
                              >
                                Return to Shopify order page
                              </a>
                            </div>
                            {model && model.customerEmail ? (
                              <div className="mb-4">
                                <Row>
                                  <Col md="7">
                                    <div className="mb-4">
                                      {model &&
                                        model.refundLineItems &&
                                        model.refundLineItems?.length > 0 && (
                                          <div
                                            className="alert alert-warning"
                                            role="alert"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div className="w-8 text-lg">
                                                <i className="bi bi-info-circle-fill"></i>
                                              </div>
                                              <span className="font-bold">
                                                Order contains refunded items
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="list-group mb-4">
                                      {model &&
                                        model.lineItems?.map((lineItem, i) => (
                                          <div
                                            key={"lineItem" + i}
                                            className="list-group-item d-flex align-items-center"
                                          >
                                            <div className="me-4">
                                              <div className="icon icon-lg icon-shape text-xl bg-opacity-30 bg-warning text-warning">
                                                <img
                                                  alt={
                                                    lineItem.title +
                                                    " - " +
                                                    lineItem.variantTitle
                                                  }
                                                  src={lineItem.imageUrl}
                                                />
                                              </div>
                                            </div>
                                            <div className="flex-fill">
                                              <span className="d-block h6 text-sm font-semibold mb-1">
                                                {lineItem.title + " "}
                                                <span className="text-muted">
                                                  - {lineItem.variantTitle}
                                                </span>
                                              </span>

                                              <span className="d-block h6 text-sm">
                                                <CurrencyFormatter
                                                  amount={lineItem?.price!}
                                                  currencyCode={
                                                    model?.currency!
                                                  }
                                                />
                                              </span>
                                            </div>
                                            <div className="ms-auto">
                                              <InputGroup className="mb-3">
                                                <Form.Control
                                                  name={"refundQuantity"}
                                                  className="form-control"
                                                  type="number"
                                                  value={
                                                    lineItem.refundQuantity!
                                                  }
                                                  max={lineItem.quantity!}
                                                  min={0}
                                                  onChange={(e) =>
                                                    handleRefundQuantityLineItemChange(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                  required
                                                  aria-label=""
                                                />
                                                <InputGroup.Text>
                                                  {" "}
                                                  / {lineItem.quantity}
                                                </InputGroup.Text>
                                              </InputGroup>
                                            </div>
                                            <div className="ms-4 text-end">
                                              <span className="h6 text-sm">
                                                <CurrencyFormatter
                                                  amount={
                                                    lineItem?.refundTotal!
                                                  }
                                                  currencyCode={
                                                    model?.currency!
                                                  }
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                      {model &&
                                        model.refundLineItems?.map(
                                          (lineItem, i) => (
                                            <div
                                              key={"lineItem" + i}
                                              className="list-group-item d-flex align-items-center"
                                            >
                                              <div className="me-4">
                                                <div className="icon icon-lg icon-shape text-xl bg-opacity-30 bg-warning text-warning">
                                                  <img
                                                    alt={
                                                      lineItem.title +
                                                      " - " +
                                                      lineItem.variantTitle
                                                    }
                                                    src={lineItem.imageUrl}
                                                  />
                                                </div>
                                              </div>
                                              <div className="flex-fill">
                                                <span className="d-block h6 text-sm font-semibold mb-1">
                                                  {lineItem.title + " "}
                                                  <span className="text-muted">
                                                    - {lineItem.variantTitle}
                                                  </span>
                                                </span>

                                                <span className="d-block h6 text-sm">
                                                  <CurrencyFormatter
                                                    amount={lineItem?.price!}
                                                    currencyCode={
                                                      model?.currency!
                                                    }
                                                  />
                                                  <span className="ml-10 badge text-uppercase bg-opacity-30 bg-danger text-danger">
                                                    Refunded
                                                  </span>
                                                </span>
                                              </div>
                                              <div className="ms-auto">
                                                <InputGroup className="mb-3">
                                                  <Form.Control
                                                    name={"refundQuantity"}
                                                    className="form-control"
                                                    type="number"
                                                    value={
                                                      lineItem.refundQuantity!
                                                    }
                                                    max={lineItem.quantity!}
                                                    min={0}
                                                    disabled={true}
                                                    aria-label=""
                                                  />
                                                  <InputGroup.Text>
                                                    {" "}
                                                    / {lineItem.quantity}
                                                  </InputGroup.Text>
                                                </InputGroup>
                                              </div>
                                              <div className="ms-4 text-end">
                                                <span className="h6 text-sm">
                                                  <CurrencyFormatter
                                                    amount={
                                                      lineItem?.refundTotal!
                                                    }
                                                    currencyCode={
                                                      model?.currency!
                                                    }
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      {model && model.refundAmount! > 0 && (
                                        <div className="list-group-item d-flex align-items-center">
                                          <div className="row align-items-center">
                                            <div className="col-md-12">
                                              <div className="form-check">
                                                <FormCheck
                                                  name="restockItems"
                                                  id="restockItems"
                                                  className=""
                                                  type="checkbox"
                                                  checked={model.restockItems!}
                                                  onChange={handleChange}
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="restockItems"
                                                >
                                                  Restock {model.refundQuantity}{" "}
                                                  item(s) at
                                                </label>
                                              </div>
                                            </div>
                                            {model &&
                                              model.restockItems === true && (
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Form.Select
                                                      name="restockItemSelectedSiteId"
                                                      onChange={handleChange}
                                                      value={
                                                        model.restockItemSelectedSiteId!
                                                      }
                                                      required
                                                    >
                                                      {model &&
                                                        model.sites?.map(
                                                          (site, i) => (
                                                            <option
                                                              key={"site-" + i}
                                                              value={
                                                                site.sourceId
                                                              }
                                                            >
                                                              {site.name}
                                                            </option>
                                                          )
                                                        )}
                                                    </Form.Select>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="list-group">
                                      <div className="list-group-item d-flex align-items-center">
                                        <div className="col-md-12">
                                          <div className="">
                                            <label
                                              className="form-label"
                                              htmlFor="internalNote"
                                            >
                                              Internal note
                                            </label>
                                            <Form.Control
                                              name={"internalNote"}
                                              className="form-control"
                                              type="text"
                                              value={model.internalNote}
                                              onChange={handleChange}
                                              required
                                            />
                                            <span className="d-block mt-2 text-sm text-muted">
                                              Will not be shared with the
                                              customer.
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="5">
                                    <div className="list-group mb-4">
                                      <div className="list-group-item d-flex align-items-center">
                                        <div className="me-4">
                                          <div className="avatar rounded-circle">
                                            <Gravatar
                                              email={model.customerEmail}
                                            />
                                            {/* <img
                                          alt="..."
                                          src="data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9IlBvbGFyaXNBdmF0YXIxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4KICAgIDxkZWZzPjxzdHlsZT4KICAgICAgICBbZGF0YS1uYW1lPSJQb2xhcmlzQXZhdGFyMSJdIC5jbHMtMXtmaWxsOiMyNDViNDh9CiAgICAgICAgW2RhdGEtbmFtZT0iUG9sYXJpc0F2YXRhcjEiXSAuY2xzLTJ7ZmlsbDojMmRiMTY3fQogICAgICAgIFtkYXRhLW5hbWU9IlBvbGFyaXNBdmF0YXIxIl0gLmNscy0ze2ZpbGw6I2ZmZn0KICAgICAgICBbZGF0YS1uYW1lPSJQb2xhcmlzQXZhdGFyMSJdIC5jbHMtNHtmaWxsOiM4ZGM5NTh9CiAgICAgICAgW2RhdGEtbmFtZT0iUG9sYXJpc0F2YXRhcjEiXSAuY2xzLTV7ZmlsbDojZWJlZGYxOwogICAgICAgIG9wYWNpdHk6LjJ9CiAgICA8L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0wIDBoMTAwdjEwMEgweiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTY5IDB2NjUuNjVsLTItLjAxdjE5Ljg1bDIgLjAyVjEwMEgwVjBoNjl6Ii8+PHBhdGggY2xhc3M9ImNscy0zIiBkPSJNNjcgNjUuNjR2MTkuODVsLTI1LjU3LS4xOUMzMiA4NS4yMiAyNS42IDgxLjQ2IDI1LjY4IDcyLjA2cy4yNS02Ljc0LjI1LTYuNzR6Ii8+PHBhdGggY2xhc3M9ImNscy00IiBkPSJNODYuOTkgNThINjlWMGgxMC45M2w3LjA2IDU4eiIvPjxwYXRoIGNsYXNzPSJjbHMtNSIgZD0iTTI0LjUzIDQwLjI5YTEyLjIzIDEyLjIzIDAgMCAxIDI0LjQ2IDAiLz48cGF0aCBjbGFzcz0iY2xzLTQiIGQ9Ik01OS42MiA1OEExMC42OSAxMC42OSAwIDAgMSA4MSA1OHoiLz48L3N2Zz4="
                                        /> */}
                                          </div>
                                        </div>
                                        <div className="flex-fill">
                                          <div className="d-block h6 font-semibold mb-1">
                                            {model.customerName}
                                          </div>
                                          <span className="d-block text-sm text-muted">
                                            {model.customerEmail}
                                          </span>
                                          <div className="d-block col-md-12">
                                            <div className="form-check">
                                              <FormCheck
                                                name="sendNotificationToCustomer"
                                                id="sendNotificationToCustomer"
                                                className=""
                                                type="checkbox"
                                                checked={
                                                  model.sendNotificationToCustomer!
                                                }
                                                onChange={handleChange}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="sendNotificationToCustomer"
                                              >
                                                Send a notification to the
                                                customer
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="list-group mb-4 border">
                                      {model.refundAmount! > 0 ? (
                                        <>
                                          <div className="list-group-item d-flex align-items-center border-0 pb-1">
                                            <div className="flex-fill">
                                              <div className="d-block h4 font-semibold mb-5">
                                                Summary
                                              </div>

                                              <span className="d-block text-sm mb-1">
                                                Items subtotal
                                              </span>
                                              <span className="d-block text-sm text-muted">
                                                {model.refundQuantity} item(s)
                                              </span>
                                            </div>
                                            <div className="ms-4 text-end">
                                              <span className="text-sm">
                                                {" "}
                                                <CurrencyFormatter
                                                  amount={
                                                    model?.refundSubTotal!
                                                  }
                                                  currencyCode={
                                                    model?.currency!
                                                  }
                                                />
                                              </span>
                                            </div>
                                          </div>
                                          {model &&
                                            model.discounts?.map(
                                              (discount, i) => (
                                                <div
                                                  key={"discount-" + i}
                                                  className="list-group-item d-flex align-items-center border-0 pb-1 pt-1"
                                                >
                                                  <div className="flex-fill">
                                                    <span className="d-block text-sm mb-1">
                                                      Discount
                                                    </span>
                                                    <span className="d-block text-sm text-muted">
                                                      {discount.code}
                                                    </span>
                                                  </div>
                                                  <div className="ms-4 text-end">
                                                    <span className="text-sm">
                                                      {" -"}
                                                      <CurrencyFormatter
                                                        amount={
                                                          discount?.amount!
                                                        }
                                                        currencyCode={
                                                          model?.currency!
                                                        }
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          {model?.includeTax && (
                                            <div className="list-group-item d-flex align-items-center border-0 pb-1 pt-1">
                                              <div className="flex-fill">
                                                <span className="d-block text-sm mb-1">
                                                  Tax
                                                </span>
                                              </div>
                                              <div className="ms-4 text-end">
                                                <span className="text-sm">
                                                  <CurrencyFormatter
                                                    amount={model?.totalTax!}
                                                    currencyCode={
                                                      model?.currency!
                                                    }
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          )}

                                          {model?.includeShipping && (
                                            <div className="list-group-item d-flex align-items-center border-0 pb-1 pt-1">
                                              <div className="flex-fill">
                                                <span className="d-block text-sm mb-1">
                                                  Shipping
                                                </span>
                                              </div>
                                              <div className="ms-4 text-end">
                                                <span className="text-sm">
                                                  <CurrencyFormatter
                                                    amount={
                                                      model?.totalShipping!
                                                    }
                                                    currencyCode={
                                                      model?.currency!
                                                    }
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          )}

                                          <div className="list-group-item d-flex align-items-center border-0 pt-1">
                                            <div className="flex-fill">
                                              <span className="d-block h6 text-sm mb-1">
                                                Total
                                              </span>
                                            </div>
                                            <div className="ms-4 text-end">
                                              <span className="h6 text-sm">
                                                <CurrencyFormatter
                                                  amount={model?.refundAmount!}
                                                  currencyCode={
                                                    model?.currency!
                                                  }
                                                />
                                              </span>
                                            </div>
                                          </div>
                                          <hr />

                                          <div className="list-group-item align-items-center border-0 pb-1 pt-1">
                                            <div className="d-block h4 font-semibold mb-5">
                                              Issue gift card
                                            </div>
                                            <div className="d-block col-md-12">
                                              <div className="form-check">
                                                <FormCheck
                                                  name="includeTax"
                                                  id="includeTax"
                                                  className=""
                                                  type="checkbox"
                                                  checked={model.includeTax!}
                                                  onChange={handleChange}
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="includeTax"
                                                >
                                                  Include tax (
                                                  <CurrencyFormatter
                                                    amount={model?.totalTax!}
                                                    currencyCode={
                                                      model?.currency!
                                                    }
                                                  />
                                                  )
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="list-group-item align-items-center border-0 pb-1">
                                            <div className="d-block col-md-12">
                                              <div className="form-check">
                                                <FormCheck
                                                  name="includeShipping"
                                                  id="includeShipping"
                                                  className=""
                                                  type="checkbox"
                                                  checked={
                                                    model.includeShipping!
                                                  }
                                                  onChange={handleChange}
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="includeShipping"
                                                >
                                                  Include shipping (
                                                  <CurrencyFormatter
                                                    amount={
                                                      model?.totalShipping!
                                                    }
                                                    currencyCode={
                                                      model?.currency!
                                                    }
                                                  />
                                                  )
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="list-group-item d-flex align-items-center border-0 pb-1">
                                            <div className="flex-fill">
                                              <div className="d-block h4 font-semibold mb-5">
                                                Summary
                                              </div>
                                              <span className="d-block text-sm text-muted">
                                                No items selected.
                                              </span>
                                            </div>
                                          </div>
                                          <hr />
                                          <div className="list-group-item align-items-center border-0 pb-1 pt-1">
                                            <div className="d-block h4 font-semibold mb-5">
                                              Issue gift card
                                            </div>
                                          </div>{" "}
                                        </>
                                      )}
                                      <div className="list-group-item align-items-center border-0 pb-1 pt-1">
                                        <div className="col-md-12">
                                          <div className="">
                                            <label
                                              className="form-label"
                                              htmlFor="refundAmount"
                                            >
                                              Refund amount
                                            </label>
                                            <div className="input-group input-group-inline">
                                              <span
                                                className="input-group-text"
                                                id=""
                                              >
                                                {model?.currency ?? "NZD"}
                                              </span>
                                              <Form.Control
                                                required
                                                name={"refundAmount"}
                                                value={model.refundAmount ?? ""}
                                                className="form-control"
                                                type="number"
                                                placeholder="0.00"
                                                onChange={handleChange}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="list-group-item align-items-center border-0 pb-1">
                                        <div className="d-block col-md-12">
                                          <div className="form-check">
                                            <FormCheck
                                              name="includeExpirationDate"
                                              id="includeExpirationDate"
                                              className=""
                                              type="checkbox"
                                              checked={
                                                model.includeExpirationDate!
                                              }
                                              onChange={handleChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="includeExpirationDate"
                                            >
                                              Add expiration date
                                            </label>
                                          </div>
                                        </div>
                                        {model &&
                                          model.includeExpirationDate && (
                                            <div>
                                              <label
                                                className="form-label pt-3"
                                                htmlFor="expiresOn"
                                              >
                                                Expires on
                                              </label>
                                              <Form.Control
                                                name={"expirationDate"}
                                                className="form-control"
                                                type="date"
                                                value={model.expirationDate!}
                                                onChange={handleChange}
                                                required
                                                disabled={
                                                  !model.includeExpirationDate
                                                }
                                              />
                                            </div>
                                          )}
                                      </div>
                                      <hr />
                                      <div className="list-group-item align-items-center border-0 pt-0">
                                        <div className="d-grid gap-2">
                                          <button
                                            className="btn btn-primary"
                                            type="submit"
                                            disabled={model.refundAmount! <= 0 || isSubmitting}
                                          >
                                            {isSubmitting?"Refunding":"Refund"}{" "}
                                            <CurrencyFormatter
                                              amount={model?.refundAmount!}
                                              currencyCode={model?.currency!}
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              <div className="mb-4">
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="w-8 text-lg">
                                      <i className="bi bi-info-circle-fill"></i>
                                    </div>
                                    <span className="font-bold">
                                      Email address needed!
                                    </span>
                                  </div>
                                  <div className="ps-8">
                                    <p className="text-sm">
                                      To process a refund as gift card, you need
                                      to add an email address to the order.
                                    </p>
                                    <a
                                      className="btn btn-primary mt-5"
                                      href={`https://${shop}/admin/orders/${id}`}
                                    >
                                      Return to order page
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default ShopifyRefundPage;

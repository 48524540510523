import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row, Badge } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
    EmailApi,
    VerifyDomainPayload,
    ProcessResultModel,
    SenderDomainInstallPageViewModel,
} from "../../apiclient";
import { apiConfig } from "../../config";
import moment from "moment";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";

const SenderDomainAuthenticateModalComponent = (props : any) => {
    let domainId = props.domainId;
    let settingsApi = new EmailApi(
        apiConfig.configuration,
        apiConfig.baseUrl
    );
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState({
        domainAuthOption: "CNAME",
    } as SenderDomainAuthenticationPageViewModel);

    const [authModel, setAuthModel] = useState(
        {} as SenderDomainInstallPageViewModel
    );

    const handleChange = (event: any) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = event.target.name;
        setModel((values: SenderDomainAuthenticationPageViewModel) => ({
            ...values,
            [name]: value,
        }));
    };

    const handleInstallChange = (event: any) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = event.target.name;
        setAuthModel((values: SenderDomainInstallPageViewModel) => ({
            ...values,
            [name]: value,
        }));
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            settingsApi
                .emailAuthenticateDomainPost("1", {
                    domainRegistrar: model.domainRegistrar,
                    domainAuthOption: model.domainAuthOption,
                    domainId: domainId,
                })
                .then(function (response) {
                    //fetch domain auth
                    fetchInstalledCheck();
                    props.refreshDomains();
                    setLoading(false);
                })
                .catch(function (error) {
                    toast.error("Domain could not be authenticated");
                    setLoading(false);
                });
        } else {
            setValidated(true);
        }
    };

    const fetchInstalledCheck = () => {
        setLoading(true);
        settingsApi
            .emailSenderDomainInstallIdGet(domainId, "1")
            .then(function (response) {
                var data = response.data as SenderDomainInstallPageViewModel;
                setAuthModel(data);
                setLoading(false);
            });
    };

    const submitDomainRecordsInstalledConfirmation = () => {
        setLoading(true);
        settingsApi
            .emailDomainUpdateDnsTimeIdGet(domainId, "1")
            .then(function (response) {
                var data = response.data as ProcessResultModel;
                setLoading(false);
                if (data.isSuccessful) {
                    toast.success(data.message!);
                    props.refreshDomains();
                    props.onHide();
                } else {
                    toast.error(data.message!);
                }
            });
    };

    const modalLoaded = () => {
        fetchInstalledCheck();
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(function () {
                toast.success("Copied to clipboard!");
            })
            .catch(function (error) {
                toast.error("Failed to copy to clipboard");
            });
    };

    return (
        <React.Fragment>
            <Modal
                size="xl"
                show={props.isOpen}
                onHide={props.onHide}
                onEntered={() => modalLoaded()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Domain Authentication</Modal.Title>
                    {loading && (
                        <div className="float-end">
                            <LoaderIcon className="mr-20" />
                        </div>
                    )}
                </Modal.Header>

                {authModel &&
                    (!authModel.isSuccessful ? (
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                        >
                            <Modal.Body>
                                <Row className="mb-3">
                                    <p>What is your domain provider?</p>
                                    <Form.Group className="mt-20">
                                        <Form.Label>Domain Provider</Form.Label>
                                        <Form.Control
                                            name="domainRegistrar"
                                            type="text"
                                            placeholder=""
                                            value={model.domainRegistrar}
                                            onChange={handleChange}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Required.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mt-20">
                                        <Form.Label>
                                            Choose authentication type which
                                            suits your domain provider
                                        </Form.Label>

                                        <Form.Check
                                            id="domainAuthOptionCname"
                                            name="domainAuthOption"
                                            type="radio"
                                            checked={
                                                model.domainAuthOption ===
                                                "CNAME"
                                            }
                                            value="CNAME"
                                            onChange={handleChange}
                                            label="CNAME"
                                        />
                                        <Form.Check
                                            id="domainAuthOptionMX"
                                            name="domainAuthOption"
                                            type="radio"
                                            checked={
                                                model.domainAuthOption === "MX"
                                            }
                                            value="MX"
                                            onChange={handleChange}
                                            label="MX"
                                        />
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                size="sm" 
                                    variant="secondary"
                                    onClick={props.onHide}
                                >
                                    Cancel
                                </Button>
                                {model.domainRegistrar ? (
                                    <Button size="sm" variant="primary" type="submit">
                                        Authenticate
                                    </Button>
                                ) : (
                                    <Button size="sm" variant="primary" disabled>
                                        Authenticate
                                    </Button>
                                )}
                            </Modal.Footer>
                        </Form>
                    ) : (
                        <>
                            <Modal.Body>
                                <p>Make sure you have updated your DNS with the following. It may take up to 24 hours for the changes to be applied and your domain to be authenticated with Wrapped.</p>
                                <table className="table table-lg">
                                    <thead>
                                        <tr>
                                            <th scope="col">Status</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Host</th>
                                            <th scope="col">Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {authModel.dns &&
                                            authModel.dns.map((dns, i) => (
                                                <tr>
                                                    <th scope="row">
                                                        {dns.isValid ? (
                                                            <Badge bg="success">
                                                                Verified
                                                            </Badge>
                                                        ) : (
                                                            <Badge bg="info">
                                                                Pending
                                                            </Badge>
                                                        )}
                                                    </th>
                                                    <td>{dns.type}</td>
                                                    <td className="host">
                                                        <div className="form-group m-b-0">
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={
                                                                        dns.key +
                                                                        "host"
                                                                    }
                                                                    value={
                                                                        dns.host
                                                                    }
                                                                    disabled
                                                                />
                                                                <span className="input-group-append">
                                                                    <button
                                                                        onClick={() => {
                                                                            copyToClipboard(
                                                                                dns.host ??
                                                                                    ""
                                                                            );
                                                                        }}
                                                                        className="btn btn-neutral btn-icon"
                                                                    >
                                                                        <span className="bi bi-clipboard"></span>
                                                                    </button>
                                                                </span>
                                                                <span className="input-group-append ml-10 pt-3">
                                                                    {
                                                                        "." + authModel.domain
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group m-b-0">
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={
                                                                        dns.key +
                                                                        "data"
                                                                    }
                                                                    value={
                                                                        dns.data
                                                                    }
                                                                    disabled
                                                                />
                                                                <span className="input-group-append">
                                                                    <button
                                                                        onClick={() => {
                                                                            copyToClipboard(
                                                                                dns.data ??
                                                                                    ""
                                                                            );
                                                                        }}
                                                                        className="btn btn-neutral btn-icon"
                                                                    >
                                                                        <span className="bi bi-clipboard"></span>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                                {!authModel.dnsRecordUpdatedAt && (
                                    <Row className="mt-3">
                                        <Form.Group controlId="confirmDomainRecordsInstalled">
                                            <Form.Check
                                                id="chkConfirmDomainRecordsInstalled"
                                                name="confirmDomainRecordsInstalled"
                                                checked={
                                                    authModel.confirmDomainRecordsInstalled
                                                }
                                                onChange={handleInstallChange}
                                                label="I have installed the following records with my domain provider"
                                            />
                                        </Form.Group>
                                    </Row>
                                )}
                            </Modal.Body>
                            {authModel && !authModel.dnsRecordUpdatedAt &&
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={props.onHide}
                                >
                                    Cancel
                                </Button>
                                {authModel.confirmDomainRecordsInstalled ? (
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            submitDomainRecordsInstalledConfirmation();
                                        }}
                                    >
                                        Confirm
                                    </Button>
                                ) : (
                                    <Button variant="primary" disabled>
                                        Confirm
                                    </Button>
                                )}
                            </Modal.Footer>
}
                        </>
                    ))}
            </Modal>
        </React.Fragment>
    );
};

export default SenderDomainAuthenticateModalComponent;

export interface SenderDomainAuthenticationPageViewModel {
    /**
     * 
     * @type {string}
     * @memberof SenderDomainAuthenticationPageViewModel
     */
    'domainAuthOption'?: string;
    /**
     * 
     * @type {string}
     * @memberof SenderDomainAuthenticationPageViewModel
     */
    'domainRegistrar'?: string;
}

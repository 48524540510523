import React, { useState, useEffect, useContext } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AccountContext";
import { Button, Row, Col, InputGroup, Modal, Form } from "react-bootstrap";
import { WooCommerceApi } from "../../apiclient";
import { apiConfig } from "../../config";
import toast, { Toaster } from "react-hot-toast";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const WooCommerceSetup = () => {
  let wooApi = new WooCommerceApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const authContext = useContext(AuthContext);
  const [showWooCommerceSettingsModal, setShowWooCommerceSettingsModal] =
    useState(false as boolean);
  const [isSubmitting, setIsSubmitting] = useState(false as boolean);
  const [validated, setValidated] = useState(false as boolean);
  const [apiKey, setApiKey] = useState("" as string);

  useEffect(() => {
    fetchApiKeyData();
  }, []);

  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    toast.success("API key copied to clipboard");
  };

  const fetchApiKeyData = () => {
    wooApi
      .wooCommerceApiKeyGet()
      .then((response) => {
        setApiKey(response.data);

        //setLoading(false);
      })
      .catch((error: any) => {
        console.log("get woocommerce api key error", error);

        //setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="border-bottom pt-6">
            <div className="row align-items-center text-center">
              <div className="">
                <img
                  alt="..."
                  className="h-10"
                  src="/img/integrations/woocommerce-logo-color-black@2x.png"
                />
                <h1 className="h2 ls-tight">Finish setup</h1>
              </div>

              <p className="text-sm pb-5">
                Complete the steps below then press continue.
              </p>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Main --> */}
      <main className="py-6 bg-surface-secondary">
        <div className="container-fluid max-w-screen-md vstack gap-6">
          <div className="card">
            <div className="card-body">
              <div className="mb-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="mb-1">Step 1: Connect WooCommerce</h4>
                  <div className="text-end">
                    <div className="d-flex align-items-center text-success text-sm font-semibold">
                      <i className="bi bi-check-circle-fill me-1"></i>
                      <span>Connected</span>
                    </div>
                  </div>
                </div>
                <p className="text-sm text-muted">
                  Domain: https://{authContext.account?.integrationDomain}
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="mb-5">
                <h4 className="mb-1">
                  Step 2: Install WooCommerce Gift cards product
                </h4>
                <p className="text-sm text-muted">
                Offer prepaid digital gift cards that customers can redeem online.
                </p>
              </div>
              <form className="vstack gap-5">
                <div className="hstack gap-2 justify-content-end">
                  <a href={"https://woocommerce.com/products/gift-cards/"} className="btn btn-sm btn-primary">
                    Install
                  </a>
                </div>
              </form>
            </div>
          </div>
          <div className="hstack gap-2 justify-content-end">
          <Link to={"/onboarding/selectplan"} className="btn btn-sm btn-primary">
              Continue
            </Link>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default WooCommerceSetup;

import React, { useState, useEffect } from "react";
import { AdminApi } from "../../apiclient";
import {
  BillingPlan,
  AdminCancelAccountReasonViewModel,
} from "../../apiclient/models";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import Modal from "react-bootstrap/Modal";
import { Button, Row, Col, InputGroup, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { useParams } from "react-router-dom";

const AdminCancelAccountModal = (props: any) => {
  let adminApi = new AdminApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
  const [model, setModel] = useState({} as AdminCancelAccountReasonViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let { id } = useParams();
  useEffect(() => {
    setModel((values: AdminCancelAccountReasonViewModel) => ({
      ...values,
      accountId: id,
    }));
  }, []);

  const handleSubmit = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      adminApi
        .adminCancelAccountPost("1", model)
        .then((response) => {
          toast.success("Account cancelled.");
          props.onCancelled();
        })
        .catch((error: any) => {
          toast.error("Error cancelling account.");
        });
    }
  };



  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: AdminCancelAccountReasonViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="text-center py-5 px-5">
              <div className="icon icon-shape rounded-circle bg-opacity-30 bg-danger text-danger text-lg">
                <i className="bi bi-exclamation-octagon-fill"></i>
              </div>
              <h3 className="mt-7 mb-4">Cancel account?</h3>
              <p className="text-sm text-muted">
                Cancelling account will set account status to cancelled and will cancel their stripe subscription if they have one.
              </p>
              <div className="mt-5">
                <Form.Control
                  required
                  name={"reason"}
                  className="form-control"
                  as={"textarea"}
                  placeholder="Reason.."
                  rows={3}
                  onChange={handleChange}
                />
              </div>
              <div className="mt-5">
                <Form.Group className="mb-3 float-start">
                  <Form.Check
                    checked={model?.disableAdmin || false}
                    onChange={handleChange}
                    name="disableAdmin"
                    id="disableAdmin"
                    type="checkbox"
                    label="Disable admin?"
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <Button
              className="btn btn-sm btn-neutral"
              variant={"secondary"}
              onClick={props.onHide}
            >
              Close
            </Button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-sm btn-danger"
            >
              Cancel account
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AdminCancelAccountModal;

import React, { useState, useEffect } from "react";
import { StorefrontApi } from "../../apiclient";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import Modal from "react-bootstrap/Modal";
import { Button, Row, Col, InputGroup, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const EditStorefrontDomainModal = (props: any) => {
  let storefrontApi = new StorefrontApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subdomain, setSubdomain] = useState("");
  useEffect(() => {
    console.log("props.subject", props.subdomain);
    if (props.subdomain) {
      setSubdomain(props.subdomain);
    }
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    const form = event.currentTarget;

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      storefrontApi
        .storefrontSaveStorefrontDomainGet(subdomain)
        .then((response) => {
          toast.success("Domain saved");
          props.onHide();
          props.updated(subdomain);
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error saving Domain.");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setSubdomain(value);
    setIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="text-center py-5 px-5">
              <div className="icon icon-shape rounded-circle bg-opacity-30 bg-info text-info text-lg">
                <i className="bi bi-globe"></i>
              </div>
              <h3 className="mt-7 mb-4">Update storefront subdomain</h3>
              <p className="text-sm text-muted">
                Your subdomain should reflect your business. Do not use the
                domain of another business.
              </p>
              <div className="text-start">
                <div className="mt-5">
                  <div className="input-group input-group-inline">
                    <span className="input-group-text" id="">
                      https://
                    </span>
                    <Form.Control
                      required
                      value={subdomain}
                      name={"subdomain"}
                      className="form-control"
                      onChange={handleChange}
                    />
                    <span className="input-group-text" id="">
                      .wrappedgiftcards.store
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <Button
              className="btn btn-sm btn-neutral"
              variant={"secondary"}
              onClick={props.onHide}
            >
              Close
            </Button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-sm btn-primary"
            >
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default EditStorefrontDomainModal;

/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DashboardGiftCardsAnalyticsModel } from '../models';
/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsGetAnalyticsForAccountByDateRangeGet: async (startDate?: string, endDate?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Analytics/GetAnalyticsForAccountByDateRange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsGetTopLevelStatsForAccountGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Analytics/GetTopLevelStatsForAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsGetAnalyticsForAccountByDateRangeGet(startDate?: string, endDate?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardGiftCardsAnalyticsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsGetAnalyticsForAccountByDateRangeGet(startDate, endDate, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsGetTopLevelStatsForAccountGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardGiftCardsAnalyticsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsGetTopLevelStatsForAccountGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsGetAnalyticsForAccountByDateRangeGet(startDate?: string, endDate?: string, apiVersion?: string, options?: any): AxiosPromise<DashboardGiftCardsAnalyticsModel> {
            return localVarFp.analyticsGetAnalyticsForAccountByDateRangeGet(startDate, endDate, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsGetTopLevelStatsForAccountGet(apiVersion?: string, options?: any): AxiosPromise<DashboardGiftCardsAnalyticsModel> {
            return localVarFp.analyticsGetTopLevelStatsForAccountGet(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public analyticsGetAnalyticsForAccountByDateRangeGet(startDate?: string, endDate?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).analyticsGetAnalyticsForAccountByDateRangeGet(startDate, endDate, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public analyticsGetTopLevelStatsForAccountGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).analyticsGetTopLevelStatsForAccountGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { FormCheck, Button, Spinner, Form, Col, Row, Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import LaunchBanner from "../../components/LaunchBanner";
import { CopyBlock, dracula } from "react-code-blocks";

const AssetsListPage = () => {
  const authContext = useContext(AuthContext);
  const [showCheckBalanceCodeModal, setShowCheckBalanceCodeModal] = useState(false as boolean);

  const [checkBalanceCodeBlockHtml, setShowCheckBalanceCodeBlockHtml] = useState(
    `<div style="height: 500px; background-color: #f3f3f3;">
      <iframe src="https://manage.wrappedgiftcards.com/pc/check-balance/${authContext.account?.accountId}" style="display: block; border: none; outline: 0; height: 100%; width: 100%; overflow: hidden;" scrolling="no" sandbox="allow-same-origin allow-scripts allow-forms allow-popups">
      </iframe>
     </div>` as string);

    
  const copyToClipboard = (textToCopy: string, successMessage: string) => {
    navigator.clipboard.writeText(textToCopy);
    toast.success(successMessage);
  };

  const copyBalanceCodeToClipboard = () => {
    copyToClipboard(checkBalanceCodeBlockHtml, 'Copied!');
    setShowCheckBalanceCodeModal(false);
  }

  return (
    <React.Fragment>
            <LaunchBanner />
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  <h1 className="h2 ls-tight">Gift card assets</h1>
                </div>
                <p className="text-sm pb-5">
                Customize your gift card pages to create a branded gifting experience.{" "}
                  <a className="" href="https://help.wrappedgiftcards.com/en/articles/8157052-customize-your-gift-card-assets" target="_blank">
                    Learn about gift card customization.
                  </a>
                </p>
              </div>
              <ul className="nav nav-tabs overflow-x border-0">
                <li className="nav-item">
                  <Link className="nav-link" to={`/emails`}>
                    Emails
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active" to={`/assets`}>
                    Gift card assets
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid vstack gap-3">
            <div className="card mb-3" style={{ maxWidth: "540px;" }}>
              <div className="row g-0">
                <div className="col-md-3">
                  <img
                    src="/img/assets/asset-check-balance.png"
                    className="img-fluid rounded-start p-10"
                    alt="Check balance page"
                  />
                </div>
                <div className="col-md-9">
                  <div className="card-body">
                    <h4 className="card-title">Check balance page</h4>
                    <p className="card-text">
                    Allow customers to check their gift card balance on your online store.
                    </p>
                    <div className="vstack gap-5">
                      <div className="hstack gap-2 justify-content-end">
                      <button
                          className="btn btn-sm btn-neutral"
                          onClick={()=>setShowCheckBalanceCodeModal(true)}
                        >
                           <i className="bi bi-code"></i> Get embed code
                        </button>

                      <Link
                          className="btn btn-sm btn-neutral"
                          to={`https://manage.wrappedgiftcards.com/pc/check-balance/${authContext.account?.accountId}`}
                          target="_blank"
                        >
                          View Page
                        </Link>
                        
                        <Link
                          className="btn btn-sm btn-primary"
                          to={`/assets/check-balance-page`}
                        >
                          Customize
                        </Link>

                        

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3" style={{ maxWidth: "540px;" }}>
              <div className="row g-0">
                <div className="col-md-3">
                  <img
                    src="/img/assets/asset-gift-to-friend.png"
                    className="img-fluid rounded-start p-10"
                    alt="Gift to friend page"
                  />
                </div>
                <div className="col-md-9">
                  <div className="card-body">
                    <h4 className="card-title">Gift to a friend page</h4>
                    <p className="card-text">
                    Allow customers to gift their gift card to a friend. Contains the ability to personalize a message, schedule send time, and even add a video message.
                    </p>
                    <div className="vstack gap-5">
                      <div className="hstack gap-2 justify-content-end">
                      <Link
                          className="btn btn-sm btn-neutral"
                          to={`https://manage.wrappedgiftcards.com/public/gift-to-friend/test`}
                          target="_blank"
                        >
                          View Page
                        </Link>
                        
                        <Link
                          className="btn btn-sm btn-primary"
                          to={`/assets/gift-to-friend-page`}
                        >
                          Customize
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3" style={{ maxWidth: "540px;" }}>
              <div className="row g-0">
                <div className="col-md-3">
                  <img
                    src="/img/assets/asset-apple-wallet.png"
                    className="img-fluid rounded-start p-10"
                    alt="Apple wallet"
                  />
                </div>
                <div className="col-md-9">
                  <div className="card-body">
                    <h4 className="card-title">Apple and Google Wallet card</h4>
                    <p className="card-text">
                    Keep gift cards easily accessible and top of mind in Apple and Google Wallet.
                    </p>
                    <div className="vstack gap-5">
                      <div className="hstack gap-2 justify-content-end">
                        <Link
                          className="btn btn-sm btn-primary"
                          to={`/assets/wallet-design`}
                        >
                          Customize
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3" style={{ maxWidth: "540px;" }}>
              <div className="row g-0">
                <div className="col-md-3">
                  <img
                    src="/img/assets/asset-pdf-download.png"
                    className="img-fluid rounded-start p-10"
                    alt="Gift card issued (PDF)"
                  />
                </div>
                <div className="col-md-9">
                  <div className="card-body">
                    <h4 className="card-title">Gift card issued (PDF)</h4>
                    <p className="card-text">
                    Customer can download or print this version of their digital gift card.
                    </p>
                    <div className="vstack gap-5">
                      <div className="hstack gap-2 justify-content-end">
                        {/* <Link
                          className="btn btn-sm btn-primary"
                          to={`/assets/wallet-design`}
                        >
                          Customize
                        </Link> */}
                        <a
                        href="/notifications/email-edit/GiftCardPurchasedPdf"
                        className="btn btn-sm btn-primary"
                      >
                        Customize
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {checkBalanceCodeBlockHtml && (
      <Modal
        show={showCheckBalanceCodeModal}
        onHide={() => setShowCheckBalanceCodeModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Get embed code for check balance widget</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
              <p className="mb-3">
                Copy the HTML block below and embed on your website:
                </p>
                <CopyBlock
                  language={"html"}
                  text={checkBalanceCodeBlockHtml}
                  showLineNumbers={true}
                  theme={dracula}
                  wrapLongLines={false}
                  onCopy={copyBalanceCodeToClipboard}
                  codeBlock
                />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={()=>copyBalanceCodeToClipboard()}>Copy</button>
          <button className="btn btn-secondary" onClick={()=>setShowCheckBalanceCodeModal(false)}>Cancel</button>
        </Modal.Footer>
      </Modal>
      )}
    </React.Fragment>
  );
};

export default AssetsListPage;

import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { EmailApi, VerifyDomainPayload, ProcessResultModel, SenderDomainsVerifyCodeModelComponentViewModel } from "../../apiclient";
import { apiConfig } from "../../config";
import moment from "moment";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";

const VerifyCodeModalComponent = (props : any) => {
    let domainId = props.domainId;
    let settingsApi = new EmailApi(apiConfig.configuration,apiConfig.baseUrl);
    const [validated, setValidated] = useState(false);
    const [model, setModel] = useState(
        {} as SenderDomainsVerifyCodeModelComponentViewModel
    );

    const handleChange = (event: any) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = event.target.name;
            setModel((values: SenderDomainsVerifyCodeModelComponentViewModel) => ({
            ...values,
            [name]: value,
        }));
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {

            settingsApi.emailVerifyDomainPost('1',{
                verificationCode: model.verificationCode,
                domainId: model.domainId
            })
                .then(function (response) {
                    var data = response.data as ProcessResultModel;
                    if(data.isSuccessful){
                        toast.success(data.message!);
                        props.onHide(); 
                        props.refreshDomains();
                    }else{
                        toast.error(data.message!);
                    }
                    
                })
                .catch(function (error) {
                    toast.error("Verify code could not be validated");
                });
        } else {
            setValidated(true);
        }
    };

    const modalLoaded = () => {
        settingsApi.emailSenderDomainsVerifyCodeIdGet(domainId, "1").then(function (response) {
            var data = response.data as SenderDomainsVerifyCodeModelComponentViewModel;
            setModel(data);
        });
    };

    return (
        <React.Fragment>
            <Modal
            show={props.isOpen} 
            onHide={props.onHide}
            onEntered={() => modalLoaded()}>
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Verify Domain: {model.domain}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                        <p className="pb-3">We sent an email to {model.verifyEmailAddress} to verify the domain {model.domain} (<time>{moment.utc(model.verifyTime).local().format('MMMM Do YYYY, h:mm:ss A z')}</time>). To verify {model.domain}, click Verify Domain Access in the email. Or enter the verification code in this field.</p>
                            <Form.Label>Verification Code:</Form.Label>
                            <Form.Control
                                    name="verificationCode"
                                    type="text"
                                    required
                                    placeholder="ABC123"
                                    onChange={handleChange}
                                    value={model.verificationCode}
                                />
                            <Form.Control.Feedback type="invalid">
                                Please provide a vaild code.
                            </Form.Control.Feedback>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="sm" variant="secondary" onClick={props.onHide}>
                            Cancel
                        </Button>
                        {model.verificationCode ? (
                            <Button size="sm" variant="primary" type="submit">
                                Verify Code
                            </Button>
                        ) : (
                            <Button size="sm" variant="primary" disabled>
                                Verify Code
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default VerifyCodeModalComponent;

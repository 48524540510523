/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddGiftCardToSalePostPayload } from '../models';
// @ts-ignore
import { LSSale } from '../models';
/**
 * RSeriesApi - axios parameter creator
 * @export
 */
export const RSeriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AddGiftCardToSalePostPayload} [addGiftCardToSalePostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rSeriesAddGiftCardProductToSalePost: async (apiVersion?: string, addGiftCardToSalePostPayload?: AddGiftCardToSalePostPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RSeries/AddGiftCardProductToSale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addGiftCardToSalePostPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AddGiftCardToSalePostPayload} [addGiftCardToSalePostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rSeriesApplyGiftCardPaymentToSalePost: async (apiVersion?: string, addGiftCardToSalePostPayload?: AddGiftCardToSalePostPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RSeries/ApplyGiftCardPaymentToSale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addGiftCardToSalePostPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [saleId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rSeriesGetLSSaleByIdGet: async (id?: string, saleId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RSeries/GetLSSaleById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (saleId !== undefined) {
                localVarQueryParameter['saleId'] = saleId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RSeriesApi - functional programming interface
 * @export
 */
export const RSeriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RSeriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AddGiftCardToSalePostPayload} [addGiftCardToSalePostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rSeriesAddGiftCardProductToSalePost(apiVersion?: string, addGiftCardToSalePostPayload?: AddGiftCardToSalePostPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rSeriesAddGiftCardProductToSalePost(apiVersion, addGiftCardToSalePostPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AddGiftCardToSalePostPayload} [addGiftCardToSalePostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rSeriesApplyGiftCardPaymentToSalePost(apiVersion?: string, addGiftCardToSalePostPayload?: AddGiftCardToSalePostPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rSeriesApplyGiftCardPaymentToSalePost(apiVersion, addGiftCardToSalePostPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [saleId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rSeriesGetLSSaleByIdGet(id?: string, saleId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LSSale>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rSeriesGetLSSaleByIdGet(id, saleId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RSeriesApi - factory interface
 * @export
 */
export const RSeriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RSeriesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AddGiftCardToSalePostPayload} [addGiftCardToSalePostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rSeriesAddGiftCardProductToSalePost(apiVersion?: string, addGiftCardToSalePostPayload?: AddGiftCardToSalePostPayload, options?: any): AxiosPromise<boolean> {
            return localVarFp.rSeriesAddGiftCardProductToSalePost(apiVersion, addGiftCardToSalePostPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AddGiftCardToSalePostPayload} [addGiftCardToSalePostPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rSeriesApplyGiftCardPaymentToSalePost(apiVersion?: string, addGiftCardToSalePostPayload?: AddGiftCardToSalePostPayload, options?: any): AxiosPromise<boolean> {
            return localVarFp.rSeriesApplyGiftCardPaymentToSalePost(apiVersion, addGiftCardToSalePostPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [saleId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rSeriesGetLSSaleByIdGet(id?: string, saleId?: string, apiVersion?: string, options?: any): AxiosPromise<LSSale> {
            return localVarFp.rSeriesGetLSSaleByIdGet(id, saleId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RSeriesApi - object-oriented interface
 * @export
 * @class RSeriesApi
 * @extends {BaseAPI}
 */
export class RSeriesApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AddGiftCardToSalePostPayload} [addGiftCardToSalePostPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RSeriesApi
     */
    public rSeriesAddGiftCardProductToSalePost(apiVersion?: string, addGiftCardToSalePostPayload?: AddGiftCardToSalePostPayload, options?: AxiosRequestConfig) {
        return RSeriesApiFp(this.configuration).rSeriesAddGiftCardProductToSalePost(apiVersion, addGiftCardToSalePostPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AddGiftCardToSalePostPayload} [addGiftCardToSalePostPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RSeriesApi
     */
    public rSeriesApplyGiftCardPaymentToSalePost(apiVersion?: string, addGiftCardToSalePostPayload?: AddGiftCardToSalePostPayload, options?: AxiosRequestConfig) {
        return RSeriesApiFp(this.configuration).rSeriesApplyGiftCardPaymentToSalePost(apiVersion, addGiftCardToSalePostPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [saleId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RSeriesApi
     */
    public rSeriesGetLSSaleByIdGet(id?: string, saleId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return RSeriesApiFp(this.configuration).rSeriesGetLSSaleByIdGet(id, saleId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}

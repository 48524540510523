/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Platform = {
    None: 'None',
    Shopify: 'Shopify',
    WooCommerce: 'WooCommerce',
    BigCommerce: 'BigCommerce',
    Wix: 'Wix',
    Vend: 'Vend',
    WrappedPublicApi: 'WrappedPublicApi',
    Dashboard: 'Dashboard',
    CsvUpload: 'CsvUpload',
    Widget: 'Widget',
    RSeries: 'RSeries',
    WrappedWorkflow: 'WrappedWorkflow',
    Kounta: 'Kounta',
    KSeries: 'KSeries',
    Square: 'Square',
    Ecwid: 'Ecwid',
    GiftCardBatch: 'GiftCardBatch',
    WrappedStorefront: 'WrappedStorefront',
    Bopple: 'Bopple',
    CSeries: 'CSeries',
    WrappedAutomation: 'WrappedAutomation',
    WrappedRedemptionApp: 'WrappedRedemptionApp',
    KountaGiftCardTopup: 'KountaGiftCardTopup',
    Marsello: 'Marsello',
    Klaviyo: 'Klaviyo',
    HeartlandRetail: 'HeartlandRetail',
    Zapier: 'Zapier',
    Hike: 'Hike'
} as const;

export type Platform = typeof Platform[keyof typeof Platform];




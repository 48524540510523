import React, { useState, useEffect, useContext } from "react";
import { AccountApi } from "../../apiclient";
import { InviteMemberModalViewModel } from "../../apiclient/models";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button, Row, Col, InputGroup } from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { AuthContext } from "../../contexts/AccountContext";

const InviteMemberModal = (props: any) => {
  let accountApi = new AccountApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({
    jobTitle: "Marketing",
    userRole: "Admin"
  } as InviteMemberModalViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    
    // const fetchData = () => {
    //   testApi.testLetsGoGet().then((response) => {
    //     setModel(response.data);
    //   });
    // };
    // fetchData();
  }, []);

  const handleSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      accountApi
        .accountInviteMemberPost("1", model)
        .then((response) => {
          toast.success("Invite sent!");
          props.refreshUsers();
          props.onHide();
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error sending invite.");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: InviteMemberModalViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      {(authContext.account?.isAdmin || authContext.account?.isOwner) && (
          <>
            <Modal show={props.show} onHide={props.onHide}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Invite member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        required
                        name={"name"}
                        className="form-control"
                        type="text"
                        value={model.name ?? ""}
                        placeholder="Name"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        required
                        name={"emailAddress"}
                        value={model.emailAddress ?? ""}
                        className="form-control"
                        type="email"
                        placeholder="Email Address"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>User role</Form.Label>
                      <Form.Select
                        name="userRole"
                        onChange={handleChange}
                        value={model.userRole ?? "Admin"}
                      >
                        <option value="Admin">Admin</option>
                        <option value="Member">Member</option>
                        <option value="Readonly">Readonly</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Job Title</Form.Label>
                      <Form.Select
                        name="jobTitle"
                        onChange={handleChange}
                        value={model.jobTitle ?? "Marketing"}
                      >
                        <option value="Marketing">Marketing</option>
                        <option value="Product / UX">Product / UX</option>
                        <option value="Founder / Business Owner">
                          Founder / Business Owner
                        </option>
                        <option value="Agency">Agency</option>
                        <option value="Customer Experience">
                          Customer Experience
                        </option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button size="sm" variant="secondary" onClick={props.onHide}>
                    Close
                  </Button>
                  <Button
                  size="sm"
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Send invite
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
    </React.Fragment>
  );
};

export default InviteMemberModal;

import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "../components/NavMenu";
import { Outlet } from 'react-router-dom';
import { AdminAuthProvider } from "../contexts/AdminAccountContext";
import TopNavBar from "../components/TopNavBar";
import toast, { Toaster } from 'react-hot-toast';
import AdminTopNavBar from "../components/AdminTopNavBar";
export class AdminLayout extends Component {
  static displayName = AdminLayout.name;

  render() {
    return (
      <AdminAuthProvider>
      <div>
        <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
          <div className="flex-lg-1 h-screen overflow-y-lg-auto">
            <AdminTopNavBar showMenu={false} />
            <Toaster position={"top-right"} />
            <Outlet />
          </div>
        </div>
      </div>
      </AdminAuthProvider>
    );
  }
}

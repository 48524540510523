import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import {
  GiftCardSyncErrorsViewModel,
  PerformSyncGiftCardViewModel,
} from "../../apiclient/models";

interface GiftCardSyncErrorBannerComponentProps {
  giftCardId: string;
}

const GiftCardSyncErrorBannerComponent = (
  props: GiftCardSyncErrorBannerComponentProps
) => {
  const [model, setModel] = useState({} as GiftCardSyncErrorsViewModel);
  const [btnResyncButtonIsSubmitting, setBtnResyncButtonIsSubmitting] =
    useState(false);

  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    giftcardApi
      .giftcardGetGiftCardSyncErrorsViewModelGet(props.giftCardId)
      .then((response) => {
        var data = response.data;
        setModel(data);
      })
      .catch((error: any) => {});
  };

  const onBtnResyncClicked = (event: any) => {
    setBtnResyncButtonIsSubmitting(true);
    giftcardApi
      .giftcardPerformSyncGiftCardPost("1", {
        giftCardId: props.giftCardId,
      } as PerformSyncGiftCardViewModel)
      .then((response) => {
        var result = response.data as boolean;
        if (result) {
          toast.success("The gift card has been synced to connected platforms", {
            duration: 5000,
          });
        } else {
          toast.error("There was an error re-syncing your gift card");
        }

        setBtnResyncButtonIsSubmitting(false);
        fetchData();
      })
      .catch(function (error) {
        toast.error("There was an error re-syncing your gift card");
        setBtnResyncButtonIsSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      {model && model.hasErrors && (
        <div className="alert alert-danger" role="alert">
          <button
            className="btn btn-outline-danger float-end"
            onClick={onBtnResyncClicked}
            disabled={btnResyncButtonIsSubmitting}
          >
            {btnResyncButtonIsSubmitting ? "Syncing.." : "Re-sync again"}
          </button>
          <div className="d-flex align-items-center">
            <div className="w-8 text-lg">
              <i className="bi bi-exclamation-circle"></i>
            </div>

            <span className="font-bold">
              There was an error syncing the gift card to platforms
            </span>
          </div>

          <div className="ps-10">
            <ul className="m-0 mt-1 ps-3">
              {model.errors &&
                model.errors.map((err, i) => (
                  <li key={`sync_error${i}`} className=" text-sm">{err}</li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default GiftCardSyncErrorBannerComponent;

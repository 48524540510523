import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { GiftcardApi } from "../../apiclient";
import { GiftCardViewModel } from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import { FormCheck, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Util from "../../js/util";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const GiftCardNewPage = () => {
  const navigate = useNavigate();
  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as GiftCardViewModel);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);

  useEffect(() => {

    giftcardApi.giftcardInitCreateGiftCardGet().then((response) => {
      var data = response.data;
      setModel(data);
    });

  }, []);

  const handleChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "hasExpiration") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }
    setModel((values: GiftCardViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);

      giftcardApi
        .giftcardCreateGiftCardPost("1", model)
        .then((response) => {
          toast.success("Gift card created");
          setUnsavedChanges(false);
          navigate("/gift-cards");
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving settings.");
          setUnsavedChanges(false);
        });
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <Spinner animation="border" />
        </>
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
            <div className="container-fluid py-4">
              <div className="row align-items-center">
                <div className="col">
                  <div className="d-flex align-items-center gap-4">
                    <div>
                      <Link
                        className="bi bi-chevron-left text-xs"
                        to={`/gift-cards`}
                      ></Link>
                    </div>
                    <div className="vr opacity-20 my-1"></div>
                    <h1 className="h4 ls-tight">Issue gift card</h1>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="hstack gap-2 justify-content-end">
                    {/* <a
                      href="https://help.wrappedgiftcard.com/en/collections/3830625-account#general"
                      target={"_blank"}
                      className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block"
                    >
                      <i className="bi bi-question-circle-fill"></i>
                      <span className="d-none d-sm-inline ms-2">
                        Need help?
                      </span>
                    </a> */}
                    <button type="submit" className="btn btn-sm btn-primary">
                      <span>Issue and activate</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="bg-surface-secondary">
            <div className="container py-10">
              <div className="row mb-10">
                <div className="col-lg-8">
                  <div className="card mb-10">
                    <div className="card-body">
                      <div className="row g-5">
                        <div className="col-12">
                          <h4 className="font-semibold">Gift card details</h4>
                          <div className="">
                            <label className="form-label" htmlFor="code">
                              Gift card code
                            </label>
                            <Form.Control
                              name={"code"}
                              className="form-control"
                              type="text"
                              value={model.code}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="">
                            <label
                              className="form-label"
                              htmlFor="initialValue"
                            >
                              Initial value
                            </label>
                            <div className="input-group input-group-inline">
                              <span className="input-group-text" id="">
                                $
                              </span>
                              <Form.Control
                                name={"initialValue"}
                                className="form-control"
                                type="number"
                                value={model.initialValue}
                                onChange={handleChange}
                                placeholder="0.00"
                                aria-label="0.00"
                                required
                              />
                              <span className="input-group-text" id="">
                                {authContext.account?.isoCurrencyCode}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="">
                            <label
                              className="form-label"
                              htmlFor="initialValue"
                            >
                              Reason
                            </label>
                            <Form.Select
                              name="reasonType"
                              onChange={handleChange}
                              value={model.reasonType}
                              required
                            >
                              <option value={"Purchased"}>Purchased</option>
                              <option value={"Refund"}>
                                Refund
                              </option>
                              <option value={"Compensation"}>
                                Compensation
                              </option>
                              <option value={"Reward"}>
                                Reward
                              </option>
                              <option value={"Transfer"}>
                                Transfer
                              </option>
                              <option value={"Employee"}>
                                Employee
                              </option>
                              <option value={"Donation"}>
                                Donation
                              </option>
                              <option value={"Campaign"}>
                                Campaign
                              </option>
                              <option value={"Other"}>
                                Other
                              </option>
                            </Form.Select>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex mt-3">
                            <div className="me-3">
                              <Form.Check
                                name="isPromotional"
                                id="isPromotional"
                                type={"checkbox"}
                                checked={model.isPromotional}
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label
                                className="form-label mb-0"
                                htmlFor="isPromotional"
                              >
                                Used for promotional purposes?
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-10">
                    <div className="card-body">
                      <div className="row g-5">
                        <div className="col-md-12">
                          <h4 className="font-semibold">Expiration date</h4>
                          <p className="text-sm text-muted mb-5">
                            Countries have different laws for gift card expiry
                            dates. Check the laws for your country before
                            changing this date.
                          </p>
                          <div className="d-flex mb-3">
                            <div className="me-3">
                              <Form.Check
                                name="hasExpiration"
                                type={"radio"}
                                id={`hasExpirationNo`}
                                checked={!model.hasExpiration}
                                onChange={handleChange}
                                value={"false"}
                              />
                            </div>
                            <div>
                              <label
                                className="form-label mb-0"
                                htmlFor="hasExpirationNo"
                              >
                                No expiration date
                              </label>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="me-3">
                              <Form.Check
                                name="hasExpiration"
                                type={"radio"}
                                id={`hasExpirationYes`}
                                checked={model.hasExpiration}
                                onChange={handleChange}
                                value={"true"}
                              />
                            </div>
                            <div>
                              <label
                                className="form-label mb-0"
                                htmlFor="hasExpirationYes"
                              >
                                Set expiration date
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label
                              className="form-label pt-3"
                              htmlFor="expiresOn"
                            >
                              Expires on
                            </label>

                            <Form.Control
                              name={"expiresOn"}
                              className="form-control"
                              type="date"
                              value={model.expiresOn!}
                              onChange={handleChange}
                              required
                              disabled={!model.hasExpiration}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                  <div className="card mb-10">
                    <div className="card-body">
                      <div className="col-12">
                        <div className="">
                          <label
                            className="form-label"
                            htmlFor="recipientEmailAddress"
                          >
                            Recipient email address
                          </label>
                          <Form.Control
                            name={"recipientEmailAddress"}
                            className="form-control"
                            type="text"
                            value={model.recipientEmailAddress!}
                            onChange={handleChange}
                          />
                        </div>
                        <p className="text-sm pt-3 text-muted">
                          To send the gift card code, add a customer with an
                          email address.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-10">
                    <div className="card-body">
                      <div className="col-12">
                        <div className="">
                          <label className="form-label" htmlFor="notes">
                            Notes
                          </label>
                          {/* <textarea
                          className="form-control"
                          id="formInputExample"
                          placeholder=""
                          rows={3}
                          value={""}
                        ></textarea> */}
                          <Form.Control
                            name={"notes"}
                            className="form-control"
                            value={model.notes!}
                            onChange={handleChange}
                            as="textarea"
                            rows={3}
                          />
                        </div>
                        <p className="text-sm pt-3 text-muted">
                          These notes are private and won't be shared with the
                          customer.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mb-7" />
                <div className="text-end mb-5 mb-lg-0 pe-lg-16">
                  <button type="submit" className="btn btn-sm btn-primary">
                    Issue and activate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </React.Fragment>
  );
};

export default GiftCardNewPage;

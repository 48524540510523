import React, { useContext, useState, useEffect, useRef } from "react";
import { EmailApi } from "../../apiclient";
import {
  UnsubscribePageModel,
  UnsubscribePagePostModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import * as Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

const UnsubscribePage = () => {
  let { accountId } = useParams();

  let emailApi = new EmailApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [isLoading, setLoading] = useState(false as boolean);
  const [model, setModel] = useState({} as UnsubscribePageModel);
  const [searchParams, setSearchParams] = useSearchParams();
  const [uid, setUid] = useState(searchParams.get("uid") ?? "");

  useEffect(() => {
    getUnsubscribePageModel();
  }, []);

  const getUnsubscribePageModel = () => {
    if (uid) {
      setLoading(true);
      emailApi
        .emailGetUnsubscribePageModelGet(accountId, uid)
        .then((response) => {
          setLoading(false);
          if (response.data) {
            setModel(response.data);
          } else {
            toast.error("Cannot find email to unsubscribe");
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred");
        });
    }
  };

  const performToggleUnsubscribe = () => {
    // do stuff

    if (model.isUnsubscribeLink) {
      Swal.default
        .fire({
          title: "Are you sure?",
          text: `You will not receive any more gift card updates from ${model.companyName}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, unsubscribe",
        })
        .then((result) => {
          if (result.isConfirmed) {
            emailApi
              .emailToggleUnsubscribePost("1", {
                accountId: accountId,
                uid: uid,
              } as UnsubscribePagePostModel)
              .then((response) => {
                var data = response.data as UnsubscribePageModel;
                if (data && data.isSuccessful) {
                  toast.success("You have been unsubscribed", {
                    duration: 10000,
                  });
                  setModel(data);
                  console.log('data', data);
                } else {
                  toast.error("Failed to unsubscribe");
                }
              })
              .catch((error: any) => {
                toast.error("Failed to unsubscribe");
              });
          }
        });
    } else {
      emailApi
        .emailToggleUnsubscribePost("1", {
          accountId: accountId,
          uid: uid,
        } as UnsubscribePagePostModel)
        .then((response) => {
          var data = response.data as UnsubscribePageModel;
          if (data && data.isSuccessful) {
            toast.success("You have been resubscribed", { duration: 10000 });
            setModel(data);
          } else {
            toast.error("Failed to resubscribe");
          }
        })
        .catch((error: any) => {
          toast.error("Failed to resubscribe");
        });
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          <div className="container py-10">
            <div className="row mb-10 text-center">
              <div className="mx-auto">
                <div className="card mb-10">
                  <div className="card-body">
                    <div className="row g-5">
                      <div className="col-12">
                          
                          {model.isUnsubscribeLink ? (
                            <>
                            <i className="bi bi-exclamation-circle text-warning h1" />
                               <h4 className="font-semibold mb-5">
                              Unsubscribe {model.emailAddress} from {model.companyName} gift card
                              email updates?
                        </h4>

                            </>
                          ) : (
                            <>
                            <i className="bi bi-check-circle text-success h1" />
                            <h4 className="font-semibold mb-5">
                              {model.emailAddress} is currently unsubscribed from {model.companyName} gift card
                              email updates
                              </h4>
                            </>
                          )}
                        <div className="">
                            <button className="btn btn-primary" onClick={performToggleUnsubscribe}>{model.isUnsubscribeLink?<>Unsubscribe</>:<>Resubscribe</>}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default UnsubscribePage;

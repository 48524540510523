/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppleWalletLocation } from '../models';
// @ts-ignore
import { AppleWalletSettingsPageViewModel } from '../models';
// @ts-ignore
import { CheckBalancePageSettingsViewModel } from '../models';
// @ts-ignore
import { CustomEmbedScriptsViewModel } from '../models';
// @ts-ignore
import { GiftCardImageAssetViewModel } from '../models';
// @ts-ignore
import { GiftToFriendPageSettingsViewModel } from '../models';
/**
 * AssetApi - axios parameter creator
 * @export
 */
export const AssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetActiveImageAssetsIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetActiveImageAssetsIdGet', 'id', id)
            const localVarPath = `/Asset/ActiveImageAssets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetAllImageAssetsIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetAllImageAssetsIdGet', 'id', id)
            const localVarPath = `/Asset/AllImageAssets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetAppleWalletSettingsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/AppleWalletSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetCheckBalancePagePublicIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetCheckBalancePagePublicIdGet', 'id', id)
            const localVarPath = `/Asset/CheckBalancePagePublic/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetCheckBalancePageSettingsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/CheckBalancePageSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetCheckbalancestyleCssGet: async (accountId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/checkbalancestyle.css`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [type] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGetCustomEmbedScriptByTypeGet: async (accountId?: string, type?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/GetCustomEmbedScriptByType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGetCustomEmbedScriptsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/GetCustomEmbedScripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGiftToFriendPagePublicIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetGiftToFriendPagePublicIdGet', 'id', id)
            const localVarPath = `/Asset/GiftToFriendPagePublic/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGiftToFriendPageSettingsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/GiftToFriendPageSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGifttofriendstyleCssGet: async (accountId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/gifttofriendstyle.css`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetResetAppleWalletSettingsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/ResetAppleWalletSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardImageAssetViewModel} [giftCardImageAssetViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetToggleImageAssetIsActivePost: async (apiVersion?: string, giftCardImageAssetViewModel?: GiftCardImageAssetViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/ToggleImageAssetIsActive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardImageAssetViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [headerText] 
         * @param {string} [backgroundColor] 
         * @param {string} [logoImageUrl] 
         * @param {string} [bannerImageUrl] 
         * @param {string} [labelColor] 
         * @param {string} [foregroundColor] 
         * @param {string} [barcodeType] 
         * @param {Array<AppleWalletLocation>} [locations] 
         * @param {any} [customBanner] 
         * @param {any} [customLogo] 
         * @param {boolean} [useCustomLogo] 
         * @param {boolean} [useCustomBanner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUpdateAppleWalletSettingsPost: async (apiVersion?: string, headerText?: string, backgroundColor?: string, logoImageUrl?: string, bannerImageUrl?: string, labelColor?: string, foregroundColor?: string, barcodeType?: string, locations?: Array<AppleWalletLocation>, customBanner?: any, customLogo?: any, useCustomLogo?: boolean, useCustomBanner?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/UpdateAppleWalletSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (headerText !== undefined) { 
                localVarFormParams.append('headerText', headerText as any);
            }
    
            if (backgroundColor !== undefined) { 
                localVarFormParams.append('backgroundColor', backgroundColor as any);
            }
    
            if (logoImageUrl !== undefined) { 
                localVarFormParams.append('logoImageUrl', logoImageUrl as any);
            }
    
            if (bannerImageUrl !== undefined) { 
                localVarFormParams.append('bannerImageUrl', bannerImageUrl as any);
            }
    
            if (labelColor !== undefined) { 
                localVarFormParams.append('labelColor', labelColor as any);
            }
    
            if (foregroundColor !== undefined) { 
                localVarFormParams.append('foregroundColor', foregroundColor as any);
            }
    
            if (barcodeType !== undefined) { 
                localVarFormParams.append('barcodeType', barcodeType as any);
            }
                if (locations) {
                localVarFormParams.append('locations', locations.join(COLLECTION_FORMATS.csv));
            }

    
            if (customBanner !== undefined) { 
                localVarFormParams.append('customBanner', customBanner as any);
            }
    
            if (customLogo !== undefined) { 
                localVarFormParams.append('customLogo', customLogo as any);
            }
    
            if (useCustomLogo !== undefined) { 
                localVarFormParams.append('useCustomLogo', useCustomLogo as any);
            }
    
            if (useCustomBanner !== undefined) { 
                localVarFormParams.append('useCustomBanner', useCustomBanner as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CheckBalancePageSettingsViewModel} [checkBalancePageSettingsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUpdateCheckBalancePageSettingsPost: async (apiVersion?: string, checkBalancePageSettingsViewModel?: CheckBalancePageSettingsViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/UpdateCheckBalancePageSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkBalancePageSettingsViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CustomEmbedScriptsViewModel} [customEmbedScriptsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUpdateCustomEmbedScriptsPost: async (apiVersion?: string, customEmbedScriptsViewModel?: CustomEmbedScriptsViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/UpdateCustomEmbedScripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customEmbedScriptsViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftToFriendPageSettingsViewModel} [giftToFriendPageSettingsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUpdateGiftToFriendPageSettingsPost: async (apiVersion?: string, giftToFriendPageSettingsViewModel?: GiftToFriendPageSettingsViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/UpdateGiftToFriendPageSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftToFriendPageSettingsViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardImageAssetViewModel} [giftCardImageAssetViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUpdateImageAssetPost: async (apiVersion?: string, giftCardImageAssetViewModel?: GiftCardImageAssetViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/UpdateImageAsset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardImageAssetViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUploadGiftToFriendPageHeaderImagePost: async (apiVersion?: string, image?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/UploadGiftToFriendPageHeaderImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUploadImageAssetPost: async (apiVersion?: string, image?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Asset/UploadImageAsset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetApi - functional programming interface
 * @export
 */
export const AssetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetActiveImageAssetsIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GiftCardImageAssetViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetActiveImageAssetsIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetAllImageAssetsIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GiftCardImageAssetViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetAllImageAssetsIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetAppleWalletSettingsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppleWalletSettingsPageViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetAppleWalletSettingsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetCheckBalancePagePublicIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckBalancePageSettingsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetCheckBalancePagePublicIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetCheckBalancePageSettingsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckBalancePageSettingsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetCheckBalancePageSettingsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetCheckbalancestyleCssGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetCheckbalancestyleCssGet(accountId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [type] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetGetCustomEmbedScriptByTypeGet(accountId?: string, type?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetGetCustomEmbedScriptByTypeGet(accountId, type, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetGetCustomEmbedScriptsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomEmbedScriptsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetGetCustomEmbedScriptsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetGiftToFriendPagePublicIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftToFriendPageSettingsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetGiftToFriendPagePublicIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetGiftToFriendPageSettingsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftToFriendPageSettingsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetGiftToFriendPageSettingsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetGifttofriendstyleCssGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetGifttofriendstyleCssGet(accountId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetResetAppleWalletSettingsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppleWalletSettingsPageViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetResetAppleWalletSettingsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardImageAssetViewModel} [giftCardImageAssetViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetToggleImageAssetIsActivePost(apiVersion?: string, giftCardImageAssetViewModel?: GiftCardImageAssetViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetToggleImageAssetIsActivePost(apiVersion, giftCardImageAssetViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [headerText] 
         * @param {string} [backgroundColor] 
         * @param {string} [logoImageUrl] 
         * @param {string} [bannerImageUrl] 
         * @param {string} [labelColor] 
         * @param {string} [foregroundColor] 
         * @param {string} [barcodeType] 
         * @param {Array<AppleWalletLocation>} [locations] 
         * @param {any} [customBanner] 
         * @param {any} [customLogo] 
         * @param {boolean} [useCustomLogo] 
         * @param {boolean} [useCustomBanner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetUpdateAppleWalletSettingsPost(apiVersion?: string, headerText?: string, backgroundColor?: string, logoImageUrl?: string, bannerImageUrl?: string, labelColor?: string, foregroundColor?: string, barcodeType?: string, locations?: Array<AppleWalletLocation>, customBanner?: any, customLogo?: any, useCustomLogo?: boolean, useCustomBanner?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetUpdateAppleWalletSettingsPost(apiVersion, headerText, backgroundColor, logoImageUrl, bannerImageUrl, labelColor, foregroundColor, barcodeType, locations, customBanner, customLogo, useCustomLogo, useCustomBanner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CheckBalancePageSettingsViewModel} [checkBalancePageSettingsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetUpdateCheckBalancePageSettingsPost(apiVersion?: string, checkBalancePageSettingsViewModel?: CheckBalancePageSettingsViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetUpdateCheckBalancePageSettingsPost(apiVersion, checkBalancePageSettingsViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CustomEmbedScriptsViewModel} [customEmbedScriptsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetUpdateCustomEmbedScriptsPost(apiVersion?: string, customEmbedScriptsViewModel?: CustomEmbedScriptsViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetUpdateCustomEmbedScriptsPost(apiVersion, customEmbedScriptsViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftToFriendPageSettingsViewModel} [giftToFriendPageSettingsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetUpdateGiftToFriendPageSettingsPost(apiVersion?: string, giftToFriendPageSettingsViewModel?: GiftToFriendPageSettingsViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetUpdateGiftToFriendPageSettingsPost(apiVersion, giftToFriendPageSettingsViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardImageAssetViewModel} [giftCardImageAssetViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetUpdateImageAssetPost(apiVersion?: string, giftCardImageAssetViewModel?: GiftCardImageAssetViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetUpdateImageAssetPost(apiVersion, giftCardImageAssetViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetUploadGiftToFriendPageHeaderImagePost(apiVersion?: string, image?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetUploadGiftToFriendPageHeaderImagePost(apiVersion, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetUploadImageAssetPost(apiVersion?: string, image?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardImageAssetViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetUploadImageAssetPost(apiVersion, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetApi - factory interface
 * @export
 */
export const AssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetActiveImageAssetsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<Array<GiftCardImageAssetViewModel>> {
            return localVarFp.assetActiveImageAssetsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetAllImageAssetsIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<Array<GiftCardImageAssetViewModel>> {
            return localVarFp.assetAllImageAssetsIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetAppleWalletSettingsGet(apiVersion?: string, options?: any): AxiosPromise<AppleWalletSettingsPageViewModel> {
            return localVarFp.assetAppleWalletSettingsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetCheckBalancePagePublicIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<CheckBalancePageSettingsViewModel> {
            return localVarFp.assetCheckBalancePagePublicIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetCheckBalancePageSettingsGet(apiVersion?: string, options?: any): AxiosPromise<CheckBalancePageSettingsViewModel> {
            return localVarFp.assetCheckBalancePageSettingsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetCheckbalancestyleCssGet(accountId?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetCheckbalancestyleCssGet(accountId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [type] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGetCustomEmbedScriptByTypeGet(accountId?: string, type?: string, apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.assetGetCustomEmbedScriptByTypeGet(accountId, type, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGetCustomEmbedScriptsGet(apiVersion?: string, options?: any): AxiosPromise<CustomEmbedScriptsViewModel> {
            return localVarFp.assetGetCustomEmbedScriptsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGiftToFriendPagePublicIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<GiftToFriendPageSettingsViewModel> {
            return localVarFp.assetGiftToFriendPagePublicIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGiftToFriendPageSettingsGet(apiVersion?: string, options?: any): AxiosPromise<GiftToFriendPageSettingsViewModel> {
            return localVarFp.assetGiftToFriendPageSettingsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetGifttofriendstyleCssGet(accountId?: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetGifttofriendstyleCssGet(accountId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetResetAppleWalletSettingsGet(apiVersion?: string, options?: any): AxiosPromise<AppleWalletSettingsPageViewModel> {
            return localVarFp.assetResetAppleWalletSettingsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardImageAssetViewModel} [giftCardImageAssetViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetToggleImageAssetIsActivePost(apiVersion?: string, giftCardImageAssetViewModel?: GiftCardImageAssetViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.assetToggleImageAssetIsActivePost(apiVersion, giftCardImageAssetViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [headerText] 
         * @param {string} [backgroundColor] 
         * @param {string} [logoImageUrl] 
         * @param {string} [bannerImageUrl] 
         * @param {string} [labelColor] 
         * @param {string} [foregroundColor] 
         * @param {string} [barcodeType] 
         * @param {Array<AppleWalletLocation>} [locations] 
         * @param {any} [customBanner] 
         * @param {any} [customLogo] 
         * @param {boolean} [useCustomLogo] 
         * @param {boolean} [useCustomBanner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUpdateAppleWalletSettingsPost(apiVersion?: string, headerText?: string, backgroundColor?: string, logoImageUrl?: string, bannerImageUrl?: string, labelColor?: string, foregroundColor?: string, barcodeType?: string, locations?: Array<AppleWalletLocation>, customBanner?: any, customLogo?: any, useCustomLogo?: boolean, useCustomBanner?: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.assetUpdateAppleWalletSettingsPost(apiVersion, headerText, backgroundColor, logoImageUrl, bannerImageUrl, labelColor, foregroundColor, barcodeType, locations, customBanner, customLogo, useCustomLogo, useCustomBanner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CheckBalancePageSettingsViewModel} [checkBalancePageSettingsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUpdateCheckBalancePageSettingsPost(apiVersion?: string, checkBalancePageSettingsViewModel?: CheckBalancePageSettingsViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.assetUpdateCheckBalancePageSettingsPost(apiVersion, checkBalancePageSettingsViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CustomEmbedScriptsViewModel} [customEmbedScriptsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUpdateCustomEmbedScriptsPost(apiVersion?: string, customEmbedScriptsViewModel?: CustomEmbedScriptsViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.assetUpdateCustomEmbedScriptsPost(apiVersion, customEmbedScriptsViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftToFriendPageSettingsViewModel} [giftToFriendPageSettingsViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUpdateGiftToFriendPageSettingsPost(apiVersion?: string, giftToFriendPageSettingsViewModel?: GiftToFriendPageSettingsViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.assetUpdateGiftToFriendPageSettingsPost(apiVersion, giftToFriendPageSettingsViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {GiftCardImageAssetViewModel} [giftCardImageAssetViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUpdateImageAssetPost(apiVersion?: string, giftCardImageAssetViewModel?: GiftCardImageAssetViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.assetUpdateImageAssetPost(apiVersion, giftCardImageAssetViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUploadGiftToFriendPageHeaderImagePost(apiVersion?: string, image?: any, options?: any): AxiosPromise<string> {
            return localVarFp.assetUploadGiftToFriendPageHeaderImagePost(apiVersion, image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {any} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetUploadImageAssetPost(apiVersion?: string, image?: any, options?: any): AxiosPromise<GiftCardImageAssetViewModel> {
            return localVarFp.assetUploadImageAssetPost(apiVersion, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
export class AssetApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetActiveImageAssetsIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetActiveImageAssetsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetAllImageAssetsIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetAllImageAssetsIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetAppleWalletSettingsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetAppleWalletSettingsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetCheckBalancePagePublicIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetCheckBalancePagePublicIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetCheckBalancePageSettingsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetCheckBalancePageSettingsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetCheckbalancestyleCssGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetCheckbalancestyleCssGet(accountId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [type] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetGetCustomEmbedScriptByTypeGet(accountId?: string, type?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetGetCustomEmbedScriptByTypeGet(accountId, type, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetGetCustomEmbedScriptsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetGetCustomEmbedScriptsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetGiftToFriendPagePublicIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetGiftToFriendPagePublicIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetGiftToFriendPageSettingsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetGiftToFriendPageSettingsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetGifttofriendstyleCssGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetGifttofriendstyleCssGet(accountId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetResetAppleWalletSettingsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetResetAppleWalletSettingsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftCardImageAssetViewModel} [giftCardImageAssetViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetToggleImageAssetIsActivePost(apiVersion?: string, giftCardImageAssetViewModel?: GiftCardImageAssetViewModel, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetToggleImageAssetIsActivePost(apiVersion, giftCardImageAssetViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {string} [headerText] 
     * @param {string} [backgroundColor] 
     * @param {string} [logoImageUrl] 
     * @param {string} [bannerImageUrl] 
     * @param {string} [labelColor] 
     * @param {string} [foregroundColor] 
     * @param {string} [barcodeType] 
     * @param {Array<AppleWalletLocation>} [locations] 
     * @param {any} [customBanner] 
     * @param {any} [customLogo] 
     * @param {boolean} [useCustomLogo] 
     * @param {boolean} [useCustomBanner] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetUpdateAppleWalletSettingsPost(apiVersion?: string, headerText?: string, backgroundColor?: string, logoImageUrl?: string, bannerImageUrl?: string, labelColor?: string, foregroundColor?: string, barcodeType?: string, locations?: Array<AppleWalletLocation>, customBanner?: any, customLogo?: any, useCustomLogo?: boolean, useCustomBanner?: boolean, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetUpdateAppleWalletSettingsPost(apiVersion, headerText, backgroundColor, logoImageUrl, bannerImageUrl, labelColor, foregroundColor, barcodeType, locations, customBanner, customLogo, useCustomLogo, useCustomBanner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {CheckBalancePageSettingsViewModel} [checkBalancePageSettingsViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetUpdateCheckBalancePageSettingsPost(apiVersion?: string, checkBalancePageSettingsViewModel?: CheckBalancePageSettingsViewModel, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetUpdateCheckBalancePageSettingsPost(apiVersion, checkBalancePageSettingsViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {CustomEmbedScriptsViewModel} [customEmbedScriptsViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetUpdateCustomEmbedScriptsPost(apiVersion?: string, customEmbedScriptsViewModel?: CustomEmbedScriptsViewModel, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetUpdateCustomEmbedScriptsPost(apiVersion, customEmbedScriptsViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftToFriendPageSettingsViewModel} [giftToFriendPageSettingsViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetUpdateGiftToFriendPageSettingsPost(apiVersion?: string, giftToFriendPageSettingsViewModel?: GiftToFriendPageSettingsViewModel, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetUpdateGiftToFriendPageSettingsPost(apiVersion, giftToFriendPageSettingsViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {GiftCardImageAssetViewModel} [giftCardImageAssetViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetUpdateImageAssetPost(apiVersion?: string, giftCardImageAssetViewModel?: GiftCardImageAssetViewModel, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetUpdateImageAssetPost(apiVersion, giftCardImageAssetViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {any} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetUploadGiftToFriendPageHeaderImagePost(apiVersion?: string, image?: any, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetUploadGiftToFriendPageHeaderImagePost(apiVersion, image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {any} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public assetUploadImageAssetPost(apiVersion?: string, image?: any, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).assetUploadImageAssetPost(apiVersion, image, options).then((request) => request(this.axios, this.basePath));
    }
}

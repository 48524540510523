import { createContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ProfileApi } from "../apiclient";
import {ProfileViewModel, Platform} from "../apiclient/models"
import { initializeTracking } from "../components/TrackingScripts";
import { apiConfig, shopifyAppBridge } from "../config";
import {Provider, Loading, useAppBridge} from '@shopify/app-bridge-react';
import {Buffer} from 'buffer';
import { axiosInstance } from "../ShopifyAxiosInterceptor";
import { useNavigate } from "react-router-dom";


export interface SignInProps {
    authenticated: boolean,
    account: ProfileViewModel | null,
    shopifyHost: string | null,
    signIn: () => void,
    signOut: () => void,
    refresh: () => void
}
const initialState: SignInProps = {
    authenticated: false,
    account: null,
    shopifyHost: null,
    signIn: () => {

    },
    signOut: () => {

    },
    refresh: () => {

    }
}


const AuthContext = createContext(initialState);

function AuthProvider({ children }: any) {
    let navigate = useNavigate();

    let accountApi = new ProfileApi(
        apiConfig.configuration,
        apiConfig.baseUrl,
        axiosInstance
    );

    const [loading, setLoading] = useState(false);

    //try{
    //    let shopifyApp = useAppBridge();
    //    shopifyAppBridge.app = shopifyApp;
    //}catch(error){
    //    // not shopify embedded, so don't throw an error
    //}

    const signIn = () => {
        console.log("signIn");
        if (!loading) {
            setLoading(true);

                accountApi.profileProfileGet()
                    .then(response => {
                        console.log("profileProfileGet", response);

                        setState({ ...state, authenticated: true, account: response.data });

                        //init scripts
                        initializeTracking(response.data);
                        setLoading(false);

                        // redirect to onboarding steps if needed
                        var accountData = response.data as ProfileViewModel;
                        console.log('accountData', accountData);
                        if(!accountData || !accountData.accountId){
                            window.location.href = "/account/login";
                        }
                        else if (accountData.accountStatus === "AbuseDisabled" || accountData.accountStatus === "AdminDisabled"){
                            setState({ ...state, authenticated: false, account: null })
                            window.location.href="/account/login";
                        }
                        else if(!accountData.hdyhau && window.location.href.indexOf("/onboarding/complete-profile") === -1){
                            navigate("/onboarding/complete-profile");
                        }
                        //else if(accountData.integration == Platform.None && window.location.href.indexOf("/onboarding/connect-store") === -1){
                        //    navigate("/onboarding/connect-store");
                        //}
                        

                    }).catch((error: any) => {
                        if (error.response?.status === 401) {
                            setState({ ...state, authenticated: false, account: null })
                            window.location.href="/account/login";
                        }
                        else if (error.response?.status === 404) {
                            setState({ ...state, authenticated: true, account: null })
                        }
                        setLoading(false);
                    })
        }
    };

    const refresh = () => {
                console.log('refresh')
                accountApi.profileProfileGet()
                    .then(response => {
                        console.log("profileProfileGet", response);
                        setState({ ...state, authenticated: true, account: response.data })
                    }).catch((error: any) => {
                        console.log("error refresh", error);
                        if (error.response?.status === 401) {
                            setState({ ...state, authenticated: false, account: null })
                        }
                        else if (error.response?.status === 404) {
                            setState({ ...state, authenticated: true, account: null })
                        }
                    })
        };

    const signOut = () => {
        window.location.href = '/Account/Logout'
    };

    const [state, setState] = useState({ ...initialState, signOut: signOut, signIn: signIn, refresh: refresh });
    
    useEffect(() => {
        const initialize = () => {
            if (!state.account) {
                signIn();
            }
        }
    initialize();
  },[state]);

    const loadContent = (children:any) => {

        if(loading){
            return (
                <div className="d-flex justify-content-center align-items-center">
                    <h2><Spinner animation="border" /></h2>
                </div>
            );
        }else{

            return (<>{children}</>);
        }
    }

    return (
        <AuthContext.Provider value={{ ...state }}>
           {loadContent(children)}
        </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };


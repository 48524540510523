import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CustomersApi } from "../../apiclient";
import { CustomerListItemViewModel } from "../../apiclient/models";
import _ from "lodash";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const CustomerDetailPage = () => {
  let customerApi = new CustomersApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [loading, setLoading] = useState(true as boolean);
  const authContext = useContext(AuthContext);
  //const dayjs = require("dayjs");
  const [data, setData] = useState([] as CustomerListItemViewModel[]);
  const [pageCount, setPageCount] = React.useState(0);

  return (
    <React.Fragment>
      <div className="flex-lg-1 h-screen overflow-y-lg-auto">
        <header className="bg-surface-secondary">
          <div className="container-fluid max-w-screen-xl mt-4">
            <div className="row g-0">
              <div className="col pt-4">
                <h6 className="text-xs text-uppercase text-muted mb-1">
                  Store credit holder
                </h6>
                <h1 className="lh-tight ls-tighter font-bolder h2 mb-2">
                  Brady Thomas
                </h1>
                {/* <div className="d-flex gap-2 flex-wrap mt-3 d-none d-sm-block">
                  <a
                    href="#"
                    className="bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                  >
                    VIP
                  </a>{" "}
                </div> */}
              </div>
              <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3 hstack gap-2 mt-4 mt-sm-0">
                <a
                  href="#"
                  className="btn btn-sm btn-primary d-block d-md-inline-block ms-auto ms-md-0"
                >
                  Adjust balance
                </a>
              </div>
            </div>
          </div>
        </header>
        <div className="py-6 bg-surface-secondary">
          <div className="container-fluid max-w-screen-xl">
            <div className="row g-6">
              <div className="col-xl-4">
                <div className="vstack gap-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <h5 className="mb-4">Details</h5>
                        <div className="ms-auto text-end">
                          <a className="text-sm font-semibold cursor-pointer">
                            Edit
                          </a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="bi bi-envelope-fill me-2 text-muted"></i>{" "}
                        <a
                          href="#"
                          className="text-sm text-heading text-primary-hover"
                        >
                          thomasbrad1@gmail.com
                        </a>
                      </div>
                      <div className="d-flex align-items-center mt-2">
                        <i className="bi bi-tags-fill me-2 text-muted"></i>{" "}
                        <div className="d-flex gap-2 flex-wrap">
                          <a
                            href="#"
                            className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                          >
                            VIP
                          </a>
                          <a
                            href="#"
                            className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                          >
                            Football
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="mb-4">Store credit code</h5>
                      <div className="d-flex align-items-center">
                        <i className="bi bi-gift-fill me-2 text-muted"></i>{" "}
                        <a
                          href="#"
                          className="text-sm text-heading text-primary-hover"
                        >
                          3f66e2be8664687e{" "}
                          <button className="btn btn-sm btn-square btn-neutral">
                            <span className="bi bi-arrow-clockwise"></span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <h5 className="mb-4">Active gift cards (3)</h5>
                      <div className="">
                        <div className="bg-light list-group">
                          <div className="list-group-item d-flex align-items-center">
                            <div className="flex-fill ms-4 text-limit">
                              <a href="#" className="d-block mb-2 h6">
                                D5JW-SYS8-FQO3-1D5B
                              </a>
                              <div className="progress progress-sm mb-1">
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  style={{
                                    width: 100 + "%",
                                  }}
                                  aria-valuenow={100}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                ></div>
                              </div>
                              <div className="d-flex justify-content-between text-xs text-muted text-end mt-1">
                                <div>
                                  <span className="badge text-uppercase bg-opacity-30 bg-success text-success">
                                    Full
                                  </span>
                                </div>
                                <div>
                                  <p className="card-text text-muted">
                                    <time>$5.00 / $5.00 NZD</time>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="text-end ms-7">
                              <div className="ms-auto text-end align-self-center">
                                <i className="bi bi-chevron-right"></i>
                              </div>
                            </div>
                          </div>
                          <div className="list-group-item d-flex align-items-center">
                            <div className="flex-fill ms-4 text-limit">
                              <a href="#" className="d-block mb-2 h6">
                                718S-QZO9-LIQI-PUB8
                              </a>
                              <div className="progress progress-sm mb-1">
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  style={{
                                    width: 100 + "%",
                                  }}
                                  aria-valuenow={100}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                ></div>
                              </div>
                              <div className="d-flex justify-content-between text-xs text-muted text-end mt-1">
                                <div>
                                  <span className="badge text-uppercase bg-opacity-30 bg-success text-success">
                                    Full
                                  </span>
                                </div>
                                <div>
                                  <p className="card-text text-muted">
                                    <time>$56.00 / $56.00 NZD</time>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="text-end ms-7">
                              <div className="ms-auto text-end align-self-center">
                                <i className="bi bi-chevron-right"></i>
                              </div>
                            </div>
                          </div>
                          <div className="list-group-item d-flex align-items-center">
                            <div className="flex-fill ms-4 text-limit">
                              <a href="#" className="d-block mb-2 h6">
                                ESKG-Q4RQ-6IIQ-0W4P
                              </a>
                              <div className="progress progress-sm mb-1">
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  style={{
                                    width: 50 + "%",
                                  }}
                                  aria-valuenow={50}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                ></div>
                              </div>
                              <div className="d-flex justify-content-between text-xs text-muted text-end mt-1">
                                <div>
                                  <span className="badge text-uppercase bg-opacity-30 bg-success text-success">
                                    Partial
                                  </span>
                                </div>
                                <div>
                                  <p className="card-text text-muted">
                                    <time>50.10 / $100.00</time>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="text-end ms-7">
                              <div className="ms-auto text-end align-self-center">
                                <i className="bi bi-chevron-right"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="vstack gap-2">
                  <div className="row g-6">
                    <div className="col-xl-4 col-sm-6 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                Store credit balance
                              </span>{" "}
                              <span className="h3 font-bold mb-0">$750.90</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                <i className="bi bi-gift"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                Gift card(s) balance
                              </span>{" "}
                              <span className="h3 font-bold mb-0">
                                $140.23 NZD
                              </span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-info text-white text-lg rounded-circle">
                                <i className="bi bi-credit-card"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                Lifetime value
                              </span>{" "}
                              <span className="h3 font-bold mb-0">
                                700.82 NZD
                              </span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                <i className="bi bi-people"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="nav nav-tabs overflow-x ms-1 mb-3">
                    <li className="nav-item">
                      <a href="#!" className="nav-link active font-bold">
                        Store credit activity
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#!" className="nav-link">
                        Timeline
                      </a>
                    </li>
                  </ul>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="mb-6">Store credit activity</h5>
                      <div className="table-responsive">
                        <table className="table table-hover table-nowrap">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">Source</th>
                              <th scope="col">Store credit amount</th>
                              <th scope="col">Notes</th>
                              <th scope="col">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td data-label="Source">
                                <span className="badge bg-opacity-30 bg-dark text-dark">
                                  Order
                                </span>
                              </td>
                              <td data-label="Amount">
                                <span className="">-10.00 NZD</span>
                              </td>
                              <td data-label="Lead Score">
                                <span>
                                  Order{" "}
                                  <a className="text-link" href="#">
                                    #1015
                                  </a>
                                </span>
                              </td>
                              <td data-label="Company">Jun 8, 2023 22:08</td>
                            </tr>
                            <tr>
                              <td data-label="Source">
                                <span className="badge bg-opacity-30 bg-dark text-dark">
                                  Manual
                                </span>
                              </td>
                              <td data-label="Amount">
                                <span className="text-success">10.00 NZD</span>
                              </td>
                              <td data-label="Lead Score">
                                <span>
                                  Order{" "}
                                  <a className="text-link" href="#">
                                    #1014
                                  </a>
                                </span>
                              </td>
                              <td data-label="Company">Jun 7, 2023 22:08</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerDetailPage;

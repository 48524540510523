import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { GiftCardDetailViewModel } from "../../apiclient/models";
import Util from "../../js/util";

interface GiftCardMergeModalComponentProps {
  giftCard: GiftCardDetailViewModel;
  onHide: any;
  show: boolean;
  onRefresh: any;
}

const GiftCardMergeModalComponent = (
  props: GiftCardMergeModalComponentProps
) => {
  //modal + form
  let { id } = useParams();
  const [model, setModel] = useState({} as GiftCardDetailViewModel);
  const [mergeGiftCardCode, setMergedGiftCardCode] = useState("");
  const [validated, setValidated] = useState(false);
  let cardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setMergedGiftCardCode(value);
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      //setIsSubmitting(false);
      getGiftCardByCode();
    }
  };
  const modalLoaded = () => {
    setModel({});
    setMergedGiftCardCode("");
  };

  const getGiftCardByCode = () => {
    if (mergeGiftCardCode) {
      cardApi
        .giftcardGetByCodeCodeGet(mergeGiftCardCode, id)
        .then((response) => {
          if (response.data) {
            setModel(response.data);
          } else {
            toast.error("Invalid code.");
            //setShowInvalidCodeError(true);
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred check code.");
        });
    }
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide} onEntered={modalLoaded}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Combine a gift card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="h3">Current Balance: {props.giftCard.formattedBalance}</div> */}
            <Row>
              <Form.Group className="mt-5">
                <Form.Label>Gift card to find:</Form.Label>
                <Form.Control
                  placeholder="Code"
                  name="mergeGiftCardCode"
                  value={mergeGiftCardCode || ""}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Gift card code required.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {model && model.id &&
            <div>
            <hr className="mt-8 mb-0" />
            <div className="list-group list-group-flush">
              <div className="list-group-item d-flex align-items-center px-0">
                <div className="flex-fill">
                  <div className="d-block h6 font-semibold mb-1">{model.formattedBalance}</div>
                  <span className="d-block text-sm mb-2">Expires: {model.expiresOn ? model.expiresOn : "never"}</span>
                  <div className="d-flex mx-n1"></div>
                </div>
                <div className="ms-auto text-end">
                  <a href="#" className="btn btn-sm btn-primary">
                    Combine
                  </a>
                </div>
              </div>
            </div>
            </div>}
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="primary" type="submit">
              Combine
            </Button>
          </Modal.Footer> */}
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default GiftCardMergeModalComponent;

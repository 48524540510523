import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { FormCheck, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const RefundExplainerPage = () => {
  const authContext = useContext(AuthContext);
  return (
    <React.Fragment>
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  <h1 className="h2 ls-tight">Refunds and returns</h1>
                </div>
                <p className="text-sm pb-5">
                Issue gift cards as part of your refunds and returns process, reducing return costs by up to 42%.{" "}
                  <a className="" href="#">
                  Learn how to issue gift card refunds
                  </a>
                </p>
              </div>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid vstack gap-3">
            <div className="card mb-3" style={{ maxWidth: "540px;" }}>
              <div className="row g-0">
                <div className="col-md-4">
                  <img
                    src="/img/assets/Check_Balance.svg"
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h4 className="card-title">Shopify process</h4>
                    {/* <p className="card-text">
                    Allow customers to check their Gift Card balance in your online store.
                    </p> */}
                    <div className="vstack gap-5">
                      <div className="hstack gap-2 justify-content-end">
                        <Link
                          className="btn btn-sm btn-primary"
                          to={`/assets/check-balance-page`}
                        >
                          Customize
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3" style={{ maxWidth: "540px;" }}>
              <div className="row g-0">
                <div className="col-md-4">
                  <img
                    src="/img/assets/Apple_Wallet.svg"
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h4 className="card-title">Lightspeed X-Series process</h4>
                    {/* <p className="card-text">
                    Use gift cards as part of your refunds and returns policy, reducing return costs by up to 42%.
                    </p> */}
                    <div className="vstack gap-5">
                      <div className="hstack gap-2 justify-content-end">
                        <Link
                          className="btn btn-sm btn-primary"
                          to={`/assets/apple-wallet`}
                        >
                          Customize
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default RefundExplainerPage;

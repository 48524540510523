import { useEffect, useRef, useState } from "react";
import { useMediaDevices } from "react-media-devices";
import { useZxing } from "react-zxing";

const constraints: MediaStreamConstraints = {
  video: true,
  audio: false,
};

export const BarcodeScanner = (props: any) => {
  const { devices } = useMediaDevices({ constraints });
  const deviceId = devices?.[0]?.deviceId;
  const videoRef = useRef(null);
  const [debugMessage, setDebugMessage] = useState("");
  const { ref } = useZxing({
    constraints: { video: { facingMode: props.facingMode }, audio: false },
    paused: props.paused,
    onDecodeResult(result) {
      props.onScanSucess(result);
    },
    onError(error : any) {
        console.log("barcode init error", error);
        //setDebugMessage("err-" + JSON.stringify(error));
        //props.onScanError(error);
    },
  });

  useEffect(() => {
    // Cleanup: Stop the camera stream when the component unmounts
    return () => {
      if (ref.current) {
        props.paused = true;
        ref.current.pause(); 
      }
    };
  }, []);

  return (
    <div
      id="reader__scan_region"
      style={{
        width: "100%",
        minHeight: "100px",
        textAlign: "center",
        position: "relative",
      }}
    >
      {/* <div>Debug: {JSON.stringify(props)}</div> */}
      <video
        playsInline
        autoPlay
        ref={ref}
        style={{ width: "887px", display: "block" }}
      />

      {!props.paused && (
        <div
          id="qr-shaded-region"
          style={{
            position: "absolute",
            borderWidth: "83.5px 194.5px",
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.48)",
            boxSizing: "border-box",
            inset: "0px",
          }}
        >
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgb(255, 255, 255)",
              width: "40px",
              height: "5px",
              top: "-5px",
              left: "0px",
            }}
          />
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgb(255, 255, 255)",
              width: "40px",
              height: "5px",
              top: "-5px",
              right: "0px",
            }}
          />
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgb(255, 255, 255)",
              width: "40px",
              height: "5px",
              bottom: "-5px",
              left: "0px",
            }}
          />
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgb(255, 255, 255)",
              width: "40px",
              height: "5px",
              bottom: "-5px",
              right: "0px",
            }}
          />
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgb(255, 255, 255)",
              width: "5px",
              height: "45px",
              top: "-5px",
              left: "-5px",
            }}
          />
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgb(255, 255, 255)",
              width: "5px",
              height: "45px",
              bottom: "-5px",
              left: "-5px",
            }}
          />
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgb(255, 255, 255)",
              width: "5px",
              height: "45px",
              top: "-5px",
              right: "-5px",
            }}
          />
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgb(255, 255, 255)",
              width: "5px",
              height: "45px",
              bottom: "-5px",
              right: "-5px",
            }}
          />
        </div>
      )}
    </div>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthenticateDomainPayload } from '../models';
// @ts-ignore
import { EmailNotificationReminderSettingsSaveViewModel } from '../models';
// @ts-ignore
import { EmailNotificationReminderViewModel } from '../models';
// @ts-ignore
import { EmailNotificationSubjectViewModel } from '../models';
// @ts-ignore
import { EmailNotificationViewModel } from '../models';
// @ts-ignore
import { ProcessResultModel } from '../models';
// @ts-ignore
import { RegisterDomainPayload } from '../models';
// @ts-ignore
import { SenderDomainInstallPageViewModel } from '../models';
// @ts-ignore
import { SenderDomainsPageViewModel } from '../models';
// @ts-ignore
import { SenderDomainsVerifyCodeModelComponentViewModel } from '../models';
// @ts-ignore
import { TestEmailNotificationViewModel } from '../models';
// @ts-ignore
import { UnsubscribePageModel } from '../models';
// @ts-ignore
import { UnsubscribePagePostModel } from '../models';
// @ts-ignore
import { VerifyDomainPayload } from '../models';
/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AuthenticateDomainPayload} [authenticateDomainPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailAuthenticateDomainPost: async (apiVersion?: string, authenticateDomainPayload?: AuthenticateDomainPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/AuthenticateDomain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticateDomainPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailDeleteDomainIdDelete: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailDeleteDomainIdDelete', 'id', id)
            const localVarPath = `/Email/DeleteDomain/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailDomainUpdateDnsTimeIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailDomainUpdateDnsTimeIdGet', 'id', id)
            const localVarPath = `/Email/DomainUpdateDnsTime/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [uid] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailGetUnsubscribePageModelGet: async (accountId?: string, uid?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/GetUnsubscribePageModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} notificationType 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailNotificationNotificationTypeGet: async (notificationType: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationType' is not null or undefined
            assertParamExists('emailNotificationNotificationTypeGet', 'notificationType', notificationType)
            const localVarPath = `/Email/Notification/{notificationType}`
                .replace(`{${"notificationType"}}`, encodeURIComponent(String(notificationType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} notificationType 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailNotificationSettingsNotificationTypeGet: async (notificationType: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationType' is not null or undefined
            assertParamExists('emailNotificationSettingsNotificationTypeGet', 'notificationType', notificationType)
            const localVarPath = `/Email/Notification/Settings/{notificationType}`
                .replace(`{${"notificationType"}}`, encodeURIComponent(String(notificationType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} notificationType 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailNotificationToggleEnabledNotificationTypeGet: async (notificationType: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationType' is not null or undefined
            assertParamExists('emailNotificationToggleEnabledNotificationTypeGet', 'notificationType', notificationType)
            const localVarPath = `/Email/Notification/ToggleEnabled/{notificationType}`
                .replace(`{${"notificationType"}}`, encodeURIComponent(String(notificationType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RegisterDomainPayload} [registerDomainPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailRegisterDomainPost: async (apiVersion?: string, registerDomainPayload?: RegisterDomainPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/RegisterDomain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDomainPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EmailNotificationViewModel} [emailNotificationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSaveNotificationPost: async (apiVersion?: string, emailNotificationViewModel?: EmailNotificationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/SaveNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailNotificationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EmailNotificationReminderSettingsSaveViewModel} [emailNotificationReminderSettingsSaveViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSaveNotificationReminderSettingsPost: async (apiVersion?: string, emailNotificationReminderSettingsSaveViewModel?: EmailNotificationReminderSettingsSaveViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/SaveNotificationReminderSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailNotificationReminderSettingsSaveViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EmailNotificationSubjectViewModel} [emailNotificationSubjectViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSaveNotificationSubjectPost: async (apiVersion?: string, emailNotificationSubjectViewModel?: EmailNotificationSubjectViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/SaveNotificationSubject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailNotificationSubjectViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSendDomainVerificationEmailIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailSendDomainVerificationEmailIdGet', 'id', id)
            const localVarPath = `/Email/SendDomainVerificationEmail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {TestEmailNotificationViewModel} [testEmailNotificationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSendTestEmailPost: async (apiVersion?: string, testEmailNotificationViewModel?: TestEmailNotificationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/SendTestEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testEmailNotificationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSenderDomainInstallIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailSenderDomainInstallIdGet', 'id', id)
            const localVarPath = `/Email/SenderDomainInstall/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSenderDomainsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/SenderDomains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSenderDomainsVerifyCodeIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailSenderDomainsVerifyCodeIdGet', 'id', id)
            const localVarPath = `/Email/SenderDomainsVerifyCode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UnsubscribePagePostModel} [unsubscribePagePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailToggleUnsubscribePost: async (apiVersion?: string, unsubscribePagePostModel?: UnsubscribePagePostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/ToggleUnsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unsubscribePagePostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyDomainPayload} [verifyDomainPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailVerifyDomainPost: async (apiVersion?: string, verifyDomainPayload?: VerifyDomainPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Email/VerifyDomain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyDomainPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AuthenticateDomainPayload} [authenticateDomainPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailAuthenticateDomainPost(apiVersion?: string, authenticateDomainPayload?: AuthenticateDomainPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailAuthenticateDomainPost(apiVersion, authenticateDomainPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailDeleteDomainIdDelete(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailDeleteDomainIdDelete(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailDomainUpdateDnsTimeIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailDomainUpdateDnsTimeIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [uid] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailGetUnsubscribePageModelGet(accountId?: string, uid?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnsubscribePageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailGetUnsubscribePageModelGet(accountId, uid, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} notificationType 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailNotificationNotificationTypeGet(notificationType: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailNotificationViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailNotificationNotificationTypeGet(notificationType, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} notificationType 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailNotificationSettingsNotificationTypeGet(notificationType: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailNotificationReminderViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailNotificationSettingsNotificationTypeGet(notificationType, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} notificationType 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailNotificationToggleEnabledNotificationTypeGet(notificationType: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailNotificationToggleEnabledNotificationTypeGet(notificationType, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RegisterDomainPayload} [registerDomainPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailRegisterDomainPost(apiVersion?: string, registerDomainPayload?: RegisterDomainPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailRegisterDomainPost(apiVersion, registerDomainPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EmailNotificationViewModel} [emailNotificationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailSaveNotificationPost(apiVersion?: string, emailNotificationViewModel?: EmailNotificationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailSaveNotificationPost(apiVersion, emailNotificationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EmailNotificationReminderSettingsSaveViewModel} [emailNotificationReminderSettingsSaveViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailSaveNotificationReminderSettingsPost(apiVersion?: string, emailNotificationReminderSettingsSaveViewModel?: EmailNotificationReminderSettingsSaveViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailSaveNotificationReminderSettingsPost(apiVersion, emailNotificationReminderSettingsSaveViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EmailNotificationSubjectViewModel} [emailNotificationSubjectViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailSaveNotificationSubjectPost(apiVersion?: string, emailNotificationSubjectViewModel?: EmailNotificationSubjectViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailSaveNotificationSubjectPost(apiVersion, emailNotificationSubjectViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailSendDomainVerificationEmailIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailSendDomainVerificationEmailIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {TestEmailNotificationViewModel} [testEmailNotificationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailSendTestEmailPost(apiVersion?: string, testEmailNotificationViewModel?: TestEmailNotificationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailSendTestEmailPost(apiVersion, testEmailNotificationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailSenderDomainInstallIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SenderDomainInstallPageViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailSenderDomainInstallIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailSenderDomainsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SenderDomainsPageViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailSenderDomainsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailSenderDomainsVerifyCodeIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SenderDomainsVerifyCodeModelComponentViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailSenderDomainsVerifyCodeIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UnsubscribePagePostModel} [unsubscribePagePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailToggleUnsubscribePost(apiVersion?: string, unsubscribePagePostModel?: UnsubscribePagePostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnsubscribePageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailToggleUnsubscribePost(apiVersion, unsubscribePagePostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyDomainPayload} [verifyDomainPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailVerifyDomainPost(apiVersion?: string, verifyDomainPayload?: VerifyDomainPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailVerifyDomainPost(apiVersion, verifyDomainPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AuthenticateDomainPayload} [authenticateDomainPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailAuthenticateDomainPost(apiVersion?: string, authenticateDomainPayload?: AuthenticateDomainPayload, options?: any): AxiosPromise<boolean> {
            return localVarFp.emailAuthenticateDomainPost(apiVersion, authenticateDomainPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailDeleteDomainIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<ProcessResultModel> {
            return localVarFp.emailDeleteDomainIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailDomainUpdateDnsTimeIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ProcessResultModel> {
            return localVarFp.emailDomainUpdateDnsTimeIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [uid] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailGetUnsubscribePageModelGet(accountId?: string, uid?: string, apiVersion?: string, options?: any): AxiosPromise<UnsubscribePageModel> {
            return localVarFp.emailGetUnsubscribePageModelGet(accountId, uid, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} notificationType 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailNotificationNotificationTypeGet(notificationType: string, apiVersion?: string, options?: any): AxiosPromise<EmailNotificationViewModel> {
            return localVarFp.emailNotificationNotificationTypeGet(notificationType, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} notificationType 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailNotificationSettingsNotificationTypeGet(notificationType: string, apiVersion?: string, options?: any): AxiosPromise<EmailNotificationReminderViewModel> {
            return localVarFp.emailNotificationSettingsNotificationTypeGet(notificationType, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} notificationType 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailNotificationToggleEnabledNotificationTypeGet(notificationType: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.emailNotificationToggleEnabledNotificationTypeGet(notificationType, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {RegisterDomainPayload} [registerDomainPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailRegisterDomainPost(apiVersion?: string, registerDomainPayload?: RegisterDomainPayload, options?: any): AxiosPromise<ProcessResultModel> {
            return localVarFp.emailRegisterDomainPost(apiVersion, registerDomainPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EmailNotificationViewModel} [emailNotificationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSaveNotificationPost(apiVersion?: string, emailNotificationViewModel?: EmailNotificationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.emailSaveNotificationPost(apiVersion, emailNotificationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EmailNotificationReminderSettingsSaveViewModel} [emailNotificationReminderSettingsSaveViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSaveNotificationReminderSettingsPost(apiVersion?: string, emailNotificationReminderSettingsSaveViewModel?: EmailNotificationReminderSettingsSaveViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.emailSaveNotificationReminderSettingsPost(apiVersion, emailNotificationReminderSettingsSaveViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {EmailNotificationSubjectViewModel} [emailNotificationSubjectViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSaveNotificationSubjectPost(apiVersion?: string, emailNotificationSubjectViewModel?: EmailNotificationSubjectViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.emailSaveNotificationSubjectPost(apiVersion, emailNotificationSubjectViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSendDomainVerificationEmailIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<ProcessResultModel> {
            return localVarFp.emailSendDomainVerificationEmailIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {TestEmailNotificationViewModel} [testEmailNotificationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSendTestEmailPost(apiVersion?: string, testEmailNotificationViewModel?: TestEmailNotificationViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.emailSendTestEmailPost(apiVersion, testEmailNotificationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSenderDomainInstallIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SenderDomainInstallPageViewModel> {
            return localVarFp.emailSenderDomainInstallIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSenderDomainsGet(apiVersion?: string, options?: any): AxiosPromise<SenderDomainsPageViewModel> {
            return localVarFp.emailSenderDomainsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSenderDomainsVerifyCodeIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<SenderDomainsVerifyCodeModelComponentViewModel> {
            return localVarFp.emailSenderDomainsVerifyCodeIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {UnsubscribePagePostModel} [unsubscribePagePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailToggleUnsubscribePost(apiVersion?: string, unsubscribePagePostModel?: UnsubscribePagePostModel, options?: any): AxiosPromise<UnsubscribePageModel> {
            return localVarFp.emailToggleUnsubscribePost(apiVersion, unsubscribePagePostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {VerifyDomainPayload} [verifyDomainPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailVerifyDomainPost(apiVersion?: string, verifyDomainPayload?: VerifyDomainPayload, options?: any): AxiosPromise<ProcessResultModel> {
            return localVarFp.emailVerifyDomainPost(apiVersion, verifyDomainPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AuthenticateDomainPayload} [authenticateDomainPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailAuthenticateDomainPost(apiVersion?: string, authenticateDomainPayload?: AuthenticateDomainPayload, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailAuthenticateDomainPost(apiVersion, authenticateDomainPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailDeleteDomainIdDelete(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailDeleteDomainIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailDomainUpdateDnsTimeIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailDomainUpdateDnsTimeIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [uid] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailGetUnsubscribePageModelGet(accountId?: string, uid?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailGetUnsubscribePageModelGet(accountId, uid, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} notificationType 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailNotificationNotificationTypeGet(notificationType: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailNotificationNotificationTypeGet(notificationType, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} notificationType 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailNotificationSettingsNotificationTypeGet(notificationType: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailNotificationSettingsNotificationTypeGet(notificationType, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} notificationType 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailNotificationToggleEnabledNotificationTypeGet(notificationType: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailNotificationToggleEnabledNotificationTypeGet(notificationType, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {RegisterDomainPayload} [registerDomainPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailRegisterDomainPost(apiVersion?: string, registerDomainPayload?: RegisterDomainPayload, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailRegisterDomainPost(apiVersion, registerDomainPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {EmailNotificationViewModel} [emailNotificationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailSaveNotificationPost(apiVersion?: string, emailNotificationViewModel?: EmailNotificationViewModel, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailSaveNotificationPost(apiVersion, emailNotificationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {EmailNotificationReminderSettingsSaveViewModel} [emailNotificationReminderSettingsSaveViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailSaveNotificationReminderSettingsPost(apiVersion?: string, emailNotificationReminderSettingsSaveViewModel?: EmailNotificationReminderSettingsSaveViewModel, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailSaveNotificationReminderSettingsPost(apiVersion, emailNotificationReminderSettingsSaveViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {EmailNotificationSubjectViewModel} [emailNotificationSubjectViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailSaveNotificationSubjectPost(apiVersion?: string, emailNotificationSubjectViewModel?: EmailNotificationSubjectViewModel, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailSaveNotificationSubjectPost(apiVersion, emailNotificationSubjectViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailSendDomainVerificationEmailIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailSendDomainVerificationEmailIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {TestEmailNotificationViewModel} [testEmailNotificationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailSendTestEmailPost(apiVersion?: string, testEmailNotificationViewModel?: TestEmailNotificationViewModel, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailSendTestEmailPost(apiVersion, testEmailNotificationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailSenderDomainInstallIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailSenderDomainInstallIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailSenderDomainsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailSenderDomainsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailSenderDomainsVerifyCodeIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailSenderDomainsVerifyCodeIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {UnsubscribePagePostModel} [unsubscribePagePostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailToggleUnsubscribePost(apiVersion?: string, unsubscribePagePostModel?: UnsubscribePagePostModel, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailToggleUnsubscribePost(apiVersion, unsubscribePagePostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {VerifyDomainPayload} [verifyDomainPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailVerifyDomainPost(apiVersion?: string, verifyDomainPayload?: VerifyDomainPayload, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailVerifyDomainPost(apiVersion, verifyDomainPayload, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { useContext, useState, useEffect, useRef } from "react";
import TimezoneSelect from "react-timezone-select";
import { AssetApi, GiftcardApi } from "../../apiclient";
import {
  CheckBalancePageSettingsViewModel,
  GiftCardDetailViewModel,
  RegisterInstoreGiftCardViewModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Tabs,
  Tab,
  InputGroup,
} from "react-bootstrap";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import ContentEditable from "react-contenteditable";
//import "./assets.css";
const PublicRegisterInstoreGiftCardPage = () => {
  let { id, platformId } = useParams();
  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  let cardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [isLoading, setLoading] = useState(false as boolean);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showInvalidCodeError, setShowInvalidCodeError] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false as boolean);
  const [showGiftToFriendModal, setShowGiftToFriendModal] = useState(false as boolean);
  const [model, setModel] = useState({} as CheckBalancePageSettingsViewModel);
  const [giftCardModel, setGiftCardModel] = useState(
    {} as GiftCardDetailViewModel
  );
  const [postModel, setPostModel] = useState(
    {} as RegisterInstoreGiftCardViewModel
  );
  const [showTab, setShowTab] = useState("main");
  const [searchParams, setSearchParams] = useSearchParams();
  const text = useRef("");
  const [giftCardCode, setGiftCardCode] = useState(
    searchParams.get("code") ?? ""
  );

  useEffect(() => {
    fetchData();
    setPostModel((values: RegisterInstoreGiftCardViewModel) => ({
      ...values,
      accountPlatformId: platformId,
    }));
  }, []);

  const fetchData = () => {
    assetApi
      .assetCheckBalancePagePublicIdGet(id!)
      .then((response) => {
        var data = response.data;
        setModel(data);
        setLoading(false);

        //getGiftCardByCode();
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);

      cardApi
        .giftcardRegisterInstoreCardPost("1", postModel)
        .then((response) => {
          if (response.data) {
            setGiftCardModel(response.data);
            setGiftCardCode(response.data.code!);
          } else {
            toast.error("Invalid code.");
            setShowInvalidCodeError(true);
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred check code.");
        });

    }
  };

  const logout = () => {
    setGiftCardModel({});
    setGiftCardCode("");
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setPostModel((values: RegisterInstoreGiftCardViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <LoaderIcon />
        </>
      ) : (
        <div className="d-xl-flex row">
          <div className="col-md-12 flex-xl-none border-start-xl h-xl-calc position-sticky top-xl-18" style={{
              backgroundColor: model.backgroundColor,
            }}>
            <aside className="p-8 h-full overflow-y-xl-auto">
              <div className="vstack gap-6">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="">
                    {giftCardModel && giftCardModel.id ? (
                      <div className="">
                        <div className="container py-10">
                          <div className="row mb-10 text-center">
                            <div className="d-grid gap-2 mx-auto">
                              <div className="card mb-10" style={{
                              backgroundColor: model.cardColor,
                            }}>
                                <div className="card-header d-flex align-items-center">
                                  <h5 style={{
                                      color: model.primaryColor,
                                    }}>Balance</h5>
                                  <div className="ms-auto">
                                    <button
                                      onClick={logout}
                                      className="btn btn-sm btn-neutral ms-2"
                                    >
                                      Check another card
                                    </button>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="row g-5">
                                    <div className="col-12">
                                      <h5 className="font-semibold" style={{
                                      color: model.primaryColor,
                                    }}>
                                        {model.codeInsertedPageTitleText}
                                      </h5>
                                      <h2 className="font-semibold" style={{
                                      color: model.primaryColor,
                                    }}>
                                        {giftCardModel.formattedBalance}
                                      </h2>
                                      <h5 className="font-semibold mt-10" style={{
                                      color: model.primaryColor,
                                    }}>
                                        {model.codeInsertedPageExpirationText?.replace("{giftcard_expires_on}", "")}{" "}
                                        {giftCardModel.expiresOn ? giftCardModel.expiresOn : "Never"}
                                      </h5>
                                      <h5 className="font-semibold mt-2" style={{
                                      color: model.primaryColor,
                                    }}>
                                        Code: {giftCardCode}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="container py-10">
                        <div className="row mb-10">
                          <div className="mx-auto">
                            <div className="card mb-10" style={{
                                      backgroundColor: model.cardColor,
                                    }}>
                              <div className="card-body">
                                <div className="row g-5">
                                  <div className="col-12">
                                    <h2 className="font-semibold text-center" style={{
                                      color: model.primaryColor,
                                    }}>
                                      Register existing gift card to generate a new gift card that can be used both in-store and online
                                    </h2>
                                    <div className="">
                                      <label className="form-label mt-5" style={{
                                      color: model.primaryColor,
                                    }}>
                                        Gift code
                                      </label>
                                      <Form.Control
                                        name={"code"}
                                        className="form-control mt-5"
                                        type="text"
                                        value={postModel.code}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                    <div className="">
                                      <label className="form-label mt-5" style={{
                                      color: model.primaryColor,
                                    }}>
                                        Email address
                                      </label>
                                      <Form.Control
                                        name={"emailAddress"}
                                        className="form-control mt-5"
                                        type="text"
                                        placeholder={
                                          postModel.emailAddress
                                        }
                                        value={postModel.emailAddress}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                    <div className="">
                                      <label className="form-label mt-5" style={{
                                      color: model.primaryColor,
                                    }}>
                                        Name
                                      </label>
                                      <Form.Control
                                        name={"name"}
                                        className="form-control mt-5"
                                        type="text"
                                        placeholder={
                                          postModel.name
                                        }
                                        value={postModel.name}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="d-grid gap-2 mx-auto">
                                    <button
                                      className="btn btn-primary"
                                      type="submit"
                                      style={{
                                        backgroundColor: model.buttonColor,
                                      }}
                                    >
                                      Register
                                    </button>
                                  </div>
                                  {showInvalidCodeError && (
                                    <div className="font-semibold text-danger">
                                      {model.mainPageErrorText}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </aside>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PublicRegisterInstoreGiftCardPage;

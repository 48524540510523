import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const ErrorPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const requestId = searchParams.get("requestId") ?? "";
  return (
    <React.Fragment>
      <main className="d-flex flex-grow-1 align-items-center">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-lg-7 order-lg-2">
              <div className="svg-fluid">
                <svg
                  className=""
                  width="1600"
                  height="1200"
                  viewBox="0 0 1600 1200"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M78.7002 940.3C148.2 909.3 227.1 913.3 300 928.7C377.1 945 451.3 973.4 529.1 986.6C572.2 993.9 616.2 996.5 659.6 989.7C661.5 989.4 660.7 986.5 658.8 986.8C578.2 999.3 497.6 979.3 420.5 957.5C346.7 936.6 272 913.4 194.4 914C154.2 914.3 114.1 921.3 77.2002 937.7C75.4002 938.5 76.9002 941.1 78.7002 940.3Z"
                    fill="#E8E8E8"
                  ></path>
                  <path
                    d="M467.9 966.1C562.1 968 653.3 941.9 745.4 926.4C772.3 921.9 799.4 918.3 826.7 916.5C828.6 916.4 828.6 913.4 826.7 913.5C732.4 919.7 641.5 947.9 547.9 958.9C521.4 962 494.7 963.7 468 963.2C465.9 963.1 465.9 966.1 467.9 966.1Z"
                    fill="#E8E8E8"
                  ></path>
                  <path
                    d="M908.2 932.5C941.7 925.3 976.4 924.1 1010.3 929.3C1041.5 934.1 1071.4 944.4 1100.9 955.4C1130 966.2 1158.9 977.7 1189.1 985.4C1220.3 993.3 1252 995.3 1284.2 995.3C1322.2 995.3 1360.5 993.8 1397.8 1002.2C1399.7 1002.6 1400.5 999.7 1398.6 999.3C1332.7 984.5 1264.4 999.5 1198.5 984.5C1137 970.5 1080.8 939 1018.6 927.7C981.8 921 944 921.8 907.5 929.6C905.5 930 906.3 932.9 908.2 932.5Z"
                    fill="#E8E8E8"
                  ></path>
                  <path
                    d="M362 548C353.4 545.4 336.5 538.3 342.8 526.3C347.3 517.7 360.7 517 369 517.3C397 518.1 425 520.5 453 518.2C479.3 516 504.4 508.5 530 502.5C556 496.3 583.5 491.8 609.7 499.4C621.2 502.7 631.9 508.2 643.2 512C648.6 513.8 654.2 515.2 659.9 515.8C666.7 516.5 673.5 515.5 680.2 515.2C687.1 514.9 694.2 515.1 700.1 519.2C705.5 522.9 710 529.9 706.5 536.4C701.5 545.8 686.9 543.2 678.5 541.4C653.6 536.2 628.5 534.6 603.2 538C589.8 539.8 576.7 543.1 563.9 547.2C550.1 551.6 536.7 555.5 522 553.5C508.3 551.6 495.2 547.2 481.3 546.7C467.4 546.1 453.6 547.9 439.9 549.7C413.3 553.3 384.9 557.9 360 545C358.3 544.1 356.8 546.7 358.5 547.6C381.8 559.7 408.1 556.9 433.3 553.6C446.5 551.8 459.8 549.9 473.2 549.6C487.9 549.3 501.6 552.7 515.9 555.5C530.5 558.3 543.7 556.8 557.9 552.4C572 548 585.9 543.7 600.5 541.5C615.6 539.2 630.9 538.6 646.1 539.7C659.6 540.6 672.5 544.2 685.8 545.7C693.7 546.6 703.8 546.3 708.7 538.9C714.3 530.4 708.3 520.5 700.6 515.8C688.9 508.7 674.4 513.7 661.7 512.9C647.7 512 634.8 505.5 621.9 500.5C595.1 490.1 567.7 491.4 540 497.3C510.7 503.5 482.2 513 452.2 515.4C434.9 516.8 417.4 516.5 400.1 515.8C391.1 515.4 382 515 373 514.6C367.4 514.4 361.9 514.3 356.4 515.4C347.9 517.1 338.2 522.1 338.8 532.2C339.5 543.3 352.3 548.4 361.3 551.1C363 551.5 363.8 548.6 362 548Z"
                    fill="#E8E8E8"
                  ></path>
                  <path
                    d="M1100 179.1C1091.4 177.9 1077.3 174.8 1074.8 165C1071.2 151.4 1090.6 147.1 1100 145.4C1127.5 140.7 1155.7 145 1183.4 145C1238.3 144.9 1292.4 134.5 1347 130.6C1374.3 128.6 1401.8 128.3 1429 131.8C1435.2 132.6 1441.7 133.2 1447.7 135C1450.2 135.7 1453 136.9 1454.9 138.7C1459.5 143.2 1455 147.3 1450.8 149.5C1440 155.2 1427.7 157.5 1415.6 158C1391.4 159.1 1367.3 154.1 1343.1 155C1331.8 155.4 1320.7 157.3 1310 161.2C1297.5 165.8 1285.9 172.3 1273.3 176.6C1258.7 181.6 1243.3 184.1 1227.8 184.1C1212.1 184.1 1196.6 181.7 1181.1 179.9C1149.7 176.3 1118.2 176.4 1086.9 180.6C1085 180.9 1085 183.9 1086.9 183.6C1117.5 179.5 1148.5 179.2 1179.2 182.7C1194.4 184.4 1209.6 186.8 1225 187.1C1239.2 187.4 1253.5 185.6 1267.2 181.7C1281.2 177.7 1294 170.8 1307.5 165.4C1320.6 160.2 1334.1 157.9 1348.1 157.9C1373.9 157.7 1399.8 163.5 1425.6 160.1C1431.9 159.3 1438 157.9 1444 155.7C1448.6 154 1454.8 151.9 1458.1 148C1464.3 140.5 1455.3 133.9 1448.6 132C1435.4 128.1 1420.5 127.4 1406.8 126.6C1391.9 125.7 1377 125.8 1362.1 126.5C1301.8 129.4 1242.2 142 1181.7 141.8C1164.5 141.8 1147.3 140.2 1130.1 140.1C1115.5 140 1098.8 140.3 1085.1 146C1076.5 149.6 1068.1 158 1072.9 167.9C1077.4 177.2 1090.1 180.4 1099.3 181.7C1101.1 182.3 1101.9 179.4 1100 179.1Z"
                    fill="#E8E8E8"
                  ></path>
                  <path
                    d="M132.9 315.6C216.3 279.4 307.7 313.2 384.6 349.5C423.8 368 462.4 387.8 503.3 402.4C543.4 416.7 585.1 426.6 627.4 432.3C712.3 443.8 799.2 438.7 882.7 420C970.2 400.4 1053.1 364.8 1139.9 342.5C1230.2 319.3 1324.6 314.3 1416.7 329.2C1427.9 331 1439 333.1 1450.1 335.5C1452 335.9 1452.8 333 1450.9 332.6C1361.5 313.6 1268.6 312.7 1179 330.5C1089.4 348.3 1005.1 384.7 917.2 408.4C831.9 431.4 742.8 440.7 654.7 432.4C610.9 428.3 567.5 419.8 525.5 406.6C482.1 393 441.4 373.2 400.5 353.6C326.6 318.1 243.2 282.1 159.6 303.2C149.9 305.6 140.6 308.9 131.4 312.9C129.7 313.8 131.2 316.4 132.9 315.6Z"
                    fill="#E8E8E8"
                  ></path>
                  <path
                    d="M475.1 279.8C572.8 334.3 689.1 338.4 797.5 323.2C920.2 306 1038.4 265.5 1161.3 249.7C1229.7 240.9 1299.5 239.8 1367.2 254.8C1369.1 255.2 1369.9 252.3 1368 251.9C1248.3 225.5 1125.5 248.8 1008.3 276.1C892.8 303 776 334.4 656.3 326.7C593.8 322.7 531.6 307.8 476.6 277.2C475 276.2 473.5 278.8 475.1 279.8Z"
                    fill="#E8E8E8"
                  ></path>
                  <path
                    d="M183.4 93.1001C267.4 73.1001 357 81.0001 436.2 115.5C475.1 132.4 511.6 154.4 549.8 172.7C588.3 191.1 628.5 203.8 670.9 209.5C719.4 216 768.5 214.9 817.1 211.7C819 211.6 819 208.6 817.1 208.7C773.2 211.6 728.9 212.8 685.1 208.2C640.7 203.5 598.3 191.9 557.8 173.2C519.3 155.3 482.9 133.3 444.2 115.9C406.2 98.5001 365.9 87.0001 324.4 82.1001C277.1 76.4001 228.9 79.2001 182.6 90.2001C180.7 90.6001 181.5 93.5001 183.4 93.1001Z"
                    fill="#E8E8E8"
                  ></path>
                  <path
                    d="M1035.4 699.2C1026.6 697.4 1012.2 692.9 1011.5 682C1011.1 675.3 1016.7 670.4 1022.6 668.6C1029.7 666.3 1037.2 668.4 1044.1 670.4C1056.7 674.1 1069.4 677.1 1082.6 677.6C1094.9 678.1 1107.1 676.3 1119 673.1C1131.8 669.7 1144.3 665.1 1157 661.5C1170.4 657.7 1184.1 655.7 1198.1 655.5C1224.8 655.1 1251.2 659.9 1277.8 661C1291.1 661.5 1304.2 660.8 1317.3 658.8C1331.5 656.6 1346 653.3 1360.5 655C1370.9 656.2 1387.6 663.2 1384.2 676.6C1381.2 688.3 1364.6 694.9 1354.4 698.2C1303.2 714.8 1250.4 687.2 1198.5 694.5C1168.6 698.7 1140.4 711.3 1110.2 713.7C1096.4 714.8 1082.2 713.9 1069.1 709C1057.1 704.4 1046.3 694.4 1032.6 696.8C1030.7 697.1 1031.5 700 1033.4 699.7C1041.6 698.3 1048.8 702.2 1055.7 706C1061.3 709 1066.9 711.8 1073 713.6C1085.5 717.4 1098.9 717.9 1111.9 716.7C1140.5 714 1167.4 702.7 1195.7 698.1C1226.9 693.1 1257.6 700.3 1288.4 704.4C1313.5 707.7 1340.4 708.5 1364 698C1373 694 1385 687.6 1387.3 677C1389.4 667.5 1382.1 659.9 1374.2 656C1362.5 650.3 1348.4 651.4 1336 653.1C1320.9 655.1 1306.2 658.3 1290.9 658.5C1261.1 659 1231.7 652.9 1202 652.8C1187.8 652.8 1173.7 654.4 1159.9 658C1146.2 661.6 1132.9 666.6 1119.3 670.3C1102.5 674.8 1085.6 676.2 1068.4 673.5C1059.8 672.1 1051.7 669.7 1043.4 667.3C1037.2 665.5 1030.5 664 1024 665.4C1014.3 667.5 1005.5 676.7 1009.8 687C1013.7 696.4 1025.6 700.5 1034.8 702.4C1036.4 702.5 1037.2 699.6 1035.4 699.2Z"
                    fill="#E8E8E8"
                  ></path>
                  <path
                    d="M586.7 1094.1C624.1 1085.1 660.7 1072.8 696 1057.5C714.3 1049.5 732.2 1040.4 751.7 1035.6C770.4 1030.9 789.8 1029.5 809.1 1030C831.4 1030.5 853.5 1033.5 875.5 1037C877.4 1037.3 878.2 1034.4 876.3 1034.1C838.8 1028.2 800.2 1023.4 762.5 1030.2C743.1 1033.7 725.3 1041.1 707.5 1049.2C688.8 1057.7 669.9 1065.4 650.5 1072.2C629.3 1079.6 607.8 1086 585.9 1091.2C584 1091.6 584.8 1094.5 586.7 1094.1Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M823.001 1031.1C839.801 1030.7 856.401 1028.3 872.601 1023.6C889.101 1018.9 905.401 1012.3 922.901 1014C931.801 1014.8 940.501 1017.3 949.101 1019.5C958.101 1021.8 967.001 1024.1 976.001 1026.4C993.301 1030.8 1010.5 1035.2 1027.8 1039.6C1029.7 1040.1 1030.5 1037.2 1028.6 1036.7C1010.7 1032.1 992.801 1027.6 974.901 1023C958.501 1018.8 941.701 1013.2 924.901 1011.2C908.401 1009.3 892.801 1014.4 877.201 1019.2C859.501 1024.6 841.501 1027.7 823.101 1028.2C821.101 1028.1 821.101 1031.1 823.001 1031.1Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M998.1 1046.6C1037.4 1037.9 1078.7 1030.4 1118.6 1039.6C1138.4 1044.2 1157 1053 1175.5 1061.1C1194.9 1069.7 1214.4 1078.2 1233.8 1086.8C1235.6 1087.6 1237.1 1085 1235.3 1084.2C1204.9 1070.8 1174.8 1056.3 1143.8 1044.4C1113.8 1032.9 1082.6 1030.6 1050.8 1034.2C1032.8 1036.3 1014.9 1039.8 997.2 1043.8C995.4 1044.1 996.2 1047 998.1 1046.6Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M642.8 1128.8C653.1 1127 663.5 1125.2 673.8 1123.4C684.1 1121.6 694.5 1119.3 704.9 1118.2C714.5 1117.2 724.3 1118.9 733.8 1119.8C744.3 1120.9 754.9 1121.9 765.4 1123C767.3 1123.2 767.3 1120.2 765.4 1120C754.9 1118.9 744.3 1117.9 733.8 1116.8C723.6 1115.8 713.3 1114.1 703 1115.4C682.6 1117.9 662.3 1122.4 642 1125.9C640.1 1126.3 640.9 1129.2 642.8 1128.8Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M366.6 677.8C373.449 677.8 379 672.248 379 665.4C379 658.552 373.449 653 366.6 653C359.752 653 354.2 658.552 354.2 665.4C354.2 672.248 359.752 677.8 366.6 677.8Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M400.101 705C403.69 705 406.601 702.09 406.601 698.5C406.601 694.91 403.69 692 400.101 692C396.511 692 393.601 694.91 393.601 698.5C393.601 702.09 396.511 705 400.101 705Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M1097.5 70.1C1105.95 70.1 1112.8 63.25 1112.8 54.8C1112.8 46.35 1105.95 39.5 1097.5 39.5C1089.05 39.5 1082.2 46.35 1082.2 54.8C1082.2 63.25 1089.05 70.1 1097.5 70.1Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M1133.1 87.4C1137.91 87.4 1141.8 83.5049 1141.8 78.7C1141.8 73.8951 1137.91 70 1133.1 70C1128.3 70 1124.4 73.8951 1124.4 78.7C1124.4 83.5049 1128.3 87.4 1133.1 87.4Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M1127.3 83.8001C1135.31 83.8001 1141.8 77.3082 1141.8 69.3001C1141.8 61.2919 1135.31 54.8 1127.3 54.8C1119.29 54.8 1112.8 61.2919 1112.8 69.3001C1112.8 77.3082 1119.29 83.8001 1127.3 83.8001Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M1092.6 830C1103.42 830 1112.2 821.225 1112.2 810.4C1112.2 799.575 1103.42 790.8 1092.6 790.8C1081.78 790.8 1073 799.575 1073 810.4C1073 821.225 1081.78 830 1092.6 830Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M381 79.8001C387.904 79.8001 393.5 74.2036 393.5 67.3001C393.5 60.3965 387.904 54.8 381 54.8C374.096 54.8 368.5 60.3965 368.5 67.3001C368.5 74.2036 374.096 79.8001 381 79.8001Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M578.8 934.6C587.25 934.6 594.1 927.75 594.1 919.3C594.1 910.85 587.25 904 578.8 904C570.35 904 563.5 910.85 563.5 919.3C563.5 927.75 570.35 934.6 578.8 934.6Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M689.2 646.8C687.1 645.9 689.5 642.2 690.6 641.7C691.9 641.1 694.5 641.9 695.9 642.3C698.1 643 700 644.2 702.2 644.8C704.6 645.5 707.5 645.7 709.8 644.6C717.8 640.5 708.3 626.7 720 626.7C726.4 626.7 731.8 636.2 737.1 629.1C739 626.6 739.8 623.4 740.8 620.4C741.6 617.8 742.3 615.1 744.1 613C746.2 610.6 752.6 608.2 753.3 613.4C753.5 615.3 756.6 615.3 756.3 613.4C755.8 609.8 753.2 607.4 749.5 607.2C745.1 606.9 741.9 610.2 740.1 613.8C738.8 616.3 738.2 619.1 737.3 621.8C736.7 623.7 735.9 627 734.2 628.2C732.4 629.4 731 628.1 729.5 627.2C725.6 624.6 720.9 622.6 716.2 624.3C711.9 625.9 710.2 629.6 710.4 634C710.4 635.3 710.8 636.9 710.7 638.2C710 642.8 706.9 643 703.7 642.3C700.5 641.6 697.8 639.6 694.5 639C691 638.3 687.5 639.2 685.9 642.7C684.6 645.7 685.4 648.7 688.4 649.9C690.2 650.4 691 647.5 689.2 646.8Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M740.8 651.6C739.3 649.3 739.3 646.4 740.9 644.1C743.2 640.8 746.4 641.3 749.9 641.8C752.5 642.2 755.7 642.7 757.6 640.4C758.9 638.9 758.7 637.1 759.2 635.4C759.9 632.9 761.5 630.7 763.6 629.2C768 626 774.2 625.5 778.6 628.9C779.7 629.7 781 629.2 781.2 627.8C782.4 618.1 792 610.9 801.6 612.6C803.5 612.9 804.3 610 802.4 609.7C790.7 607.7 779.6 616.1 778.2 627.8C779.1 627.4 779.9 627.1 780.8 626.7C776.7 623.5 771.5 622.7 766.6 624.4C761 626.3 758.4 630 756.3 635.2C755.3 637.8 754.1 639.4 751.2 639C749.6 638.8 748.1 638.4 746.5 638.3C744.2 638.2 742.2 638.8 740.4 640.3C736.6 643.5 735.6 649 738.3 653.2C739.3 654.8 741.9 653.3 740.8 651.6Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M791.4 104.3C789.7 102.7 790.2 101.7 792.8 101.2C793.9 99.7001 795.1 99.4001 796.4 100.4C798.2 100.8 799.4 102.4 800.7 103.6C802.3 105 803.7 106.6 805.5 107.7C808 109.1 811.1 109.7 813.7 108.4C816.9 106.8 817 103.6 818.1 100.6C821.1 92.6001 831.3 91.8001 836.1 98.6001C836.7 99.4001 838.2 99.7001 838.7 98.6001C840.6 94.1001 843 89.0001 847.3 86.3001C851.6 83.6001 858.8 83.9001 860.7 89.5001C861.3 91.3001 864.2 90.5001 863.6 88.7001C861.7 83.3001 856.2 80.9001 850.7 81.8001C843.1 83.1001 838.9 90.7001 836.1 97.1001C837 97.1001 837.8 97.1001 838.7 97.1001C835.2 92.1001 829.2 89.4001 823.2 91.6001C820.7 92.5001 818.4 94.3001 816.9 96.6001C816.1 97.8001 815.6 99.1001 815.1 100.5C814.8 101.4 814.8 102.5 814.3 103.3C813 105.5 809.4 106.4 807.1 105.1C802.2 102.4 797.4 93.8001 790.6 97.3001C786.8 99.2001 786.3 104.6 789.9 106.9C791.6 107.9 793.1 105.3 791.4 104.3Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M855.601 103.5C854.901 93.5 867.701 96.8 872.801 99.3C875.401 100.6 878.601 103 881.401 100.8C882.301 100.1 882.701 99.1 883.101 98.1C884.601 94.8 885.601 92.4 889.601 91.2C895.801 89.4 901.801 93.3 906.801 96.5C908.401 97.5 909.901 94.9 908.301 93.9C903.001 90.6 897.001 86.9 890.501 88C888.401 88.3 886.301 89.1 884.601 90.4C882.001 92.3 881.501 95.6 878.901 97.2C876.401 98.7 875.401 97.2 872.901 96C871.601 95.4 870.301 94.8 868.901 94.3C866.301 93.4 863.501 93 860.801 93.6C856.101 94.7 852.301 98.5 852.601 103.5C852.701 105.4 855.701 105.4 855.601 103.5Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M1145 517.7C1138.4 503.2 1163.4 496.8 1165.1 512C1165.7 517.1 1162.9 522.5 1157.7 523.9C1151.9 525.4 1145.7 521.5 1144.1 515.9C1143.6 514 1140.7 514.8 1141.2 516.7C1143.2 523.9 1151.1 528.7 1158.5 526.8C1165.8 524.9 1169.4 516.8 1167.7 509.8C1165.9 502.4 1158.2 498.8 1151.1 500.7C1142.8 502.9 1138.9 511.6 1142.4 519.4C1143.2 521 1145.8 519.4 1145 517.7Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M1163.6 529.2C1158.2 528.7 1156.2 521.5 1159.9 517.9C1163 514.8 1168.3 515 1171.6 517.6C1175 520.3 1178.3 528.2 1174.5 532C1169.5 537 1163.4 528.9 1161 525.3C1159.9 523.7 1157.3 525.2 1158.4 526.8C1161.9 532.2 1169.9 540.7 1176.6 534.1C1181.9 528.9 1178.1 518.4 1172.9 514.7C1167.6 510.9 1158.9 512.2 1155.9 518.4C1152.9 524.4 1157.3 531.6 1163.7 532.2C1165.5 532.4 1165.5 529.4 1163.6 529.2Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M255.2 403.5C259.2 403.3 261.3 400.6 264.1 398.1C270.7 392.2 278.3 397.8 282.5 403.6C286.5 409.1 292.3 413 297.4 406.4C299.8 403.3 301 398.7 304.3 396.5C311 392 316.7 400.9 320.9 404.8C325.7 409.2 335.6 411.2 337.8 403C338.3 401.1 335.4 400.3 334.9 402.2C333.8 406.5 328.6 406.4 325.5 404.6C323.1 403.2 321.4 401 319.5 399.1C316.7 396.1 313.6 393.2 309.4 392.4C302.5 391.2 299.4 397.4 296.3 402.4C295.5 403.8 294.5 405.7 293 406.5C289.4 408.4 286.8 404.6 284.9 402.1C282.5 398.7 279.8 395.8 275.9 394C272.7 392.5 268.9 392 265.6 393.4C261.8 395 259.4 400.4 255.1 400.6C253.3 400.6 253.3 403.6 255.2 403.5Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M325.1 382.9C329.3 387.9 336.8 394.6 343.4 389.1C345 387.7 345.9 385.7 347.6 384.3C350.1 382.4 353.4 382 356.3 382.9C362.6 384.6 366.3 390.6 369.6 395.7C370.2 396.6 371.7 396.8 372.2 395.7C376.5 386.3 388 384 397 387.6C398.8 388.3 399.6 385.4 397.8 384.7C387.7 380.6 374.4 383.6 369.6 394.1C370.5 394.1 371.3 394.1 372.2 394.1C367.7 387.1 362 379.1 352.6 379.4C349.3 379.5 346.3 380.9 344.1 383.3C341.5 386.3 339.3 388.7 335 387.4C331.7 386.4 329.3 383.3 327.1 380.7C326 379.3 323.9 381.4 325.1 382.9Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M505.201 487.5C503.901 484.9 502.401 482.2 501.201 479.5C499.901 476.7 500.501 475.6 502.201 473.2C504.401 470.1 507.801 467.3 511.701 469.4C514.501 470.9 516.301 474.3 517.101 477.2C518.501 482.1 517.301 489.1 511.601 490.7C506.901 492 500.801 489.1 501.001 483.7C501.001 481.8 498.001 481.8 498.001 483.7C497.801 490.4 504.501 494.6 510.701 493.9C518.301 493 521.501 485 520.401 478.2C519.301 471.6 513.201 463.2 505.601 466.3C501.901 467.8 499.101 471.7 497.401 475.2C496.401 477.3 496.601 477.4 497.601 479.5C499.201 482.7 500.801 485.9 502.401 489C503.501 490.7 506.101 489.2 505.201 487.5Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M516.3 507.1C512.5 502.6 506 493.8 511.6 488.2C515.6 484.2 522.8 485.7 526.1 489.7C529.5 493.8 530.2 501.8 524.9 504.8C520.4 507.4 513.9 504.9 513.1 499.7C512.8 497.8 509.9 498.6 510.2 500.5C511.4 507.3 519.7 510.5 525.7 507.8C533 504.4 533.3 494.3 529 488.4C524.9 482.7 515.9 480.7 510.3 485.4C502.2 492.1 509.2 503.1 514.3 509.1C515.4 510.7 517.5 508.6 516.3 507.1Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    d="M1107.6 304.7C1107.7 295.9 1119.8 298.5 1125 299.1C1129.5 299.6 1133.6 299 1137.6 296.8C1142 294.5 1145.6 290.9 1149.9 288.4C1152.7 286.7 1156.1 285.2 1159.4 285.2C1162.9 285.3 1165.1 287.4 1168.2 288.7C1175.4 291.6 1180 284.9 1184.5 280.5C1193.4 271.8 1206.1 275.4 1216.5 279C1218.3 279.6 1219.1 276.7 1217.3 276.1C1208 272.9 1196.2 269.4 1187 274.7C1182.6 277.2 1180 281.8 1176 284.7C1170.3 288.8 1165.5 282.3 1159.5 282.2C1146.6 282 1139.8 296.9 1126.8 296.2C1121.2 295.9 1115.8 293.8 1110.4 296.3C1106.9 297.9 1104.7 300.7 1104.6 304.6C1104.5 306.6 1107.5 306.6 1107.6 304.7Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M1182.4 301.3C1188.8 292.2 1200.8 287.8 1211.6 290.9C1216.3 292.3 1220.7 296.1 1225.9 295.8C1230.2 295.5 1232.5 292 1235.4 289.3C1240.1 285 1246.2 281.5 1252.8 281.7C1256.8 281.8 1259.9 283.3 1263 285.9C1264 286.8 1264.8 287.6 1266.2 288C1268.2 288.5 1270 287.6 1271.8 286.8C1274.9 285.4 1278.1 284.2 1281.4 283.3C1288.2 281.6 1295.1 281.3 1302 282.2C1303.9 282.4 1303.9 279.4 1302 279.2C1295.4 278.3 1288.8 278.5 1282.3 280C1279.4 280.7 1276.5 281.6 1273.7 282.7C1271.5 283.6 1270.4 284.2 1267.9 284.2C1265.2 284.2 1265.4 283.8 1263.6 282.4C1255.1 275.9 1243.7 278.9 1236 285C1233.9 286.6 1232.2 288.8 1230.2 290.4C1225.4 294.2 1221.6 291.8 1216.7 289.6C1212.5 287.7 1208.1 286.8 1203.5 287C1194 287.4 1185.4 292.1 1180 299.8C1178.7 301.4 1181.3 302.9 1182.4 301.3Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M964.899 972C966.799 984 968.699 996.1 970.499 1008.1C930.999 1020.9 911.599 1029 912.399 1032.4C913.399 1036.6 944.999 1033.5 1007.2 1023.4C1003.6 1004.4 1000.1 985.4 996.499 966.4C985.899 968.2 975.399 970.1 964.899 972Z"
                    fill="black"
                  ></path>
                  <path
                    d="M879.899 570.2C879.199 583.8 878.199 603.2 877.299 626.7C876.399 651.6 875.899 664.6 875.999 680.6C875.999 687.5 876.299 715.6 879.899 751.8C882.899 782.3 887.599 827.9 904.299 884C910.899 906.2 921.099 936 936.999 970C944.299 971.1 952.899 971.7 962.699 971.3C981.099 970.4 995.999 965.9 1006.3 961.7C1003.3 951.9 999.099 938 994.699 921.3C988.799 898.7 971.799 830.6 965.199 739.1C961.899 693.1 960.699 635.7 966.499 569.7C937.699 569.8 908.799 570 879.899 570.2Z"
                    fill="white"
                  ></path>
                  <path
                    opacity="0.09"
                    d="M963.999 577.9C962.099 585.1 959.599 595.5 956.899 608.1C949.899 641.4 942.699 688.9 943.399 769.2C943.799 818.6 947.299 886.9 960.099 969.4C965.099 968.9 970.199 968.3 975.499 967.5C986.599 965.8 996.899 963.6 1006.3 961.1C1002.1 947.5 996.299 927.3 990.299 902.7C968.999 815 962.999 740.6 961.399 691.5C960.499 662.3 960.499 623.6 963.999 577.9Z"
                    fill="#606060"
                  ></path>
                  <path
                    d="M964.3 572.8C954.3 674 960.5 776.7 982.6 876C988.9 904.2 996.4 932 1005.2 959.5C1005.8 961.3 1008.7 960.5 1008.1 958.7C977.3 862 961.9 760.6 962.7 659.1C962.9 630.3 964.5 601.4 967.3 572.7C967.5 570.9 964.5 570.9 964.3 572.8Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M875.799 662.5C872.099 744.5 883.599 827 909.399 904.9C916.599 926.7 924.999 948.1 934.499 969C935.299 970.8 937.899 969.2 937.099 967.5C903.599 893 883.899 812.5 879.199 731C877.899 708.2 877.799 685.4 878.799 662.5C878.899 660.5 875.899 660.6 875.799 662.5Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M935.699 969.9C939.099 973.7 946.899 972.8 951.499 972.9C958.499 973 965.599 972.3 972.499 971.3C979.999 970.2 987.499 968.6 994.899 966.8C998.599 965.9 1006.3 965.7 1008.2 961.6C1009 959.9 1006.4 958.3 1005.6 960.1C1004.7 962.2 999.699 962.6 997.799 963C994.999 963.7 992.299 964.3 989.499 965C984.199 966.2 978.999 967.3 973.699 968.1C968.199 969 962.699 969.6 957.199 969.8C952.999 970 940.999 971.3 937.899 967.8C936.499 966.3 934.399 968.5 935.699 969.9Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M850.4 569.6C847.5 575.9 843.6 587.9 840.8 598.5C837.3 612 836.4 620.1 834.4 631.9C830.7 653.7 828.2 657.4 822.8 681.3C817.1 706.9 814.5 726.6 812.5 742.3C810.9 754.5 808 779.4 806.7 811.6C806.1 825.5 805.8 833.7 806.1 845C806.9 880.5 812.6 908 815.1 918.8C821.8 948.5 831.6 973.9 841.4 994.5C845.9 996.8 852.7 999.7 861.3 1001.6C871.9 1003.9 885.5 1006.9 898.5 1000.3C902.1 998.5 910.3 993.5 916.5 981.7C911.7 968.6 905.8 950 901.1 927.2C889.5 870.6 893.7 825.8 896.6 797.6C902 744.3 914.4 704.3 935.7 635.3C942.9 612.1 949.3 593.3 953.7 580.8C955.2 570.9 958.7 541.2 942.8 528.8C919.5 510.4 865.2 537.7 850.4 569.6Z"
                    fill="white"
                  ></path>
                  <path
                    opacity="0.08"
                    d="M939.599 622.8C933.099 633.1 924.099 648.4 915.199 667.7C904.199 691.5 887.999 733.3 879.299 816.6C874.499 862.1 871.299 925.8 878.699 1002.7C881.799 1003 894.999 1004.1 906.299 995C912.099 990.3 915.099 984.9 916.599 981.5C911.699 968 905.399 948.2 900.599 923.7C886.399 852.3 895.299 794.9 901.899 754.9C907.299 721.5 917.799 675.4 939.599 622.8Z"
                    fill="#606060"
                  ></path>
                  <path
                    d="M866.1 1001.4C869.5 1013.6 872.9 1025.9 876.3 1038.1C822.7 1054.4 796.4 1064.6 797.3 1068.6C798.3 1073.2 834.8 1069.8 906.8 1058.4C904.9 1038.8 903 1019.3 901.2 999.7C889.4 1000.2 877.7 1000.8 866.1 1001.4Z"
                    fill="black"
                  ></path>
                  <path
                    d="M849.299 570.8C838.699 597.6 833.299 626 827.599 654.2C821.599 683.7 815.799 713.2 811.599 743C803.799 798.2 801.399 854.7 812.099 909.7C817.799 939.3 827.399 968.1 841.499 994.7C842.399 996.4 844.999 994.9 844.099 993.2C790.599 891.5 806.799 772.5 828.599 664.6C834.899 633.3 840.499 601.4 852.299 571.6C852.899 569.8 849.999 569 849.299 570.8Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M938.3 622.6C904.8 711.9 882.4 807.9 895.4 903.6C899 930 905.3 955.9 914.5 980.9C915.2 982.7 918.1 981.9 917.4 980.1C884.1 890.2 890.9 791.6 915.9 700.5C923.1 674.4 931.7 648.7 941.2 623.3C941.9 621.5 939 620.8 938.3 622.6Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M841.8 994.6C855.9 1002.4 872.1 1005.7 888.1 1003.6C901.2 1001.8 916.4 995.8 917.7 980.7C917.9 978.8 914.9 978.8 914.7 980.7C913.3 996.4 894.3 1000.6 881.4 1001.2C868.1 1001.8 855 998.5 843.4 992C841.6 991.1 840.1 993.7 841.8 994.6Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M871 309C868.3 312.2 864.5 317.1 860.9 323.6C848.6 345.2 847.8 366.4 847.6 385C847.4 412.1 847.2 425.7 850.1 455.2C852.8 482.8 853.5 522.4 843.8 572.9C852.5 579.3 865.2 586.7 881.8 590.6C929.6 601.8 969.2 573.9 976.7 568.5C967.2 512.8 962 468.2 959 436.9C957.1 417.1 950.1 388.3 936.2 330.6C932 313 927.7 296.2 914.7 291.4C896.7 284.6 874.6 305.5 871 309Z"
                    fill="#EC615B"
                  ></path>
                  <path
                    opacity="0.1"
                    d="M914.2 292.8C915.8 299.3 918.1 309.1 920.4 321.2C923.4 336.8 927.4 360.4 930.2 395.5C933.1 431.7 936.4 499.6 937.4 587.4C944.2 585.4 952.4 582.4 961.1 577.6C966.9 574.4 971.9 571 976.1 567.8C975.4 560.1 974.1 548.4 972 534.3C969.3 516.6 966.6 504.3 965.3 498.2C962.1 482.4 958.5 458.8 958.1 426C954 408.6 950.9 394.6 948.8 385.3C943 358.9 941.3 349.2 936.9 333.2C932.5 317.1 927.9 304.3 925 296.6C921.4 295.2 917.8 294 914.2 292.8Z"
                    fill="#606060"
                  ></path>
                  <path
                    d="M870.9 318.6C871.8 320.4 873.3 321 876.6 322.6C879.4 324 882.1 325.7 884.9 326.9C893.5 330.6 903.6 347 905.2 349.6C911.3 359.5 918.4 371.1 922.5 387.9C923.6 392.3 926.1 403.7 923.2 442.2C920.1 481.7 916.1 487.2 919.2 506.9C921.5 521.2 924.4 538.3 938.5 547.6C951.6 556.2 966.7 553.2 969.8 552.6C988.8 548.9 999.6 534.2 1010.1 519.9C1026.1 498.2 1032.2 476.5 1034.8 463.9C1036 458 1035.8 440.7 1035.5 406.2C1035.1 370.6 1034.5 358.3 1025.2 347.9C1018.9 340.9 1011.2 337.6 1005.9 335.9C1006.3 333.7 1006.4 330.4 1004.6 327.6C1001.2 322.4 993.1 322.5 987.9 322.6C965.6 323.2 949 338.2 948.2 338.9C934.3 351.8 930.2 367.6 928.9 374.2C926.1 361.8 921.5 352.3 917.9 346.2C914.8 340.9 910.2 335.5 900.9 324.5C893.6 315.9 890.1 312.6 884.6 310.8C883.4 310.4 874.3 307.7 871.3 311.8C869.9 313.7 869.9 316.6 870.9 318.6Z"
                    fill="white"
                  ></path>
                  <path
                    opacity="0.11"
                    d="M870.2 314.9C870.8 313.8 877.5 316.3 878.2 316.6C888.1 320.4 896.9 328.3 908.2 346.6C914 356 921.3 369.3 927.5 386.6C931.9 382.1 935.6 378.4 938.5 375.6C938.5 375.6 949.2 365.3 966.5 355.3C968.2 354.3 970.3 353.1 971.8 354C974.9 355.7 971.6 364 972.5 371.7C973.2 377.5 976.6 385.5 989.5 394.4C989.8 396.1 989.9 398.5 988.5 400.1C985.7 403.2 978.5 400.7 974.8 399.4C968.8 397.3 957.2 391.8 943.5 375.7C942.2 380.9 940.5 388.3 939.2 397.4C938 405.3 934.7 431.6 940.2 465.4C942.4 478.8 946.5 496.7 954.9 517.1C958.6 518.7 963.3 520.2 968.9 521.1C980.6 522.9 990.3 520.6 995.9 518.8C992.6 520.4 988.1 523.1 984.2 527.8C979.5 533.5 979.4 537.8 975.5 543.1C972.9 546.7 968.2 551.2 959.5 554.4C956.8 554.1 952.7 553.4 948.2 551.4C934.5 545.3 928.3 532.4 925.5 526.4C921.8 518.3 916.1 501.9 921.5 446.4C924.6 414.7 928 404.8 922.8 386.7C918.8 372.8 911.9 361.3 909.5 357.4C905.2 350.4 897.5 337.9 883.5 326.4C876.9 320.8 869.6 316.2 870.2 314.9Z"
                    fill="#606060"
                  ></path>
                  <path
                    d="M981.7 337.3C979.9 339.7 977.6 343.2 975.8 347.8C974.8 350.5 972.4 356.8 973 364.8C974.1 380.7 985.5 390.4 987.6 392.1C989 393.2 990.2 394.1 991 394.6C989.8 398.7 988.4 404.8 987.9 412.3C987.1 422.9 986.3 434.8 992.5 438.3C996.5 440.5 1002.5 439 1005.8 435.8C1006.6 435 1008.9 432.5 1009.5 421.2C1010.2 407.8 1007.4 402.2 1011.4 398.3C1013.1 396.5 1016 395.4 1018.5 394.3C1021.3 393.1 1023.8 392.5 1025.6 392.1C1024.5 400.3 1023.7 407.6 1023.1 413.5C1022.4 420.8 1022.1 424.5 1024.6 426.8C1026.9 428.9 1030.7 429.3 1033.6 428C1037.4 426.3 1038.6 421.6 1040.7 412.2C1042.4 404.8 1043.8 398.6 1042.9 390.2C1042.4 385.9 1041.5 382.4 1040.7 380C1040.5 378.3 1040 375.8 1039.2 372.9C1037.5 367.1 1035.9 365.4 1034.9 361.7C1033.4 356.4 1035.1 353.8 1035.5 347.8C1035.9 343.1 1035.5 336.4 1032.1 328C1030.8 328.1 1028.1 328.3 1025.3 330.2C1021.9 332.4 1020.5 335.6 1020 336.7C1017.4 336.1 1012.7 335.5 1007.3 337C998 339.5 993.1 346.4 991.8 348.5C994.4 342.8 992.2 337.2 988.7 335.8C985.7 334.5 982.1 337.1 981.7 337.3Z"
                    fill="black"
                  ></path>
                  <path
                    d="M992.999 368.3C993.999 366.4 998.999 362.2 1000.7 365.7C1001.5 367.4 1004.1 365.9 1003.3 364.2C1002.2 361.9 999.499 361.2 997.099 361.7C994.199 362.2 991.799 364.2 990.399 366.8C989.499 368.5 992.099 370 992.999 368.3Z"
                    fill="white"
                  ></path>
                  <path
                    d="M1022.3 360.6C1022.9 359.3 1023.5 357.8 1024.5 356.8C1025.5 355.8 1028.5 354.7 1028.6 357C1028.7 358.9 1031.7 358.9 1031.6 357C1031.4 354.1 1028.9 352.2 1026 352.6C1022.7 353.2 1021 356.2 1019.7 359C1018.9 360.8 1021.5 362.4 1022.3 360.6Z"
                    fill="white"
                  ></path>
                  <path
                    d="M1023.1 370.1C1023.1 370.2 1023.1 370.3 1023.1 370.4C1022.9 370.6 1022.7 370.8 1022.6 370.9C1022.1 371.2 1021.3 370.9 1021.4 370.3C1021.4 370.3 1021.4 370 1021.5 370.1L1021.2 370.4C1021.9 370.4 1022.6 370.4 1023.3 370.4C1023.2 370.3 1023.2 370.3 1023.1 370.2C1023.2 370.3 1023.2 370.3 1023.2 370.3C1023.1 370.2 1023.2 370.2 1023.2 370.4C1023.3 371 1023 371.4 1022.2 371.7C1021.9 371.6 1021.7 371.6 1021.4 371.5C1021 371.3 1021.1 370.7 1021.2 370.4C1021.3 370.3 1021.2 370.3 1021.1 370.5C1021.2 370.4 1021.2 370.4 1021.1 370.5C1020.4 371 1020.1 371.8 1020.6 372.6C1021 373.2 1022 373.6 1022.7 373.1C1023.4 372.6 1024.1 371.9 1024.2 370.9C1024.2 369.8 1023.4 368.8 1022.3 368.8C1021.2 368.8 1020.4 369.5 1020.3 370.6C1020.2 371.4 1020.7 372.1 1021.2 372.6C1021.8 373.2 1022.7 373.1 1023.3 372.6C1024.6 371.6 1024.9 369.5 1023.4 368.5C1022.7 368.1 1021.8 368 1021.1 368.4C1020.2 368.9 1020.1 370.1 1020.3 371C1020.4 371.8 1021.4 372.2 1022.1 372C1022.9 371.7 1023.2 370.9 1023.1 370.1Z"
                    fill="white"
                  ></path>
                  <path
                    d="M1007.1 373.2C1004.2 374.9 1000.3 374.1 998.4 371.3C997.3 369.7 994.7 371.2 995.8 372.8C998.7 376.9 1004.2 378.3 1008.7 375.8C1010.3 374.8 1008.8 372.2 1007.1 373.2Z"
                    fill="white"
                  ></path>
                  <path
                    d="M1004.9 379.7C1002.7 381.4 1000.8 383.6 999.5 386.1C998.6 387.8 1001.2 389.3 1002.1 387.6C1003.3 385.3 1005 383.4 1007.1 381.8C1007.7 381.3 1007.6 380.2 1007.1 379.7C1006.4 379.1 1005.5 379.2 1004.9 379.7Z"
                    fill="white"
                  ></path>
                  <path
                    d="M1029.2 368.7C1031.2 366.4 1032.7 363.8 1033.7 360.9C1034.3 359.1 1031.4 358.3 1030.8 360.1C1030 362.5 1028.7 364.7 1027.1 366.6C1025.9 368.1 1028 370.2 1029.2 368.7Z"
                    fill="white"
                  ></path>
                  <path
                    d="M1029.4 372.5C1031.8 372 1034.6 371.4 1036.5 373.4C1037.8 374.8 1039.9 372.7 1038.6 371.3C1035.9 368.4 1032 368.9 1028.5 369.7C1026.7 370 1027.5 372.9 1029.4 372.5Z"
                    fill="white"
                  ></path>
                  <path
                    d="M869.7 316.3C874.3 309.1 885.2 313.4 890.7 317C897.3 321.2 902.3 327.5 906.8 333.8C915.9 346.5 924 360.3 927 375.8C927.4 377.7 930.3 376.9 929.9 375C926.4 356.5 915.6 338.7 903.4 324.5C896.3 316.3 876.2 300.8 867.1 314.9C866.1 316.4 868.7 318 869.7 316.3Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M875.9 323.1C899.9 336.6 913.4 361.9 920.2 387.7C920.7 389.6 923.6 388.8 923.1 386.9C916.1 360.4 902 334.3 877.4 320.5C875.7 319.5 874.2 322.1 875.9 323.1Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M929.9 374.5C932.4 356.8 943.7 342 959.2 333.5C967.2 329.1 976.1 326.4 985.2 325.1C989.5 324.5 994.5 323.9 998.7 325.5C1003.3 327.2 1007.7 334.5 1001.9 337.8C1000.2 338.7 1001.7 341.3 1003.4 340.4C1009.8 336.8 1008.2 327.6 1002.6 324.1C993.9 318.6 979.5 322.5 970.6 325.5C948.6 332.6 930.4 350.4 927.1 373.9C926.7 375.6 929.6 376.4 929.9 374.5Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M936.6 379C941.5 373.3 947.7 368.5 953.7 364C959.5 359.6 965.7 356 972.9 354.4C974.8 354 974 351.1 972.1 351.5C964.7 353.2 958.2 356.9 952.2 361.4C946 366.1 939.6 371 934.5 376.9C933.2 378.3 935.3 380.5 936.6 379Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M973.3 354.7C975.4 349.9 978.1 345.3 981.4 341.2C982 340.5 982.7 339.3 983.5 338.8C984.2 337.5 985.1 337.4 986.4 338.6C988 338.6 988.7 339.2 988.7 340.6C989.8 342.7 989.9 346.3 990.3 348.7C990.7 350.6 993.6 349.8 993.2 347.9C992.4 344.1 991.4 330.5 984.2 334.2C981.4 335.6 979.3 339.1 977.5 341.5C974.8 345.2 972.6 349.1 970.7 353.2C969.9 354.9 972.5 356.5 973.3 354.7Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M992.6 349.6C997.7 343 1008.1 333.9 1017 338.8C1018.7 339.7 1020.2 337.1 1018.5 336.2C1008.2 330.7 996.6 339.7 990.4 347.5C989.3 349 991.4 351.1 992.6 349.6Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M1019.9 338.3C1021.3 336.1 1027.8 328.2 1030.6 330.3C1032.1 331.4 1032.4 335.3 1032.8 337.1C1033.4 339.9 1033.6 342.7 1033.7 345.6C1033.8 347.5 1036.8 347.5 1036.7 345.6C1036.6 341.6 1036.1 337.7 1035.1 333.8C1034.5 331.6 1034 327.4 1031.4 326.7C1029 326.1 1025.7 328.4 1023.9 329.7C1021.2 331.6 1019.1 334 1017.4 336.8C1016.3 338.4 1018.9 339.9 1019.9 338.3Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M940.5 374.1C947.4 380.8 954.7 387 962.5 392.6C970.2 398.1 977.7 402 987.4 401.5C989.3 401.4 989.3 398.4 987.4 398.5C977.5 399 970.2 394.6 962.5 389C955.4 383.8 948.8 378.1 942.5 372C941.2 370.7 939.1 372.8 940.5 374.1Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M920.3 384.4C931.2 432.8 902.8 485.7 926.3 532C930.7 540.7 937.4 548.6 946.6 552.5C955.6 556.3 966 556 975.3 553C995.9 546.4 1009.2 527.3 1019.4 509.3C1025.9 498 1031.3 485.9 1034.5 473.2C1036.1 466.9 1037.1 460.3 1037.4 453.8C1037.7 446.3 1036.7 438.8 1037 431.3C1037.1 429.4 1034.1 429.4 1034 431.3C1033.6 443.5 1035.2 455.1 1032.8 467.2C1030.7 478 1026.8 488.5 1021.9 498.3C1012.8 516.7 1000.5 537.4 981.6 547.1C971.8 552.1 959.7 554 949.2 550.1C938.9 546.3 932.1 537.2 927.7 527.5C918.3 506.4 919.4 482.4 921.9 460C924.7 434.6 929.1 408.9 923.4 383.5C922.7 381.8 919.8 382.5 920.3 384.4Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M964.8 484.5C959.2 494.3 955.5 504.9 953.9 516.1C953.6 518 956.5 518.8 956.8 516.9C958.4 505.9 961.9 495.6 967.4 486C968.4 484.4 965.8 482.9 964.8 484.5Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M966.7 485.6C981.5 484.3 997.1 481.5 1011.9 483.1C1013.8 483.3 1013.8 480.3 1011.9 480.1C997.1 478.4 981.5 481.3 966.7 482.6C964.8 482.8 964.8 485.8 966.7 485.6Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M955.4 518.8C962.7 523.1 971.2 522.7 979.4 522.5C988 522.2 996.6 521.2 1004.1 516.7C1005.8 515.7 1004.2 513.1 1002.6 514.1C995.5 518.4 987.5 519.2 979.4 519.5C971.8 519.8 963.8 520.3 956.9 516.3C955.3 515.3 953.8 517.9 955.4 518.8Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M1010.5 482.4C1008.6 493.4 1005.7 504 1001.8 514.4C1001.1 516.2 1004 517 1004.7 515.2C1008.7 504.8 1011.6 494.1 1013.4 483.2C1013.7 481.3 1010.8 480.5 1010.5 482.4Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M964 489.7C968.9 500.5 982.8 503.2 993.5 503.3C995.4 503.3 995.4 500.3 993.5 500.3C983.9 500.1 971 497.9 966.6 488.1C965.8 486.4 963.2 488 964 489.7Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M985.6 499.4C985.3 501.9 985 504.4 984.7 506.8C984.5 508.7 987.5 508.7 987.7 506.8C988 504.3 988.3 501.8 988.6 499.4C988.8 497.5 985.8 497.5 985.6 499.4Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M991.3 499.4C991 501.9 990.7 504.4 990.5 506.9C990.3 508.8 993.3 508.8 993.5 506.9C993.8 504.4 994.1 501.9 994.3 499.4C994.5 497.5 991.5 497.5 991.3 499.4Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M993.9 503.6C1001 502 1006.9 497.6 1010.6 491.4C1011.6 489.7 1009 488.2 1008 489.9C1004.6 495.5 999.4 499.2 993.1 500.7C991.2 501.2 992 504.1 993.9 503.6Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M972 349.3C969 367 972.3 388.1 990.4 396.2C992.1 397 993.7 394.4 991.9 393.6C975 386 972.1 366.5 974.9 350.1C975.2 348.2 972.3 347.4 972 349.3Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M1033.5 344.5C1033.7 350.2 1033.6 356 1034.4 361.6C1035.2 367.7 1040.7 374.5 1037.8 380.6C1037 382.3 1039.6 383.9 1040.4 382.1C1043 376.6 1040.7 372 1038.8 366.6C1036.3 359.7 1036.9 351.7 1036.6 344.4C1036.4 342.5 1033.4 342.5 1033.5 344.5Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M998.6 394.1C1003 391.8 1008 394.2 1009 399C1009.1 399.5 1009.8 399.3 1009.7 398.8C1008.5 393.5 1003 390.9 998.2 393.4C997.8 393.7 998.2 394.4 998.6 394.1Z"
                    fill="white"
                  ></path>
                  <path
                    d="M1006.2 399.2C1006.9 400 1007.4 400.9 1007.7 401.9C1007.9 402.4 1008.6 402.2 1008.4 401.7C1008 400.6 1007.4 399.6 1006.7 398.7C1006.4 398.3 1005.8 398.9 1006.2 399.2Z"
                    fill="white"
                  ></path>
                  <path
                    d="M1026.3 392.2C1027.2 388.1 1029.8 384.7 1033.6 382.8C1034 382.6 1033.7 381.9 1033.2 382.2C1029.2 384.1 1026.5 387.7 1025.6 392C1025.5 392.5 1026.2 392.7 1026.3 392.2Z"
                    fill="white"
                  ></path>
                  <path
                    d="M850.2 180.2C843.3 183.7 837.5 190.3 834.9 193.2C828.5 200.5 825.2 207.3 821.6 214.9C818.6 221.1 815.1 229.9 812.3 240.9C816.2 241.6 820.1 242.2 824 242.9C823.8 246.7 823.8 252.3 825.3 258.9C826.7 264.9 829 274.5 837 281.2C848 290.6 862.6 288.3 865.3 287.9C865.5 292.6 866.5 299.3 870.3 306.2C871.3 307.9 872.3 309.5 873.3 310.9C878.8 309.3 887.3 306 895.6 299.2C916.8 282.1 919.7 256.6 920.6 249.2C921.9 237.8 924.4 216.3 910.3 198.5C895.6 180.1 867.7 171.4 850.2 180.2Z"
                    fill="white"
                  ></path>
                  <path
                    d="M829.7 201C832.5 196.8 837.9 190.6 844 186.3C853.8 179.4 858.2 182.3 863.2 176.2C866.8 171.8 869.1 164.6 866.4 161.2C862.1 155.8 846.8 162.3 844.8 163.2C840.4 165.1 825.9 171.2 820.1 186.6C815.3 199.5 818.5 213.6 820.7 213.9C821.3 214 821.7 213 824.9 208.1C826.4 205.6 828.1 203.3 829.7 201Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M851.499 170.9C850.699 173.5 849.499 178.7 853.499 188.9C860.599 207.2 877.199 223.6 885.499 220.6C887.299 219.9 889.199 218.1 892.799 217.9C893.699 217.9 897.299 217.7 900.099 219.9C904.499 223.4 904.699 231 901.799 235.9C901.299 236.8 898.599 240.9 894.499 241.2C890.899 241.5 889.699 238.7 886.799 238.5C879.699 238.1 873.599 253.7 872.799 255.8C871.199 259.8 867.299 270.2 870.099 283.5C870.699 286.4 872.099 292.5 876.399 298.8C885.699 312.3 901.299 315.6 907.099 316.8C912.799 318 934.499 322.5 951.399 308.5C954.099 306.3 962.999 298.7 965.399 285.8C969.499 264 951.199 247.7 950.099 246.8C949.299 235.5 946.599 217.5 937.099 197.8C931.099 185.3 925.499 173.8 912.799 165.1C896.099 153.8 871.799 150.6 859.099 160.4C857.799 161.6 853.499 165 851.499 170.9Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    d="M832.5 278.2C831 279.7 826.6 284.3 825.8 291.5C824.7 301.5 831.4 308.4 832.8 309.8C840.1 317.3 852.3 320.3 861.1 315.8C861.8 315.5 869.5 311.4 869.8 305.5C869.9 302.9 868.5 301.6 866.5 295.8C865.2 292 864.4 288.9 863.8 286.8C861.1 287.7 856.5 288.9 850.8 288.1C840.6 286.8 834.3 280.2 832.5 278.2Z"
                    fill="#4A4DC4"
                  ></path>
                  <path
                    opacity="0.12"
                    d="M852.5 179.7C852.6 182.8 853 187.4 855.1 192.3C856.5 195.6 857.9 197.6 859 199.2C863.2 205.6 864.6 211.6 866.8 218.3C870.3 228.7 869.7 237.4 869 248.2C868.4 257.2 866.4 270.9 859.9 286.8C862.6 287.6 866.5 289.2 870.7 292C878.4 297.2 881.9 303.4 884.1 306.3C888.6 312.2 898.6 318.4 922.7 318C920.3 315.6 916.9 312 913.2 307.6C904.5 297.4 896.9 288.5 892.4 277.7C889.8 271.5 885.8 258.8 888.9 240.4C891.7 241.8 895 241.7 897.6 240C900.3 238.2 902 234.6 901.1 231.3C900 227.5 895.4 224.9 890.7 226.1C889.7 223.6 888.1 220 885.5 216.1C881.4 210 877.1 206.2 875.5 204.8C870.4 200.4 863 192.9 852.5 179.7Z"
                    fill="#606060"
                  ></path>
                  <path
                    opacity="0.14"
                    d="M813.399 239.1C813.499 237.5 818.599 236.2 822.899 236.5C826.699 236.8 831.199 238.5 831.099 240C830.999 241.4 826.799 242.5 823.699 242.6C818.899 242.8 813.399 240.7 813.399 239.1Z"
                    fill="#606060"
                  ></path>
                  <path
                    d="M825.8 201.9C820.1 214.4 815 227.1 810.6 240.1C810.3 240.9 810.9 241.7 811.6 241.9C815.5 243.4 819.4 244.1 823.5 244C825.4 244 825.4 241 823.5 241C819.6 241 816 240.3 812.4 239C812.7 239.6 813.1 240.2 813.4 240.8C817.7 228 822.6 215.5 828.3 203.3C829.2 201.7 826.6 200.1 825.8 201.9Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M823.1 244.6C823.9 257.5 825.1 271.8 834.7 281.5C843.9 290.9 857.2 290.1 869.3 288.7C871.2 288.5 871.2 285.5 869.3 285.7C858.1 287 845.4 288.1 836.8 279.4C827.8 270.3 826.9 256.7 826.1 244.6C826 242.7 823 242.7 823.1 244.6Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M863.4 289C864.7 296 867.1 302.7 870.5 308.9C871.4 310.6 874 309.1 873.1 307.4C869.8 301.3 867.6 295 866.3 288.2C866 286.3 863.1 287.1 863.4 289Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M825.1 259.9C830 264.2 837.3 262.2 839.2 255.9C839.8 254 836.9 253.3 836.3 255.1C835.1 259.1 830.4 260.6 827.3 257.7C825.8 256.5 823.7 258.6 825.1 259.9Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M834.3 255.8C835.9 256.2 837.1 257.1 838.1 258.3C838.6 258.9 839.7 258.8 840.2 258.3C840.8 257.7 840.7 256.8 840.2 256.2C838.9 254.6 837.1 253.4 835.1 252.9C833.3 252.4 832.5 255.3 834.3 255.8Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M838.8 218.4C839.6 214.2 844.8 215 847.6 216.5C850 217.8 851.7 219.9 852.7 222.4C853.4 224.2 856.3 223.4 855.6 221.6C854 217.3 850.7 213.9 846.1 212.7C841.9 211.6 836.9 212.9 836 217.6C835.6 219.5 838.5 220.3 838.8 218.4Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M838.7 200.7C843.2 193.2 852 199 853.8 205.3C854.3 207.2 857.2 206.4 856.7 204.5C855.4 199.9 852.2 195.6 847.3 194.4C842.9 193.4 838.4 195.3 836.1 199.1C835.1 200.9 837.7 202.4 838.7 200.7Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M841.7 235.4C843.6 235.4 843.6 232.4 841.7 232.4C839.8 232.4 839.7 235.4 841.7 235.4Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M847.399 230.9C847.499 230.9 847.699 230.9 847.799 230.9C848.599 230.9 849.299 230.2 849.299 229.4C849.299 228.6 848.599 227.9 847.799 227.9C847.699 227.9 847.499 227.9 847.399 227.9C846.599 227.9 845.899 228.6 845.899 229.4C845.899 230.3 846.599 230.9 847.399 230.9Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M847.5 236C847.5 236.1 847.5 236.2 847.5 236.3C847.5 238.2 850.5 238.2 850.5 236.3C850.5 236.2 850.5 236.1 850.5 236C850.5 234.1 847.5 234.1 847.5 236C847.5 236.1 847.5 236.2 847.5 236.3C847.5 238.2 850.5 238.2 850.5 236.3C850.5 236.2 850.5 236.1 850.5 236C850.5 234.1 847.5 234.1 847.5 236Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M891.099 220.7C893.799 216.8 898.399 219.3 899.999 222.5C901.299 225 901.299 228 901.199 230.7C900.799 237.5 895.099 242.3 888.499 238.1C886.899 237 885.399 239.6 886.999 240.7C890.899 243.3 895.899 243.6 899.799 240.6C903.799 237.5 904.499 232.4 904.199 227.6C903.899 223 902.099 217.9 897.299 216.2C893.899 215 890.499 216.3 888.499 219.2C887.499 220.7 890.099 222.2 891.099 220.7Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M622.099 163.6C619.999 163.6 617.899 163.7 615.899 163.7C612.499 170.3 610.599 176.3 609.499 180.7C606.299 193.6 607.099 204.3 607.699 209.5C608.099 213 608.699 215.9 609.199 217.9C610.999 217.9 612.799 217.9 614.599 217.9C615.299 220.3 615.999 222.7 616.699 225.1C633.599 225 647.499 225 657.199 225.1C715.999 225.6 756.999 227.9 766.499 228.4C778.399 229.1 788.099 229.8 794.099 230.2C794.299 226.5 795.299 225.2 796.199 224.8C797.799 224.1 799.199 225.8 801.299 225.4C803.499 225 804.899 222.7 805.799 220.6C807.599 221.5 810.899 222.7 814.799 222.1C815.699 222 816.499 221.7 817.199 221.5C818.899 218.1 820.599 214.7 822.299 211.3C819.899 210.5 816.499 209.8 812.399 209.8C810.099 209.8 808.099 210.1 806.399 210.4C806.199 209.9 804.599 205.4 800.399 204.4C799.099 204.1 797.999 204.2 797.099 204.4C796.499 203.3 795.899 202.2 795.299 201.1C792.899 200.7 789.199 200.1 784.799 199.3C749.099 192.8 724.199 184.9 705.199 179.2C686.999 173.7 660.499 166.2 626.499 157.9C625.099 159.8 623.599 161.7 622.099 163.6Z"
                    fill="white"
                  ></path>
                  <path
                    opacity="0.07"
                    d="M607.999 202.4C608.899 202.2 609.899 204.5 610.999 204.2C612.799 203.8 612.399 197.5 612.799 192.8C613.099 188.3 614.399 181.8 618.799 173.9C621.699 175.1 626.099 177 631.399 179.3C650.499 187.6 663.699 194 667.699 195.8C694.099 208.1 723.099 213.6 730.399 215C746.999 218.2 769.999 221 797.599 219.8C796.399 223.1 795.199 226.4 793.999 229.7C789.399 229.6 782.699 229.6 774.499 229.4C739.299 228.7 714.699 227.6 700.599 227C680.699 226.2 652.199 225.4 616.199 225.5C615.799 222.8 615.399 220.1 614.999 217.4C612.699 217.8 610.399 218.2 608.099 218.6C606.599 206.1 606.999 202.6 607.999 202.4Z"
                    fill="#606060"
                  ></path>
                  <path
                    d="M624.2 158C611.7 177.3 608.2 201.3 614.6 223.4C615.1 225.3 618 224.5 617.5 222.6C611.3 201.2 614.7 178.2 626.8 159.5C627.9 157.8 625.3 156.3 624.2 158Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M626.8 160.9C683.1 175.1 739.3 189.3 795.6 203.4C797.5 203.9 798.3 201 796.4 200.5C740.1 186.3 683.9 172.1 627.6 158C625.8 157.5 625 160.4 626.8 160.9Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M616.699 226.7C675.399 226.2 734.099 227.6 792.699 230.8C794.599 230.9 794.599 227.9 792.699 227.8C734.099 224.6 675.399 223.2 616.699 223.7C614.799 223.7 614.799 226.7 616.699 226.7Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M794.7 202.3C798.7 211.2 797.8 221.3 792.3 229.4C791.2 231 793.8 232.5 794.9 230.9C801 221.9 801.8 210.7 797.3 200.8C796.5 199.1 793.9 200.6 794.7 202.3Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M797.7 206.2C798.8 207 799.6 206.7 800.9 207C803.4 207.7 804.4 210 804.9 212.4C805.7 216.8 804.6 226.1 798.1 223.5C796.3 222.8 795.5 225.7 797.3 226.4C803.9 229 808.2 222.1 808.2 216.3C808.2 213.1 807.8 209.5 806 206.8C805.4 205.9 804.6 205 803.6 204.5C802.3 203.9 800.5 204.3 799.4 203.5C797.6 202.5 796.1 205.1 797.7 206.2Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M806.499 211.7C811.599 211.4 816.699 211.5 821.699 212.2C823.599 212.4 823.599 209.4 821.699 209.2C816.599 208.6 811.599 208.4 806.499 208.7C804.599 208.9 804.599 211.9 806.499 211.7Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M806.1 222.7C810.2 223.4 814.3 223.7 818.4 223.3C820.3 223.1 820.3 220.1 818.4 220.3C814.5 220.6 810.7 220.5 806.9 219.8C805 219.5 804.2 222.3 806.1 222.7Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M622.5 162.8C612.8 157.2 610.1 174.7 608.8 180.2C605.8 192.9 604.4 206 608.1 218.7C608.3 219.3 608.9 219.8 609.5 219.8C610.8 219.8 612.2 219.8 613.5 219.8C615.4 219.8 615.4 216.8 613.5 216.8C612.2 216.8 610.8 216.8 609.5 216.8C610 217.2 610.5 217.5 610.9 217.9C608.3 208.7 608.3 199.4 609.8 190C610.5 185.8 611.4 181.6 612.5 177.5C613.2 174.8 615.4 162.1 620.9 165.4C622.7 166.4 624.2 163.8 622.5 162.8Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M730.6 184.3C728.8 182.1 725.8 182.4 725.4 182.4C722.1 182.6 718.9 185.6 715.4 192.9C708.6 206.8 708.3 220.7 708.3 222.9C708.2 232.5 709.1 248.1 715.9 266.7C716.5 274.5 717.8 285.4 720.7 298.1C725.8 320.9 731.2 344.9 749.8 366.2C756.8 374.3 770.6 390 794.6 395.7C819.7 401.7 840.1 392.8 845.1 390.5C849.5 388.4 861.8 382.2 872.2 369.1C878.5 361.2 885.8 352.6 884.6 342C884.1 337.4 882.1 333.5 879.1 327.3C876.1 321.1 874.5 318 871.3 316.8C866.9 315.1 862.2 318.1 857 321.1C848.2 326.1 847.4 330.9 837.9 335.9C835.4 337.2 832.9 338.1 827.9 339.7C820 342.3 812.2 344.9 805 345.4C789.3 346.5 776.7 337.5 773.1 334.9C759.8 325.4 754.6 312.7 751.2 304.4C743.6 285.8 744.2 268.7 745.5 258.7C750.2 257.9 753.1 256 754.5 254.9C759.8 250.8 761.1 245.1 763.1 236.3C764.4 230.8 765 227.9 763.6 226.8C760.7 224.6 751.8 231.8 749.8 233.5C750.2 226.7 751.2 221.2 752.2 217.3C753.5 212.1 754.2 209.4 756 207.3C759.5 203.2 763.6 203.6 765 199.7C765.8 197.6 765.7 194.7 764 193C762 191 757.7 191.1 754 194C752.8 191.4 750.4 189.8 747.8 189.7C745.2 189.6 743.5 191.2 743 191.6C743.3 188.9 742.1 186.4 740.1 185.4C737.4 184 733.1 185.6 731.5 189.7C731.6 189.2 732.3 186.4 730.6 184.3Z"
                    fill="white"
                  ></path>
                  <path
                    opacity="0.12"
                    d="M725.4 182.4C723 186.6 720 192.8 718 200.9C713.6 218.5 717.2 233.2 718.8 240.3C736 313.6 728.5 315.4 740.5 332.6C744.9 338.8 775.1 380.4 820.1 374.9C857.8 370.3 877.8 336 880.4 331.4C881.9 335.4 883.7 342.2 882.5 350.3C880.7 362.2 873.3 369.7 870.6 372.5C868 375.1 864.8 377.4 858.3 381.9C852.5 386 846.8 389.6 844.3 390.9C832.4 397.6 814 399.2 801.2 397.5C766.4 392.9 745.5 359.9 736.8 346.2C716.9 314.9 714.9 283.2 715.1 267.4C713.2 261 711.3 254.5 709.4 248.1C708.1 238.2 708 230 708.2 224.3C708.7 210 709.1 199.3 716.4 190.2C719.6 186.2 723.1 183.8 725.4 182.4Z"
                    fill="#606060"
                  ></path>
                  <path
                    d="M860.8 380.4C876.6 372.9 887.2 357.2 884.6 339.4C883.1 328.6 874.4 310 860.5 316.6C854.3 319.6 849.2 325.1 843.5 329C835.6 334.4 826.5 338.6 817.4 341.5C805.8 345.2 792.9 346 781.7 340.4C773.5 336.3 767.1 329.6 762.2 322C751.4 305.3 745.8 285.6 747.1 265.7C747.2 263.8 744.2 263.8 744.1 265.7C743 282.5 746.7 298.5 754.1 313.5C760.6 326.9 770.1 339.5 784.5 344.9C802 351.4 821.1 344.8 836.9 336.5C844 332.7 850.1 327.8 856.5 322.9C862.3 318.4 868.1 316.2 874 322.2C881.3 329.6 883.5 342.5 881.3 352.3C878.6 364 869.9 372.8 859.3 377.8C857.6 378.6 859.1 381.2 860.8 380.4Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M746.9 267C745.6 261.5 749.5 260.1 753.1 257.5C755.2 256 756.8 253.9 758.3 251.8C762.1 246.3 765.1 239.9 765.9 233.2C766.3 229.9 767 224.7 762.5 224.5C757.2 224.3 755 231 750 231.5C748.1 231.7 748.1 234.7 750 234.5C753.8 234.1 755.7 231.7 758.6 229.5C760.3 228.2 762.2 226.7 763.1 229.6C763.6 231.2 762.8 234.2 762.4 235.8C761.7 239.2 760.2 242.5 758.6 245.6C757.1 248.4 755.4 251.3 753.1 253.6C751 255.8 748.1 256.3 745.8 258.1C742.7 260.5 743.2 264.6 744 267.9C744.5 269.7 747.4 268.9 746.9 267Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M712 251.2C710 238.3 709.8 225.1 711.8 212.2C712.6 207 713.6 201.7 715.4 196.7C716.5 193.6 718 190 720.2 187.5C723.2 184.2 729.1 182.5 730.7 188.1C731.2 190 734.1 189.2 733.6 187.3C732.2 182.5 727.6 179.9 722.8 181.8C716.7 184.3 713.8 191.8 711.9 197.5C706.3 214.8 706.3 234.2 709 252C709.4 253.9 712.3 253.1 712 251.2Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M726.499 199.6C728.899 193.7 735.699 181.8 743.099 189.9C744.399 191.3 746.499 189.2 745.199 187.8C741.799 184.1 736.899 183.5 732.599 186C727.799 188.7 725.599 194 723.599 198.9C722.899 200.6 725.799 201.4 726.499 199.6Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M739.1 202.1C739.2 198.3 740.7 194.1 744.4 192.3C747.5 190.8 751.2 191.5 753.3 194.2C754.5 195.7 756.6 193.6 755.4 192.1C752.5 188.5 747.3 187.6 743.2 189.5C738.4 191.8 736.2 197 736.1 202.1C736 204 739 204 739.1 202.1Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M747.799 203.1C749.599 196.4 757.099 190.3 763.599 195.7C765.099 196.9 767.199 194.8 765.699 193.6C757.399 186.8 747.399 193.2 744.899 202.3C744.399 204.2 747.299 205 747.799 203.1Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M763 195.2C766.2 199.7 759.1 202.5 756.6 204.6C754.5 206.3 753.3 208.5 752.4 211C750 217.9 748.9 225.4 748.3 232.6C748.1 234.5 751.1 234.5 751.3 232.6C751.8 227.3 752.6 222 753.9 216.8C754.3 215.1 754.7 213.4 755.3 211.7C756.6 208.2 758.5 206.9 761.4 204.8C765 202.2 768.8 198 765.6 193.5C764.5 192.1 761.9 193.6 763 195.2Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M708.299 248.2C709.899 255 711.9 261.7 714.4 268.3C715.1 270.1 717.999 269.3 717.299 267.5C714.799 260.9 712.8 254.2 711.2 247.4C710.8 245.5 707.899 246.3 708.299 248.2Z"
                    fill="#161616"
                  ></path>
                  <path
                    d="M713.9 270.2C717.7 304.6 726.2 339.9 749.3 366.7C767.7 388 796.9 404.2 825.7 398.9C839.3 396.4 851.7 389.6 861.6 380.1C863 378.8 860.9 376.6 859.5 378C839.7 397 811.5 402 786.4 390.7C759.4 378.4 740.5 355.3 729.8 328C722.6 309.5 719 289.9 716.8 270.3C716.7 268.3 713.7 268.3 713.9 270.2Z"
                    fill="#161616"
                  ></path>
                </svg>{" "}
              </div>
            </div>
            <div className="col-lg-5 order-lg-1">
              <div className="">
              <h1 className="lh-tight ls-tighter font-bolder display-1 mb-4">
                  Oops!
                </h1>
                <p className="lead lh-relaxed">
                  An error occurred <br/>Request ID: {requestId}
                </p>
                <p className="lead lh-relaxed mt-10">
                  The server encountered an error and could not complete your request. If the problem persists, please report the problem and mention this error message and the query that caused it.
                </p>
                <div className="my-10">
                  <a href="/dashboard" className="btn btn-dark">
                    Return home
                  </a>
                </div>
                <div className="vstack gap-5 w-lg-88">
                  <div className="">
                    <a
                    target="_blank"
                      href="https://help.wrappedgiftcards.com"
                      className="h5 link-primary"
                    >
                      Help documentation
                      <i className="bi bi-arrow-right ms-2"></i>
                    </a>
                    <p className="text-sm">
                      Everything you need to know is here.
                    </p>
                  </div>
                  {/* <div className="">
              <a href="https://webpixels.io/support" className="h5 link-primary">Get our support<i className="far fa-arrow-right ms-2"></i></a>
              <p className="text-sm">
                Check out our communication channels.
              </p>
            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default ErrorPage;

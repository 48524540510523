import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AccountContext";
import { BillingPlan } from "../apiclient";
const UpsellBanner = (props : any) => {
  const authContext = useContext(AuthContext);
  const isValidPlan = authContext.account?.billingPlan !== BillingPlan.Free;
  return (
    <React.Fragment>
      {!isValidPlan ? (<div className="alert alert-warning" role="alert">
          <div className="row align-items-center">
            {props.message ? ( <div className="col font-bold">
              {props.message}
            </div>) : ( <div className="col font-bold">
              This feature is only available on a paid plan.
            </div>)}
           
            <div className="col-auto">
                <div className="hstack gap-2 justify-content-end">
                  <Link
                    to={"/billingplan"}
                    className="btn d-inline-flex btn-sm btn-primary mx-1"
                  >
                    <span className=" pe-2">
                      <i className="bi bi-unlock-fill"></i>
                    </span>
                    <span>Start trial</span>
                  </Link>
                 
                </div>
              </div>
          </div>
        </div>): (<></>)}
        
    </React.Fragment>
  );
};

export default UpsellBanner;

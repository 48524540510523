import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AccountContext";
import { AccountStatus, ProfileApi } from "../apiclient";

const SettingsNavMenu = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const authContext = useContext(AuthContext);

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <ul className="nav nav-tabs overflow-x border-0">
        <li className="nav-item">
          <Link
            className={
              location.pathname === "/account"
                ? "nav-link active"
                : "nav-link"
            }
            to={`/account`}
          >
            General
          </Link>
        </li>

        {authContext.account && authContext.account.isOwner && (
          <li className="nav-item">
            <Link
              className={
                location.pathname === "/billingplan"
                  ? "nav-link active"
                  : "nav-link"
              }
              to={`/billingplan`}
            >
              Billing
            </Link>
          </li>
        )}
        <li className="nav-item">
          <Link
            className={
              location.pathname === "/account/sales-channels"
                ? "nav-link active"
                : "nav-link"
            }
            to={`/account/sales-channels`}
          >
            Sales channels
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              location.pathname === "/gift-cards/settings"
                ? "nav-link active"
                : "nav-link"
            }
            to={`/gift-cards/settings`}
          >
            Gift card settings
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              location.pathname === "/manage-integrations" || location.pathname === "manage-api-keys"
                ? "nav-link active"
                : "nav-link"
            }
            to={`/manage-integrations`}
          >
            Integrations
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link
            className={
              location.pathname === "/manage-api-keys"
                ? "nav-link active"
                : "nav-link"
            }
            to={`/manage-api-keys`}
          >
            Private API keys
          </Link>
        </li> */}
        <li className="nav-item">
          <Link
            className={
              location.pathname === "/emails/senderdomains"
                ? "nav-link active"
                : "nav-link"
            }
            to={`/emails/senderdomains`}
          >
            Sender domains
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              location.pathname === "/account/users"
                ? "nav-link active"
                : "nav-link"
            }
            to={`/account/users`}
          >
            Team
          </Link>
        </li>
              {/*<li className="nav-item">
          <Link
            className={
              location.pathname === "/account/notifications"
                ? "nav-link active"
                : "nav-link"
            }
            to={`/account/notifications`}
          >
            Notifications
          </Link>
        </li>*/}
        <li className="nav-item">
          <Link
            className={
              location.pathname === "/account/storefront-settings"
                ? "nav-link active"
                : "nav-link"
            }
            to={`/account/storefront-settings`}
          >
            Storefront settings
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              location.pathname === "/account/redeem-app"
                ? "nav-link active"
                : "nav-link"
            }
            to={`/account/redeem-app`}
          >
            Redeem app
          </Link>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default SettingsNavMenu;

import {
    faSort, faSortDown, faSortUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import { Col, Form, Pagination, Row, Spinner, Table } from "react-bootstrap";
import { usePagination, useSortBy, useTable } from "react-table";
import CustomPaginationWithoutTotalCount from "./paging/CustomPaginationWithoutTotalCount";

function GridTable({
    columns,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    search,
    goToFirstPage,
    useTotalPages = true,
    useSorting = true,
    status
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { pageIndex, pageSize, sortBy },
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            manualSortBy: useSorting,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: controlledPageCount, // Use -1 if no page count is given
            initialState: { pageIndex: 0, pageSize: 50 },
            disableSortBy: !useSorting,
        },
        useSortBy,
        usePagination
    );

    const lastSearch = useRef("");
    const lastStatus = useRef("");
    const lastSort = useRef(sortBy);
    const lastGoTo = useRef("");

    useEffect(() => {
        if (lastSearch.current !== search || lastSort.current !== sortBy || lastGoTo.current !== goToFirstPage || lastStatus.current !== status) {
            lastSearch.current = search;
            lastStatus.current = status;
            lastSort.current = sortBy;
            lastGoTo.current = goToFirstPage;
            // if search or sort is changed then go back to first page
            if (pageIndex === 0) {
                fetchData({ pageIndex, pageSize, search, sortBy, status });
            }
            else {
                gotoPage(0);
            }
        }
        else {
            fetchData({ pageIndex, pageSize, search, sortBy, status });
        }
    }, [pageIndex, pageSize, search, sortBy, goToFirstPage, status]);

    const renderPagination = () => {
        return (
            <>
                {loading ? (
                    <td colSpan="10000">Loading...</td>
                ) : (
                    <>
                        {
                            useTotalPages ?
                                <Row className="mt-5 text-sm">
                                    <Col md="6">
                                        <span className="mx-2">
                                            Page{" "}
                                            <strong>
                                                {pageIndex + 1} of {pageOptions.length}
                                            </strong>
                                        </span>
                                        <span className="ms-3 me-2">Show:</span>
                                        <Form.Select
                                            className="d-inline-block w-auto form-select form-select-sm"
                                            value={pageSize}
                                            onChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                            }}
                                        >
                                            {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))}
                                        </Form.Select>

                                        <span className="ms-3 me-2">Go to page:</span>
                                        <Form.Control
                                            className="d-inline-block form-control form-control-sm"
                                            type="number"
                                            defaultValue={pageIndex + 1}
                                            onChange={(e) => {
                                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                                gotoPage(page);
                                            }}
                                            style={{ width: "75px" }}
                                        />
                                    </Col>
                                    <Col md="6">
                                        <Pagination className="float-end">
                                            <Pagination.First
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            />
                                            <Pagination.Prev
                                                onClick={() => previousPage()}
                                                disabled={!canPreviousPage}
                                            />
                                            <Pagination.Next
                                                onClick={() => nextPage()}
                                                disabled={!canNextPage}
                                            />
                                            <Pagination.Last
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            />
                                        </Pagination>
                                    </Col>
                                </Row>
                                :
                                <CustomPaginationWithoutTotalCount
                                    page={pageIndex}
                                    pageSize={pageSize}
                                    pageSizeOptions={[10, 20, 30, 40, 50, 100]}
                                    totalItems={page.length}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    onPageChange={(newPage) => gotoPage(newPage)}
                                />
                        }
                    </>
                )}
            </>
        )
    }

    if (data.length > 0) return (
        <>
            <Table bordered {...getTableProps()}>
                <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {useSorting && !column.disableSortBy &&
                                            (column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                                                ) : (
                                                    <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                                                )
                                            ) : (
                                                <FontAwesomeIcon icon={faSort} className="ms-2" />
                                            ))}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.slice(0, pageSize).map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {renderPagination()}
        </>
    );
    else if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spinner animation="border" role="status" />
            </div>
        )
    }
    else {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <span>No data to display</span>
            </div>
        )
    }
}
export default GridTable;

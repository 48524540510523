import React, { useContext, useEffect, useState } from "react";
const PoweredByFooter = (props: any) => {
  return (
    <React.Fragment>
      <footer className="">
        <div className="container">
          <a
            target="_blank"
            href="https://wrappedgiftcards.com?utm_source=poweredby&utm_medium=web"
          >
            <div className="row justify-content-center text-center">
              <div className="col-xl-9">
                <p className="text-xs text-muted opacity-80">
                  Powered by <img
                    src="/img/logos/wrapped-logo-grey.png"
                    className="h-8"
                    alt="..."
                  />
                </p>
              </div>
            </div>
          </a>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default PoweredByFooter;

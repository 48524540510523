import React, { useContext, useState, useEffect, useRef } from "react";
import TimezoneSelect from "react-timezone-select";
import { AssetApi } from "../../apiclient";
import { AppleWalletSettingsPageViewModel } from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Tabs,
  Tab,
  InputGroup,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import "./WalletDesignPage.css";
import * as Swal from "sweetalert2";
const WalletDesignPage = () => {
  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [isLoading, setLoading] = useState(false as boolean);
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as AppleWalletSettingsPageViewModel);
  const [validated, setValidated] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [imageData, setImageData] = useState(null as any);
  const [imageBannerData, setBannerImageData] = useState(null as any);
  const [previewLogo, setPreviewLogo] = useState("" as string);
  const [previewBanner, setPreviewBanner] = useState("" as string);
  const [previewBarcode, setPreviewBarcode] = useState("" as string);
  const [radioPreview, setRadioPreview] = useState("iphone");
  const previewOptions = [
    { name: "iPhone", value: "iphone" },
    { name: "Android", value: "android" },
  ];
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    generateBarcodePreview();
  }, [model.barcodeType]);

  const fetchData = () => {
    assetApi
      .assetAppleWalletSettingsGet()
      .then((response) => {
        var data = response.data;
        setModel(data);
        setLoading(false);
        setPreviewLogo(data.customLogoImageUrl! ?? data.logoImageUrl);
        setPreviewBanner(data.customBannerImageUrl! ?? data.bannerImageUrl);
        console.log("fetch data", data, authContext.account);
      })
      .catch((error: any) => {
        console.log("get survey error", error);
        setLoading(false);
      });
  };

  const generateBarcodePreview = () => {
    if (model.barcodeType === "PKBarcodeFormatPDF417") {
      setPreviewBarcode("/img/barcodes/barcode_pdf417.gif");
    } else if (model.barcodeType === "PKBarcodeFormatAztec") {
      setPreviewBarcode("/img/barcodes/barcode_aztec.gif");
    } else if (model.barcodeType === "PKBarcodeFormatCode128") {
      setPreviewBarcode("/img/barcodes/barcode_code128.gif");
    } else {
      setPreviewBarcode("/img/barcodes/barcode_qr.gif");
    }
  };

  const saveChanges = () => {
    const formData = new FormData();
    formData.append("customLogo", imageData);
    formData.append("customBanner", imageBannerData);

    assetApi
      .assetUpdateAppleWalletSettingsPost(
        "1",
        model.headerText,
        model.backgroundColor,
        model.logoImageUrl,
        model.bannerImageUrl,
        model.labelColor,
        model.foregroundColor,
        model.barcodeType,
        model.locations!,
        imageBannerData,
        imageData,
        model.useCustomLogo,
        model.useCustomBanner
      )
      .then((response) => {
        toast.success("Settings saved!");
        setUnsavedChanges(false);
        fetchData();
      })
      .catch((error: any) => {
        toast.error("An error occurred when saving settings.");
        setUnsavedChanges(false);
      });
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: AppleWalletSettingsPageViewModel) => ({
      ...values,
      [name]: value,
    }));

    setUnsavedChanges(true);
  };

  const onFileInputChange = (event: any, imageType: string) => {
    let file = event.target.files[0];
    let extension = file.name.split(".").at(-1);
    let allowedFileExtensions = ["jpg", "png", "jpeg", "gif", "bmp"];
    if (!allowedFileExtensions.includes(extension.toLowerCase())) {
      toast.error("Incorrect file format, failed to import");
      // clear the file input
      event.target.value = null;
      return;
    }

    var reader = new FileReader();
    reader.onload = (e) => {
      if (e.target !== null) {
        var image = new Image();
        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result as string;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = image.height;
          var width = image.width;
          // if (height > 150 || width > 600) {
          //      toast.error(
          //"File size is too large, maximum dimensions are 600x150"
          //);
          //     // clear the file input
          //     event.target.value = null;
          //     setImageData(null);
          //     setModel({ ...model, customLogoImageUrl: undefined });
          //     return false;
          // }

          if (imageType === "logo") {
            setModel({ ...model, customLogoImageUrl: image.src });
          } else {
            setModel({ ...model, customBannerImageUrl: image.src });
          }
        };
      }
    };
    reader.readAsDataURL(event.currentTarget.files[0]);
    if (imageType === "logo") {
      setImageData(event.currentTarget.files[0]);
    } else {
      setBannerImageData(event.currentTarget.files[0]);
    }
  };

  const resetDefaults = () => {
    assetApi
      .assetResetAppleWalletSettingsGet()
      .then((response) => {
        toast.success("Settings reset to defaults.");
        setUnsavedChanges(false);
        setModel(response.data);
        setPreviewLogo(
          response.data.customLogoImageUrl! ?? response.data.logoImageUrl
        );
        setPreviewBanner(
          response.data.customBannerImageUrl! ?? response.data.bannerImageUrl
        );
      })
      .catch((error: any) => {
        toast.error("An error occurred when saving settings.");
        setUnsavedChanges(false);
      });
  };

  const confirmResetDefaults = () => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `You want to reset your settings back to original.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Reset",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          resetDefaults();
        }
      });
  };

  return (
    <React.Fragment>
      <Form>
        <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
          <div className="container-fluid py-4">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-flex align-items-center gap-4">
                  <div>
                    <Link
                      className="bi bi-chevron-left text-xs"
                      to={`/assets`}
                    ></Link>
                  </div>
                  <div className="vr opacity-20 my-1"></div>
                  <h1 className="h4 ls-tight">Apple and Google Wallet</h1>
                </div>
              </div>
              <div className="col">
                <ButtonGroup>
                  {previewOptions.map((radio, idx) => (
                    <ToggleButton
                    size="sm"
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      variant={"outline-primary"}
                      name="radio"
                      value={radio.value}
                      checked={radioPreview === radio.value}
                      onChange={(e) => setRadioPreview(e.currentTarget.value)}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </div>
              <div className="col-auto">
                <div className="hstack gap-2 justify-content-end">
                  {hasUnsavedChanges && (
                    <div className="text-sm text-muted font-semibold me-2 d-none d-md-block">
                      <span className="d-none d-sm-inline ms-2">
                        You have unsaved changes
                      </span>
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={saveChanges}
                  >
                    <span>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        {isLoading ? (
          <>
            <LoaderIcon />
          </>
        ) : (
          <div className="d-xl-flex row">
            <div className=" bg-surface-primary col-md-3">
              <div className="mb-3 ml-10">
                <div className="list-group">
                  <div className="list-group-item shadow-1 border rounded mb-5 mt-3">
                    <div className="row mb-3">
                      <label
                        htmlFor="headerText"
                        className="col-sm-10 col-form-label"
                      >
                        Pass Header
                      </label>
                      <div className="col-sm-12">
                        <Form.Control
                          type="text"
                          name="headerText"
                          value={model.headerText}
                          title="Pass Header"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-2">
                        <Form.Control
                          type="color"
                          name="backgroundColor"
                          value={model.backgroundColor}
                          title="Card color"
                          onChange={handleChange}
                        />
                      </div>
                      <label
                        htmlFor="backgroundColor"
                        className="col-sm-10 col-form-label"
                      >
                        Card color
                      </label>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-2">
                        <Form.Control
                          type="color"
                          name="foregroundColor"
                          value={model.foregroundColor}
                          title="Text color"
                          onChange={handleChange}
                        />
                      </div>
                      <label
                        htmlFor="foregroundColor"
                        className="col-sm-10 col-form-label"
                      >
                        Text color
                      </label>
                    </div>
                    <div className="row mb-3">
                      <Form.Group className="">
                        <div className="col-sm-10 col-form-label">Logo</div>
                        {previewLogo && (
                          <img
                            className="mb-3"
                            style={{
                              maxHeight: 150,
                              maxWidth: 600,
                            }}
                            src={previewLogo}
                          ></img>
                        )}
                        <Form.Check
                          type="checkbox"
                          name="useCustomLogo"
                          checked={model.useCustomLogo}
                          onChange={handleChange}
                          label="Use a custom logo (90px x 90px)"
                        />
                        {model.useCustomLogo && (
                          <Form.Control
                            onChange={(e) => onFileInputChange(e, "logo")}
                            type="file"
                            accept="image/jpg, image/png, image/jpeg, image/gif, image/bmp"
                          />
                        )}
                      </Form.Group>
                    </div>
                    <div className="row mb-3">
                      <Form.Group className="">
                        <div className="col-sm-10 col-form-label">
                          Strip Banner
                        </div>
                        {previewBanner && (
                          <img
                            className="mb-3"
                            style={{
                              maxWidth: 300,
                            }}
                            src={previewBanner}
                          ></img>
                        )}
                        <Form.Check
                          type="checkbox"
                          name="useCustomBanner"
                          checked={model.useCustomBanner}
                          onChange={handleChange}
                          label="Use a custom banner (600px x 150px)"
                        />
                        {model.useCustomBanner && (
                          <Form.Control
                            onChange={(e) => onFileInputChange(e, "banner")}
                            type="file"
                            accept="image/jpg, image/png, image/jpeg, image/gif, image/bmp"
                          />
                        )}
                      </Form.Group>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="headerText"
                        className="col-sm-10 col-form-label"
                      >
                        Barcode Type
                      </label>
                      <div className="col-sm-12">
                        <Form.Select
                          aria-label="Barcode"
                          name="barcodeType"
                          value={model?.barcodeType ?? "PKBarcodeFormatQR"}
                          onChange={handleChange}
                        >
                          <option value="PKBarcodeFormatQR">QR</option>
                          <option value="PKBarcodeFormatPDF417">PDF 417</option>
                          <option value="PKBarcodeFormatAztec">Aztec</option>
                          <option value="PKBarcodeFormatCode128">
                            Code 128
                          </option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-grid gap-2 mx-auto">
                  <button
                    className="btn btn-neutral"
                    type="button"
                    onClick={confirmResetDefaults}
                  >
                    Reset to defaults
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-9 flex-xl-none border-start-xl h-xl-calc position-sticky top-xl-18 bg-surface-secondary">
              <aside className="p-8 h-full overflow-y-xl-auto">
                <div className="vstack gap-6">
                  <div className="">
                    <div className="container py-10">
                      <div className="row mb-10">
                        <div className="d-grid gap-2 col-6 mx-auto">
                          {radioPreview === "iphone" ? (
                          <div className="device device-iphone-14">
                            <div className="device-frame">
                              <div className="card device-body">
                                <div className="card-body">
                                  <h5 className="card-header">Done</h5>
                                </div>
                                <div className="card-body">
                                  <div className="card wallet-card">
                                    <div
                                      className="card-body p-0"
                                      style={{
                                        backgroundColor: model.backgroundColor,
                                      }}
                                    >
                                      <div className="row p-3">
                                        <div className="d-flex">
                                          <img
                                            src={previewLogo}
                                            width="36"
                                            height="36"
                                            className="me-2"
                                            alt="logo"
                                          />
                                          <div className="flex-grow-1 pt-2">
                                            <strong
                                              style={{
                                                color: model.foregroundColor,
                                              }}
                                            >
                                              {model.headerText}
                                            </strong>
                                          </div>
                                          <div className="text-end d-block">
                                            <div className="h6">BALANCE</div>
                                            <div
                                              className="h4"
                                              style={{
                                                color: model.foregroundColor,
                                              }}
                                            >
                                              {authContext &&
                                              authContext.account &&
                                              authContext.account
                                                .isoCurrencyCode
                                                ? new Intl.NumberFormat(
                                                    undefined,
                                                    {
                                                      style: "currency",
                                                      currency:
                                                        authContext.account
                                                          ?.isoCurrencyCode,
                                                      currencyDisplay: "symbol",
                                                    }
                                                  ).format(211.9)
                                                : "$0.0"}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row pb-3">
                                        <img
                                          src={previewBanner}
                                          width="600"
                                          height="150"
                                          className="me-2"
                                          alt="logo"
                                        />
                                      </div>
                                      <div className="row p-3">
                                        <div className="col d-block">
                                          <div className="h6">GIFT CODE</div>
                                          <div
                                            className="h4"
                                            style={{
                                              color: model.foregroundColor,
                                            }}
                                          >
                                            ABC1JK43GHS2BN45
                                          </div>
                                        </div>
                                        <div className="col text-end d-block">
                                          <div className="h6">EXPIRY DATE</div>
                                          <div
                                            className="h4"
                                            style={{
                                              color: model.foregroundColor,
                                            }}
                                          >
                                            30 DEC
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-20 pb-10">
                                        <div className="">
                                          <div className="d-flex justify-content-center bd-highlight">
                                            <div className="text-center">
                                              <img
                                                width={250}
                                                src={previewBarcode}
                                                alt={"code"}
                                              />
                                              {model.barcodeType !==
                                                "PKBarcodeFormatCode128" && (
                                                <div
                                                  className=""
                                                  style={{
                                                    color:
                                                      model.foregroundColor,
                                                  }}
                                                >
                                                  ABC1JK43GHS2BN45
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="device-stripe"></div>
                            <div className="device-header"></div>
                            <div className="device-sensors"></div>
                            <div className="device-btns"></div>
                            <div className="device-power"></div>
                            <div className="device-home"></div>
                          </div>) :
                          (
                            <div className="device device-galaxy-s8">
                            <div className="device-frame">
                              <div className="card device-body">
                                <div className="card-body">
                                  <h5 className="card-header border-0">
                                    <span className="bi bi-arrow-left float-start"></span>
                                    <span className="bi bi-three-dots-vertical float-end"></span>
                                  </h5>
                                </div>
                                <div className="card-body">
                                  <div className="card wallet-card">
                                    <div
                                      className="card-body p-0"
                                      style={{
                                        backgroundColor: model.backgroundColor,
                                      }}
                                    >
                                      <div className="card-header p-3">
                                        <div className="d-flex">
                                          <img
                                            src={previewLogo}
                                            width="36"
                                            height="36"
                                            className="me-2"
                                            alt="logo"
                                          />
                                          <div className="flex-grow-1 pt-2">
                                            <strong
                                              style={{
                                                color: model.foregroundColor,
                                              }}
                                            >
                                              {model.headerText}
                                            </strong>
                                          </div>
                                        </div>
                                      </div>
                                      
                                      <div className="row p-3">
                                        <div className="col d-block">
                                          <div className="h3">Gift Card: {authContext &&
                                              authContext.account &&
                                              authContext.account
                                                .isoCurrencyCode
                                                ? new Intl.NumberFormat(
                                                    undefined,
                                                    {
                                                      style: "currency",
                                                      currency:
                                                        authContext.account
                                                          ?.isoCurrencyCode,
                                                      currencyDisplay: "symbol",
                                                    }
                                                  ).format(211)
                                                : "$0.0"}</div>
                                          
                                        </div>
                                      </div>
                                      <div className="row p-10">
                                        <div className="">
                                          <div className="d-flex justify-content-center bd-highlight">
                                            <div className="text-center">
                                              <img
                                                width={250}
                                                src={previewBarcode}
                                                alt={"code"}
                                              />
                                              {model.barcodeType !==
                                                "PKBarcodeFormatCode128" && (
                                                <div
                                                  className=""
                                                  style={{
                                                    color:
                                                      model.foregroundColor,
                                                  }}
                                                >
                                                  ABC1JK43GHS2BN45
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row pb-3">
                                        <img
                                          src={previewBanner}
                                          width="600"
                                          height="150"
                                          className="me-2"
                                          alt="logo"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="device-stripe"></div>
                            <div className="device-header"></div>
                            <div className="device-sensors"></div>
                            <div className="device-btns"></div>
                            <div className="device-home"></div>
                          </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        )}
      </Form>
    </React.Fragment>
  );
};

export default WalletDesignPage;

import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import { FormCheck, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import { RedeemAppApi, RedeemAppSettingsViewModel } from "../../apiclient";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";

const RedeemAppToggleEnabledBanner = (props: any) => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  let redeemAppApi = new RedeemAppApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as RedeemAppSettingsViewModel);
  const [isLoading, setLoading] = useState(false as boolean);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();
  const [isEnabled, setIsEnabled] = useState(false);
  
  
  const toggleRedeemAppEnabled = (val: any) => {
    var toggleState = !isEnabled;
    setIsEnabled(toggleState);

    redeemAppApi
      .redeemAppToggleRedeemAppStatusPost()
      .then((response) => {
        if (response.data) {
          toast.success("Redeem App enabled");
          setShowConfetti(true);
        } else {
          toast.success("Redeem App disabled");
        }
        //props.onToggled();
      })
      .catch((error: any) => {
        console.log("toggle storefront error", error);

        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = () => {
    setLoading(true);
    redeemAppApi
      .redeemAppGetSettingsGet()
      .then((response) => {
        var data = response.data;
        setModel(data);
        setIsEnabled(data.isEnabled!);
      })
      .catch((error: any) => {
        console.log("get error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <>
        {isEnabled ? (
          <div className="alert alert-success" role="alert">
            <div className="float-end">
              <FormCheck
                name="isEnabled"
                className="form-switch-lg"
                type="switch"
                checked={isEnabled}
                onChange={toggleRedeemAppEnabled}
              />
            </div>
            <div className="d-flex align-items-center">
              <div className="w-8 text-lg">
                <i className="bi bi-check-circle-fill"></i>
              </div>
              <span className="font-bold">
              Redeem App is ENABLED.
              </span>
            </div>
            {showConfetti && (
              <Confetti
                width={width}
                height={height}
                recycle={false}
                numberOfPieces={500}
              />
            )}
          </div>
        ) : (
          <div className="alert alert-warning" role="alert">
            <div className="float-end">
              <a
                className="btn btn-primary btn-sm"
                onClick={() => toggleRedeemAppEnabled(true)}
              >
                Enable storefront
              </a>
              {/* <FormCheck
                    name="surveyEnabled"
                    className="form-switch-lg"
                    type="switch"
                    checked={isSurveyEnabled}
                    onChange={toggleStorefrontEnabled}
                  /> */}

              {/* <label className="form-check-label ms-2" htmlFor="surveyEnabled">
                    Enable
                  </label> */}
            </div>
            <div className="d-flex align-items-center">
              <span className="text-warning font-bold">
              Redeem App is DISABLED.
              </span>
            </div>
            {/* <div className="">
              <p className="text-sm">
                Enable to add to your order status page and start collecting
                post-purchase responses.
              </p>
            </div> */}
          </div>
        )}
      </>
    </React.Fragment>
  );
};

export default RedeemAppToggleEnabledBanner;

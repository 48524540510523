/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Automation } from '../models';
// @ts-ignore
import { AutomationEmailSubjectViewModel } from '../models';
// @ts-ignore
import { AutomationEmailViewModel } from '../models';
// @ts-ignore
import { AutomationListItem } from '../models';
// @ts-ignore
import { AutomationTestEmailViewModel } from '../models';
/**
 * AutomationApi - axios parameter creator
 * @export
 */
export const AutomationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationCreateAutomationPost: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Automation/CreateAutomation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationDeleteAutomationIdDelete: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('automationDeleteAutomationIdDelete', 'id', id)
            const localVarPath = `/Automation/DeleteAutomation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationDisableAutomationIdPut: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('automationDisableAutomationIdPut', 'id', id)
            const localVarPath = `/Automation/DisableAutomation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationEnableAutomationIdPut: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('automationEnableAutomationIdPut', 'id', id)
            const localVarPath = `/Automation/EnableAutomation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationGetAutomationByIdIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('automationGetAutomationByIdIdGet', 'id', id)
            const localVarPath = `/Automation/GetAutomationById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationListAutomationsGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Automation/ListAutomations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AutomationEmailSubjectViewModel} [automationEmailSubjectViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationSaveAutomationEmailSubjectPost: async (apiVersion?: string, automationEmailSubjectViewModel?: AutomationEmailSubjectViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Automation/SaveAutomationEmailSubject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(automationEmailSubjectViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AutomationEmailViewModel} [automationEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationSaveEmailPost: async (apiVersion?: string, automationEmailViewModel?: AutomationEmailViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Automation/SaveEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(automationEmailViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AutomationTestEmailViewModel} [automationTestEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationSendTestEmailPost: async (apiVersion?: string, automationTestEmailViewModel?: AutomationTestEmailViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Automation/SendTestEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(automationTestEmailViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {Automation} [automation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationUpdateAutomationPut: async (apiVersion?: string, automation?: Automation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Automation/UpdateAutomation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(automation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutomationApi - functional programming interface
 * @export
 */
export const AutomationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AutomationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automationCreateAutomationPost(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Automation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automationCreateAutomationPost(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automationDeleteAutomationIdDelete(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Automation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automationDeleteAutomationIdDelete(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automationDisableAutomationIdPut(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Automation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automationDisableAutomationIdPut(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automationEnableAutomationIdPut(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Automation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automationEnableAutomationIdPut(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automationGetAutomationByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Automation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automationGetAutomationByIdIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automationListAutomationsGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AutomationListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automationListAutomationsGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AutomationEmailSubjectViewModel} [automationEmailSubjectViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automationSaveAutomationEmailSubjectPost(apiVersion?: string, automationEmailSubjectViewModel?: AutomationEmailSubjectViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automationSaveAutomationEmailSubjectPost(apiVersion, automationEmailSubjectViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AutomationEmailViewModel} [automationEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automationSaveEmailPost(apiVersion?: string, automationEmailViewModel?: AutomationEmailViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automationSaveEmailPost(apiVersion, automationEmailViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AutomationTestEmailViewModel} [automationTestEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automationSendTestEmailPost(apiVersion?: string, automationTestEmailViewModel?: AutomationTestEmailViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automationSendTestEmailPost(apiVersion, automationTestEmailViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {Automation} [automation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automationUpdateAutomationPut(apiVersion?: string, automation?: Automation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automationUpdateAutomationPut(apiVersion, automation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AutomationApi - factory interface
 * @export
 */
export const AutomationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AutomationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationCreateAutomationPost(apiVersion?: string, options?: any): AxiosPromise<Automation> {
            return localVarFp.automationCreateAutomationPost(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationDeleteAutomationIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<Automation> {
            return localVarFp.automationDeleteAutomationIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationDisableAutomationIdPut(id: string, apiVersion?: string, options?: any): AxiosPromise<Automation> {
            return localVarFp.automationDisableAutomationIdPut(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationEnableAutomationIdPut(id: string, apiVersion?: string, options?: any): AxiosPromise<Automation> {
            return localVarFp.automationEnableAutomationIdPut(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationGetAutomationByIdIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<Automation> {
            return localVarFp.automationGetAutomationByIdIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationListAutomationsGet(apiVersion?: string, options?: any): AxiosPromise<Array<AutomationListItem>> {
            return localVarFp.automationListAutomationsGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AutomationEmailSubjectViewModel} [automationEmailSubjectViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationSaveAutomationEmailSubjectPost(apiVersion?: string, automationEmailSubjectViewModel?: AutomationEmailSubjectViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.automationSaveAutomationEmailSubjectPost(apiVersion, automationEmailSubjectViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AutomationEmailViewModel} [automationEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationSaveEmailPost(apiVersion?: string, automationEmailViewModel?: AutomationEmailViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.automationSaveEmailPost(apiVersion, automationEmailViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {AutomationTestEmailViewModel} [automationTestEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationSendTestEmailPost(apiVersion?: string, automationTestEmailViewModel?: AutomationTestEmailViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.automationSendTestEmailPost(apiVersion, automationTestEmailViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {Automation} [automation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automationUpdateAutomationPut(apiVersion?: string, automation?: Automation, options?: any): AxiosPromise<boolean> {
            return localVarFp.automationUpdateAutomationPut(apiVersion, automation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutomationApi - object-oriented interface
 * @export
 * @class AutomationApi
 * @extends {BaseAPI}
 */
export class AutomationApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomationApi
     */
    public automationCreateAutomationPost(apiVersion?: string, options?: AxiosRequestConfig) {
        return AutomationApiFp(this.configuration).automationCreateAutomationPost(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomationApi
     */
    public automationDeleteAutomationIdDelete(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AutomationApiFp(this.configuration).automationDeleteAutomationIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomationApi
     */
    public automationDisableAutomationIdPut(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AutomationApiFp(this.configuration).automationDisableAutomationIdPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomationApi
     */
    public automationEnableAutomationIdPut(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AutomationApiFp(this.configuration).automationEnableAutomationIdPut(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomationApi
     */
    public automationGetAutomationByIdIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return AutomationApiFp(this.configuration).automationGetAutomationByIdIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomationApi
     */
    public automationListAutomationsGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return AutomationApiFp(this.configuration).automationListAutomationsGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AutomationEmailSubjectViewModel} [automationEmailSubjectViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomationApi
     */
    public automationSaveAutomationEmailSubjectPost(apiVersion?: string, automationEmailSubjectViewModel?: AutomationEmailSubjectViewModel, options?: AxiosRequestConfig) {
        return AutomationApiFp(this.configuration).automationSaveAutomationEmailSubjectPost(apiVersion, automationEmailSubjectViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AutomationEmailViewModel} [automationEmailViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomationApi
     */
    public automationSaveEmailPost(apiVersion?: string, automationEmailViewModel?: AutomationEmailViewModel, options?: AxiosRequestConfig) {
        return AutomationApiFp(this.configuration).automationSaveEmailPost(apiVersion, automationEmailViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {AutomationTestEmailViewModel} [automationTestEmailViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomationApi
     */
    public automationSendTestEmailPost(apiVersion?: string, automationTestEmailViewModel?: AutomationTestEmailViewModel, options?: AxiosRequestConfig) {
        return AutomationApiFp(this.configuration).automationSendTestEmailPost(apiVersion, automationTestEmailViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {Automation} [automation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomationApi
     */
    public automationUpdateAutomationPut(apiVersion?: string, automation?: Automation, options?: AxiosRequestConfig) {
        return AutomationApiFp(this.configuration).automationUpdateAutomationPut(apiVersion, automation, options).then((request) => request(this.axios, this.basePath));
    }
}

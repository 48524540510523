import React, { useContext, useState, useEffect } from "react";
import AccountPlanComponent from "./AccountPlanComponent";
import { Link } from 'react-router-dom';
import { AuthContext } from "../../contexts/AccountContext";
import "./AccountPlanPage.css";
const AccountPlanPage = () => {
  const authContext = useContext(AuthContext);
  return (
    <React.Fragment>
      <AccountPlanComponent />
      
    </React.Fragment>
  );
};

export default AccountPlanPage;

import React, { useContext, useState, useEffect, useRef } from "react";
import { AssetApi } from "../../apiclient";
import {
  CheckBalancePageSettingsViewModel,
  GiftCardImageAssetViewModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { Button, Row, Col, InputGroup, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import EditAssetImageModal from "./EditAssetImageModal";

const SelectAndUploadAssetImagesModal = (props: any) => {
  const authContext = useContext(AuthContext);
  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [isLoading, setLoading] = useState(false as boolean);
  const [listUpdated, setListUpdated] = useState(false as boolean);
  const [images, setImages] = useState([] as GiftCardImageAssetViewModel[]);
  const [selectedImage, setSelectedImage] = useState(
    {} as GiftCardImageAssetViewModel
  );
  const [showEditImageModal, setShowEditImageModal] = useState(
    false as boolean
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onShow = () => {
    fetchAssetImages();
    setValidated(false);
  };

  const onClose = () => {
    if (listUpdated) {
      props.refreshImages();
    }
    props.onHide();
  };

  const fetchAssetImages = () => {
    assetApi
      .assetAllImageAssetsIdGet(authContext.account?.accountId!)
      .then((response) => {
        var data = response.data;
        setImages(data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log("get survey error", error);
        setLoading(false);
      });
  };

  const handleImageSelect = (updateImage: GiftCardImageAssetViewModel) => {
    //setSelectedImage(image);
    assetApi
      .assetToggleImageAssetIsActivePost("1", updateImage)
      .then((response) => {
        var data = response.data;
        setListUpdated(true);
      })
      .catch((error: any) => {
        setListUpdated(false);
      });

    setImages((prevImages) =>
      prevImages.map((image) => {
        if (image.id === updateImage.id) {
          updateImage.isActive = !updateImage.isActive;
          // Update the image object with newImageData
          return { ...image, ...updateImage };
        }
        return image; // Leave other images unchanged
      })
    );
  };

  const onEditImageDetailsClicked = (
    updateImage: GiftCardImageAssetViewModel
  ) => {
    setSelectedImage(updateImage);
    setShowEditImageModal(true);
  };

  const onDesignFileInputChange = (event: any) => {
    console.log("onDesignFileInputChange");
    let file = event.target.files[0];
    let extension = file.name.split(".").at(-1);
    let allowedFileExtensions = ["jpg", "png", "jpeg", "gif", "bmp"];
    if (!allowedFileExtensions.includes(extension.toLowerCase())) {
      toast.error("Incorrect file format, failed to import");
      // clear the file input
      event.target.value = null;
      return;
    }

    var reader = new FileReader();
    reader.onload = (e) => {
      if (e.target !== null) {
        var image = new Image();
        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result as string;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = image.height;
          var width = image.width;
          // if (height > 150 || width > 600) {
          //      toast.error(
          //"File size is too large, maximum dimensions are 600x150"
          //);
          //     // clear the file input
          //     event.target.value = null;
          //     setImageData(null);
          //     setModel({ ...model, customLogoImageUrl: undefined });
          //     return false;
          // }
        };
      }
    };
    reader.readAsDataURL(event.currentTarget.files[0]);
    //upload file
    var uploadFile = event.currentTarget.files[0];
    console.log("upload design file");
    assetApi
      .assetUploadImageAssetPost("1", uploadFile)
      .then((response) => {
        var data = response.data;
        console.log("upload design file uploaded", data);
        setListUpdated(true);
        fetchAssetImages();
      })
      .catch((error: any) => {
        setListUpdated(false);
      });
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={onClose}
        onShow={onShow}
        fullscreen={true}
      >
        <Form noValidate validated={validated}>
          <Modal.Header closeButton>
            <Modal.Title>Configure gift cards designs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex px-5 py-10">
              <div className="file-upload-border rounded bg-primary bg-opacity-10 bg-opacity-20-hover border-2 border-primary border-dashed border-primary-hover position-relative">
                <div className="d-flex justify-content-center px-5 py-10">
                  <label
                    htmlFor="design-file-upload"
                    className="position-absolute w-full h-full top-0 start-0 cursor-pointer"
                  >
                    <Form.Control
                      onChange={(e) => onDesignFileInputChange(e)}
                      name="design-file-upload"
                      id="design-file-upload"
                      className="visually-hidden"
                      type="file"
                      accept="image/jpg, image/png, image/jpeg, image/gif, image/bmp"
                    />
                  </label>
                  <div className="text-center">
                    <div className="text-2xl text-primary">
                      <i className="bi bi-cloud-upload-fill"></i>
                    </div>
                    <div className="d-flex text-sm text-primary mt-3">
                      <p className="font-semibold">
                        Upload a file or drag and drop
                      </p>
                    </div>
                    <p className="text-xs text-primary">
                      PNG, JPG, GIF up to 3MB
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Row className="g-6">
              <div className="h5 mb-0">Designs</div>
              {images.map((image) => (
                <div key={image.id} className="col-xl-2 col-sm-6">
                  <div className="card border-0 shadow shadow-4-hover">
                    <div className="p-2">
                      <div className="d-block">
                        <label
                          className={`radio-image ${
                            image.isActive ? "selected" : ""
                          }`}
                          onClick={() => handleImageSelect(image)}
                        >
                          <img
                            className="img-fluid rounded-2"
                            alt={image.name}
                            src={image.imageUrl}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="px-4 pt-2 pb-4">
                      <h6 className="font-semibold mb-2">{image.name}</h6>
                      <div className="d-flex align-items-center">
                        <div className="text-xs text-muted">
                          <i className="bi bi-tag me-1"></i>
                          {image.category}
                        </div>
                        <div className="text-xs text-muted ms-auto">
                          <a
                            onClick={() => onEditImageDetailsClicked(image)}
                            className="btn btn-sm btn-neutral px-2 text-muted text-primary-hover"
                          >
                            <i className="bi bi-pencil-square"></i>
                          </a>
                        </div>
                      </div>
                      <span className="d-block"></span>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
          </Modal.Body>
        </Form>
      </Modal>
      <EditAssetImageModal
        show={showEditImageModal}
        onHide={()=>setShowEditImageModal(false)}
        image={selectedImage}
        refreshImages={fetchAssetImages}
      />
    </React.Fragment>
  );
};

export default SelectAndUploadAssetImagesModal;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AccountContext";
import { CrispChatContext } from "./CrispChat";
import SwitchAccountModal from "../pages/account/SwitchAccountModal";
import { shopifyAppBridge } from "../config";
import LaunchBanner from "./LaunchBanner";

const AdminTopNavBar = (props: any) => {
  const authContext = useContext(AuthContext);
  const crispChatContext = useContext(CrispChatContext);
  const [isSwitchAccountModalShown, setIsSwitchAccountModalShown] =
    useState(false);
  const numProfiles = authContext.account?.associatedAccountIds?.length ?? 1;
  const shopifyEmbedHostParam =
    new URLSearchParams(window.location.search).get("host") ||
    shopifyAppBridge.host;

  const [hideLogoutFromMenu, setHideLogoutFromMenu] = useState(
    false as boolean
  );

  useEffect(() => {
    if (shopifyEmbedHostParam || inIframe()) {
      setHideLogoutFromMenu(true);
    }
  }, []);

  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  // console.log('top nav host param', shopifyEmbedHostParam)
  //let navigate = useNavigate();
  const logout = (event: any) => {
    window.location.href = "/Account/Logout";
  };

  function openHelpChat() {
    console.log("loadHelpChat");
    crispChatContext.openChat();
  }

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-0 py-3">
        <div className="container-fluid">
          {/* <!-- Toggler --> */}
          <button
            className="navbar-toggler ms-n2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExampleXxl"
            aria-controls="navbarsExampleXxl"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <!-- Brand --> */}
          {props.showMenu ? (
            <Link
              to={`/dashboard`}
              className="navbar-brand d-flex align-items-center gap-2 py-lg-2 px-lg-6 me-0"
            >
              <div className="w-md-auto">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="..."
                />
              </div>
            </Link>
          ) : (
            <a className="navbar-brand d-flex align-items-center gap-2 py-lg-2 px-lg-6 me-0">
              <div className="w-md-auto ">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="..."
                />
              </div>
            </a>
          )}

          {/* <!-- Avatar --> */}
           <div className="navbar-nav mb-4 mb-lg-0 order-lg-1">
            <a
              href="https://help.wrappedgiftcards.com"
              target="_blank"
              className="nav-item nav-link float-end"
            >
              <i className="bi bi-question-circle-fill"></i> Help center
            </a>
          </div> 
          <div className="order-lg-last ms-lg-4 position-relative">
            <a
              className="d-flex align-items-center dropdown"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="d-none d-sm-block ms-3">
                <span className="h6 text-white">
                WrappedAdmin
                </span>
              </div>
              <div className="d-none d-md-block ms-md-5">
                <i className="bi bi-chevron-down text-muted text-xs"></i>
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              {!hideLogoutFromMenu && (
                <>
                  <li>
                    <a className="dropdown-item cursor-pointer" onClick={logout}>
                      <i className="bi bi-box-arrow-left"></i> Logout
                    </a>
                  </li>
                </>
              )}
              <div className="dropdown-divider"></div>
              <li>
                <p className="text-center p-2 text-muted">
                  <small>
                    You are logged in as: WrappedAdmin
                  </small>
                </p>
              </li>
            </ul>
          </div>
          <div className="collapse navbar-collapse" id="navbarsExampleXxl">
           
          </div>
        </div>
      </nav>
      {authContext.account && (
        <SwitchAccountModal
          show={isSwitchAccountModalShown}
          onHide={() => setIsSwitchAccountModalShown(false)}
        />
      )}
    </React.Fragment>
  );
};

export default AdminTopNavBar;

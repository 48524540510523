import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import 'react-quill/dist/quill.snow.css';

import {
  GiftCardDetailViewModel,
  EditGiftCardViewModel,
} from "../../apiclient/models";
import Util from "../../js/util";
import ReactQuill from "react-quill";

interface GiftCardCustomerDetailsEditModalComponentProps {
  giftCard: GiftCardDetailViewModel;
  onHide: any;
  show: boolean;
  onRefresh: any;
}

const GiftCardCustomerDetailsEditModalComponent = (
  props: GiftCardCustomerDetailsEditModalComponentProps
) => {
  //modal + form
  let { id } = useParams();
  const [model, setModel] = useState({} as EditGiftCardViewModel);
  const [validated, setValidated] = useState(false);
  let cardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: EditGiftCardViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      //setIsSubmitting(false);
      cardApi
        .giftcardSaveEditGiftCardPost("1", model)
        .then((response) => {
          if (response.data) {
            toast.success("Customer details have been saved.");
            props.onHide();
            props.onRefresh();
          } else {
            toast.error("Could not save customer details.");
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred.");
        });
    }
  };
  const modalLoaded = () => {
    if(props.giftCard){
    setModel({
      buyerEmailAddress: props.giftCard.buyerEmailAddress,
      buyerName: props.giftCard.buyerName,
      giftCardId: id,
      giftMessage: props.giftCard.giftMessage,
      recipientEmailAddress: props.giftCard.recipientEmailAddress,
      recipientName: props.giftCard.recipientName
    });
      
    }
    //setGiftMessage("<p>Happy birthday.</p>");
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide} onEntered={modalLoaded}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit customer details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="">
                <Form.Label>Recipient email address:</Form.Label>
                <Form.Control
                  name="recipientEmailAddress"
                  value={model.recipientEmailAddress || ""}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mt-5">
                <Form.Label>Recipient name:</Form.Label>
                <Form.Control
                  name="recipientName"
                  value={model.recipientName || ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-5">
                <Form.Label>Gifted from name:</Form.Label>
                <Form.Control
                  name="buyerName"
                  value={model.buyerName || ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-5">
                <Form.Label>Gifted from email address:</Form.Label>
                <Form.Control
                  name="buyerEmailAddress"
                  value={model.buyerEmailAddress || ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-5">
                <Form.Label>Gift message:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="e.g. Happy birthday"
                  name="giftMessage"
                  value={model.giftMessage || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={props.onHide}>
              Close
            </Button>
            <Button size="sm" variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default GiftCardCustomerDetailsEditModalComponent;

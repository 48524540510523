import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { AuthContext } from "../contexts/AccountContext";
import { AccountStatus, ProfileApi } from "../apiclient";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import { axiosInstance } from "../ShopifyAxiosInterceptor";
import { apiConfig } from "../config";
import toast, { Toaster } from "react-hot-toast";

const LaunchBanner = (props: any) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const checklist = searchParams.get("checklist") ?? "";
  const authContext = useContext(AuthContext);
  const [showLaunchBanner, setShowLaunchBanner] = useState(false as boolean);
  const [bannerType, setBannerType] = useState("");
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);
  let profileApi = new ProfileApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  useEffect(() => {
    if (checklist === "complete") {
      setShowConfetti(true);
      toast.success("Gift card program is enabled.", { duration: 0 });
    }
    if (authContext.account) {
      if(!authContext.account.onboardingStepUpgradeToPaidPlan!){
        setBannerType("payment");
      }else if(!authContext.account.onboardingStepGiftCardProgramEnabled!){
        setBannerType("enableProgram");
      }
      setShowLaunchBanner(
        !authContext.account.onboardingStepUpgradeToPaidPlan! ||
        !authContext.account.onboardingStepGiftCardProgramEnabled
      );
    }
  }, []);

  const toggleAccountStatus = () => {
    profileApi
      .profileToggleAccountStatusPost()
      .then((response) => {
        if (response.data) {
          toast.success(
            `Gift card program ${authContext.account?.onboardingStepGiftCardProgramEnabled
              ? "disabled"
              : "enabled"
            }.`
          );
          setShowConfetti(true);
          window.location.href = "/dashboard?checklist=complete";
        } else {
          toast.success("Gift card program disabled.");
        }
      })
      .catch((error: any) => { });
  };

  return (
    <React.Fragment>
      {showLaunchBanner && bannerType === "payment" && (
        <div className="alert alert-warning" role="alert">
          <div className="row align-items-center">
            <div className="col font-bold">
              Start your 14 day free trial to start selling and accepting gift cards
              in-store and online.
            </div>

            <div className="col-auto">
              <div className="hstack gap-2 justify-content-end">
                <Link
                  className="btn d-inline-flex btn-sm btn-primary mx-1"
                  to={`/billingplan`}
                >
                  Choose plan
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {showLaunchBanner && bannerType === "enableProgram" && (
        <div className="alert alert-warning" role="alert">
          <div className="row align-items-center">
            <div className="col font-bold">
              Turn on gift cards to start selling and accepting gift cards
              in-store and online.
            </div>

            <div className="col-auto">
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  onClick={() => toggleAccountStatus()}
                  className="btn d-inline-flex btn-sm btn-primary mx-1"
                >
                  <span className=" pe-2">
                    <i className="bi bi-rocket"></i>
                  </span>
                  <span>Turn on gift cards</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={500}
        />
      )}
    </React.Fragment>
  );
};

export default LaunchBanner;

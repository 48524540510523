  class Util {

    static generateRandomString = (n: number) => {
      let randomString = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
      for (let i = 0; i < n; i++) {
        randomString += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return randomString;
    };

    static CurrencyFormatter = ({
      amount,
      currencyCode,
    }: {
      amount: number;
      currencyCode: string;
    }) => {
      if (currencyCode) {
        const formattedAmount = new Intl.NumberFormat(undefined, {
          style: "currency",
          currency: currencyCode,
          currencyDisplay: "symbol",
        }).format(amount);
  
        return <span>{formattedAmount}</span>;
      } else {
        return <span>{amount}</span>;
      }
    };
  }
  
  export default Util;
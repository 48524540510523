import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AssetApi, GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";

import {
  CheckBalancePageSettingsViewModel,
  GiftCardDetailViewModel,
  SendGiftCardToFriendViewModel,
} from "../../apiclient/models";
import Util from "../../js/util";
import ReactQuill from "react-quill";
import TimezoneSelect from "react-timezone-select";
import { toLocaleString } from "../../data/admins";
//import "./assets.css";
const PublicGiftToFriendPage = () => {
  let { id } = useParams();
  const [isLoading, setLoading] = useState(false as boolean);
  const [model, setModel] = useState({} as SendGiftCardToFriendViewModel);
  const [themeModel, setThemeModel] = useState(
    {} as CheckBalancePageSettingsViewModel
  );
  const [giftMessage, setGiftMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  let cardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  useEffect(() => {
    fetchData();
  }, []);

  const timezoneOnChange = (selectedTimezone: any) => {
    console.log("selectedTimezone", selectedTimezone);
    setTimezone(selectedTimezone.value);
  };

  const fetchThemeData = (id: string) => {
    assetApi
      .assetCheckBalancePagePublicIdGet(id!)
      .then((response) => {
        var data = response.data;
        setThemeModel(data);
      })
      .catch((error: any) => {});
  };

  const getLocalDate = (dateStr :string) => {
    const currentDateTime = new Date(dateStr);
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const hours = String(currentDateTime.getHours()).padStart(2, "0");
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");

    var localDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return localDate;
  };

  const fetchData = () => {
    setModel({});
    setGiftMessage(
      "<p>Best wishes for your birthday! Hope you enjoy this gift card!.</p>"
    );

    cardApi
      .giftcardGiftCardToFriendIdGet(id!)
      .then((response) => {
        var data = response.data;
        if (data.giftScheduledFor) {
          data.sendLater = true;
          data.giftScheduledFor = getLocalDate(data.giftScheduledFor);
        } else {
          data.sendLater = false;
          data.giftScheduledFor = getLocalDate(new Date().toString());
        }
        setModel(data);
        setGiftMessage(data.giftMessage!);
        fetchThemeData(data.accountId!);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "sendLater") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }
    setModel((values: SendGiftCardToFriendViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      //setIsSubmitting(false);
      model.giftMessage = giftMessage;
      model.timezone = timezone;
      cardApi
        .giftcardSendGiftCardToFriendPost("1", model)
        .then((response) => {
          if (response.data) {
            toast.success("Gift card has been sent to friend.");
          } else {
            toast.error("Count not send gift card to friend.");
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred.");
        });
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <LoaderIcon />
        </>
      ) : (
        <div className="d-xl-flex row">
          <div
            className="col-md-12 flex-xl-none border-start-xl h-xl-calc position-sticky top-xl-18"
            style={{
              backgroundColor: themeModel.backgroundColor,
            }}
          >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="">
                <div className="container py-10">
                  <div className="row mb-10 public-forms pt-0">
                    <div className="mx-auto">
                      <div className="row align-items-center">
                        <div className="text-center">
                          <h1
                            className="h1 ls-tight mb-10"
                            style={{
                              color: themeModel.primaryColor,
                            }}
                          >
                            {model.companyName}
                          </h1>
                          <h1
                            className="h2 ls-tight mb-5"
                            style={{
                              color: themeModel.primaryColor,
                            }}
                          >
                            Gift {model.giftCardBalance} to a friend
                          </h1>
                        </div>
                      </div>
                      <div
                        className="card mb-10"
                        style={{
                          backgroundColor: themeModel.cardColor,
                        }}
                      >
                        <div className="card-header text-center">
                          <h5
                            style={{
                              color: themeModel.primaryColor,
                            }}
                          >
                            Gift card code: {model.giftCardCode}
                          </h5>
                        </div>
                        <div className="card-body">
                          <Row>
                            <Form.Group className="">
                              <Form.Label
                                style={{
                                  color: themeModel.primaryColor,
                                }}
                              >
                                From Name:
                              </Form.Label>
                              <Form.Control
                                name="giftedFromName"
                                value={model.giftedFromName || ""}
                                onChange={handleChange}
                                required
                              />
                            </Form.Group>
                            <Form.Group className="mt-5">
                              <Form.Label
                                style={{
                                  color: themeModel.primaryColor,
                                }}
                              >
                                Friend's name:
                              </Form.Label>
                              <Form.Control
                                name="recipientName"
                                value={model.recipientName || ""}
                                onChange={handleChange}
                                required
                              />
                            </Form.Group>
                            <Form.Group className="mt-5">
                              <Form.Label
                                style={{
                                  color: themeModel.primaryColor,
                                }}
                              >
                                Friend's email address:
                              </Form.Label>
                              <Form.Control
                                name="recipientEmailAddress"
                                value={model.recipientEmailAddress || ""}
                                onChange={handleChange}
                                required
                              />
                            </Form.Group>
                            <Form.Group className="mt-5">
                              <Form.Label
                                style={{
                                  color: themeModel.primaryColor,
                                }}
                              >
                                Gift Message:
                              </Form.Label>
                              <ReactQuill
                                value={giftMessage}
                                onChange={setGiftMessage}
                              />
                            </Form.Group>
                            <Form.Group className="mt-5">
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="sendLater"
                                  id="btnradio1"
                                  value="false"
                                  checked={model.sendLater === false}
                                  onChange={handleChange}
                                />
                                <label
                                  className="btn btn-outline-primary"
                                  htmlFor="btnradio1"
                                >
                                  Send now
                                </label>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="sendLater"
                                  id="btnradio2"
                                  value="true"
                                  checked={model.sendLater === true}
                                  onChange={handleChange}
                                />
                                <label
                                  className="btn btn-outline-primary"
                                  htmlFor="btnradio2"
                                >
                                  Send later
                                </label>
                              </div>
                            </Form.Group>
                            {model.sendLater && (
                              <>
                                <Form.Group className="mt-5">
                                  <Form.Label
                                    style={{
                                      color: themeModel.primaryColor,
                                    }}
                                  >
                                    Send At:
                                  </Form.Label>
                                  <Form.Control
                                    name="giftScheduledFor"
                                    type="datetime-local"
                                    value={model.giftScheduledFor || ""}
                                    onChange={handleChange}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group className="mt-5">
                                  <Form.Label
                                    style={{
                                      color: themeModel.primaryColor,
                                    }}
                                  >
                                    Timezone:
                                  </Form.Label>
                                  <TimezoneSelect
                                    value={timezone}
                                    onChange={timezoneOnChange}
                                  />
                                </Form.Group>
                              </>
                            )}
                          </Row>
                          <div className="mt-10">
                            <button
                              className="btn btn-primary w-full"
                              type="submit"
                              style={{
                                backgroundColor: themeModel.buttonColor,
                              }}
                            >
                              Send as gift
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PublicGiftToFriendPage;

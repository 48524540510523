import React, { useContext, useState, useEffect } from "react";
import { RefundApi } from "../../apiclient";
import {
  VendRefundPostModel,
  ReturnIdResult
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  InputGroup,
  FormCheck,
  Spinner,
} from "react-bootstrap";
import "./VendPaymentTypeRefundPage.css";

const VendPaymentTypeRefundPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramPaymentTypeAmount = searchParams.get("amount")
    ? parseFloat(searchParams.get("amount") ?? "0")
    : 0;
  const paramPaymentTypeOrigin = searchParams.get("origin") ?? "";
  const paramPaymentTypeCurrency = searchParams.get("currency") ?? "";
  const [isLoading, setIsLoading] = useState(true as boolean);
  const [isSubmitting, setIsSubmitting] = useState(false as boolean);
  const [showValidBlock, setShowValidBlock] = useState(false as boolean);
  const [validated, setValidated] = useState(false as boolean);
  const [emailAddress, setEmailAddress] = useState("" as string);
  const [vendOrderId, setVendOrderId] = useState("" as string);

  let refundApi = new RefundApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  useEffect(() => {
    const postMessageHandler = (ev: MessageEvent) => {
      try {
        var payload = JSON.parse(ev.data);
        console.log("payload", payload);
        if (payload.step === "DATA") {
          var vendCustomerId = payload?.register_sale?.customer_id;
          console.log('vendCustomerId', vendCustomerId);
          if(vendCustomerId){
            refundApi.refundGetVendCustomerEmailAddressGet(paramPaymentTypeOrigin, vendCustomerId).then((response)=>{
              if(response.data){
                setEmailAddress(response.data);
              }
            });
          }

          var vendOrderId = payload?.register_sale.id;
          setVendOrderId(vendOrderId);
        }
      } catch (e) {}
    };

    window.addEventListener("message", postMessageHandler);

    // get vend order data
    sendObjectToVend({
      step: "DATA",
    });

    setIsLoading(false);
    setShowValidBlock(paramPaymentTypeAmount < 0);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", postMessageHandler);
  }, []);

  const sendObjectToVend = (object: any) => {
    // Define parent/opener window.
    var receiver = window.opener !== null ? window.opener : window.parent;
    if (paramPaymentTypeOrigin !== "") {
      receiver.postMessage(JSON.stringify(object), paramPaymentTypeOrigin);
    }
  };

  const backToPaymentTypes = () => {
    console.log("backToPaymentTypes");
    sendObjectToVend({
      step: "EXIT",
    });
  };

  const CurrencyFormatter = ({
    amount,
    currencyCode,
  }: {
    amount: number;
    currencyCode: string;
  }) => {
    if (currencyCode) {
      const formattedAmount = new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: currencyCode,
        currencyDisplay: "symbol",
      }).format(amount);

      return <span>{formattedAmount}</span>;
    } else {
      return <span>{amount}</span>;
    }
  };

  const handleSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }

    setValidated(true);
    if (form.checkValidity() === true) {
      var model = {
        amountToRefund: paramPaymentTypeAmount,
        customerEmailAddress: emailAddress,
        vendDomain: paramPaymentTypeOrigin,
        vendOrderId: vendOrderId
      } as VendRefundPostModel;
      
      refundApi
        .refundCreateVendRefundAsGiftCardPost("1", model)
        .then((response) => {
          var responseData = response.data as ReturnIdResult;
          if(responseData.isSuccessful){
            toast.success("Refund has been sent as a gift card");
            sendObjectToVend({
              step: 'ACCEPT',
              transaction_id: responseData.id,
              receipt_html_extra: `REFUNDED TO GIFT CARD: ${responseData.id}`
            })
          }else{
            toast.error(responseData.message ?? "Unable to refund to gift card");
          }

          setIsSubmitting(false);

        })
        .catch((error: any) => {
          toast.error("Unable to perform refund as a gift card");
          setIsSubmitting(false);
        });
    }
  };

  const handleChange = (event: any) => {
    setEmailAddress(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="container m-10 mt-20">
        {isLoading && <Spinner animation="border" />}

        {!showValidBlock && (
          <div className="">
            <h1>Invalid use of &quot;Refund as a new Gift Card&quot;</h1>
            <p className="mt-5">
              This payment type can only be used for refunds and returns.
            </p>
            <div className="mt-10">
              <button
                className="btn btn-primary vd-btn-primary btn-lg float-end"
                onClick={backToPaymentTypes}
              >
                Choose Another Payment Type
              </button>
            </div>
          </div>
        )}
        {showValidBlock && (
          <div className="">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <h1 className="">
              Refund as a new Gift card for{" "}
              <CurrencyFormatter
                amount={paramPaymentTypeAmount * -1!}
                currencyCode={paramPaymentTypeCurrency!}
              />
              ?
            </h1>
            <div className="row mt-5">
              <div className="col-12">
                <div className="form-group">
                  <label>Customer Email address*</label>
                  
                  <Form.Control
                    required
                    name={"emailAddress"}
                    value={emailAddress ?? ""}
                    className="form-control"
                    type="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    />
                  <small className="form-text text-muted">
                    We'll send a new gift card to this email address. This can
                    be redeemed instore or online.
                  </small>
                </div>
              </div>
            </div>
            <div className="mt-10 float-end">
              <button
                className="btn btn-lg btn-secondary"
                onClick={backToPaymentTypes}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary vd-btn-primary btn-lg margin-left-5px" disabled={isSubmitting}>
                Confirm and Accept
              </button>
            </div>
            </Form>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default VendPaymentTypeRefundPage;

import React, { useState, useEffect } from "react";

import { TestApi } from "../apiclient";
import { TestViewModel } from "../apiclient/models";
import { apiConfig } from "../config"
import { axiosInstance } from "../ShopifyAxiosInterceptor";
const TestPage = () => {
    let testApi = new TestApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
    const [model, setModel] = useState({} as TestViewModel);

    useEffect(() => {
        const fetchData = () => {
            testApi.testLetsGoGet()
                .then(response => {
                    setModel(response.data);
                })
        };

        fetchData();

    }, []);


    return (
        <React.Fragment>
            <h1>Testing: {model.title}</h1>
        </React.Fragment>
    );
}

export default TestPage;

import React, { useContext, useState, useEffect } from "react";
const FaqComponent = () => {

  return (
    <React.Fragment>
                    <div className="row">
                <div className=" pt-24 pb-24">
                  <div className="container max-w-screen-xl">
                    <div className="row g-10 g-lg-32">
                      <div className="col-lg-4 mb-10 mb-lg-0">
                        <h1 className="lh-tight ls-tight display-6 mb-7">
                          Frequently asked questions
                        </h1>
                        <p className="lead">
                          Here are some of the most frequent questions we get
                          from our customers.
                          <br />
                          <br />
                          If you have any further questions, get in touch with
                          our friendly team 😃
                        </p>
                      </div>
                      <div className="col-lg-7 ms-auto">
                        <div
                          id="faqs"
                          className="accordion accordion-flush mb-0"
                        >
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading_faq_2">
                              <button
                                className="accordion-button px-0 py-7 collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse_faq_2"
                                aria-expanded="false"
                                aria-controls="collapse_faq_2"
                              >
                                <span className="h5">
                                  How does the 14-day trial work?
                                </span>
                              </button>
                            </h2>
                            <div
                              id="collapse_faq_2"
                              className="accordion-collapse collapse"
                              aria-labelledby="heading_faq_2"
                              data-bs-parent="#faqs"
                            >
                              <div className="accordion-body px-0">
                                <p>
                                You'll get 14 days to try Wrapped absolutely risk-free for 14 days.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading_faq_3">
                              <button
                                className="accordion-button px-0 py-7 collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse_faq_3"
                                aria-expanded="false"
                                aria-controls="collapse_faq_3"
                              >
                                <span className="h5">
                                When will I be charged?
                                </span>
                              </button>
                            </h2>
                            <div
                              id="collapse_faq_3"
                              className="accordion-collapse collapse"
                              aria-labelledby="heading_faq_3"
                              data-bs-parent="#faqs"
                            >
                              <div className="accordion-body px-0">
                                <p>
                                If you are on a monthly plan, you'll be charged 1 month in advance. If you are on an annual plan, you'll be charged 1 year in advance.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading_faq_4">
                              <button
                                className="accordion-button px-0 py-7 collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse_faq_4"
                                aria-expanded="false"
                                aria-controls="collapse_faq_4"
                              >
                                <span className="h5">
                                  Can I cancel anytime?
                                </span>
                              </button>
                            </h2>
                            <div
                              id="collapse_faq_4"
                              className="accordion-collapse collapse"
                              aria-labelledby="heading_faq_4"
                              data-bs-parent="#faqs"
                            >
                              <div className="accordion-body px-0">
                                <p>
                                  Yes, of course. Simply go to the manage subscription portal to cancel your plan.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading_faq_5">
                              <button
                                className="accordion-button px-0 py-7 collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse_faq_5"
                                aria-expanded="false"
                                aria-controls="collapse_faq_5"
                              >
                                <span className="h5">
                                  How can I request a refund?
                                </span>
                              </button>
                            </h2>
                            <div
                              id="collapse_faq_5"
                              className="accordion-collapse collapse"
                              aria-labelledby="heading_faq_5"
                              data-bs-parent="#faqs"
                            >
                              <div className="accordion-body px-0">
                                <p>
                                  If you are not happy within the first 14 days,
                                  you can request a refund, no questions asked.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading_faq_6">
                              <button
                                className="accordion-button px-0 py-7 collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse_faq_6"
                                aria-expanded="false"
                                aria-controls="collapse_faq_6"
                              >
                                <span className="h5">
                                  What if I have a question that isn&#39;t
                                  answered here?
                                </span>
                              </button>
                            </h2>
                            <div
                              id="collapse_faq_6"
                              className="accordion-collapse collapse"
                              aria-labelledby="heading_faq_6"
                              data-bs-parent="#faqs"
                            >
                              <div className="accordion-body px-0">
                                <p>
                                  Please look at our knowledge base or contact
                                  or friendly support team.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    </React.Fragment>
  );
};

export default FaqComponent;

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ProfileApi } from "../../apiclient";
import {
  ProfileViewModel,
  UpdateNotificationsViewModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import toast, { Toaster } from "react-hot-toast";
import { FormCheck, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import {axiosInstance} from "../../ShopifyAxiosInterceptor";
import AuthorizedAdminOrOwnerCheckComponent from "../../components/AuthorizedAdminOrOwnerCheckComponent";
import SettingsNavMenu from "../../components/SettingsNavMenu";

const AccountNotificationsPage = () => {
  let accountApi = new ProfileApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
  const [model, setModel] = useState({} as ProfileViewModel);
  const [isLoading, setLoading] = useState(false as boolean);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext.account) {
      setModel(authContext?.account);
    }
  }, []);

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: ProfileViewModel) => ({
      ...values,
      [name]: value,
    }));

    var submitModel = {
      notificationBestPracticeEnabled: model.notificationBestPracticeEnabled,
      notificationMarketingEnabled: model.notificationMarketingEnabled,
      notificationWeeklyDigestEnabled: model.notificationWeeklyDigestEnabled,
      notificationDailyDigestEnabled: model.notificationDailyDigestEnabled,
    } as UpdateNotificationsViewModel;

    if (name === "notificationBestPracticeEnabled") {
      submitModel.notificationBestPracticeEnabled = value;
    } else if (name === "notificationMarketingEnabled") {
      submitModel.notificationMarketingEnabled = value;
    } else if (name === "notificationWeeklyDigestEnabled") {
      submitModel.notificationWeeklyDigestEnabled = value;
    } else if (name === "notificationDailyDigestEnabled") {
      submitModel.notificationDailyDigestEnabled = value;
    }

    accountApi
      .profileUpdateNotificationsPost("1", submitModel)
      .then((response) => {
        toast.success("Notification setting updated.");
        authContext.refresh(); //refresh context;
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <AuthorizedAdminOrOwnerCheckComponent />
      <header>
        <div className="container">
          <div className="border-bottom pt-6">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12">
                <h1 className="h2 ls-tight">Notifications</h1>
              </div>
              <div className="col-sm-6 col-12">
              {/* <a
                    href="https://help.wrappedgiftcard.com/en/collections/3830625-account#notifications"
                    target={"_blank"}
                    className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block float-end"
                  >
                    <i className="bi bi-question-circle-fill"></i>
                    <span className="d-none d-sm-inline ms-2">Help articles</span>
                  </a> */}
              </div>
            </div>
            <SettingsNavMenu />
          </div>
        </div>
      </header>
      <main className="py-6 bg-surface-secondary">
        <div className="container vstack">
          <div className="row g-6">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="mb-2">Weekly digest</h5>
                  <p className="text-sm text-muted mb-6">
                    Weekly summary of responses and insights.
                  </p>
                  {/* <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" />
                                        </div> */}
                  <FormCheck
                    name="notificationWeeklyDigestEnabled"
                    className=""
                    type="switch"
                    checked={model.notificationWeeklyDigestEnabled}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="mb-2">Daily digest</h5>
                  <p className="text-sm text-muted mb-6">
                    Daily summary of responses and insights.
                  </p>
                  <FormCheck
                    name="notificationDailyDigestEnabled"
                    className=""
                    type="switch"
                    checked={model.notificationDailyDigestEnabled}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="mb-2">Best practices</h5>
                  <p className="text-sm text-muted mb-6">
                    Best practices to super charge your data.
                  </p>
                  <FormCheck
                    name="notificationBestPracticeEnabled"
                    className=""
                    type="switch"
                    checked={model.notificationBestPracticeEnabled}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div> */}
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="mb-2">Newsletter and annoucements</h5>
                  <p className="text-sm text-muted mb-6">
                    Get the latest news right to your inbox.
                  </p>
                  <FormCheck
                    name="notificationMarketingEnabled"
                    className=""
                    type="switch"
                    checked={model.notificationMarketingEnabled}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default AccountNotificationsPage;

import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CustomersApi } from "../../apiclient";
import {
  CustomerListItemViewModel,
} from "../../apiclient/models";
import _ from "lodash";
import AnalyticsPanelComponent from "../analytics/AnalyticsPanelComponent";
//import CustomerCsvUploadModalComponent from "./CustomerCsvUploadModalComponent";
import GridTable from "../../components/GridTable";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const CustomersListPage = () => {
  let customerApi = new CustomersApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [loading, setLoading] = useState(true as boolean);
  const authContext = useContext(AuthContext);
  //const dayjs = require("dayjs");
  const [data, setData] = useState([] as CustomerListItemViewModel[]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setfilterStatus] = useState("");
  const [showUploadCustomersCsvModal, setShowUploadCustomersCsvModal] =
    useState(false as boolean);

  const tableColumns = [
    {
      Header: "Customer Name",
      accessor: "customerName",
      Cell: ({ value, row }: any) => {
        var item = row.original as CustomerListItemViewModel;
        return (
          <React.Fragment>
            <div>
              <Link
                className="nav-link font-semibold row"
                to={`/customers/detail/${item.customerId}`}
              >
                <div className="d-flex align-items-center col">
                  <i className="bi bi-credit-card me-2 text-lg"></i>
                  {item.customerName}<br />{item.customerEmail}
                </div>
                {/* {item.notes &&
                <div className="col align-items-center text-end text-sm pt-1">
                  <OverlayTrigger
                    key={"tooltip" + item.customerId}
                    placement={"top"}
                    overlay={<Tooltip id={`tooltip-top`}>{item.notes}</Tooltip>}
                  >
                    <i className="bi bi-card-text"></i>
                  </OverlayTrigger>
                </div>} */}
              </Link>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: ({ value, row }: any) => {
        var item = row.original as CustomerListItemViewModel;
        return (
          <React.Fragment>
            <span>
              {dayjs(item.createdAt)
                .utc()
                .tz(authContext?.account?.ianaTimezone)
                .format("YYYY-MM-DD hh:mm a")}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Total Spent",
      Cell: ({ value, row }: any) => {
        var item = row.original as CustomerListItemViewModel;
        return (
          <React.Fragment>
            <span className="font-semibold text-heading">
              {item.formattedTotalSpent}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Gift Cards",
      Cell: ({ value, row }: any) => {
        var item = row.original as CustomerListItemViewModel;
        return (
          <React.Fragment>
            <span className="font-semibold text-heading">
              {item.numGiftCards}
            </span>
          </React.Fragment>
        );
      },
    },

    {
      Header: "Gift Cards Balance",
      Cell: ({ value, row }: any) => {
        var item = row.original as CustomerListItemViewModel;
        return (
          <React.Fragment>
            <span className="font-semibold text-heading">
              {item.formattedOutstandingGiftCardBalances}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Store Credit",
      Cell: ({ value, row }: any) => {
        var item = row.original as CustomerListItemViewModel;
        return (
          <React.Fragment>
            <span className="font-semibold text-heading">
              {item.formattedStoreCreditBalance}
            </span>
          </React.Fragment>
        );
      },
    },
  ];

  const fetchData = ({ pageSize, pageIndex, search, sortBy, status }: any) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      var isDesc = sortBy[0] ? sortBy[0].desc ?? "true" : "true";
      var sortField = sortBy[0] ? sortBy[0].id ?? "totalSpent" : "totalSpent";

      fetchAPIData({
        limit: pageSize,
        pageIndex: pageIndex,
        skip: pageSize * pageIndex,
        search: search,
        sortField: sortField,
        isDesc: isDesc,
        status,
      });
    }
  };

  const fetchAPIData = async ({
    limit,
    pageIndex,
    skip,
    search,
    sortField,
    isDesc,
    status,
  }: any) => {
    try {
      setLoading(true);
      if (!sortField) {
        sortField = "createdAt";
      }
      const serverSortField = sortField[0].toUpperCase() + sortField.slice(1); //capitalize first letter to match server field name

      customerApi
        .customersListGet(
          status,
          pageIndex,
          skip,
          limit,
          serverSortField,
          isDesc,
          search
        )
        .then((response) => {
          const list = response.data.result;
          var pageSize = limit;
          var TotalCount = response.data.totalCount ?? 0;
          var pageCount = ((TotalCount + pageSize - 1) / pageSize) | 0;

          setData(list ?? ([] as CustomerListItemViewModel[]));
          setPageCount(pageCount);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  const _handleSearch = _.debounce(
    (search: string) => {
      setSearchTerm(search);
    },
    1500,
    {
      maxWait: 1500,
    }
  );

  return (
    <React.Fragment>
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  {(authContext.account?.userRole == "Admin" || authContext.account?.userRole == "Owner") && <>
                  
                  <Dropdown as={ButtonGroup} className="float-end ms-2">
                    <Link className="btn btn-primary" to={`/gift-cards/new`}>
                      Add customer
                    </Link>

                    <Dropdown.Toggle
                      split
                      variant="primary"
                      id="dropdown-split-basic"
                    />

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setShowUploadCustomersCsvModal(true)}
                      >
                        Import from CSV file
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          window.open(
                            `/Giftcard/ExportCustomersToCsv`,
                            "_blank"
                          )
                        }
                      >
                        Export customers
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Link
                    className="pt-75rem btn btn-neutral font-semibold me-2 d-none d-md-block float-end ms2"
                    to={`/account/sales-channels`}
                  >
                    Settings
                  </Link>
                  </>}

                  {/* <Link
                    className="pt-75rem link-primary font-semibold me-2 d-none d-md-block float-end ms-2"
                    to={`/gift-cards`}
                  >
                    Export
                  </Link> */}
                  <h1 className="h2 ls-tight">Customers</h1>
                </div>
                <p className="text-sm pb-5">
                  Manage your customers and store credit balances
                </p>
              </div>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid vstack gap-3">
            {/* {authContext.account && (authContext.account?.userRole == "Admin" || authContext.account?.userRole == "Owner") && (
              <>
                <AnalyticsPanelComponent />
              </>
            )} */}

            <div className="card">
              <div className="col-sm-12 col-12">
                <div className="input-group input-group-sm input-group-inline float-end py-3 pr-20 width-350">
                  <span className="input-group-text pe-2">
                    <i className="bi bi-search"></i>{" "}
                  </span>
                  <input
                    id="searchTerm"
                    name="searchTerm"
                    type="text"
                    className="form-control"
                    placeholder="Search gift cards"
                    aria-label="Search"
                    onChange={(e) => _handleSearch(e.target.value)}
                  />
                </div>
                <ul className="nav nav-pills py-3 pl-20">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        filterStatus === "" ? "active" : ""
                      }`}
                      onClick={() => setfilterStatus("")}
                    >
                      All
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        filterStatus === "active" ? "active" : ""
                      }`}
                      onClick={() => setfilterStatus("active")}
                    >
                      Active
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        filterStatus === "disabled" ? "active" : ""
                      }`}
                      onClick={() => setfilterStatus("disabled")}
                    >
                      Disabled
                    </button>
                  </li>
                 
                </ul>
              </div>
              <div className="p-5 pt-0">
                <GridTable
                  columns={tableColumns}
                  data={data}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  search={searchTerm}
                  goToFirstPage={undefined}
                  status={filterStatus}
                />
              </div>
              <div></div>
            </div>
          </div>
        </main>
      </div>
      {/* <CustomerCsvUploadModalComponent
        show={showUploadCustomersCsvModal}
        onHide={() => setShowUploadCustomersCsvModal(false)}
      /> */}
    </React.Fragment>
  );
};

export default CustomersListPage;

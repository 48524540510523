import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi, CampaignApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Table,
  DropdownButton,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { GiftCardBatchApi } from "../../apiclient";
import {
  GiftCardListItemViewModel,
  GiftCardReasonType,
  GiftCardBatchViewModel,
  GiftCardBatchStatus,
  AccountFeatureType
} from "../../apiclient/models";
import _ from "lodash";
import AnalyticsPanelComponent from "../analytics/AnalyticsPanelComponent";
import GiftCardCsvUploadModalComponent from "../giftcards/GiftCardCsvUploadModalComponent";
import GridTable from "../../components/GridTable";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
import * as Swal from "sweetalert2";
import UpsellBanner from "../../components/UpsellBanner";


const GiftCardBatchListPage = () => {
  let navigate = useNavigate();

  let giftCardBatchApi = new GiftCardBatchApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  let campaignApi = new CampaignApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [loading, setLoading] = useState(true as boolean);
  const authContext = useContext(AuthContext);
  //const dayjs = require("dayjs");
  const [model, setModel] = useState([] as GiftCardBatchViewModel[]);

  useEffect(() => {
    refreshList();
  }, []);

  const refreshList = () => {
    giftCardBatchApi.giftCardBatchGiftCardBatchListGet().then((response) => {
      var data = response.data as GiftCardBatchViewModel[];
      setModel(data);
    });
  }

  const createCampaign = (batchId: string) => {
    campaignApi.campaignCreateCampaignByBatchIdPost(batchId!)
      .then((response) => {
        var data = response.data;
        if (data) {
          toast.success("Campaign draft created.");
          navigate(`/campaigns/edit/${data.id}`)
        } else {
          toast.error("Couldn't create campaign.");
        }
      })
      .catch((error: any) => {
        toast.error("Couldn't create campaign.");
      });
  }


  const deleteGiftCardBatch = (batch: GiftCardBatchViewModel) => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `This will delete the batch. Note: this will not delete any generated gift cards.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete batch",

        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          giftCardBatchApi.giftCardBatchDeleteGiftCardBatchIdDelete(batch.giftCardBatchId!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Gift card batch deleted.");
                refreshList();
              } else {
                toast.error("Couldn't delete batch.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't delete batch.");
            });
        }
      });
  };

  const getBatchStatusBadge = (batch: GiftCardBatchViewModel) => {
    var cardStatus = batch.batchStatus;
    var badgeColor = "bg-secondary text-secondary";
    if (cardStatus == GiftCardBatchStatus.Completed) {
      badgeColor = "bg-success text-success";
    } else if (cardStatus == GiftCardBatchStatus.Error) {
      badgeColor = "bg-danger text-danger";
    }
    return (<span
      className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
    >
      {cardStatus}
    </span>)
  }

  return (
    <React.Fragment>
      <UpsellBanner message="Start your trial to use this feature" />
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  {(authContext.account?.userRole == "Admin" ||
                    authContext.account?.userRole == "Owner") && (
                      <>
                        <Link
                          className="float-end ms-2 btn btn-primary btn-sm"
                          to={`/bulk-gift-cards/new`}
                        >
                          Create gift card batch
                        </Link>
                        <a href={`https://www.plasticprinters.com/wrapped-gift-cards?company=${authContext.account.companyName}&merchant_id=${authContext.account.accountId}&email=${authContext.account.emailAddress}&phone=${authContext.account.phone}`} target="_blank" className="float-end ms-2 btn btn-neutral btn-sm">Buy physical cards</a>
                      </>
                    )}
                  <h1 className="h2 ls-tight">Batch gift cards</h1>
                </div>
                <p className="text-sm pb-5">
                  Create a bulk amount of gift cards. Use them to launch
                  marketing campaigns, offer corporate gifting or facilitate
                  selling physical gift cards.
                </p>
              </div>
              <ul className="nav nav-tabs overflow-x border-0">
                <li className="nav-item">
                  <Link className="nav-link" to={`/gift-cards`}>
                    All gift cards
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active" to={`/bulk-gift-cards`}>
                    Batches
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="">
            <div className="bg-light">
              <div className="">
                <Table striped bordered hover className="tb-batches">
                  <thead className="table-light">
                    <tr>
                      <th>Batch Name</th>
                      <th>Source</th>
                      <th>Credit Redeemed / Issued</th>
                      <th>Cards Created </th>
                      <th>Revenue</th>
                      <th>Created at</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {model &&
                      model.map((batch: GiftCardBatchViewModel, i: number) => (
                        <>
                          <tr key={`row-batch-${batch.giftCardBatchId}`}>
                            <td>
                              <Link
                                className="text-link active"
                                to={`/gift-cards?batchId=${batch.giftCardBatchId}`}
                              >
                                {batch.batchName} {batch.isPromotional &&
                                  <span className={`ml-5 badge text-uppercase bg-opacity-30 bg-primary text-primary`}>Promo</span>}
                              </Link>
                            </td>
                            <td>{batch.batchType}</td>
                            <td>
                              {batch.creditRedeemedFormatted} /{" "}
                              {batch.creditIssuedFormatted}
                            </td>
                            <td>{batch.numCardsCreated}</td>
                            <td><Link to={`/reporting/revenue/batch/${batch.giftCardBatchId}`} className="text-link">{batch.revenueGeneratedFormatted}</Link></td>
                            <td className="td-date">
                              <span>
                                {dayjs(batch.createdAt)
                                  .utc()
                                  .tz(authContext?.account?.ianaTimezone)
                                  .format("YYYY-MM-DD hh:mm a")}
                              </span>
                            </td>
                            <td>{getBatchStatusBadge(batch)}</td>
                            <td>
                              <DropdownButton
                                key={`actions-menu-${batch.giftCardBatchId}`}
                                id="`actions-menu-${batch.giftCardBatchId}`"
                                title="Actions"
                                variant="neutral"
                                size="sm"
                              >
                                <Dropdown.Item onClick={() => navigate(`/gift-cards?batchId=${batch.giftCardBatchId}`)}>
                                  View gift cards
                                </Dropdown.Item>
                                {/* <Dropdown.Item>
                                  View reporting
                                </Dropdown.Item> */}

                                <Dropdown.Item
                                  onClick={() =>
                                    window.open(
                                      `/GiftCardBatch/ExportGiftCardBatchToCsv/${batch.giftCardBatchId}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Export codes
                                </Dropdown.Item>
                                {authContext?.account?.enabledFeatures && authContext?.account?.enabledFeatures.filter(a => a == AccountFeatureType.Automations).length > 0 && (
                                  <Dropdown.Item onClick={() => createCampaign(batch.giftCardBatchId!)}>
                                    Create marketing campaign
                                  </Dropdown.Item>
                                )}
                                {/* <Dropdown.Divider />
                                <Dropdown.Item onClick={()=>deleteGiftCardBatch(batch)}>
                                  Delete batch
                                </Dropdown.Item> */}
                              </DropdownButton>
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default GiftCardBatchListPage;

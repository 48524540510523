import React, { useState, useEffect, useContext } from "react";
import { AssetApi } from "../../apiclient";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button, Row, Col, InputGroup } from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { AuthContext } from "../../contexts/AccountContext";
import { GiftCardImageAssetViewModel } from "../../apiclient/models";
import * as Swal from "sweetalert2";

const EditAssetImageModal = (props: any) => {
  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as GiftCardImageAssetViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authContext = useContext(AuthContext);

  const handleOnShow = () => {
    if (props.image) {
      setModel(props.image);
    }
    setValidated(false);
    setIsSubmitting(false);
  };

  const handleSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      assetApi
        .assetUpdateImageAssetPost("1", model)
        .then((response) => {
          toast.success("Image updated!");
          props.refreshImages();
          props.onHide();
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error updating image.");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const onDeleteImage = () => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `Do you want to delete this image?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          setModel({ ...model, status: "Deleted" });
          model.status = "Deleted";
          assetApi
            .assetUpdateImageAssetPost("1", model)
            .then((response) => {
              toast.success("Image deleted!");
              props.refreshImages();
              props.onHide();
            })
            .catch((error: any) => {
              console.log("post error", error);
              toast.error("Error deleting image.");
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }
      });
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: GiftCardImageAssetViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      {(authContext.account?.isAdmin || authContext.account?.isOwner) && (
        <>
          <Modal show={props.show} onHide={props.onHide} onShow={handleOnShow}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Image</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      required
                      name={"name"}
                      className="form-control"
                      type="text"
                      value={model.name ?? ""}
                      placeholder="Name"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      required
                      name={"category"}
                      value={model.category ?? ""}
                      className="form-control"
                      type="text"
                      placeholder="Category"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>List order</Form.Label>
                    <Form.Control
                      required
                      name={"sortOrder"}
                      value={model.sortOrder ?? ""}
                      className="form-control"
                      type="number"
                      placeholder=""
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <div className="me-auto">
                  <a onClick={onDeleteImage} className="text-sm font-semibold text-danger cursor-pointer">
                    <i className="bi bi-trash me-2"></i>Delete
                  </a>
                </div>

                <button
                  type="button"
                  className="btn btn-sm btn-neutral"
                  onClick={props.onHide}
                >
                  Close
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save changes
                </button>
              </Modal.Footer>
            </Form>
          </Modal>
        </>
      )}
    </React.Fragment>
  );
};

export default EditAssetImageModal;

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import VideoRecorderWithCountdown from "./VideoRecorderWithCountdown";
import { LoaderIcon, toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { AssetApi, GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const VideoRecorderModal = (props: any) => {
  let { id } = useParams();
  let cardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [showRecording, setShowRecording] = useState(false);
  const [useVideoInput, setUseVideoInput] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState(null as any);
  const [uploadedVideoThumbnail, setUploadedVideoThumbnail] = useState(
    null as any
  );

  const createNewVideo = () => {
    setShowRecording(true);
    setUseVideoInput(false);
  };

  const uploadExistingVideo = () => {
    setShowRecording(true);
    setUseVideoInput(true);
  };

  const videoUploadCompleted = (event: any) => {
    console.log("videoUploadCompleted", event);
    if(event.duration > 60000){
      toast.error("Duration needs to be less than 60 seconds.");
      setShowRecording(false);
    }else{
    setUploadedVideo(event.video);
    setUploadedVideoThumbnail(event.thumbnail);
    }
  };

  const handleClose = () => {
    setShowRecording(false);
    props.onClose();
  };

  const reRecordVideo = () => {
    if (useVideoInput) {
      uploadExistingVideo();
    } else {
      createNewVideo();
    }
  };

  const convertSizeToMB = (sizeBytes : number) => {
    const sizeInMB = sizeBytes / (1024 * 1024);
    return parseFloat(sizeInMB.toFixed(2))
  };

  const acceptVideoUpload = () => {
    setIsUploading(true);

    const formData = new FormData();
    formData.append("videoMessageFile", uploadedVideo);
    formData.append("videoMessageThumbnailFile", uploadedVideoThumbnail);
    const thumbnailSize = convertSizeToMB(uploadedVideoThumbnail.size);
    const videoSize = convertSizeToMB(uploadedVideo.size);

    cardApi
      .giftcardUploadGiftCardVideoMessagePost(
        "1",
        id,
        uploadedVideo,
        uploadedVideoThumbnail,
        videoSize,
        thumbnailSize
      )
      .then((response) => {
        toast.success("Video message uploaded.");
        handleClose();
        console.log("giftcardUploadGiftCardVideoMessagePost", response.data);
        props.uploadComplete(response.data);
      })
      .catch((error: any) => {
        toast.error("An error occurred upload video file.");
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <Modal
      size="xl"
      show={props.showModal}
      onHide={handleClose}
      fullscreen={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.translation?.giftCardRecordVideoModalHeaderLabel}</Modal.Title>
        {uploadedVideo && (
          <div className="ms-auto">
            <div className="d-flex mx-n2">
              {/* <button type="button" className="btn btn-danger mr-10" onClick={reRecordVideo}>
              <i className="bi bi-arrow-clockwise"></i> Re-record
            </button> */}
              {isUploading ? (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={acceptVideoUpload}
                >
                  <LoaderIcon />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={acceptVideoUpload}
                >
                  <i className="bi bi-check"></i> Accept
                </button>
              )}
            </div>
          </div>
        )}
      </Modal.Header>
      <Modal.Body>
        {showRecording ? (
          <VideoRecorderWithCountdown
            onClose={handleClose}
            videoUploadCompleted={videoUploadCompleted}
            uploadedVideo={uploadedVideo}
            useVideoInput={useVideoInput}
          />
        ) : (
          <>
            <Button
            size="sm" 
              className="mr-10"
              variant="primary"
              onClick={createNewVideo}
            >
              {props.translation?.giftCardRecordNewVideoButtonText}
            </Button>
            <Button size="sm" variant="secondary" onClick={uploadExistingVideo}>
            {props.translation?.giftCardUploadExistingVideoButtonText}
            </Button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default VideoRecorderModal;

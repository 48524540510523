import React, { useState, useEffect, useContext } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { AdminApi } from "../../apiclient";
import { AdminLoginViewModel, AdminLoginResult } from "../../apiclient/models";
import { Button, Row, Col, Form, Card } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const AdminLoginPage = () => {
  let adminApi = new AdminApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as AdminLoginViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authContext = useContext(AuthContext);
  let { accountId } = useParams();

  useEffect(() => {}, []);

  const handleSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }

    setValidated(true);
    if (form.checkValidity() === true) {
      var modelToSubmit = { ...model, accountId: accountId };
      adminApi
        .adminAdminLoginPost("1", modelToSubmit)
        .then((response) => {
          var result = response.data as AdminLoginResult;
          if (result.isSuccessful && result.redirectUrl) {
            //toast.success("Logged in");
            authContext.refresh();
            window.location.href = result.redirectUrl;
          } else {
            toast.error("Unable to login as an admin");
            setIsSubmitting(false);
          }
        })
        .catch((error: any) => {
          toast.error("Unable to login as an admin");
          setIsSubmitting(false);
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: AdminLoginViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col className="col-sm-12 col-lg-4 offset-lg-4">
          <Card>
            <Card.Body>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    onChange={handleChange}
                    required
                    name={"emailAddress"}
                    value={model.emailAddress ?? ""}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                    name={"password"}
                    value={model.password ?? ""}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <>Logging in..</> : <>Log in</>}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AdminLoginPage;

import React, { useContext, useState, useEffect } from "react";

import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import AccountPlanComponent from "../account/AccountPlanComponent";
const OnboardingChoosePlanPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const headerEl = document.getElementById("header-nav")!
    if(headerEl){
      headerEl.scrollIntoView();
    }
  }, []);
 
  return (
    <React.Fragment>
               <header id="header-nav">
        <div className="container">
           <div className="row justify-content-center text-center mb-5">
            <div className="col-lg-7">
              <h5 className="h5 mb-5 text-uppercase text-primary"></h5>
              <h1 className="ls-tight font-bolder mb-4">
              Please choose your plan
              </h1>
              <p className="lead"> Plans include a 14-day free trial. Cancel anytime.</p>
            </div>
          </div> 
        </div>
      </header>     
      <AccountPlanComponent stage="onboarding" />
      
    </React.Fragment>
  );
};

export default OnboardingChoosePlanPage;

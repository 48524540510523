import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import {
  Configure2faComponentViewModel,
  Verify2faCodeModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import toast, { Toaster } from "react-hot-toast";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Modal,
} from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import AuthorizedAdminOrOwnerCheckComponent from "../../components/AuthorizedAdminOrOwnerCheckComponent";
import SettingsNavMenu from "../../components/SettingsNavMenu";
import { CopyBlock, dracula } from "react-code-blocks";
import * as Swal from "sweetalert2";

const Configure2faComponent = () => {
  let accountApi = new AccountApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  const [model, setModel] = useState({} as Configure2faComponentViewModel);
  const [codeToVerify, setCodeToVerify] = useState("" as string);
  const [isLoading, setLoading] = useState(false as boolean);
  const authContext = useContext(AuthContext);
  const [showConfigure2faModal, setShowConfigure2faModal] = useState(
    false as boolean
  );
  const [showRecovery2faCodesModal, setShowRecovery2faCodesModal] = useState(
    false as boolean
  );

  useEffect(() => {
    setLoading(true);
    loadModel();
  }, []);

  const loadModel = () => {
    accountApi
      .accountGetConfigure2faComponentViewModelGet()
      .then((response) => {
        setModel(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  }

  const handleVerifyCodeChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    setCodeToVerify(value);
  };

  const handleVerifyAndEnable2faSubmit = () => {
    accountApi
      .accountVerifyAndEnable2faPost("1", {
        codeToVerify: codeToVerify,
      } as Verify2faCodeModel)
      .then((response) => {
        if (response.data) {
          toast.success("Enabled 2-factor authentication");
          authContext.refresh(); //refresh context;

          loadModel();
          setCodeToVerify('');
          setShowConfigure2faModal(false);
          setShowRecovery2faCodesModal(true);

        } else {
          toast.error("Unable to verify code");
        }
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const disable2fa = () => {

    Swal.default
    .fire({
      title: "Are you sure?",
      text: `Disabling 2-Factor Authentication will make your account less secure`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, disable 2FA",

      confirmButtonColor: "#d9534f",
    })
    .then((result) => {
      if (result.isConfirmed) {
        accountApi.accountDisable2faPut()
          .then((response) => {
            var data = response.data;
            if (data) {
              toast.success("2FA disabled");
              loadModel();
            } else {
              toast.error("Couldn't disable 2FA.");
            }
          })
          .catch((error: any) => {
            toast.error("Couldn't disable 2FA.");
          });
      }
    });

  };

  const copyToClipboard = (textToCopy: string, successMessage: string) => {
    navigator.clipboard.writeText(textToCopy);
    toast.success(successMessage);
  };

  const copyRecoveryCodesToClipboard = () => {
    copyToClipboard(model.recoveryCodes || "", "Copied!");
    setShowRecovery2faCodesModal(false);
  };

  return (
    <React.Fragment>
      <AuthorizedAdminOrOwnerCheckComponent />

      <div className="row mb-10">
        <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
          <h4 className="font-semibold mb-2">Two-Factor Authentication</h4>
          <p className="text-sm">
          Once configured, you'll be required to enter a code created by an Authenticator app in order to sign into your Wrapped account
          </p>
        </div>
        <div className="col-lg-8">
          <div className="card mb-10">
            <div className="card-body">
              <div className="row g-5">
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">
                    <div className="me-4">
                      <div className="icon icon-lg icon-shape text-xl bg-opacity-30 bg-neutral border">
                        <img src="/img/assets/phone-pass-2fa.png" alt="2fa" />
                      </div>
                    </div>
                    <div className="flex-fill">
                      <span className="d-block h6 text-sm font-semibold mb-1">
                        Two-Factor Authentication
                      </span>
                      <span className="d-block text-xs text-muted">
                        Secure your account with an additional layer of security when you log in
                      </span>
                    </div>
                    <div className="ms-auto">
                      {!model.is2faEnabled ? (
                        <button
                          type="button"
                          className="btn btn-sm btn-primary px-3 py-2 d-none d-md-inline-block"
                          onClick={() => setShowConfigure2faModal(true)}
                        >
                          Enable
                        </button>
                      ) : (
                        <div className="d-flex align-items-center text-success font-semibold">
                          <i className="bi bi-check-circle-fill me-1"></i>
                          <span>Enabled</span>
                          <a
                            className="ml-10 btn btn-sm btn-neutral px-3 py-2 d-none d-md-inline-block"
                            onClick={() => setShowRecovery2faCodesModal(true)}
                          >
                            View Recovery Codes
                          </a>
                          <a
                            className="ml-10 btn btn-sm btn-outline-danger px-3 py-2 d-none d-md-inline-block"
                            onClick={() => disable2fa()}
                          >
                            Disable
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showConfigure2faModal}
        onHide={() => setShowConfigure2faModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Configure 2-Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-3 text-center">
              <img src="/img/assets/phone-lock-2fa.png" width="150" />
            </div>
            <div className="col-9">
              <div className="text-muted">Step 1</div>
              <h4>Get the Authenticator App</h4>
              <p>
                Download and install the{" "}
                <a href="https://m.google.com/authenticator" target="_blank">
                  Google Authenticator
                </a>
                ,{" "}
                <a href="https://www.authy.com/" target="_blank">
                  Authy
                </a>
                , or{" "}
                <a
                  href="https://support.microsoft.com/en-au/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a"
                  target="_blank"
                >
                  Microsoft Authenticator
                </a>{" "}
                for your app or tablet
              </p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-3 text-center">
              <img src="/Account/GetConfigure2faQrCode" width="150" />
            </div>
            <div className="col-9">
              <div className="text-muted">Step 2</div>
              <h4>Scan this Barcode</h4>
              <p>Open the authenticator app and:</p>
              <ul>
                <li>Tap the "+" icon in the top-right of the app</li>
                <li>Scan the image to the left, using your phone's camera</li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-3 text-center">
              <img src="/img/assets/phone-pass-2fa.png" width="150" />
            </div>
            <div className="col-9">
              <div className="text-muted">Step 3</div>
              <h4>Enter Verification Code</h4>
              <p>
                Once the barcode is scanned above, enter the 6-digit
                verification code generated by the app.
              </p>
              <div className="mt-5 mb-5">
                <div className="input-group" style={{ width: "450px" }}>
                  <Form.Control
                    name={"codeToVerify"}
                    className="form-control"
                    type="number"
                    value={codeToVerify}
                    onChange={(e) => handleVerifyCodeChange(e)}
                    placeholder="Code"
                    required
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => handleVerifyAndEnable2faSubmit()}
                  >
                    Verify Code and Activate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
                <button className="btn btn-primary" onClick={copyEmbedCodeToClipboard}>Copy</button>
                <button className="btn btn-secondary" onClick={()=>setShowEmbedCodeModal(false)}>Cancel</button>
              </Modal.Footer> */}
      </Modal>

      <Modal
        show={showRecovery2faCodesModal}
        onHide={() => setShowRecovery2faCodesModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>2-Factor Authentication Backup Recovery Codes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <p className="mb-3">
              If you lose access to your authentication device, you can use one of these backup codes to log into your account. Each code may be used once. Make a copy of these codes, and store it somewhere safe. 
            </p>
            <CopyBlock
              language={"html"}
              text={model.recoveryCodes || ""}
              showLineNumbers={false}
              theme={dracula}
              wrapLongLines={false}
              onCopy={copyRecoveryCodesToClipboard}
              codeBlock
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={copyRecoveryCodesToClipboard}
          >
            Copy
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setShowRecovery2faCodesModal(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Configure2faComponent;

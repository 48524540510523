import React, { useState, useEffect, useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { ApiKeyModel, BillingPlan, IntegrationApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import * as Swal from "sweetalert2";
import UpsellBanner from "../../components/UpsellBanner";
import { Button, Row, Col, InputGroup, Modal, Form } from "react-bootstrap";
import { useCopyToClipboard } from "react-use";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { shopifyAppBridge } from "../../config";
import SettingsNavMenu from "../../components/SettingsNavMenu";

const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const ApiKeysPage = () => {
  let integrationApi = new IntegrationApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [apiKeys, setApiKeys] = useState([] as ApiKeyModel[]);
  const authContext = useContext(AuthContext);
    const isValidPlan = true;
    //authContext.account?.billingPlan === BillingPlan.Plus ||
    //authContext.account?.billingPlan === BillingPlan.PlusAnnual ||
    //authContext.account?.billingPlan === BillingPlan.Foundation ||
    //authContext.account?.billingPlan === BillingPlan.Enterprise;

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = () => {
    integrationApi
      .integrationGetApiKeysGet()
      .then((response) => {
        var keys = response.data;
        setApiKeys(keys);
        console.log("fetchApiKeys", keys);
      })
      .catch((error: any) => {
        console.log("get apiKeys error", error);
      });
  };

  const createNewApiKey = () => {
    if (!isValidPlan) {
      toast.error("You need to upgrade to a paid plan to use this feature.");
    } else {
      integrationApi
        .integrationGenerateNewApiKeyPost()
        .then((response) => {
          toast.success("New API key created");
          fetchApiKeys();
        })
        .catch((error: any) => {
          console.log("get apiKeys error", error);
        });
    }
  };

  const confirmDisableApiKey = (token: string) => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `Disable the API Key "${token}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete key",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          disableApiKey(token);
        }
      });
  };

  const disableApiKey = (token: string) => {
    integrationApi
      .integrationDisableApiKeyDelete(token)
      .then((response) => {
        if (response)
          toast.success("API key deleted and access revoked from API");
        else toast.error("Failed to delete api key");

        fetchApiKeys();
      })
      .catch((error: any) => {
        console.log("post error", error);
        toast.error("Failed to remove api key");
      });
  };

  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    toast.success("API key copied to clipboard");
  };

  return (
    <React.Fragment>
      {/* <!-- Content --> */}
      <div className="">
        {/* <!-- Navbar --> */}
        <UpsellBanner message="Start your trial to use this feature" />
        <header>
          <div className="container">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-6 col-12">
                  <h1 className="h2 ls-tight">Private API keys</h1>
                </div>
                <div className="col-sm-6 col-12">
                  {/* <a
                    href="https://help.wrappedgiftcard.com/en/collections/3830625-account#notifications"
                    target={"_blank"}
                    className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block float-end"
                  >
                    <i className="bi bi-question-circle-fill"></i>
                    <span className="d-none d-sm-inline ms-2">
                      Help articles
                    </span>
                  </a> */}
                </div>
              </div>
              <SettingsNavMenu />
            </div>
          </div>
        </header>

        {/* <!-- Main --> */}
        <main className="py-6 bg-surface-secondary">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <p>
                    Want to develop your own integration? Check out our{" "}
                    <a href="https://wrappedgiftcards.readme.io" target="_blank">
                      API documentation
                    </a>
                  </p>
                  {!isValidPlan ? (
                    <>
                      <h4 className="mt-4 mb-3">Private API Keys</h4>
                      <div className="card">
                        <p className="p-4">
                          You need to{" "}
                          <a href="/billingplan">upgrade to the Plus plan</a> to
                          generate an API key and use the Wrapped API
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => createNewApiKey()}
                        type="button"
                        className="btn btn-primary btn-sm float-end"
                      >
                        Create new API key
                      </button>

                      <h4 className="mt-4 mb-3">Private API Keys</h4>
                      <div className="card">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Token</th>
                              <th>Created at</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {apiKeys &&
                              apiKeys?.map((apiKey, i) => (
                                <tr key={"apiKey-" + i}>
                                  <td>
                                    {apiKey.token}{" "}
                                    <button
                                      title="copy to clipboard"
                                      onClick={() =>
                                        copyToClipboard(apiKey.token || "")
                                      }
                                      type="button"
                                      className="btn btn-sm btn-square btn-neutral text-info-hover"
                                    >
                                      {" "}
                                      <i className="bi bi-clipboard"></i>
                                    </button>
                                  </td>
                                  <td>
                                    {dayjs(apiKey.createdAt)
                                      .utc()
                                      .tz(authContext?.account?.ianaTimezone)
                                      .format("YYYY-MM-DD hh:mm a")}
                                  </td>
                                  <td>
                                    <button
                                      onClick={() =>
                                        confirmDisableApiKey(apiKey.token ?? "")
                                      }
                                      type="button"
                                      className="btn btn-sm btn-square btn-neutral text-danger-hover"
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default ApiKeysPage;

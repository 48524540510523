import React, { useState, useEffect } from "react";
import { AccountApi } from "../../apiclient";
import {
  SendMagicLoginLinkModel,
  LoginWithPasswordViewModel,
  LoginWithPasswordResult
} from "../../apiclient/models";
import { Button, Row, Col, Form } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import "./SignInPage.css";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

const SignInPage = () => {
  let accountApi = new AccountApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as SendMagicLoginLinkModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const navigate = useNavigate();

  const [logInWithPasswordModel, setLogInWithPasswordModel] = useState(
    {} as LoginWithPasswordViewModel
  );
  const [isLoginWithPassword, setIsLoginWithPassword] = useState(
    false as boolean
  );

  const [loginButtonText, setLoginButtonText] = useState(
    isLoginWithPassword ? "Log in" : "Send magic link"
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const loginType = searchParams.get("t") ?? "";

  useEffect(() => {
    if(loginType == "pw"){
      setIsLoginWithPassword(true);
      setLoginButtonText("Log in");
    }

  }, []);

  const handleSubmit = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }

    setValidated(true);
    if (form.checkValidity() === true) {
      setLoginButtonText("Sending..");

      if (isLoginWithPassword) {
        accountApi
          .accountLoginWithPasswordPost("1", logInWithPasswordModel)
          .then((response) => {

            var loginResult = response.data as LoginWithPasswordResult;
            if(loginResult.isSuccessful){
              toast.success("Logged in", {
                duration: 10000,
              });
              setIsSubmitting(false);

              navigate(loginResult.redirectUrl || "");
              
            }else{
              toast.error(loginResult.redirectUrl ?? "Invalid login", {
                duration: 10000,
              });
              setIsSubmitting(false);
              setLoginButtonText("Log in");
            }



          })
          .catch((error: any) => {
            toast.error("Invalid login", {
              duration: 10000,
            });
            setLoginButtonText("Log in");
            setIsSubmitting(false);
          });
      } else {
        accountApi
          .accountSendMagicLoginLinkPost("1", model)
          .then((response) => {
            setHasSentEmail(true);
            toast.success("Magic link has been emailed to you.", {
              duration: 10000,
            });
            setIsSubmitting(false);

            //reset form
            setTimeout(() => {
              setHasSentEmail(false);
              setLoginButtonText("Send magic link");
              //setModel({});
            }, 10000);
          })
          .catch((error: any) => {
            toast.error("Unable to find account with this email address", {
              duration: 10000,
            });
            setLoginButtonText("Send magic link");
            setIsSubmitting(false);
          });
      }
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;

    if (isLoginWithPassword) {
      setLogInWithPasswordModel((values: LoginWithPasswordViewModel) => ({
        ...values,
        [name]: value,
      }));
    } else {
      setModel((values: SendMagicLoginLinkModel) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const changeToLoginWithMagicLink = () =>{
    setLoginButtonText("Send magic link");
    setIsLoginWithPassword(false);
  }

  const changeToLoginWithPassword = () => {
    setLoginButtonText("Log in");
    setIsLoginWithPassword(true);
  }

  return (
    <React.Fragment>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="px-5 py-5 p-lg-0 min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-dark d-none d-lg-flex flex-column">
              {/* <!-- Logo --> */}
              <a className="d-block" href="https://wrappedgiftcards.com">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="..."
                />
              </a>
              {/* <!-- Title --> */}
              <div className="mt-32 mb-20">
                <h1 className="ls-tight font-bolder display-7 text-white mb-5">
                  Create and manage omnichannel gift cards
                </h1>
                <p className="text-white text-opacity-80">
                  Deliver a better customer experience with gift cards that can
                  be redeemed instore or online.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-screen d-flex flex-column justify-content-center position-relative">
              <div className="py-lg-16 px-lg-20">
                <div className="row">
                  <div className="col-lg-10 col-md-9 col-xl-7 mx-auto ms-xl-0">
                    <div className="mt-10 mt-lg-5 mb-6 d-lg-block">
                      <h1 className="ls-tight font-bolder h2">
                        Log in to Wrapped
                      </h1>
                    </div>
                    {/* <div>
                      <div className="hstack gap-4 box-content">
                        <a
                          href="/Shopify/RedirectAndLogin"
                          className="btn btn-neutral signinbtn w-full"
                        >
                          <div>
                            <img src="/img/integrations/shopify-bag.png" />
                            Log in with Shopify
                          </div>
                        </a>
                      </div>
                      <div className="hstack gap-4 box-content">
                        <a
                          href="/Vend/RedirectAndLogin"
                          className="btn btn-neutral signinbtn mt-5 w-full"
                        >
                          <div>
                            <img
                              alt="..."
                              src="/img/integrations/Lightspeed_Flame.svg"
                            />
                            Log in with Lightspeed Retail (X-Series)
                          </div>
                        </a>
                      </div>
                    </div>
                    <hr /> */}

                    {!isLoginWithPassword ? (
                      <div className="magic-link-box">
                        <div className="mb-5 mt-5">
                          {/* <label className="form-label" htmlFor="emailAddress">Email address</label>
                                        <input type="emailAddress" className="form-control" id="emailAddress" /> */}
                          <Form.Label>
                            Sign in using your email address
                          </Form.Label>
                          <Form.Control
                            required
                            name={"emailAddress"}
                            value={model.emailAddress ?? ""}
                            className="form-control"
                            type="email"
                            placeholder="Email Address"
                            onChange={handleChange}
                          />
                          <small>
                            We'll email you a secure, single-use login link
                          </small>
                        </div>
                        {/* <div className="mb-5">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="check_example" id="check_example" />
                                            <label className="form-check-label" htmlFor="check_example">
                                                Keep me logged in
                                            </label>
                                        </div>
                                    </div> */}
                        <div>
                          {!hasSentEmail ? (
                            <>
                              <Button
                                className="w-full"
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {loginButtonText}
                              </Button>
                            </>
                          ) : (
                            <>
                              <p>
                                ✅ Magic login link has been sent to your inbox
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="login-box">
                        <div className="mb-5 mt-5">
                          {/* <label className="form-label" htmlFor="emailAddress">Email address</label>
                                        <input type="emailAddress" className="form-control" id="emailAddress" /> */}
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="emailAddress">
                              Email address
                            </Form.Label>
                            <Form.Control
                              required
                              name={"emailAddress"}
                              value={logInWithPasswordModel.emailAddress ?? ""}
                              className="form-control"
                              type="email"
                              placeholder="Email Address"
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="password">Password </Form.Label> <span className="float-end form-label"><a onClick={()=>navigate("/account/forgotpassword")} className="text-link cursor-pointer">Forgot password?</a></span>
                            <Form.Control
                              required
                              name={"password"}
                              value={logInWithPasswordModel.password ?? ""}
                              className="form-control"
                              type="password"
                              placeholder="Password"
                              onChange={handleChange}
                            />
                          </Form.Group>
                          {/* <Form.Group className="mb-3 form-check">
                            <input
                              name={"rememberMe"}
                              checked={logInWithPasswordModel.rememberMe}
                              className="form-check-input"
                              type="checkbox"
                              onChange={handleChange}
                            />
                            <Form.Label
                              className="form-check-label"
                              htmlFor="password"
                            >
                              Remember me?
                            </Form.Label>
                          </Form.Group> */}
                        </div>
                        <div>
                          <Button
                            className="w-full"
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {loginButtonText}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>

                
                <div className="row mt-10">
                  <div className="col-12">
                    {isLoginWithPassword?(
                    <a onClick={changeToLoginWithMagicLink} className="text-link cursor-pointer change-login-link">Log in with magic link instead</a>
                    ):(
                    <a onClick={changeToLoginWithPassword} className="text-link cursor-pointer change-login-link">Log in with password instead</a>
                    )}
                  </div>
                </div>
                </div>
    
              </div>
              <div className="position-lg-absolute bottom-0 end-0 my-8 mx-12 text-center text-lg-end">
                <small>Don't have an account?</small>
                <a
                  href="/account/signup"
                  className="text-sm font-semibold ml-5"
                >
                  Create a free account
                </a>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default SignInPage;

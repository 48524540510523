/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const BillingPlan = {
    Free: 'Free',
    Base: 'Base',
    Staff: 'Staff',
    Pro: 'Pro',
    Plus: 'Plus',
    Enterprise: 'Enterprise',
    BaseAnnual: 'BaseAnnual',
    ProAnnual: 'ProAnnual',
    PlusAnnual: 'PlusAnnual',
    Foundation: 'Foundation',
    Essentials: 'Essentials',
    Basic: 'Basic',
    BasicAnnual: 'BasicAnnual'
} as const;

export type BillingPlan = typeof BillingPlan[keyof typeof BillingPlan];




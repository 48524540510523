import React, { useState, useEffect } from "react";
import { EmailApi } from "../../apiclient";
import {
  EmailNotificationSubjectViewModel,
} from "../../apiclient/models";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import Modal from "react-bootstrap/Modal";
import { Button, Row, Col, InputGroup, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const EmailEditSubjectModal = (props: any) => {
    let emailApi = new EmailApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
  const [model, setModel] = useState({} as EmailNotificationSubjectViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    console.log("props.subject", props.subject);
    if(props.subject && props.notificationType){
    setModel((values: EmailNotificationSubjectViewModel) => ({
      ...values,
      subject: props.subject!,
      notificationType: props.notificationType!
    }));
  }
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    const form = event.currentTarget;

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      var postModel = {
        subject: model.subject,
        notificationType: model.notificationType
      } as EmailNotificationSubjectViewModel;
      emailApi
        .emailSaveNotificationSubjectPost("1", postModel)
        .then((response) => {
           toast.success("Subject saved");
           props.onHide();
           props.updated(model.subject);
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error saving email subject.");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: EmailNotificationSubjectViewModel) => ({
      ...values,
      [name]: value,
    }));
    setIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="text-center py-5 px-5">
              <div className="icon icon-shape rounded-circle bg-opacity-30 bg-info text-info text-lg">
                <i className="bi bi-envelope"></i>
              </div>
              <h3 className="mt-7 mb-4">Update email subject</h3>
              <p className="text-sm text-muted">
              Please keep current merge tags in subject.
              </p>
              <div className="text-start">
              <div className="mt-5">
                {/* <label className="form-label" htmlFor="subject">
                        Subject
                      </label> */}

                <Form.Control
                  required
                  value={model.subject}
                  name={"subject"}
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <Button
              className="btn btn-sm btn-neutral"
              variant={"secondary"}
              onClick={props.onHide}
            >
              Close
            </Button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-sm btn-primary"
            >
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default EmailEditSubjectModal;

import React, { useState, useEffect, useContext } from "react";
import { EmailApi } from "../../apiclient";
import { EmailNotificationReminderSettingsSaveViewModel, EmailNotificationReminderViewModel } from "../../apiclient/models";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import Modal from "react-bootstrap/Modal";
import { AuthContext } from "../../contexts/AccountContext";
import {
  Button,
  Row,
  Col,
  InputGroup,
  Spinner,
  FormCheck,
} from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const EmailEditReminderSettingsModal = (props: any) => {
  const authContext = useContext(AuthContext);
  let emailApi = new EmailApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as EmailNotificationReminderViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    // if(props.subject){
    // setModel((values: EmailNotificationReminderViewModel) => ({
    //   ...values,
    //   subject: props.subject!,
    // }));
    //}
  }, []);

  const onModalShown = () => {
   
      emailApi
        .emailNotificationSettingsNotificationTypeGet("GiftCardReminder")
        .then((response) => {
          setModel(response.data);
        })
        .catch((error: any) => {
          toast.error("Couldn't get settings.");
        });
    
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    const form = event.currentTarget;

    setValidated(true);
    if (form.checkValidity() === true) {
      
      var postModel = {
        notificationType: "GiftCardReminder",
        reminderDaysSinceLastAction: model.reminderDaysSinceLastAction,
        reminderMinBalanceAmount: model.reminderMinBalanceAmount,
        reminderNum: model.reminderNum,
        reminderMinBalanceEnabled: model.reminderMinBalanceEnabled
      } as EmailNotificationReminderSettingsSaveViewModel;
      emailApi
        .emailSaveNotificationReminderSettingsPost("1", postModel)
        .then((response) => {
          toast.success("Settings saved");
          props.onHide();
          setValidated(false);
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error saving settings subject.");
        }).finally(() => {
          setIsSubmitting(false);
        })
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: EmailNotificationReminderViewModel) => ({
      ...values,
      [name]: value,
    }));
    setIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide} onShow={onModalShown}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title">Reminder email settings</h5>
            <div className="text-xs ms-auto">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body">
            <div className="col-md-12">
              <div className="">
                <label className="form-label" htmlFor="reminderNum">
                  How many Reminders would you like to send each customer?
                </label>
                <div className="col-md-3">
                  <Form.Control
                    required
                    value={model.reminderNum}
                    name={"reminderNum"}
                    className="form-control"
                    type="number"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="">
                <label
                  className="form-label"
                  htmlFor="reminderDaysSinceLastAction"
                >
                  How often would you like to send a Reminder email?
                </label>

                <div className="col-md-3">
                  <Form.Control
                    required
                    value={model.reminderDaysSinceLastAction}
                    name={"reminderDaysSinceLastAction"}
                    className="form-control"
                    type="number"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="form-check form-switch pl-0">
                <FormCheck
                  name="reminderMinBalanceEnabled"
                  type="switch"
                  checked={model.reminderMinBalanceEnabled}
                  label={"Set minimum balance for Gift Card Reminders"}
                  onChange={handleChange}
                />
              </div>
            </div>
            {model.reminderMinBalanceEnabled &&
            <div className="col-12 mt-3">
              <label className="text-sm" htmlFor="reminderMinBalanceAmount">
              Only issue a Reminder if the remaining gift card balance is at
                least
                </label>
              {/* <div className="col-9 text-sm">
                Only issue a Reminder if the remaining gift card balance is at
                least
              </div> */}
              <div className="col-4 mt-3">
                <div className="input-group input-group-inline">
                  {/* <span className="input-group-text" id="">
                    $
                  </span> */}
                  <Form.Control
                    name={"reminderMinBalanceAmount"}
                    className="form-control"
                    type="number"
                    value={model.reminderMinBalanceAmount}
                    onChange={handleChange}
                    placeholder="0.00"
                    aria-label="0.00"
                    required
                  />
                  <span className="input-group-text" id="">
                    {authContext.account?.isoCurrencyCode}
                  </span>
                </div>
              </div>
            </div>}
          </div>
          <Modal.Footer>
            <Button
              className="btn btn-sm btn-neutral"
              variant={"secondary"}
              onClick={props.onHide}
            >
              Close
            </Button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-sm btn-primary"
            >
              {isSubmitting ? <Spinner className="text-sm" animation="border" /> : <>Save</>}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default EmailEditReminderSettingsModal;

import React, { useState, useEffect } from "react";
import { BillingApi } from "../../apiclient";
import {
  BillingPlan,
  DowngradePlanReasonViewModel,
} from "../../apiclient/models";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import Modal from "react-bootstrap/Modal";
import { Button, Row, Col, InputGroup, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const FreePlanDowngradeModal = (props: any) => {
    let billingApi = new BillingApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
  const [model, setModel] = useState({} as DowngradePlanReasonViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    // const fetchData = () => {
    //   testApi.testLetsGoGet().then((response) => {
    //     setModel(response.data);
    //   });
    // };
    // fetchData();
  }, []);

  const handleSubmit = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      billingApi
        .billingDowngradePlanReasonPost("1", model)
        .then((response) => {
          toast.success("Feedback sent!");
          window.location.href = "/Billing/ChangePlan?plan=" + BillingPlan.Free;
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error sending feedback.");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: DowngradePlanReasonViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="text-center py-5 px-5">
              <div className="icon icon-shape rounded-circle bg-opacity-30 bg-danger text-danger text-lg">
                <i className="bi bi-exclamation-octagon-fill"></i>
              </div>
              <h3 className="mt-7 mb-4">Downgrade account to Free plan?</h3>
              <p className="text-sm text-muted">
                By downgrading you will lose access to all premium features. Are
                you sure you want to do this?
              </p>
              <div className="mt-5">
                {/* <label className="form-label" htmlFor="email">
                        Reason for downgrading
                      </label> */}

                <Form.Control
                  required
                  name={"reason"}
                  className="form-control"
                  as={"textarea"}
                  placeholder="Reason.."
                  rows={3}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <Modal.Footer>
            <Button
              className="btn btn-sm btn-neutral"
              variant={"secondary"}
              onClick={props.onHide}
            >
              Close
            </Button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-sm btn-danger"
            >
              Downgrade to Free
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default FreePlanDowngradeModal;

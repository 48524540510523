import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { GiftcardApi } from "../../apiclient";
import {
  SalesChannelListItemViewModel,
  GiftCardProductViewModel,
  GiftCardProductVariant,
  KSeriesSettingPostViewModel,
  GiftCardExpiryViewModel,
  GiftCardCodeFormattingViewModel
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Modal,
  InputGroup,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";

import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import Util from "../../js/util";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SettingsNavMenu from "../../components/SettingsNavMenu";

const GiftCardSettingsPage = () => {
  let giftcardApi = new GiftcardApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as GiftCardProductViewModel);
  const [expiryModel, setExpiryModel] = useState({} as GiftCardExpiryViewModel);
  const [codeFormattingModel, setCodeFormattingModel] = useState({} as GiftCardCodeFormattingViewModel);
  const [salesChannels, setSalesChannels] = useState(
    [] as SalesChannelListItemViewModel[]
  );
  const [validated, setValidated] = useState(false);
  const [expiryValidated, setExpiryValidated] = useState(false);
  const [codeFormattingValidated, setCodeFormattingValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);
  const [searchParams, setSearchParams] = useSearchParams();
  const [imageData, setImageData] = useState(null as any);
  const [description, setDescription] = useState("");
  const [shopifySalesChannelConnected, setShopifySalesChannelConnected] =
    useState(false as boolean);

  const [emailSuppressionList, setEmailSuppressionList] = useState("" as string);  

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = () => {
    setLoading(true);
    giftcardApi
      .giftcardProductSettingsGet()
      .then((response) => {
        var data = response.data;
        setModel(data);
        setDescription(data.description!);
      })
      .catch((error: any) => {
        console.log("get error", error);
      })
      .finally(() => {
        setLoading(false);
      });

    giftcardApi
      .giftcardExpirySettingsGet()
      .then((response) => {
        var data = response.data;
        setExpiryModel(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });

      giftcardApi
      .giftcardHasShopifyChannelConnectedGet()
      .then((response) => {
        var data = response.data;
        setShopifySalesChannelConnected(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });

      giftcardApi
      .giftcardCodeFormattingSettingsGet()
      .then((response) => {
        var data = response.data;
        setCodeFormattingModel(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });

      giftcardApi
      .giftcardEmailSuppressionSettingsGet()
      .then((response) => {
        var data = response.data;
        setEmailSuppressionList(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      });
  };

  const handleChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "canExpire") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }
    console.log("handleChange", name, value);
    setUnsavedChanges(true);
    setModel((values: GiftCardProductViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleExpiryChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "canExpire") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }
    console.log("handleChange", name, value);
    setUnsavedChanges(true);
    setExpiryModel((values: GiftCardExpiryViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleCodeFormattingChange = (event: any) => {
    const target = event.target;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    const name = event.target.name;
    if (name === "canExpire") {
      const booVal = event.currentTarget.value === "true" ? true : false;
      value = booVal;
    }
    console.log("handleChange", name, value);
    setUnsavedChanges(true);
    setCodeFormattingModel((values: GiftCardCodeFormattingViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSuppressionListChange = (event: any) => {
    const target = event.target;
    var value = target.value;
    setEmailSuppressionList(value);
  };

  const handleEmailSuppressionListSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

      setIsSubmitting(false);
      giftcardApi
        .giftcardSaveEmailSuppressionSettingsPost(emailSuppressionList || "")
        .then((response: any) => {
          if(response.data){
            toast.success("Settings saved!");
          }
          setUnsavedChanges(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving settings.");
          setUnsavedChanges(false);
        });
  };

  let handleVariantChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newVariants = [...model.variants!] as any;
    newVariants[i][name] = value;

    setModel((values: GiftCardProductViewModel) => ({
      ...values,
      variants: newVariants,
    }));
  };

  let handleVariantPriceChange = (event: any, i: number) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    let newVariants = [...model.variants!] as any;
    newVariants[i][name] = value;
    newVariants[i]["giftCardValue"] = value;

    setModel((values: GiftCardProductViewModel) => ({
      ...values,
      variants: newVariants,
    }));
  };

  let addVariant = () => {
    let newVariants = [...model.variants!] as GiftCardProductVariant[];
    newVariants.push({
      id: Util.generateRandomString(16),
      name: "",
      price: 0.0,
    });
    setModel((values: GiftCardProductViewModel) => ({
      ...values,
      variants: newVariants,
    }));
  };

  let deleteVariant = (i: number) => {
    let newVariants = [...model.variants!] as any;
    newVariants.splice(i, 1);
    setModel((values: GiftCardProductViewModel) => ({
      ...values,
      variants: newVariants,
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      const formData = new FormData();
      formData.append("image", imageData);
      formData.append("variants", JSON.stringify(model.variants));
      console.log("variants", model.variants);
      giftcardApi
        .giftcardSaveProductSettingsPost(
          "1",
          model.title,
          description,
          model.imageUrl,
          imageData,
          JSON.stringify(model.variants)
        )
        .then((response: any) => {
          toast.success("Settings saved!");
          setUnsavedChanges(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving settings.");
          setUnsavedChanges(false);
        });
    }
  };

  const handleExpirySubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setExpiryValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      giftcardApi
        .giftcardSaveExpirySettingsPost("1", expiryModel)
        .then((response: any) => {
          toast.success("Settings saved!");
          setUnsavedChanges(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving settings.");
          setUnsavedChanges(false);
        });
    }
  };

  const handleCodeFormattingSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setCodeFormattingValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      giftcardApi
        .giftcardSaveCodeFormattingSettingsPost("1", codeFormattingModel)
        .then((response: any) => {

          if(response.data){
            toast.success("Settings saved!");
          }
          setUnsavedChanges(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving settings.");
          setUnsavedChanges(false);
        });
    }
  };



  const onFileInputChange = (event: any) => {
    let file = event.target.files[0];
    let extension = file.name.split(".").at(-1);
    let allowedFileExtensions = ["jpg", "png", "jpeg", "gif", "bmp"];
    if (!allowedFileExtensions.includes(extension.toLowerCase())) {
      toast.error("Incorrect file format, failed to import.");
      // clear the file input
      event.target.value = null;
      return;
    }

    var reader = new FileReader();
    reader.onload = (e) => {
      if (e.target !== null) {
        var image = new Image();
        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result as string;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = image.height;
          var width = image.width;
          // if (height > 150 || width > 600) {
          //   toast.error(
          //     "File size is too large, maximum dimensions are 600x150"
          //   );
          //   // clear the file input
          //   event.target.value = null;
          //   setImageData(null);
          //   setModel({ ...model, imageUrl: undefined });
          //   return false;
          // }
          setModel({ ...model, imageUrl: image.src });
        };
      }
    };
    reader.readAsDataURL(event.currentTarget.files[0]);
    setImageData(event.currentTarget.files[0]);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <Spinner animation="border" />
        </>
      ) : (
        <>
      <header>
        <div className="container">
          <div className="border-bottom pt-6">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12">
                <h1 className="h2 ls-tight">Gift card settings</h1>
              </div>
              <div className="col-sm-6 col-12">
                {/* <a
                  href="https://help.wrappedgiftcard.com/en/collections/3830625-account#general"
                  target={"_blank"}
                  className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block float-end"
                >
                  <i className="bi bi-question-circle-fill"></i>
                  <span className="d-none d-sm-inline ms-2">Help articles</span>
                </a> */}
              </div>
            </div>
            <SettingsNavMenu />
          </div>
        </div>
      </header>

          <div className="bg-surface-secondary">
            <div className="container py-10">
              <Form noValidate validated={expiryValidated} onSubmit={handleExpirySubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">Expiration date</h4>
                    <p className="text-sm">
                      Countries have different laws for gift card expiry dates.
                      Check the laws for your country before changing this date.
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card mb-10">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="col-md-12">
                            <div className="d-flex mb-3">
                              <div className="me-3">
                                <Form.Check
                                  name="canExpire"
                                  type={"radio"}
                                  id={`canExpireNo`}
                                  checked={!expiryModel.canExpire}
                                  onChange={handleExpiryChange}
                                  value={"false"}
                                />
                              </div>
                              <div>
                                <label
                                  className="form-label mb-0"
                                  htmlFor="canExpireNo"
                                >
                                  Gift cards never expire
                                </label>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="me-3">
                                <Form.Check
                                  name="canExpire"
                                  type={"radio"}
                                  id={`canExpireYes`}
                                  checked={expiryModel.canExpire}
                                  onChange={handleExpiryChange}
                                  value={"true"}
                                />
                              </div>
                              <div>
                                <label
                                  className="form-label mb-0"
                                  htmlFor="canExpireYes"
                                >
                                  Gift cards expire
                                </label>
                              </div>
                            </div>
                            <div className="d-flex col-md-3">
                              <div className="me-3 pt-3">
                                <div className="input-group position-relative">
                                  <Form.Control
                                    name={"expiryInMonths"}
                                    className="form-control form-control-sm"
                                    type="number"
                                    value={expiryModel.expiryInMonths}
                                    onChange={handleExpiryChange}
                                    required
                                    disabled={!expiryModel.canExpire}
                                  />
                                  <span className="input-group-text" id="">
                                    Months
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex mt-3">
                              <div className="me-3">
                                <Form.Check
                                  name="allowExpiredToBeUsed"
                                  id="allowExpiredToBeUsed"
                                  type={"checkbox"}
                                  checked={expiryModel.allowExpiredToBeUsed}
                                  onChange={handleExpiryChange}
                                />
                              </div>
                              <div>
                                <label
                                  className="form-label mb-0"
                                  htmlFor="allowExpiredToBeUsed"
                                >
                                  Allow expired gift cards to be used (advanced)
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div className="card-footer text-end py-4">
                    <button type="submit" className="btn btn-sm btn-primary">
                      Save
                    </button>
                  </div>
                    </div>
                  </div>
                </div>
              </Form>
              {shopifySalesChannelConnected && (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="row mb-10">
                    <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                      <h4 className="font-semibold mb-2">
                        Shopify gift card product details
                      </h4>
                      <p className="text-sm">
                        This will create a new product in Shopify that will
                        generate omnichannel gift cards when purchased.
                      </p>
                    </div>
                    <div className="col-lg-8">
                      <div className="card mb-10">
                        <div className="card-body">
                          <div className="row g-5">
                            <div className="col-12">
                              <div className="">
                                <label className="form-label" htmlFor="title">
                                  Title
                                </label>
                                <Form.Control
                                  name={"title"}
                                  className="form-control"
                                  type="text"
                                  value={model.title}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="">
                                <label
                                  className="form-label"
                                  htmlFor="description"
                                >
                                  Description
                                </label>
                                <ReactQuill
                                  value={description}
                                  onChange={setDescription}
                                />
                              </div>
                            </div>
                            <div className="row mt-5">
                              <label className="form-label">Design</label>
                              <div className="col-md-6">
                                <img
                                  src={model.imageUrl}
                                  className="img-thumbnail"
                                  alt="..."
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="card shadow-none border-2 border-dashed border-primary-hover position-relative">
                                  <div className="d-flex justify-content-center px-5 py-5">
                                    <label
                                      htmlFor="file_upload"
                                      className="position-absolute w-full h-full top-0 start-0 cursor-pointer"
                                    >
                                      {/* <input
                                    id="file_upload"
                                    name="file_upload"
                                    type="file"
                                    className="visually-hidden"
                                  /> */}

                                      <Form.Control
                                        id="file_upload"
                                        name="file_upload"
                                        onChange={onFileInputChange}
                                        type="file"
                                        className="visually-hidden"
                                        accept="image/jpg, image/png, image/jpeg, image/gif, image/bmp"
                                      />
                                    </label>
                                    <div className="text-center">
                                      <div className="text-2xl text-muted">
                                        <i className="bi bi-upload"></i>
                                      </div>
                                      <div className="d-flex text-sm mt-3">
                                        <p className="font-semibold">
                                          Upload a file or drag and drop
                                        </p>
                                      </div>
                                      <p className="text-xs text-gray-500">
                                        PNG, JPG, GIF up to 3MB
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="">
                          <div className="mb-1">
                          <label className="form-label">Gift card variants</label>
                          </div>
                          <div className="row g-5 mb-3">
                            <div className="col-md-5">
                              <p className="text-sm text-muted">Variant name</p>
                            </div>
                            <div className="col-md-5">
                              <p className="text-sm text-muted">
                                Gift card price
                              </p>
                            </div>
                            <div className="col-md-2"></div>
                          </div>
                          {model &&
                            model.variants?.map((variant, i) => (
                              <div key={"variant" + i} className="row g-5 pb-5">
                                <div className="col-md-5">
                                  <div className="">
                                    <Form.Control
                                      name={"name"}
                                      className="form-control"
                                      type="text"
                                      value={variant.name}
                                      onChange={(e) =>
                                        handleVariantChange(e, i)
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-md-5">
                                  <div className="">
                                    <div className="input-group input-group-inline">
                                      <span className="input-group-text" id="">
                                        $
                                      </span>
                                      <Form.Control
                                        name={"price"}
                                        className="form-control"
                                        type="number"
                                        value={variant.price}
                                        onChange={(e) =>
                                          handleVariantPriceChange(e, i)
                                        }
                                        placeholder="0.00"
                                        aria-label="0.00"
                                        required
                                      />
                                      <span className="input-group-text" id="">
                                        {authContext.account?.isoCurrencyCode}
                                      </span>
                                    </div>
                                    <span className="mt-2 valid-feedback">
                                      Looks good!
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <button
                                    onClick={(e) => deleteVariant(i)}
                                    type="button"
                                    className="btn btn-neutral"
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </div>
                              </div>
                            ))}
                          <div className="row g-5">
                            <div className="col-md-4">
                              <button
                                type="button"
                                onClick={addVariant}
                                className="btn btn-neutral btn-sm"
                              >
                                <i className="bi bi-plus pr-5"></i>Add different
                                variant
                              </button>
                            </div>
                          </div>
                        </div>
                          </div>
                        </div>
                        <div className="card-footer text-end py-4">
                    <button type="submit" className="btn btn-sm btn-primary">
                      Save
                    </button>
                  </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}

              <Form noValidate validated={codeFormattingValidated} onSubmit={handleCodeFormattingSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">Gift card code formatting</h4>
                    <p className="text-sm">
                      Customize the formatting of any generated gift card code from Wrapped.
                    </p>
                    <p className="text-sm pt-5">
                    Note, codes are alphanumeric and should be a minimum 8 characters long.
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card mb-10">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="col-md-12">
                            <div className="col-md-8">
                              <div className="me-3 pt-3">
                              <label className="form-label" htmlFor="giftCardCodeFormattingPrefix">
                                  Code length
                                </label>
                                <div className="input-group position-relative">
                                  <Form.Control
                                    name={"numCharacters"}
                                    className="form-control form-control-sm"
                                    type="number"
                                    value={codeFormattingModel.numCharacters}
                                    onChange={handleCodeFormattingChange}
                                    min="8"
                                    required
                                  />
                                  <span className="input-group-text" id="">
                                    characters
                                  </span>
                                </div>
                              </div>
                            </div>
                            
                            <div className="d-flex mt-3">
                              <div className="me-3">
                                <Form.Check
                                  name="hasDashesForSeparators"
                                  id="hasDashesForSeparators"
                                  type={"checkbox"}
                                  checked={codeFormattingModel.hasDashesForSeparators}
                                  onChange={handleCodeFormattingChange}
                                />
                              </div>
                              <div>
                                <label
                                  className="form-label mb-0"
                                  htmlFor="hasDashesForSeparators"
                                >
                                  Add a dash every four characters
                                </label>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="me-3 pt-3">
                              <label className="form-label" htmlFor="giftCardCodeFormattingPrefix">
                                  Prefix
                                </label>
                                  <Form.Control
                                    name={"giftCardCodeFormattingPrefix"}
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={codeFormattingModel.giftCardCodeFormattingPrefix!}
                                    onChange={handleCodeFormattingChange}
                                  />
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="me-3 pt-3">
                              <label className="form-label" htmlFor="giftCardCodeFormattingCharacterSet">
                                  Character set
                                </label>
                                  <Form.Control
                                    name={"giftCardCodeFormattingCharacterSet"}
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={codeFormattingModel.giftCardCodeFormattingCharacterSet!}
                                    onChange={handleCodeFormattingChange}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div className="card-footer text-end py-4">
                    <button type="submit" className="btn btn-sm btn-primary">
                      Save
                    </button>
                  </div>
                    </div>
                  </div>
                </div>
              </Form>

              <Form noValidate onSubmit={handleEmailSuppressionListSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">Email Suppression</h4>
                    <p className="text-sm">
                      Gift cards received (or purchased from) in email address in the list will not receive gift card emails from Wrapped
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card mb-10">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="col-md-12">
                            <div className="">
                              <div className="me-3 pt-3">
                              <label className="form-label" htmlFor="emailSuppressionList">
                                  Email addresses to suppress (one per line)
                                </label>
                                  <Form.Control
                                    name={"emailSuppressionList"}
                                    className="form-control"
                                    as="textarea" rows={5}
                                    value={emailSuppressionList}
                                    onChange={handleSuppressionListChange}
                                    placeholder=" email1@email.com
                                    email2@email.com"
                                  />
                              </div>
                            </div>
               
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div className="card-footer text-end py-4">
                    <button type="submit" className="btn btn-sm btn-primary">
                      Save
                    </button>
                  </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default GiftCardSettingsPage;

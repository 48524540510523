import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { ProfileApi } from "../../apiclient";
import {
  ProfileViewModel,
  UpdateBusinessInformationViewModel,
  UpdateContactInformationViewModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { Link, redirect, useNavigate } from "react-router-dom";
import { CompleteProfileViewModel } from "../../apiclient/models/complete-profile-view-model";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const CompleteProfilePage = () => {
  let accountApi = new ProfileApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [model, setModel] = useState({} as ProfileViewModel);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const timezoneOnChange = (selectedTimezone: any) => {
    console.log("selectedTimezone", selectedTimezone);
    setTimezone(selectedTimezone.value);
  };

  useEffect(() => {
    const fetchData = () => {
      accountApi
        .profileProfileGet()
        .then((response) => {
          if (!response.data.isoCurrencyCode) {
            response.data.isoCurrencyCode = "USD";
          }
          setModel(response.data);
          console.log("profile load", response.data);

          if (!response.data.accountId) {
            window.location.href = "/account/login";
          }
        })
        .catch((error: any) => {});
    };
    fetchData();
  }, []);

  const handleSubmitCompleteProfile = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }
    var submitModel = {
      businessCategory: model.businessCategory,
      businessType: model.businessType,
      ianaTimezone: timezone,
      isoCurrencyCode: model.isoCurrencyCode,
      companyName: model.companyName,
      emailAddress: model.emailAddress,
      name: model.name,
      phone: model.phone,
      hdyhau: model.hdyhau,
      hdyhauOtherReason: model.hdyhauOtherReason,
      website: model.website,
    } as CompleteProfileViewModel;

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      accountApi
        .profileCompleteProfilePost("1", submitModel)
        .then((response) => {
          toast.success("Profile saved!");
          authContext.refresh();
          //navigate("/onboarding/selectplan");
          navigate("/dashboard");
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error updating profile information.");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: ProfileViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="border-bottom pt-6">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12">
                <h1 className="h2 ls-tight">Complete Your Profile</h1>
              </div>

              <p className="text-sm pb-5">
                To help us provide a better experience while using Wrapped,
                please complete your company profile below.
              </p>
            </div>
          </div>
        </div>
      </header>

      <div className="bg-surface-secondary">
        <div className="container py-10">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmitCompleteProfile}
          >
            <div className="row mb-10">
              <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                <h4 className="font-semibold mb-2">Contact Information</h4>
                <p className="text-sm">
                  Wrapped will use this information to contact you and
                  personalize your program
                </p>
              </div>
              <div className="col-lg-8">
                <div className="card shadow border-0">
                  <div className="card-body">
                    <div className="row g-5">
                      <div className="col-md-12">
                        <div className="">
                          <label className="form-label" htmlFor="name">
                            Contact name
                          </label>
                          <Form.Control
                            name={"name"}
                            className="form-control"
                            type="text"
                            value={model.name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="">
                          <label className="form-label" htmlFor="emailAddress">
                            Email
                          </label>
                          {/* <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Your email"
                          /> */}
                          <Form.Control
                            name={"emailAddress"}
                            className="form-control"
                            type="email"
                            value={model.emailAddress}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="">
                          <label className="form-label" htmlFor="phone">
                            Phone number
                          </label>
                          {/* <input
                            type="tel"
                            className="form-control"
                            id="phone_number"
                            placeholder="Your phone"
                          /> */}
                          <Form.Control
                            name={"phone"}
                            className="form-control"
                            type="phone"
                            value={model.phone || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-10">
              <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                <h4 className="font-semibold mb-2">Business Information</h4>
                <p className="text-sm">
                  Wrapped uses this information to improve analytics reporting
                  and personalize your program.
                </p>
              </div>
              <div className="col-lg-8">
                <div className="card shadow border-0">
                  <div className="card-body">
                    <div className="row g-5">
                      <div className="col-md-12">
                        <label
                          className="form-label"
                          htmlFor="businessCategory"
                        >
                          Business Category
                        </label>
                        <Form.Select
                          name="businessCategory"
                          onChange={handleChange}
                          value={model.businessCategory}
                          required
                        >
                          <option value={""}>Choose...</option>
                          <option value={"Apparel"}>
                            Clothing Apparel &amp; Accessories
                          </option>
                          <option value={"Automotive"}>Automotive</option>
                          <option value={"Electronics"}>
                            Consumer Electronics
                          </option>
                          <option value={"Food"}>Food &amp; Drink</option>
                          <option value={"HealthBeauty"}>
                            Health &amp; Beauty
                          </option>
                          <option value={"Homeware"}>
                            Homeware &amp; Home Furnishings
                          </option>
                          <option value={"Office"}>Office</option>
                          <option value={"Other"}>Other</option>
                          <option value={"Specialty"}>Specialty</option>
                          <option value={"Sporting"}>Sporting Goods</option>
                        </Form.Select>
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="businessType">
                          Business Type
                        </label>
                        <Form.Select
                          name="businessType"
                          onChange={handleChange}
                          value={model.businessType}
                          required
                        >
                          <option value={""}>Choose...</option>
                          <option value={"OnlineOnly"}>Online Only</option>
                          <option value={"OnlineAndPhysical"}>
                            Online + Physical Location(s)
                          </option>
                          <option value={"OnlineAndWholesale"}>
                            Online + Physical Location(s) + Wholesale
                          </option>
                          <option value={"OnlineAndWholesaleAndPhysical"}>
                            Online + Wholesale
                          </option>
                        </Form.Select>
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="timezone">
                          Time Zone
                        </label>
                        <TimezoneSelect
                          value={timezone}
                          onChange={timezoneOnChange}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="isoCurrencyCode">
                          Currency
                        </label>

                        <Form.Select
                          name="isoCurrencyCode"
                          onChange={handleChange}
                          value={model.isoCurrencyCode}
                        >
                          <option value="AFN">Afghani (AFN)</option>
                          <option value="DZD">Algerian Dinar (DZD)</option>
                          <option value="ARS">Argentine Peso (ARS)</option>
                          <option value="AMD">Armenian Dram (AMD)</option>
                          <option value="AWG">Aruban Guilder (AWG)</option>
                          <option value="AUD">Australian Dollar (AUD)</option>
                          <option value="AZN">Azerbaijanian Manat (AZN)</option>
                          <option value="BSD">Bahamian Dollar (BSD)</option>
                          <option value="BHD">Bahraini Dinar (BHD)</option>
                          <option value="THB">Baht (THB)</option>
                          <option value="PAB">
                            Balboa US Dollar (PAB USD)
                          </option>
                          <option value="BBD">Barbados Dollar (BBD)</option>
                          <option value="BYR">Belarussian Ruble (BYR)</option>
                          <option value="BZD">Belize Dollar (BZD)</option>
                          <option value="BMD">Bermudian Dollar (BMD)</option>
                          <option value="VEF">Bolivar Fuerte (VEF)</option>
                          <option value="BOB">Boliviano Mvdol (BOB BOV)</option>
                          <option value="BRL">Brazilian Real (BRL)</option>
                          <option value="BND">Brunei Dollar (BND)</option>
                          <option value="BGN">Bulgarian Lev (BGN)</option>
                          <option value="BIF">Burundi Franc (BIF)</option>
                          <option value="XOF">CFA Franc BCEAO (XOF)</option>
                          <option value="XAF">CFA Franc BEAC (XAF)</option>
                          <option value="XPF">CFP Franc (XPF)</option>
                          <option value="CAD">Canadian Dollar (CAD)</option>
                          <option value="CVE">Cape Verde Escudo (CVE)</option>
                          <option value="KYD">
                            Cayman Islands Dollar (KYD)
                          </option>
                          <option value="GHS">Cedi (GHS)</option>
                          <option value="CLP CLF">
                            Chilean Peso (CLP CLF)
                          </option>
                          <option value="COP COU">
                            Colombian Peso (COP COU)
                          </option>
                          <option value="KMF">Comoro Franc (KMF)</option>
                          <option value="CDF">Congolese Franc (CDF)</option>
                          <option value="BAM">Convertible Marks (BAM)</option>
                          <option value="NIO">Cordoba Oro (NIO)</option>
                          <option value="CRC">Costa Rican Colon (CRC)</option>
                          <option value="HRK">Croatian Kuna (HRK)</option>
                          <option value="CUP CUC">Cuban Peso (CUP CUC)</option>
                          <option value="CZK">Czech Koruna (CZK)</option>
                          <option value="GMD">Dalasi (GMD)</option>
                          <option value="DKK">Danish Krone (DKK)</option>
                          <option value="MKD">Denar (MKD)</option>
                          <option value="DJF">Djibouti Franc (DJF)</option>
                          <option value="STD">Dobra (STD)</option>
                          <option value="DOP">Dominican Peso (DOP)</option>
                          <option value="VND">Dong (VND)</option>
                          <option value="XCD">
                            East Caribbean Dollar (XCD)
                          </option>
                          <option value="EGP">Egyptian Pound (EGP)</option>
                          <option value="SVC">
                            El Salvador Colon US Dollar (SVC USD)
                          </option>
                          <option value="ETB">Ethiopian Birr (ETB)</option>
                          <option value="EUR">Euro (EUR)</option>
                          <option value="FKP">
                            Falkland Islands Pound (FKP)
                          </option>
                          <option value="FJD">Fiji Dollar (FJD)</option>
                          <option value="HUF">Forint (HUF)</option>
                          <option value="GIP">Gibraltar Pound (GIP)</option>
                          <option value="HTG">
                            Gourde US Dollar (HTG USD)
                          </option>
                          <option value="GBP">
                            Great British Pound Sterling (GBP)
                          </option>

                          <option value="PYG">Guarani (PYG)</option>
                          <option value="GNF">Guinea Franc (GNF)</option>
                          <option value="GYD">Guyana Dollar (GYD)</option>
                          <option value="HKD">Hong Kong Dollar (HKD)</option>
                          <option value="UAH">Hryvnia (UAH)</option>
                          <option value="ISK">Iceland Krona (ISK)</option>
                          <option value="INR">Indian Rupee (INR)</option>
                          <option value="BTN">
                            Indian Rupee Ngultrum (INR BTN)
                          </option>
                          <option value="IRR">Iranian Rial (IRR)</option>
                          <option value="IQD">Iraqi Dinar (IQD)</option>
                          <option value="JMD">Jamaican Dollar (JMD)</option>
                          <option value="JOD">Jordanian Dinar (JOD)</option>
                          <option value="KES">Kenyan Shilling (KES)</option>
                          <option value="PGK">Kina (PGK)</option>
                          <option value="LAK">Kip (LAK)</option>
                          <option value="EEK">Kroon (EEK)</option>
                          <option value="KWD">Kuwaiti Dinar (KWD)</option>
                          <option value="MWK">Kwacha (MWK)</option>
                          <option value="AOA">Kwanza (AOA)</option>
                          <option value="MMK">Kyat (MMK)</option>
                          <option value="GEL">Lari (GEL)</option>
                          <option value="LVL">Latvian Lats (LVL)</option>
                          <option value="LBP">Lebanese Pound (LBP)</option>
                          <option value="ALL">Lek (ALL)</option>
                          <option value="HNL">Lempira (HNL)</option>
                          <option value="SLL">Leone (SLL)</option>
                          <option value="LRD">Liberian Dollar (LRD)</option>
                          <option value="LYD">Libyan Dinar (LYD)</option>
                          <option value="SZL">Lilangeni (SZL)</option>
                          <option value="LTL">Lithuanian Litas (LTL)</option>
                          <option value="MGA">Malagasy Ariary (MGA)</option>
                          <option value="MYR">Malaysian Ringgit (MYR)</option>
                          <option value="TMT">Manat (TMT)</option>
                          <option value="MUR">Mauritius Rupee (MUR)</option>
                          <option value="MZN">Metical (MZN)</option>
                          <option value="MXN">Mexican Peso (MXN MXV)</option>
                          <option value="MDL">Moldovan Leu (MDL)</option>
                          <option value="MAD">Moroccan Dirham (MAD)</option>
                          <option value="NGN">Naira (NGN)</option>
                          <option value="ERN">Nakfa (ERN)</option>
                          <option value="NPR">Nepalese Rupee (NPR)</option>
                          <option value="ANG">
                            Netherlands Antillian Guilder (ANG)
                          </option>
                          <option value="ILS">New Israeli Sheqel (ILS)</option>
                          <option value="RON">New Leu (RON)</option>
                          <option value="TWD">New Taiwan Dollar (TWD)</option>
                          <option value="NZD">New Zealand Dollar (NZD)</option>
                          <option value="KPW">North Korean Won (KPW)</option>
                          <option value="NOK">Norwegian Krone (NOK)</option>
                          <option value="PEN">Nuevo Sol (PEN)</option>
                          <option value="MRO">Ouguiya (MRO)</option>
                          <option value="TOP">Pa'anga (TOP)</option>
                          <option value="PKR">Pakistan Rupee (PKR)</option>
                          <option value="XPD">Palladium (XPD)</option>
                          <option value="MOP">Pataca (MOP)</option>
                          <option value="UYI">Peso Uruguayo (UYU UYI)</option>
                          <option value="PHP">Philippine Peso (PHP)</option>
                          <option value="XPT">Platinum (XPT)</option>
                          <option value="BWP">Pula (BWP)</option>
                          <option value="QAR">Qatari Rial (QAR)</option>
                          <option value="GTQ">Quetzal (GTQ)</option>
                          <option value="ZAR">Rand (ZAR)</option>
                          <option value="LSL">Rand Loti (ZAR LSL)</option>
                          <option value="NAD">
                            Rand Namibia Dollar (ZAR NAD)
                          </option>
                          <option value="OMR">Rial Omani (OMR)</option>
                          <option value="KHR">Riel (KHR)</option>
                          <option value="MVR">Rufiyaa (MVR)</option>
                          <option value="IDR">Rupiah (IDR)</option>
                          <option value="RUB">Russian Ruble (RUB)</option>
                          <option value="RWF">Rwanda Franc (RWF)</option>
                          <option value="XDR">SDR (XDR)</option>
                          <option value="SHP">Saint Helena Pound (SHP)</option>
                          <option value="SAR">Saudi Riyal (SAR)</option>
                          <option value="RSD">Serbian Dinar (RSD)</option>
                          <option value="SCR">Seychelles Rupee (SCR)</option>
                          <option value="SGD">Singapore Dollar (SGD)</option>
                          <option value="SBD">
                            Solomon Islands Dollar (SBD)
                          </option>
                          <option value="KGS">Som (KGS)</option>
                          <option value="SOS">Somali Shilling (SOS)</option>
                          <option value="TJS">Somoni (TJS)</option>
                          <option value="LKR">Sri Lanka Rupee (LKR)</option>
                          <option value="SDG">Sudanese Pound (SDG)</option>
                          <option value="SRD">Surinam Dollar (SRD)</option>
                          <option value="SEK">Swedish Krona (SEK)</option>
                          <option value="CHF">Swiss Franc (CHF)</option>
                          <option value="SYP">Syrian Pound (SYP)</option>
                          <option value="BDT">Taka (BDT)</option>
                          <option value="WST">Tala (WST)</option>
                          <option value="TZS">Tanzanian Shilling (TZS)</option>
                          <option value="KZT">Tenge (KZT)</option>
                          <option value="TTD">
                            Trinidad and Tobago Dollar (TTD)
                          </option>
                          <option value="MNT">Tugrik (MNT)</option>
                          <option value="TND">Tunisian Dinar (TND)</option>
                          <option value="TRY">Turkish Lira (TRY)</option>
                          <option value="AED">UAE Dirham (AED)</option>
                          <option value="XFU">UIC-Franc (XFU)</option>
                          <option selected={true} value="USD">
                            US Dollar (USD)
                          </option>
                          <option value="UGX">Uganda Shilling (UGX)</option>
                          <option value="UZS">Uzbekistan Sum (UZS)</option>
                          <option value="VUV">Vatu (VUV)</option>
                          <option value="KRW">Won (KRW)</option>
                          <option value="YER">Yemeni Rial (YER)</option>
                          <option value="JPY">Yen (JPY)</option>
                          <option value="CNY">Yuan Renminbi (CNY)</option>
                          <option value="ZMK">Zambian Kwacha (ZMK)</option>
                          <option value="ZWL">Zimbabwe Dollar (ZWL)</option>
                          <option value="PLN">Zloty (PLN)</option>
                        </Form.Select>
                      </div>

                      <div className="col-md-12">
                        <div className="">
                          <label className="form-label" htmlFor="website">
                            Website
                          </label>
                          <Form.Control
                            name={"website"}
                            className="form-control"
                            type="text"
                            value={model.website || ""}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-10">
              <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                <h4 className="font-semibold mb-2">Survey</h4>
                <p className="text-sm">
                  Wrapped uses this information to improve marketing
                  attribution.
                </p>
              </div>
              <div className="col-lg-8">
                <div className="card shadow border-0">
                  <div className="card-body">
                    <div className="row g-5">
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="hdyhau">
                          How did you hear about us?
                        </label>
                        <Form.Check
                          id="hdyhau1"
                          name="hdyhau"
                          type="radio"
                          value="integration_partner"
                          onChange={handleChange}
                          label="Integration partner"
                          checked={model.hdyhau === "integration_partner"}
                        />

                        <Form.Check
                          id="hdyhau2"
                          name="hdyhau"
                          type="radio"
                          value="agency"
                          onChange={handleChange}
                          label="Agency"
                          checked={model.hdyhau === "agency"}
                        />

                        <Form.Check
                          id="hdyhau5"
                          name="hdyhau"
                          type="radio"
                          value="google"
                          onChange={handleChange}
                          label="Google search"
                          checked={model.hdyhau === "google"}
                        />

                        <Form.Check
                          id="hdyhau4"
                          name="hdyhau"
                          type="radio"
                          value="another_brand"
                          onChange={handleChange}
                          label="Another brand"
                          checked={model.hdyhau === "another_brand"}
                        />

                        <Form.Check
                          id="hdyhau6"
                          name="hdyhau"
                          type="radio"
                          value="ad"
                          onChange={handleChange}
                          label="Ad"
                          checked={model.hdyhau === "ad"}
                        />

                        <Form.Check
                          id="hdyhau7"
                          name="hdyhau"
                          type="radio"
                          value="social_media"
                          onChange={handleChange}
                          label="Social media"
                          checked={model.hdyhau === "social_media"}
                        />

                        <Form.Check
                          id="hdyhau8"
                          name="hdyhau"
                          type="radio"
                          value="online_community"
                          onChange={handleChange}
                          label="Online community"
                          checked={model.hdyhau === "online_community"}
                        />

                        <Form.Check
                          id="hdyhau9"
                          name="hdyhau"
                          type="radio"
                          value="support_article"
                          onChange={handleChange}
                          label="Support article"
                          checked={model.hdyhau === "support_article"}
                        />

                        <Form.Check
                          id="hdyhau3"
                          name="hdyhau"
                          type="radio"
                          value="gift_card_printer"
                          onChange={handleChange}
                          label="Gift card printer"
                          checked={model.hdyhau === "gift_card_printer"}
                        />

                        <Form.Check
                          id="hdyhau10"
                          name="hdyhau"
                          type="radio"
                          value="other"
                          onChange={handleChange}
                          label="Other"
                          checked={model.hdyhau === "other"}
                        />
                        {model && model.hdyhau && model.hdyhau === "other" && (
                          <div className="">
                            <Form.Control
                              name={"hdyhauOtherReason"}
                              className="form-control-sm mt-1"
                              type="text"
                              value={model.hdyhauOtherReason || ""}
                              onChange={handleChange}
                              placeholder={"Please specify..."}
                              required
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card-footer text-end py-4">
                    <button type="submit" className="btn btn-sm btn-primary">
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompleteProfilePage;

import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { ReportsApi } from "../../apiclient";
import {
    MetabaseReportUrlModel,
} from "../../apiclient/models";
import _ from "lodash";
import IframeResizer from 'iframe-resizer-react'
import LaunchBanner from "../../components/LaunchBanner";

const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const GiftCardReportsPage = () => {
  let reportingApi = new ReportsApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as MetabaseReportUrlModel);
  const [loading, setLoading] = useState(true as boolean);
  const authContext = useContext(AuthContext);

  useEffect(() => {

    reportingApi.reportsGetGiftCardReportsDashboardUrlGet().then((response) => {
      var data = response.data;
      setModel(data);
      setLoading(false);
    });

  }, []);
  
  return (
    <React.Fragment>
            <LaunchBanner />
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  <h1 className="h2 ls-tight">Gift card reporting</h1>
                </div>
                <p className="text-sm pb-5">
                  Report on your omnichannel gift cards
                </p>
              </div>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid vstack gap-3">
            {authContext.account && (authContext.account?.userRole == "Admin" || authContext.account?.userRole == "Owner") && (
              <>
                {loading ? <Spinner /> : (
                     <React.Fragment>
                     <IframeResizer
                         src={model.metabaseReportUrl}
                         frameBorder="0"
                         style={{ width: '100%', height: '800px' }}
                     />
                 </React.Fragment>

                )}
              </>
            )}
          </div>
        </main>
      </div>
     
    </React.Fragment>
  );
};

export default GiftCardReportsPage;

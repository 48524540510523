import React, { useState, useEffect, useContext } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AccountContext";
import { Button, Row, Col, InputGroup, Modal, Form } from "react-bootstrap";
const OnboardingConnectStore = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const authContext = useContext(AuthContext);
  const [showWooCommerceSettingsModal, setShowWooCommerceSettingsModal] =
    useState(false as boolean);
  const [isSubmitting, setIsSubmitting] = useState(false as boolean);
  const [validated, setValidated] = useState(false as boolean);
  const [wooDomain, setWooDomain] = useState("" as string);
  useEffect(() => {}, []);

  const handleWooCommerceSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      window.location.href = `/WooCommerce/Auth?storeUrl=${wooDomain}`;
    }
  };

  const handleWooDomainChange = (event: any) => {
    setIsSubmitting(false);
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setWooDomain(value);
  };
  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="border-bottom pt-6">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12">
                <h1 className="h2 ls-tight">Connect your sales channels</h1>
              </div>

              <p className="text-sm pb-5">
                Connect your e-Commerce and POS platforms to Wrapped
              </p>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Main --> */}
      <main className="py-6 bg-surface-secondary">

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <div className="row g-3 g-xl-5 mt-1">
                  <div className="col-lg-4 col-sm-6">
                    <div className="card shadow-4-hover">
                      <div className="card-body pb-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <img
                              alt="..."
                              className="h-10"
                              src="/img/integrations/shopify-bag.png"
                            />
                          </div>

                          <div className="text-end">
                            <button
                              type="button"
                              className="btn btn-sm btn-neutral rounded-pill"
                              onClick={() =>
                                setShowWooCommerceSettingsModal(true)
                              }
                            >
                              <i className="bi bi-plus-lg me-1"></i>
                              <span>Connect</span>
                            </button>
                          </div>
                        </div>
                        <div className="mt-6">
                          <a
                            className="h6 text-link"
                            href="https://WooCommerce.com/"
                            target="_blank"
                          >
                            Shopify
                          </a>
                          <p className="text-sm text-muted">
                            The global commerce platform. Build your business
                            with Shopify to sell online, offline, and everywhere
                            in between.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <div className="card shadow-4-hover">
                      <div className="card-body pb-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <img
                              alt="..."
                              className="h-10"
                              src="/img/integrations/woocommerce-logo-color-black@2x.png"
                            />
                          </div>

                          <div className="text-end">
                            <button
                              type="button"
                              className="btn btn-sm btn-neutral rounded-pill"
                              onClick={() =>
                                setShowWooCommerceSettingsModal(true)
                              }
                            >
                              <i className="bi bi-plus-lg me-1"></i>
                              <span>Connect</span>
                            </button>
                          </div>
                        </div>
                        <div className="mt-6">
                          <a
                            className="h6 text-link"
                            href="https://WooCommerce.com/"
                            target="_blank"
                          >
                            WooCommerce
                          </a>
                          <p className="text-sm text-muted">
                            WooCommerce is a customizable, open-source eCommerce
                            platform built on WordPress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal
        show={showWooCommerceSettingsModal}
        onHide={() => setShowWooCommerceSettingsModal(false)}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handleWooCommerceSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title>WooCommerce settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Enter WooCommerce store url</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">https://</InputGroup.Text>
                  <Form.Control
                    required
                    name={"WooDomain"}
                    className="form-control"
                    type="text"
                    value={wooDomain ?? ""}
                    placeholder="examplestore.com"
                    onChange={handleWooDomainChange}
                  />
                </InputGroup>
              </Form.Group>
              <p>
                <small>
                  You can <a href="#">view instructions</a> here
                </small>
              </p>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowWooCommerceSettingsModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Connect
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default OnboardingConnectStore;

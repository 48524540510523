/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { StorefrontSettingsViewModel } from '../models';
/**
 * StorefrontApi - axios parameter creator
 * @export
 */
export const StorefrontApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontConnectAccountDetailsSubmittedGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/ConnectAccountDetailsSubmitted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontCreateConnectLinkGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/CreateConnectLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [headerImageUrl] 
         * @param {string} [description] 
         * @param {any} [image] 
         * @param {string} [variants] 
         * @param {boolean} [hideCheckBalance] 
         * @param {boolean} [hideWrappedBranding] 
         * @param {boolean} [shouldChargeFees] 
         * @param {boolean} [shouldAllowTopups] 
         * @param {boolean} [shouldAllowCoupons] 
         * @param {string} [fees] 
         * @param {string} [coupons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontSaveSettingsPost: async (apiVersion?: string, headerImageUrl?: string, description?: string, image?: any, variants?: string, hideCheckBalance?: boolean, hideWrappedBranding?: boolean, shouldChargeFees?: boolean, shouldAllowTopups?: boolean, shouldAllowCoupons?: boolean, fees?: string, coupons?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/SaveSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (headerImageUrl !== undefined) { 
                localVarFormParams.append('headerImageUrl', headerImageUrl as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (variants !== undefined) { 
                localVarFormParams.append('variants', variants as any);
            }
    
            if (hideCheckBalance !== undefined) { 
                localVarFormParams.append('hideCheckBalance', hideCheckBalance as any);
            }
    
            if (hideWrappedBranding !== undefined) { 
                localVarFormParams.append('hideWrappedBranding', hideWrappedBranding as any);
            }
    
            if (shouldChargeFees !== undefined) { 
                localVarFormParams.append('shouldChargeFees', shouldChargeFees as any);
            }
    
            if (shouldAllowTopups !== undefined) { 
                localVarFormParams.append('shouldAllowTopups', shouldAllowTopups as any);
            }
    
            if (shouldAllowCoupons !== undefined) { 
                localVarFormParams.append('shouldAllowCoupons', shouldAllowCoupons as any);
            }
    
            if (fees !== undefined) { 
                localVarFormParams.append('fees', fees as any);
            }
    
            if (coupons !== undefined) { 
                localVarFormParams.append('coupons', coupons as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [subdomain] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontSaveStorefrontDomainGet: async (subdomain?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/SaveStorefrontDomain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (subdomain !== undefined) {
                localVarQueryParameter['subdomain'] = subdomain;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontSettingsGet: async (accountId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/Settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontToggleStorefrontEnabledPost: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Storefront/ToggleStorefrontEnabled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorefrontApi - functional programming interface
 * @export
 */
export const StorefrontApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorefrontApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontConnectAccountDetailsSubmittedGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontConnectAccountDetailsSubmittedGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontCreateConnectLinkGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontCreateConnectLinkGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [headerImageUrl] 
         * @param {string} [description] 
         * @param {any} [image] 
         * @param {string} [variants] 
         * @param {boolean} [hideCheckBalance] 
         * @param {boolean} [hideWrappedBranding] 
         * @param {boolean} [shouldChargeFees] 
         * @param {boolean} [shouldAllowTopups] 
         * @param {boolean} [shouldAllowCoupons] 
         * @param {string} [fees] 
         * @param {string} [coupons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontSaveSettingsPost(apiVersion?: string, headerImageUrl?: string, description?: string, image?: any, variants?: string, hideCheckBalance?: boolean, hideWrappedBranding?: boolean, shouldChargeFees?: boolean, shouldAllowTopups?: boolean, shouldAllowCoupons?: boolean, fees?: string, coupons?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontSaveSettingsPost(apiVersion, headerImageUrl, description, image, variants, hideCheckBalance, hideWrappedBranding, shouldChargeFees, shouldAllowTopups, shouldAllowCoupons, fees, coupons, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [subdomain] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontSaveStorefrontDomainGet(subdomain?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontSaveStorefrontDomainGet(subdomain, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontSettingsGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontSettingsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontSettingsGet(accountId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storefrontToggleStorefrontEnabledPost(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storefrontToggleStorefrontEnabledPost(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorefrontApi - factory interface
 * @export
 */
export const StorefrontApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorefrontApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontConnectAccountDetailsSubmittedGet(apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.storefrontConnectAccountDetailsSubmittedGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontCreateConnectLinkGet(apiVersion?: string, options?: any): AxiosPromise<string> {
            return localVarFp.storefrontCreateConnectLinkGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [headerImageUrl] 
         * @param {string} [description] 
         * @param {any} [image] 
         * @param {string} [variants] 
         * @param {boolean} [hideCheckBalance] 
         * @param {boolean} [hideWrappedBranding] 
         * @param {boolean} [shouldChargeFees] 
         * @param {boolean} [shouldAllowTopups] 
         * @param {boolean} [shouldAllowCoupons] 
         * @param {string} [fees] 
         * @param {string} [coupons] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontSaveSettingsPost(apiVersion?: string, headerImageUrl?: string, description?: string, image?: any, variants?: string, hideCheckBalance?: boolean, hideWrappedBranding?: boolean, shouldChargeFees?: boolean, shouldAllowTopups?: boolean, shouldAllowCoupons?: boolean, fees?: string, coupons?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.storefrontSaveSettingsPost(apiVersion, headerImageUrl, description, image, variants, hideCheckBalance, hideWrappedBranding, shouldChargeFees, shouldAllowTopups, shouldAllowCoupons, fees, coupons, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [subdomain] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontSaveStorefrontDomainGet(subdomain?: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.storefrontSaveStorefrontDomainGet(subdomain, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontSettingsGet(accountId?: string, apiVersion?: string, options?: any): AxiosPromise<StorefrontSettingsViewModel> {
            return localVarFp.storefrontSettingsGet(accountId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storefrontToggleStorefrontEnabledPost(apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.storefrontToggleStorefrontEnabledPost(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorefrontApi - object-oriented interface
 * @export
 * @class StorefrontApi
 * @extends {BaseAPI}
 */
export class StorefrontApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontConnectAccountDetailsSubmittedGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontConnectAccountDetailsSubmittedGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontCreateConnectLinkGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontCreateConnectLinkGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {string} [headerImageUrl] 
     * @param {string} [description] 
     * @param {any} [image] 
     * @param {string} [variants] 
     * @param {boolean} [hideCheckBalance] 
     * @param {boolean} [hideWrappedBranding] 
     * @param {boolean} [shouldChargeFees] 
     * @param {boolean} [shouldAllowTopups] 
     * @param {boolean} [shouldAllowCoupons] 
     * @param {string} [fees] 
     * @param {string} [coupons] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontSaveSettingsPost(apiVersion?: string, headerImageUrl?: string, description?: string, image?: any, variants?: string, hideCheckBalance?: boolean, hideWrappedBranding?: boolean, shouldChargeFees?: boolean, shouldAllowTopups?: boolean, shouldAllowCoupons?: boolean, fees?: string, coupons?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontSaveSettingsPost(apiVersion, headerImageUrl, description, image, variants, hideCheckBalance, hideWrappedBranding, shouldChargeFees, shouldAllowTopups, shouldAllowCoupons, fees, coupons, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [subdomain] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontSaveStorefrontDomainGet(subdomain?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontSaveStorefrontDomainGet(subdomain, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontSettingsGet(accountId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontSettingsGet(accountId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorefrontApi
     */
    public storefrontToggleStorefrontEnabledPost(apiVersion?: string, options?: AxiosRequestConfig) {
        return StorefrontApiFp(this.configuration).storefrontToggleStorefrontEnabledPost(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { useState, useEffect } from "react";
import { Link, redirect, useSearchParams } from "react-router-dom";
import { apiConfig } from "../../config";
import {Provider, Loading, useNavigate} from '@shopify/app-bridge-react';
import { Buffer } from 'buffer';

export interface ShopifyAuthModel{
  apiKey: string,
  host: string,
  forceRedirect: boolean
}

const RedirectToExternalPage = () => {
  
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectUri = searchParams.get("redirectUri") ?? "";
  const host = searchParams.get("host") ?? "";
  
  const [config, setConfig] = useState({
      apiKey: '14c942f84c3721259a38ad0ac0daba47',
    forceRedirect: true,
    host: host
  } as ShopifyAuthModel);
  return (
    <Provider config={config}>
      <Loading />
      <ShopifyRedirectBlock redirectUri={redirectUri} />
    </Provider>
  );
};

export default RedirectToExternalPage;

const ShopifyRedirectBlock = (props:any) => {
  const navigate = useNavigate();
  useEffect(() => {
    let redirectUrl = Buffer.from(props.redirectUri, "base64").toString();
    navigate(redirectUrl)
  }, []);
  return (<></>);
}

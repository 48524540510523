/* tslint:disable */
/* eslint-disable */
/**
 * GiftKard API
 * An API for GiftKard Platform.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: hello@wrappedgiftcards.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateGiftCardsGenerateCodesBatchPostViewModel } from '../models';
// @ts-ignore
import { CreateGiftCardsImportCsvBoxBatchPostViewModel } from '../models';
// @ts-ignore
import { GiftCardBatchViewModel } from '../models';
/**
 * GiftCardBatchApi - axios parameter creator
 * @export
 */
export const GiftCardBatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateGiftCardsGenerateCodesBatchPostViewModel} [createGiftCardsGenerateCodesBatchPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchCreateGiftCardsGenerateCodesBatchPost: async (apiVersion?: string, createGiftCardsGenerateCodesBatchPostViewModel?: CreateGiftCardsGenerateCodesBatchPostViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardBatch/CreateGiftCardsGenerateCodesBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGiftCardsGenerateCodesBatchPostViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [batchName] 
         * @param {boolean} [isPromotional] 
         * @param {any} [csvFile] 
         * @param {Array<string>} [syncToSelectAccountPlatformsOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchCreateGiftCardsImportCsvBatchPost: async (apiVersion?: string, batchName?: string, isPromotional?: boolean, csvFile?: any, syncToSelectAccountPlatformsOnly?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardBatch/CreateGiftCardsImportCsvBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


            if (batchName !== undefined) { 
                localVarFormParams.append('batchName', batchName as any);
            }
    
            if (isPromotional !== undefined) { 
                localVarFormParams.append('isPromotional', isPromotional as any);
            }
    
            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
                if (syncToSelectAccountPlatformsOnly) {
                localVarFormParams.append('syncToSelectAccountPlatformsOnly', syncToSelectAccountPlatformsOnly.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateGiftCardsImportCsvBoxBatchPostViewModel} [createGiftCardsImportCsvBoxBatchPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchCreateGiftCardsImportCsvBoxBatchPost: async (apiVersion?: string, createGiftCardsImportCsvBoxBatchPostViewModel?: CreateGiftCardsImportCsvBoxBatchPostViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardBatch/CreateGiftCardsImportCsvBoxBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGiftCardsImportCsvBoxBatchPostViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchDeleteGiftCardBatchIdDelete: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftCardBatchDeleteGiftCardBatchIdDelete', 'id', id)
            const localVarPath = `/GiftCardBatch/DeleteGiftCardBatch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchExportGiftCardBatchToCsvIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftCardBatchExportGiftCardBatchToCsvIdGet', 'id', id)
            const localVarPath = `/GiftCardBatch/ExportGiftCardBatchToCsv/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [giftCardBatchId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchGetBatchByIdGet: async (giftCardBatchId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardBatch/GetBatchById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (giftCardBatchId !== undefined) {
                localVarQueryParameter['giftCardBatchId'] = giftCardBatchId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchGiftCardBatchListGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardBatch/GiftCardBatchList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchInitCreateGiftCardGenerateCodesModelGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/GiftCardBatch/InitCreateGiftCardGenerateCodesModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GiftCardBatchApi - functional programming interface
 * @export
 */
export const GiftCardBatchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GiftCardBatchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateGiftCardsGenerateCodesBatchPostViewModel} [createGiftCardsGenerateCodesBatchPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftCardBatchCreateGiftCardsGenerateCodesBatchPost(apiVersion?: string, createGiftCardsGenerateCodesBatchPostViewModel?: CreateGiftCardsGenerateCodesBatchPostViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftCardBatchCreateGiftCardsGenerateCodesBatchPost(apiVersion, createGiftCardsGenerateCodesBatchPostViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [batchName] 
         * @param {boolean} [isPromotional] 
         * @param {any} [csvFile] 
         * @param {Array<string>} [syncToSelectAccountPlatformsOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftCardBatchCreateGiftCardsImportCsvBatchPost(apiVersion?: string, batchName?: string, isPromotional?: boolean, csvFile?: any, syncToSelectAccountPlatformsOnly?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftCardBatchCreateGiftCardsImportCsvBatchPost(apiVersion, batchName, isPromotional, csvFile, syncToSelectAccountPlatformsOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateGiftCardsImportCsvBoxBatchPostViewModel} [createGiftCardsImportCsvBoxBatchPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftCardBatchCreateGiftCardsImportCsvBoxBatchPost(apiVersion?: string, createGiftCardsImportCsvBoxBatchPostViewModel?: CreateGiftCardsImportCsvBoxBatchPostViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftCardBatchCreateGiftCardsImportCsvBoxBatchPost(apiVersion, createGiftCardsImportCsvBoxBatchPostViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftCardBatchDeleteGiftCardBatchIdDelete(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftCardBatchDeleteGiftCardBatchIdDelete(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftCardBatchExportGiftCardBatchToCsvIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftCardBatchExportGiftCardBatchToCsvIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [giftCardBatchId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftCardBatchGetBatchByIdGet(giftCardBatchId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardBatchViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftCardBatchGetBatchByIdGet(giftCardBatchId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftCardBatchGiftCardBatchListGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GiftCardBatchViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftCardBatchGiftCardBatchListGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftCardBatchInitCreateGiftCardGenerateCodesModelGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateGiftCardsGenerateCodesBatchPostViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftCardBatchInitCreateGiftCardGenerateCodesModelGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GiftCardBatchApi - factory interface
 * @export
 */
export const GiftCardBatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GiftCardBatchApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateGiftCardsGenerateCodesBatchPostViewModel} [createGiftCardsGenerateCodesBatchPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchCreateGiftCardsGenerateCodesBatchPost(apiVersion?: string, createGiftCardsGenerateCodesBatchPostViewModel?: CreateGiftCardsGenerateCodesBatchPostViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftCardBatchCreateGiftCardsGenerateCodesBatchPost(apiVersion, createGiftCardsGenerateCodesBatchPostViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {string} [batchName] 
         * @param {boolean} [isPromotional] 
         * @param {any} [csvFile] 
         * @param {Array<string>} [syncToSelectAccountPlatformsOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchCreateGiftCardsImportCsvBatchPost(apiVersion?: string, batchName?: string, isPromotional?: boolean, csvFile?: any, syncToSelectAccountPlatformsOnly?: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftCardBatchCreateGiftCardsImportCsvBatchPost(apiVersion, batchName, isPromotional, csvFile, syncToSelectAccountPlatformsOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {CreateGiftCardsImportCsvBoxBatchPostViewModel} [createGiftCardsImportCsvBoxBatchPostViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchCreateGiftCardsImportCsvBoxBatchPost(apiVersion?: string, createGiftCardsImportCsvBoxBatchPostViewModel?: CreateGiftCardsImportCsvBoxBatchPostViewModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftCardBatchCreateGiftCardsImportCsvBoxBatchPost(apiVersion, createGiftCardsImportCsvBoxBatchPostViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchDeleteGiftCardBatchIdDelete(id: string, apiVersion?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.giftCardBatchDeleteGiftCardBatchIdDelete(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchExportGiftCardBatchToCsvIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.giftCardBatchExportGiftCardBatchToCsvIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [giftCardBatchId] 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchGetBatchByIdGet(giftCardBatchId?: string, apiVersion?: string, options?: any): AxiosPromise<GiftCardBatchViewModel> {
            return localVarFp.giftCardBatchGetBatchByIdGet(giftCardBatchId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchGiftCardBatchListGet(apiVersion?: string, options?: any): AxiosPromise<Array<GiftCardBatchViewModel>> {
            return localVarFp.giftCardBatchGiftCardBatchListGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] The requested API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftCardBatchInitCreateGiftCardGenerateCodesModelGet(apiVersion?: string, options?: any): AxiosPromise<CreateGiftCardsGenerateCodesBatchPostViewModel> {
            return localVarFp.giftCardBatchInitCreateGiftCardGenerateCodesModelGet(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GiftCardBatchApi - object-oriented interface
 * @export
 * @class GiftCardBatchApi
 * @extends {BaseAPI}
 */
export class GiftCardBatchApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {CreateGiftCardsGenerateCodesBatchPostViewModel} [createGiftCardsGenerateCodesBatchPostViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardBatchApi
     */
    public giftCardBatchCreateGiftCardsGenerateCodesBatchPost(apiVersion?: string, createGiftCardsGenerateCodesBatchPostViewModel?: CreateGiftCardsGenerateCodesBatchPostViewModel, options?: AxiosRequestConfig) {
        return GiftCardBatchApiFp(this.configuration).giftCardBatchCreateGiftCardsGenerateCodesBatchPost(apiVersion, createGiftCardsGenerateCodesBatchPostViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {string} [batchName] 
     * @param {boolean} [isPromotional] 
     * @param {any} [csvFile] 
     * @param {Array<string>} [syncToSelectAccountPlatformsOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardBatchApi
     */
    public giftCardBatchCreateGiftCardsImportCsvBatchPost(apiVersion?: string, batchName?: string, isPromotional?: boolean, csvFile?: any, syncToSelectAccountPlatformsOnly?: Array<string>, options?: AxiosRequestConfig) {
        return GiftCardBatchApiFp(this.configuration).giftCardBatchCreateGiftCardsImportCsvBatchPost(apiVersion, batchName, isPromotional, csvFile, syncToSelectAccountPlatformsOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {CreateGiftCardsImportCsvBoxBatchPostViewModel} [createGiftCardsImportCsvBoxBatchPostViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardBatchApi
     */
    public giftCardBatchCreateGiftCardsImportCsvBoxBatchPost(apiVersion?: string, createGiftCardsImportCsvBoxBatchPostViewModel?: CreateGiftCardsImportCsvBoxBatchPostViewModel, options?: AxiosRequestConfig) {
        return GiftCardBatchApiFp(this.configuration).giftCardBatchCreateGiftCardsImportCsvBoxBatchPost(apiVersion, createGiftCardsImportCsvBoxBatchPostViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardBatchApi
     */
    public giftCardBatchDeleteGiftCardBatchIdDelete(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardBatchApiFp(this.configuration).giftCardBatchDeleteGiftCardBatchIdDelete(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardBatchApi
     */
    public giftCardBatchExportGiftCardBatchToCsvIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardBatchApiFp(this.configuration).giftCardBatchExportGiftCardBatchToCsvIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [giftCardBatchId] 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardBatchApi
     */
    public giftCardBatchGetBatchByIdGet(giftCardBatchId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardBatchApiFp(this.configuration).giftCardBatchGetBatchByIdGet(giftCardBatchId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardBatchApi
     */
    public giftCardBatchGiftCardBatchListGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardBatchApiFp(this.configuration).giftCardBatchGiftCardBatchListGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardBatchApi
     */
    public giftCardBatchInitCreateGiftCardGenerateCodesModelGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return GiftCardBatchApiFp(this.configuration).giftCardBatchInitCreateGiftCardGenerateCodesModelGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}

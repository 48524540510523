import React, { useContext, useState, useEffect, useRef } from "react";
import TimezoneSelect from "react-timezone-select";
import { AssetApi } from "../../apiclient";
import {
  BillingPlan,
  CheckBalancePageSettingsViewModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Tabs,
  Tab,
  InputGroup,
} from "react-bootstrap";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import ContentEditable from "react-contenteditable";
import * as Swal from "sweetalert2";
import "./assets.css";
import UpsellBanner from "../../components/UpsellBanner";
import PoweredByFooter from "../../components/PoweredByFooter";
const CheckBalancePage = () => {
  let assetApi = new AssetApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [isLoading, setLoading] = useState(false as boolean);
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as CheckBalancePageSettingsViewModel);
  const [validated, setValidated] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [headingHtml, setHeadingHtml] = useState(
    "<span>Check your Gift Card</span>"
  );
  const [bodyHtml, setBodyHtml] = useState(
    "<span>Enter your gift card code to see your current balance and expiration date</span>"
  );

  const [buttonHtml, setButtonHtml] = useState("See your store credit");
  const [codeNotFoundErrorHtml, setCodeNotFoundErrorHtml] = useState(
    "Gift card not found."
  );
  const [showTab, setShowTab] = useState("main");

  const text = useRef("");
  useEffect(() => {
    fetchData();
    authContext.refresh();
    console.log("authContext", authContext);
  }, []);

  const fetchData = () => {
    assetApi
      .assetCheckBalancePageSettingsGet()
      .then((response) => {
        var data = response.data;
        setModel(data);
        setLoading(false);
        console.log("fetch data", data);
      })
      .catch((error: any) => {
        console.log("get survey error", error);
        setLoading(false);
      });
  };

  const saveChanges = () => {
    assetApi
      .assetUpdateCheckBalancePageSettingsPost("1", model)
      .then((response) => {
        toast.success("Settings saved!");
        setUnsavedChanges(false);
      })
      .catch((error: any) => {
        toast.error("An error occurred when saving settings.");
        setUnsavedChanges(false);
      });
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const name = event.target.name;
    var value =
      target.type === "checkbox" ||
        target.type === "radio" ||
        target.type === "switch"
        ? target.checked
        : target.value;

    setModel((values: CheckBalancePageSettingsViewModel) => ({
      ...values,
      [name]: value,
    }));

    setUnsavedChanges(true);
  };

  const handleChangeMainPageTitleText = (event: any) => {
    const value = event.target.value;
    text.current = value;
    setModel((values: CheckBalancePageSettingsViewModel) => ({
      ...values,
      mainPageTitleText: value,
    }));

    setUnsavedChanges(true);
  };

  const handleChangeMainPageSubTitleText = (event: any) => {
    const value = event.target.value;
    text.current = value;
    setModel((values: CheckBalancePageSettingsViewModel) => ({
      ...values,
      mainPageSubTitleText: value,
    }));

    setUnsavedChanges(true);
  };

  const handleChangeMainPageButtonText = (event: any) => {
    const value = event.target.value;
    text.current = value;
    setModel((values: CheckBalancePageSettingsViewModel) => ({
      ...values,
      mainPageButtonText: value,
    }));

    setUnsavedChanges(true);
  };

  const handleChangeMainPageErrorText = (event: any) => {
    const value = event.target.value;
    text.current = value;
    setModel((values: CheckBalancePageSettingsViewModel) => ({
      ...values,
      mainPageErrorText: value,
    }));

    setUnsavedChanges(true);
  };

  const handleChangeMainPagePlaceholderText = (event: any) => {
    const value = event.target.value;
    text.current = value;
    setModel((values: CheckBalancePageSettingsViewModel) => ({
      ...values,
      mainPagePlaceholderText: value,
    }));

    setUnsavedChanges(true);
  };

  const handleChangeCodeInsertedPageBalanceText = (event: any) => {
    const value = event.target.value;
    text.current = value;
    setModel((values: CheckBalancePageSettingsViewModel) => ({
      ...values,
      codeInsertedPageBalanceText: value,
    }));

    setUnsavedChanges(true);
  };

  const handleChangeCodeInsertedPageExpirationText = (event: any) => {
    const value = event.target.value;
    text.current = value;
    setModel((values: CheckBalancePageSettingsViewModel) => ({
      ...values,
      codeInsertedPageExpirationText: value,
    }));

    setUnsavedChanges(true);
  };

  const handleChangeCodeInsertedPageTitleText = (event: any) => {
    const value = event.target.value;
    text.current = value;
    setModel((values: CheckBalancePageSettingsViewModel) => ({
      ...values,
      codeInsertedPageTitleText: value,
    }));

    setUnsavedChanges(true);
  };

  const confirmResetDefaults = () => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `You want to reset your settings back to original.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Reset",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          resetDefaults();
        }
      });
  };

  const resetDefaults = () => {
    setModel((values: CheckBalancePageSettingsViewModel) => ({
      ...values,
      backgroundColor: "#F5F9FC",
      buttonColor: "#5c60f5",
      cardColor: "#ffffff",
      codeInsertedPageBalanceText: "${giftcard_balance}",
      codeInsertedPageExpirationText: "Expiration date: {giftcard_expires_on}",
      codeInsertedPageTitleText: "Current Balance:",
      font: "Segoe UI",
      mainPageButtonText: "See your gift card credit",
      mainPageSubTitleText:
        "Enter your gift card code to see your current balance and expiration date",
      mainPageErrorText: "GIFT CARD NOT FOUND",
      mainPagePlaceholderText: "Your code",
      mainPageTitleText: "Check your Gift Card",
      primaryColor: "#16192c",
    }));
    setUnsavedChanges(true);
  };

  const handleFontChange = (event: any) => {
    const newFont: string = event.target.value;
    setModel({ ...model, font: newFont });
    setUnsavedChanges(true);
  };

  const handleWebFontChange = (event: any) => {
    const target = event.target;
    setModel({ ...model, useCustomFont: target.checked });
    setUnsavedChanges(true);
  };

  const handleWebFontNameChange = (event: any) => {
    const fontName: string = event.target.value;
    setModel({ ...model, customFont: fontName });
    setUnsavedChanges(true);
  };

  const handleWebFontURLChange = (event: any) => {
    const fontURL: string = event.target.value;
    setModel({ ...model, customFontUrl: fontURL });
    setUnsavedChanges(true);
  };

  return (
    <React.Fragment>
      <UpsellBanner message="Start your trial to use this feature" />
      <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
        <div className="container-fluid py-4">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-flex align-items-center gap-4">
                <div>
                  <Link
                    className="bi bi-chevron-left text-xs"
                    to={`/assets`}
                  ></Link>
                </div>
                <div className="vr opacity-20 my-1"></div>
                <h1 className="h4 ls-tight">Check balance page</h1>
              </div>
            </div>
            <div className="col-auto">
              <div className="hstack gap-2 justify-content-end">
                {hasUnsavedChanges && (
                  <div className="text-sm text-muted font-semibold me-2 d-none d-md-block">
                    <span className="d-none d-sm-inline ms-2">
                      You have unsaved changes
                    </span>
                  </div>
                )}
                <a
                  type="button"
                  className="btn btn-sm btn-neutral"
                  target="_blank"
                  href={`https://manage.wrappedgiftcards.com/pc/check-balance/${authContext.account?.accountId}`}
                >
                  <span>Preview</span>
                </a>
                {authContext.account?.billingPlan !== "Free" ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={saveChanges}
                    >
                      <span>Save</span>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      disabled
                    >
                      <span>Save</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      {isLoading ? (
        <>
          <LoaderIcon />
        </>
      ) : (
        <div className="d-xl-flex row">
          <div className=" bg-surface-primary col-md-3">
            <Tabs defaultActiveKey="sections" className="mb-3 ml-10" fill>
              <Tab eventKey="sections" title="Sections">
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center px-0">
                    <div className="flex-fill ms-4">
                      <a
                        onClick={() => setShowTab("main")}
                        className={`cursor-pointer d-block h6 mb-1 ${showTab === "main" ? "text-primary" : ""
                          }`}
                      >
                        Main section
                      </a>
                    </div>
                    <div className="">
                      <a
                        onClick={() => setShowTab("main")}
                        className="btn btn-sm btn-neutral px-4 py-2 text-xs"
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center px-0">
                    <div className="flex-fill ms-4">
                      <a
                        onClick={() => setShowTab("code-not-found")}
                        className={`cursor-pointer d-block h6 mb-1 ${showTab === "code-not-found" ? "text-primary" : ""
                          }`}
                      >
                        Code not found
                      </a>
                    </div>
                    <div className="">
                      <a
                        onClick={() => setShowTab("code-not-found")}
                        className="btn btn-sm btn-neutral px-4 py-2 text-xs"
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center px-0">
                    <div className="flex-fill ms-4">
                      <a
                        onClick={() => setShowTab("inserted-code")}
                        className={`cursor-pointer d-block h6 mb-1 ${showTab === "inserted-code" ? "text-primary" : ""
                          }`}
                      >
                        After code insertion
                      </a>
                    </div>
                    <div className="">
                      <a
                        onClick={() => setShowTab("inserted-code")}
                        className="btn btn-sm btn-neutral px-4 py-2 text-xs"
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="branding" title="Branding">
                <div className="ml-10 list-group">
                  <div className="list-group-item shadow-1 border rounded mb-5">
                    <h4 className="font-semibold mb-2">Colors</h4>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-sm-2">
                        {/* <input
                          type="color"
                          className="form-control form-control-color"
                          id="backgroundColor"
                          value="#F3F3F3"
                          title="Choose your color"
                        /> */}
                        <Form.Control
                          type="color"
                          name="backgroundColor"
                          value={model.backgroundColor}
                          title="Background color"
                          onChange={handleChange}
                        />
                      </div>
                      <label
                        htmlFor="backgroundColor"
                        className="col-sm-10 col-form-label"
                      >
                        Background color
                      </label>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-2">
                        {/* <input
                          type="color"
                          className="form-control form-control-color"
                          id="cardColor"
                          value="#FFFFFF"
                          title="Choose your color"
                        /> */}
                        <Form.Control
                          type="color"
                          name="cardColor"
                          value={model.cardColor}
                          title="Card color"
                          onChange={handleChange}
                        />
                      </div>
                      <label
                        htmlFor="cardColor"
                        className="col-sm-10 col-form-label"
                      >
                        Card color
                      </label>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-2">
                        {/* <input
                          type="color"
                          className="form-control form-control-color"
                          id="primaryColor"
                          value="#023246"
                          title="Choose your color"
                        /> */}
                        <Form.Control
                          type="color"
                          name="primaryColor"
                          value={model.primaryColor}
                          title="Primary color"
                          onChange={handleChange}
                        />
                      </div>
                      <label
                        htmlFor="primaryColor"
                        className="col-sm-10 col-form-label"
                      >
                        Primary color
                      </label>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-2">
                        {/* <input
                          type="color"
                          className="form-control form-control-color"
                          id="buttonColor"
                          value="#023246"
                          title="Choose your color"
                        /> */}
                        <Form.Control
                          type="color"
                          name="buttonColor"
                          value={model.buttonColor}
                          title="Button color"
                          onChange={handleChange}
                        />
                      </div>
                      <label
                        htmlFor="buttonColor"
                        className="col-sm-10 col-form-label"
                      >
                        Button color
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-10 list-group">
                  <div className="list-group-item shadow-1 border rounded mb-5">
                    <h4 className="font-semibold mb-2">Font</h4>
                    <hr />
                    <Form.Group className="mb-3">
                      <Form.Label>Default Font</Form.Label>
                      <Form.Select
                        name="font"
                        onChange={handleFontChange}
                        value={model?.font}
                      >
                        <option value="'Sergo UI'">Sergio UI</option>
                        <option value="'Open Sans', sans-serif">
                          Open Sans
                        </option>
                        <option value="Georgia, serif">Georgia</option>
                        <option value="'Palatino Linotype', 'Book Antiqua', Palatino, serif">
                          Palatino Linotype
                        </option>
                        <option value="'Times New Roman', Times, serif">
                          Times New Roman
                        </option>
                        <option value="Andale Mono,AndaleMono,monospace">
                          Andale Mono
                        </option>
                        <option value="Arial, Helvetica, sans-serif">
                          Arial
                        </option>
                        <option value="'Arial Black', Gadget, sans-serif">
                          Arial Black
                        </option>
                        <option value="'Comic Sans MS', cursive, sans-serif">
                          Comic Sans MS
                        </option>
                        <option value="Impact, Charcoal, sans-serif">
                          Impact
                        </option>
                        <option value="'Lucida Sans Unicode', 'Lucida Grande', sans-serif">
                          Lucida Sans Unicode
                        </option>
                        <option value="Tahoma, Geneva, sans-serif">
                          Tahoma
                        </option>
                        <option value="'Trebuchet MS', Helvetica, sans-serif">
                          Trebuchet MS
                        </option>
                        <option value="Verdana, Geneva, sans-serif">
                          Verdana
                        </option>
                        <option value="'Courier New', Courier, monospace">
                          Courier New
                        </option>
                        <option value="'Lucida Console', Monaco, monospace">
                          Lucida Console
                        </option>
                      </Form.Select>
                    </Form.Group>
                    {/* <Form.Group className="mb-3">
                      <Form.Check
                        checked={model?.useCustomFont || false}
                        onChange={handleWebFontChange}
                        name="primaryUseWebFont"
                        id="use-google-font-primary"
                        type="checkbox"
                        label="Use Google Font"
                      />
                    </Form.Group>
                    {model?.useCustomFont && (
                      <div>
                        <p>
                          Use{" "}
                          <a
                            href="https://fonts.google.com/"
                            className="text-link"
                            target="_blank"
                          >
                            Google Font
                          </a>{" "}
                          by entering the font's name and URL.
                        </p>
                        <Form.Group className="mb-3">
                          <Form.Label>Google Font Name</Form.Label>
                          <Form.Control
                            name="customFont"
                            type="text"
                            onChange={handleWebFontNameChange}
                            value={
                              model?.customFont == null ? "" : model?.customFont
                            }
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Google Font URL</Form.Label>
                          <Form.Control
                            name="primaryFontUrl"
                            type="text"
                            onChange={handleWebFontURLChange}
                            value={
                              model?.customFontUrl == null
                                ? ""
                                : model?.customFontUrl
                            }
                          />
                        </Form.Group>
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="ml-10 list-group">
                  <div className="list-group-item shadow-1 border rounded mb-5">
                    <Form.Group className="mb-3">
                      <Form.Check
                        checked={model?.hideWrappedBranding || false}
                        onChange={handleChange}
                        name="hideWrappedBranding"
                        id="hideWrappedBranding"
                        type="checkbox"
                        label="Hide powered by Wrapped footer"
                      />
                    </Form.Group>

                  </div>
                </div>
                <div className="ml-10 d-grid gap-2 mx-auto">
                  <button
                    className="btn btn-neutral"
                    type="button"
                    onClick={confirmResetDefaults}
                  >
                    Reset to defaults
                  </button>
                </div>
              </Tab>
            </Tabs>
          </div>
          <div
            className="col-md-9 flex-xl-none border-start-xl h-xl-calc position-sticky top-xl-18"
            style={{
              backgroundColor: model.backgroundColor,
              fontFamily: model.font
            }}
          >
            <aside className="p-8 h-full overflow-y-xl-auto">
              <div className="vstack gap-6">
                <div className="">
                  <div className="container py-10">
                    <div className="row mb-5 text-center">
                      <div className="d-grid gap-2 col-6 mx-auto">
                        {(showTab === "main" || showTab === "code-not-found") && (
                          <div
                            className="card mb-5"
                            style={{
                              backgroundColor: model.cardColor,
                            }}
                          >
                            <div className="card-body">
                              <div className="row g-5">
                                <div className="col-12">
                                  <h2
                                    className="font-semibold"
                                    style={{
                                      color: model.primaryColor,
                                    }}
                                  >
                                    <ContentEditable
                                      id="headerHtml"
                                      html={model.mainPageTitleText!} // innerHTML of the editable div
                                      disabled={false} // use true to disable edition
                                      onChange={handleChangeMainPageTitleText} // handle innerHTML change
                                    />
                                  </h2>
                                  <div className="">
                                    <label
                                      className="form-label mt-5"
                                      style={{
                                        color: model.primaryColor,
                                      }}
                                    >
                                      <ContentEditable
                                        id="bodyHtml"
                                        html={model.mainPageSubTitleText!} // innerHTML of the editable div
                                        disabled={false} // use true to disable edition
                                        onChange={
                                          handleChangeMainPageSubTitleText
                                        } // handle innerHTML change
                                      />
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control  mt-5"
                                      id="code"
                                      placeholder={
                                        model.mainPagePlaceholderText
                                      }
                                      value={""}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="d-grid gap-2 mx-auto">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    style={{
                                      backgroundColor: model.buttonColor,
                                    }}
                                  >
                                    <ContentEditable
                                      id="buttonHtml"
                                      html={model.mainPageButtonText!} // innerHTML of the editable div
                                      disabled={false} // use true to disable edition
                                      onChange={handleChangeMainPageButtonText} // handle innerHTML change
                                    />
                                  </button>
                                </div>
                                {showTab === "code-not-found" && (
                                  <div className="font-semibold text-danger">
                                    <ContentEditable
                                      id="codeNotFoundErrorHtml"
                                      html={model.mainPageErrorText!} // innerHTML of the editable div
                                      disabled={false} // use true to disable edition
                                      onChange={handleChangeMainPageErrorText} // handle innerHTML change
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {showTab === "inserted-code" && (
                          <div
                            className="card mb-5"
                            style={{
                              backgroundColor: model.cardColor,
                            }}
                          >
                            <div className="card-body">
                              <div className="row g-5">
                                <div className="col-12">
                                  <h5
                                    className="font-semibold"
                                    style={{
                                      color: model.primaryColor,
                                    }}
                                  >
                                    <ContentEditable
                                      id="headerHtml"
                                      html={model.codeInsertedPageTitleText!} // innerHTML of the editable div
                                      disabled={false} // use true to disable edition
                                      onChange={
                                        handleChangeCodeInsertedPageTitleText
                                      } // handle innerHTML change
                                    />
                                  </h5>
                                  <h2
                                    className="font-semibold"
                                    style={{
                                      color: model.primaryColor,
                                    }}
                                  >
                                    <ContentEditable
                                      id="headerHtml"
                                      html={model.codeInsertedPageBalanceText!} // innerHTML of the editable div
                                      disabled={false} // use true to disable edition
                                      onChange={
                                        handleChangeCodeInsertedPageBalanceText
                                      } // handle innerHTML change
                                    />
                                  </h2>
                                  <h5
                                    className="font-semibold"
                                    style={{
                                      color: model.primaryColor,
                                    }}
                                  >
                                    <ContentEditable
                                      id="headerHtml"
                                      html={
                                        model.codeInsertedPageExpirationText!
                                      } // innerHTML of the editable div
                                      disabled={false} // use true to disable edition
                                      onChange={
                                        handleChangeCodeInsertedPageExpirationText
                                      } // handle innerHTML change
                                    />
                                  </h5>
                                </div>
                                <div>
                                  <InputGroup className="mt-2">
                                    <Form.Control
                                      name={"gift_code_copy"}
                                      className="form-control"
                                      type="text"
                                      value={"{gift_card_code}"}
                                      placeholder="Gift card code"
                                      disabled
                                    />
                                    <Button
                                      variant="outline-secondary"
                                      title="copy to clipboard"
                                      id="button-zapier-copy-key"
                                    >
                                      <i className="bi bi-clipboard"></i>
                                    </Button>
                                  </InputGroup>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {model && !model.hideWrappedBranding && (
                          <PoweredByFooter />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CheckBalancePage;

import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { CampaignApi, GiftCardBatchApi, GiftcardApi } from "../../apiclient";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {
  Campaign,
  GiftCardBatchViewModel
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  ButtonGroup,
  ToggleButton,
  FloatingLabel,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import {
  Link,
  useSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";

import Util from "../../js/util";
import moment from "moment";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));
import * as Swal from "sweetalert2";

const CampaignEditPage = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  let campaignApi = new CampaignApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as Campaign);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [scheduled, setScheduled] = useState("");
  const [isLoading, setLoading] = useState(false as boolean);
  const [batchList, setBatchList] = useState(
    [] as SelectOption[]
  );

  interface SelectOption {
    value: string;
    label: string;
    batchName: string;
  }

  let giftCardBatchApi = new GiftCardBatchApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );

  useEffect(() => {
    loadCampaign();
    getBatchList();
  }, []);

  const getBatchList = () => {
    giftCardBatchApi.giftCardBatchGiftCardBatchListGet().then((response) => {
      var data = response.data as GiftCardBatchViewModel[];
      const options = data.map(item => ({
        value: item.giftCardBatchId,
        label: `${item.batchName} (${item.numCardsCreated} gift cards)`,
        batchName: item.batchName
      } as SelectOption));

      setBatchList(options);
    });
  }

  const loadCampaign = () => {
    campaignApi.campaignGetCampaignByIdIdGet(id!).then((response) => {
      var data = response.data;
      let editableCopy = { ...data };
      if (data) {

        if (!data.timezone) {
          editableCopy.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        setTimezone(editableCopy.timezone!);
        if (!data.scheduledAt) {
          setScheduled(convertToLocal(new Date().toString(), editableCopy.timezone!));
        } else {
          setScheduled(convertToLocal(data.scheduledAt, editableCopy.timezone!));
        }

        console.log("local", convertToLocal(data.scheduledAt!, editableCopy.timezone!));

      }
      setModel(editableCopy);
      setLoading(false);
    });
  }

  const handleSubmitCampaignUpdate = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      // if (!model.startsAt) {
      //   model.startsAt = null;
      // }

      // if (!model.endsAt) {
      //   model.endsAt = null;
      // }
      campaignApi
        .campaignUpdateCampaignPut("1", model)
        .then((response) => {
          toast.success("Campaign saved");
          loadCampaign();
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error updating campaign");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: Campaign) => ({
      ...values,
      [name]: value,
    }));
  };

  const getStatusBadge = () => {
    var cardStatus = model.status;
    var badgeColor = "bg-secondary text-dark";
    if (cardStatus !== "Disabled" && cardStatus !== "Deleted" && cardStatus !== "Draft") {
      badgeColor = "bg-success text-success";
    }
    return (<span
      className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
    >
      {cardStatus}
    </span>)
  }

  const disableCampaign = () => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `This will disable the campaign. Note: this will not delete any generated gift cards or credits.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, disable campaign",

        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          campaignApi.campaignDisableCampaignIdPut(model.id!)
            .then((response) => {
              var data = response.data;
              if (data) {
                toast.success("Campaign disabled.");
                loadCampaign();
              } else {
                toast.error("Couldn't disable campaign.");
              }
            })
            .catch((error: any) => {
              toast.error("Couldn't disable campaign.");
            });
        }
      });
  }


  const activateCampaign = () => {
    Swal.default
      .fire({
        title: "Activate campaign?",
        text: `This will activate the campaign`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, activate campaign",
      })
      .then((result) => {
        if (result.isConfirmed) {
          campaignApi
            .campaignUpdateCampaignPut("1", model)
            .then((response) => {
              campaignApi.campaignEnableCampaignIdPut(model.id!)
                .then((response) => {
                  var data = response.data;
                  if (data) {
                    toast.success("Campaign activated.");
                    navigate(`/campaigns`);
                    // loadCampaign();
                  } else {
                    toast.error("Couldn't activate campaign.");
                  }
                })
                .catch((error: any) => {
                  toast.error("Couldn't activate campaign.");
                });
            })
            .catch((error: any) => {
              console.log("post error", error);
              toast.error("Error updating campaign");
            });

        }
      });
  }

  const saveAndNavigateToEditEmail = (e: any) => {
    e.preventDefault();
    campaignApi
      .campaignUpdateCampaignPut("1", model)
      .then((response) => {
        toast.success("Campaign saved.");
        navigate(`/campaigns/design-email/${id}`);
      })
      .catch((error: any) => {
        console.log("post error", error);
        toast.error("Error updating campaign");
      });

  }

  const getStatusButton = () => {
    if (model.status == "ReadyToSend") {
      return (<button type="button" onClick={disableCampaign} className="btn btn-danger btn-sm ">Disable</button>);
    }
    else if (model.status == "Disabled") {
      return (<button type="button" onClick={activateCampaign} className="btn btn-primary btn-sm ">Enable</button>);
    }
    else if (model.status == "Draft") {
      if (model.shouldSendEmail) {
        if (model.isScheduled) {
          return (<button type="button" onClick={activateCampaign} className="btn btn-primary btn-sm ">Schedule</button>);
        }
        return (<button type="button" onClick={activateCampaign} className="btn btn-primary btn-sm ">Send now</button>);
      } else {
        return (<button type="button" onClick={activateCampaign} className="btn btn-primary btn-sm ">Activate</button>);
      }
    }
  }

  let handleBatchChange = (option: SelectOption | null) => {
    setModel((values: Campaign) => ({
      ...values,
      giftCardBatchId: option?.value,
      giftCardBatchName: option?.batchName
    }));
  };

  const timezoneOnChange = (selectedTimezone: any) => {
    console.log("selectedTimezone", selectedTimezone);
    setTimezone(selectedTimezone.value);
    setModel((values: Campaign) => ({
      ...values,
      timezone: selectedTimezone.value,
    }));
  };

  const handleScheduledChange = (event: any) => {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var utcDate = convertToUTC(value, timezone);
    setScheduled(value);
    setModel((values: Campaign) => ({
      ...values,
      scheduledAt: utcDate,
    }));
  };

  const convertToUTC = (dateString: string, timeZone: string): string => {
    try {
      const date = dayjs.tz(dateString, timeZone);
      const utcDate = date.utc().format();
      console.log("date", utcDate, timeZone);
      return utcDate;
    }
    catch {
      return dateString;
    }
  };

  const convertToLocal = (utcDateString: string, timeZone: string): string => {
    const localDate = dayjs.utc(utcDateString).tz(timeZone).format('YYYY-MM-DDTHH:mm');
    return localDate;
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <Spinner animation="border" />
        </>
      ) : (
        <>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmitCampaignUpdate}
          >
            <header className="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
              <div className="container-fluid py-4">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="d-flex align-items-center gap-4">
                      <div>
                        <Link
                          className="bi bi-chevron-left text-xs"
                          to={`/campaigns`}
                        ></Link>
                      </div>
                      <div className="vr opacity-20 my-1"></div>
                      <h1 className="h4 ls-tight">Update campaign</h1>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="hstack gap-2 justify-content-end">
                      {/* <a
                      href="https://help.wrappedgiftcard.com/en/collections/3830625-account#general"
                      target={"_blank"}
                      className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block"
                    >
                      <i className="bi bi-question-circle-fill"></i>
                      <span className="d-none d-sm-inline ms-2">
                        Need help?
                      </span>
                    </a> */}
                      {model && <>
                        Status: {getStatusBadge()}
                      </>}
                      <button type="submit" className="btn btn-sm btn-neutral">
                        <span>Save changes</span>
                      </button>
                      {getStatusButton()}
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <div className="bg-surface-secondary">
              <div className="container py-10">
                <div className="row mb-10 mt-0">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">Campaign details</h4>
                    <p className="text-sm">
                      Select an existing batch to send to or <Link
                        className="text-link active"
                        to={`/bulk-gift-cards/new`}
                      >
                        create a new batch
                      </Link>.
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card shadow border-0">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="col-md-12">
                            <div className="">
                              <label className="form-label" htmlFor="name">
                                Name
                              </label>
                              {/* <p className="text-sm text-muted d-none d-lg-block pb-5">
                                Not visible to the customer
                              </p> */}
                              <Form.Control
                                name={"name"}
                                className="form-control"
                                type="text"
                                value={model.name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row g-5 pt-5">
                          <div className="col-md-12">
                            <div className="">
                              <label className="form-label">
                                Gift card batch
                              </label>
                              <p className="text-sm text-muted d-none d-lg-block pb-5">
                                Notifications will be sent for gift cards with an email address
                              </p>
                              <Select options={batchList} placeholder="Select a batch"
                                required={true}
                                value={batchList.find(option => option.value === model.giftCardBatchId)}
                                onChange={(e) =>
                                  handleBatchChange(e)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-10 mt-0">
                  <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                    <h4 className="font-semibold mb-2">Email notification</h4>
                    <p className="text-sm">
                      Customize the gift card email you'd like to send. Ensure all recipients accept marketing.
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="card shadow border-0">
                      <div className="card-body">
                        <div className="row g-5">
                          <div className="col-md-12">
                            <div className="d-flex ">
                              <div className="me-3">
                                <Form.Check
                                  name="shouldSendEmail"
                                  id="shouldSendEmail"
                                  type={"checkbox"}
                                  checked={model.shouldSendEmail}
                                  onChange={handleChange}
                                />
                              </div>
                              <div>
                                <label
                                  className="form-label mb-0"
                                  htmlFor="shouldSendEmail"
                                >
                                  Send email notification
                                </label>
                              </div>
                            </div>
                            {model.shouldSendEmail && (
                              <div>
                                <div className="list-group mt-5">
                                  <div className="list-group-item d-flex align-items-center shadow-1 border rounded">
                                    <div className="flex-fill pl-20">
                                      <a onClick={(event) => saveAndNavigateToEditEmail(event)}
                                        href={`/campaigns/design-email/${id}`}
                                        className="d-block h6 font-semibold mb-1"
                                      >
                                        Subject: {model.emailSubject}
                                      </a>
                                      <span className="d-block text-sm mb-2">
                                        Sent to customer when a gift card is
                                        issued.
                                      </span>
                                      <div className="d-flex mx-n1"></div>
                                    </div>
                                    <div className="ms-auto text-end">
                                      <a
                                        onClick={(event) => saveAndNavigateToEditEmail(event)}
                                        href={`/campaigns/design-email/${id}`}
                                        className="btn btn-sm btn-neutral mr-10"
                                      >
                                        Preview &amp; edit
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex pt-5">
                                  <div className="me-3">
                                    <Form.Check
                                      name="isScheduled"
                                      id="isScheduled"
                                      type={"checkbox"}
                                      checked={model.isScheduled}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="isScheduled"
                                    >
                                      Schedule
                                    </label>
                                  </div>
                                </div>
                                {model.isScheduled && (
                                  <>
                                    <Form.Group className="mt-5">
                                      <FloatingLabel
                                        label="Send at"
                                        className="mb-3"
                                      >
                                        <Form.Control
                                          name="scheduled"
                                          type="datetime-local"
                                          value={scheduled || ""}
                                          onChange={handleScheduledChange}
                                          required
                                        />
                                      </FloatingLabel>

                                    </Form.Group>
                                    <Form.Group className="mt-5">
                                      <Form.Label
                                      >
                                        Timezone
                                      </Form.Label>
                                      <TimezoneSelect
                                        value={timezone}
                                        onChange={timezoneOnChange}
                                      />
                                    </Form.Group>
                                  </>
                                )}
                              </div>
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
    </React.Fragment>
  );
};

export default CampaignEditPage;

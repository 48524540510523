import React, { Component, useState, useContext, useEffect } from "react";
import {
  Button,
  Row,
  Card,
  DropdownButton,
  Dropdown,
  Container,
  Col,
  Badge,
  ButtonGroup,
  Navbar,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  EmailApi,
  SenderDomainsPageViewModel,
  SenderDomain,
  SenderDomainStatus,
  ProcessResultModel,
} from "../../apiclient";
import { apiConfig } from "../../config";
import * as Swal from "sweetalert2";
import VerifyDomainModalComponent from "./VerifyDomainModalComponent";
import VerifyCodeModalComponent from "./VerifyCodeModalComponent";
import SenderDomainAuthenticateModalComponent from "./SenderDomainAuthenticateModalComponent";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import SettingsNavMenu from "../../components/SettingsNavMenu";

const SenderDomainsPage = (props: any) => {
  let settingsApi = new EmailApi(apiConfig.configuration, apiConfig.baseUrl);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState({} as SenderDomainsPageViewModel);
  const [showVerifyDomainModal, setShowVerifyDomainModal] = useState(false);
  const [verifyCodeDomainId, setVerifyCodeDomainId] = useState("" as string);
  const [showVerifyCodeModal, setShowVerifyCodeModal] = useState(false);
  const [showDomainAuthenticateModal, setShowDomainAuthenticateModal] =
    useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    fetchDomains();
  }, []);

  const fetchDomains = () => {
    settingsApi.emailSenderDomainsGet("1").then(function (response) {
      var data = response.data as SenderDomainsPageViewModel;
      setModel(data);
      setLoading(false);
    });
  };

  const deleteDomain = (domain: SenderDomain, i: number) => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `If you remove  "${domain.domain}" from your verified domains, you can't use it in your from email address.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete domain",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // remove from list
          let newListItems = [...model.activeDomains!];
          newListItems.splice(i, 1);
          var newModel = {
            ...model,
            activeDomains: newListItems,
          };
          setModel(newModel);
          settingsApi
            .emailDeleteDomainIdDelete(domain.id ?? "", "1")
            .then(function (response) {
              var data = response.data as ProcessResultModel;
              if (data.isSuccessful) {
                toast.success(data.message!);
              } else {
                toast.error(data.message!);
              }
            });
        }
      });
  };

  const resendEmail = (domain: SenderDomain, i: number) => {
    settingsApi
      .emailSendDomainVerificationEmailIdGet(domain.id ?? "", "1")
      .then(function (response) {
        var data = response.data as ProcessResultModel;
        if (data.isSuccessful) {
          toast.success(data.message!);
        } else {
          toast.error(data.message!);
        }
      });
  };

  const checkDns = (domain: SenderDomain, i: number) => {
    settingsApi
      .emailSenderDomainInstallIdGet(domain.id ?? "", "1")
      .then(function (response) {
        var data = response.data as ProcessResultModel;
        if (data.isSuccessful) {
          toast.success(data.message!);
          fetchDomains();
        } else {
          toast.error(data.message!);
        }
      });
  };

  const enterCodeModal = (domain: SenderDomain, i: number) => {
    if (domain) {
      setVerifyCodeDomainId(domain.id!);
      setShowVerifyCodeModal(true);
    }
  };

  const onShowDomainAuthenticateModal = (domain: SenderDomain, i: number) => {
    if (domain) {
      setVerifyCodeDomainId(domain.id!);
      setShowDomainAuthenticateModal(true);
    }
  };

  const renderDomainListItem = (domain: SenderDomain, i: number) => {
    if (domain.domainStatus === SenderDomainStatus.Pending) {
      return renderPendingDomainListItem(domain, i);
    } else if (domain.domainStatus === SenderDomainStatus.Verified) {
      return renderVerifiedDomainListItem(domain, i);
    } else if (domain.domainStatus === SenderDomainStatus.Authenticated) {
      return renderAuthenticatedDomainListItem(domain, i);
    } else if (
      domain.domainStatus === SenderDomainStatus.PendingAuthentication
    ) {
      return renderPendingAuthenticationDomainListItem(domain, i);
    } else {
      return <></>;
    }
  };

  const renderPendingDomainListItem = (domain: SenderDomain, i: number) => {
    return (
      <>
        <div className="flex-grow-1">
          <span>
            <h6 className="d-inline-block h6 mb-0">{domain.domain}</h6>{" "}
            <Badge className="badge-middot badge-warning">
              Pending verification
            </Badge>
          </span>
          <p className="color-grey-7 mb-0 mt-1 text-sm">
            Enter the code sent to your email to finish verifying your domain.
          </p>
        </div>
        <div className="w-12rem">
          <Dropdown as={ButtonGroup} className="w-100">
            <Button
              onClick={() => {
                enterCodeModal(domain, i);
              }}
              variant="neutral"
              size="sm"
            >
              Enter Code
            </Button>
            <Dropdown.Toggle
              split
              id="dropdown-split-basic"
              variant="neutral"
              size="sm"
            />
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  resendEmail(domain, i);
                }}
              >
                Resend email
              </Dropdown.Item>

              <hr className="dropdown-divider" />
              <Dropdown.Item
                className="text-danger"
                onClick={() => {
                  deleteDomain(domain, i);
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  };

  const renderVerifiedDomainListItem = (domain: SenderDomain, i: number) => {
    return (
      <>
        <div className="flex-grow-1">
          <span>
            <h6 className="d-inline-block h6 mb-0">{domain.domain}</h6>{" "}
            <Badge className="badge-middot badge-secondary" bg="warning">
              Verified
            </Badge>
          </span>
          <p className="color-grey-7 mb-0 mt-1 text-sm">
            To help improve your email delivery rates, authenticate your domain.
          </p>
        </div>
        <div className="w-12rem">
          <Dropdown as={ButtonGroup} className="w-100">
            <Button
              onClick={() => {
                onShowDomainAuthenticateModal(domain, i);
              }}
              variant="neutral"
              size="sm"
            >
              Authenticate
            </Button>
            <Dropdown.Toggle
              split
              id="dropdown-split-basic"
              variant="neutral"
              size="sm"
            />
            <Dropdown.Menu>
              <Dropdown.Item
                className="text-danger"
                onClick={() => {
                  deleteDomain(domain, i);
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  };

  const renderAuthenticatedDomainListItem = (
    domain: SenderDomain,
    i: number
  ) => {
    return (
      <>
        <div className="flex-grow-1">
          <span>
            <h6 className="d-inline-block h6 mb-0">
              <a
                className="link link-secondary"
                onClick={() => {
                  onShowDomainAuthenticateModal(domain, i);
                }}
              >
                {domain.domain}
              </a>
            </h6>{" "}
            <Badge className="badge-middot badge-success" bg="warning">
              Authenticated
            </Badge>
          </span>
          <p className="color-grey-7 mb-0 mt-1 text-sm">
            Domain is properly setup.
          </p>
        </div>
        <div className="w-12rem">
          <Button
            className="w-100"
            onClick={() => {
              deleteDomain(domain, i);
            }}
            variant="outline-danger"
            size="sm"
          >
            Delete
          </Button>
        </div>
      </>
    );
  };

  const renderPendingAuthenticationDomainListItem = (
    domain: SenderDomain,
    i: number
  ) => {
    return (
      <>
        <div className="flex-grow-1">
          <span>
            <h6 className="d-inline-block h6 mb-0">{domain.domain}</h6>{" "}
            <Badge className="badge-middot badge-warning">
              Pending authentication
            </Badge>
          </span>
          <p className="color-grey-7 mb-0 mt-1 text-sm">
            You need to update your DNS settings to authenticate this domain.
          </p>
        </div>
        {/* <Button
              onClick={() => {
                checkDns(domain, i);
              }}
              variant="primary"
              size="sm"
              className="mr-10"
            >
              Check DNS <i className="bi bi-arrow-clockwise" />
            </Button> */}
        <div className="w-12rem">
          <Dropdown as={ButtonGroup} className="w-100">
            <Button
              onClick={() => {
                onShowDomainAuthenticateModal(domain, i);
              }}
              variant="neutral"
              size="sm"
            >
              Check DNS
            </Button>
            <Dropdown.Toggle
              split
              id="dropdown-split-basic"
              variant="neutral"
              size="sm"
            />
            <Dropdown.Menu>
              <Dropdown.Item
                className="text-danger"
                onClick={() => {
                  deleteDomain(domain, i);
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="border-bottom pt-6">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12">
                <h1 className="h2 ls-tight">Sender domains</h1>
              </div>
              <div className="col-sm-6 col-12">
                {/* <a
                  href="#"
                  target={"_blank"}
                  className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block float-end"
                >
                  <i className="bi bi-question-circle-fill"></i>
                  <span className="d-none d-sm-inline ms-2">Help articles</span>
                </a> */}
              </div>
              <p className="text-sm pb-5">
                Verify a domain to use as a "from" address when you send emails.
                Authenticate the domain to improve deliverability.
              </p>
            </div>
           <SettingsNavMenu />
          </div>
        </div>
      </header>

      {/* <!-- Main --> */}
      <main className="py-6 bg-surface-secondary">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row pb-3">
                <div className="col">
                  <h4 className="h4">Sender domains</h4>
                </div>
                <div className="col">
                  <button
                    onClick={() => {
                      setShowVerifyDomainModal(true);
                    }}
                    type="button"
                    className="btn btn-sm btn-primary float-end"
                  >
                    Verify a domain
                  </button>
                </div>
              </div>

              <Card className="card">
                <Card.Body className="py-3">
                  {loading && (
                    <div className="py-4 text-center">
                      <Spinner
                        animation="border"
                        role="status"
                        variant="primary"
                      />
                    </div>
                  )}
                  {model.activeDomains?.length == 0 && (
                    <>
                      <p>No sender domains registered.</p>
                    </>
                  )}
                  {model.activeDomains &&
                    model.activeDomains.map((domain, i) => (
                      <div
                        className={`align-items-center d-md-flex py-3 w-100 ${
                          model.activeDomains?.length &&
                          i != model.activeDomains?.length - 1
                            ? "border-bottom"
                            : "mb-1"
                        }`}
                        key={domain.id}
                      >
                        {renderDomainListItem(domain, i)}
                      </div>
                    ))}
                </Card.Body>
              </Card>
            </div>
          </div>
          <VerifyDomainModalComponent
            isOpen={showVerifyDomainModal}
            onHide={() => setShowVerifyDomainModal(false)}
            refreshDomains={() => fetchDomains()}
          />
          {verifyCodeDomainId && (
            <>
              <VerifyCodeModalComponent
                isOpen={showVerifyCodeModal}
                onHide={() => setShowVerifyCodeModal(false)}
                refreshDomains={() => fetchDomains()}
                domainId={verifyCodeDomainId}
              />
              <SenderDomainAuthenticateModalComponent
                isOpen={showDomainAuthenticateModal}
                onHide={() => setShowDomainAuthenticateModal(false)}
                refreshDomains={() => fetchDomains()}
                domainId={verifyCodeDomainId}
              />
            </>
          )}
        </div>
      </main>
    </React.Fragment>
  );
};

export default SenderDomainsPage;

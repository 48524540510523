import React, { useState, useEffect } from "react";
import { AccountApi } from "../../apiclient";
import { CreateAccountModel } from "../../apiclient/models";
import { Button, Row, Col, Form } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import "./CreateAccountPage.css";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import ReCAPTCHA from "react-google-recaptcha";

const CreateAccountPage = () => {
  let accountApi = new AccountApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as CreateAccountModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [createAccountButtonText, setCreateAccountButtonText] =
    useState("Create account");
  useEffect(() => {}, []);

  const handleRecaptchaChange = (value: any) => {
    console.log("handleRecaptchaChange", value);
    setModel({ ...model, recaptchaResponse: value });
  };

  const handleSubmit = (event: any) => {
    //setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    // }

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      setCreateAccountButtonText("Creating account..");

      accountApi
        .accountCreateAccountPost("1", model)
        .then((response) => {
          toast.success("Please check your email to confirm your account.");
          setIsAccountCreated(true);
        })
        .catch((error: any) => {
          toast.error("Unable to find account with this email address");
          setCreateAccountButtonText("Create account");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: CreateAccountModel) => ({
      ...values,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="px-5 py-5 p-lg-0 min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-dark d-none d-lg-flex flex-column">
              {/* <!-- Logo --> */}
              <a className="d-block" href="https://wrappedgiftcards.com">
                <img
                  src="/img/logos/wrapped-logo.png"
                  className="h-10"
                  alt="..."
                />
              </a>
              {/* <!-- Title --> */}
              <div className="mt-32 mb-20">
                <h1 className="ls-tight font-bolder display-7 text-white mb-5">
                  Create and manage omnichannel gift cards
                </h1>
                <p className="text-white text-opacity-80">
                  Deliver a better customer experience with gift cards that can
                  be redeemed instore or online.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-screen d-flex flex-column justify-content-center position-relative">
              <div className="py-lg-16 px-lg-20">
                <div className="row">
                  <div className="col-lg-10 col-md-9 col-xl-8 mx-auto ms-xl-0">
                    {isAccountCreated ? (
                      <>
                        <div className="mt-10 mt-lg-5 mb-6 d-lg-block">
                          <span className="d-inline-block d-lg-block h1 mb-4 mb-lg-6 me-3">
                            ✅
                          </span>
                          <h1 className="ls-tight font-bolder h2">
                            Account has been created.
                          </h1>
                        </div>
                        <div className="row">
                          <p>
                            Please check your email inbox to confirm your
                            account.
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mt-10 mt-lg-5 mb-6 d-lg-block">
                          <h1 className="ls-tight font-bolder h2">
                            Create a free Wrapped account
                          </h1>
                        </div>
                        {/* <div className="row">
                          <div className="hstack gap-4 box-content">
                            <a
                              href="/Shopify/RedirectAndLogin"
                              className="btn btn-neutral signinbtn w-full"
                            >
                              <div>
                                <img src="/img/integrations/shopify-bag.png" />
                                Install via Shopify
                              </div>
                            </a>
                          </div>
                          <div className="hstack gap-4 box-content">
                            <a
                              href="/Vend/RedirectAndLogin"
                              className="btn btn-neutral signinbtn mt-5 w-full"
                            >
                              <div>
                                <img
                                  alt="..."
                                  src="/img/integrations/Lightspeed_Flame.svg"
                                />
                                Install via Lightspeed Retail (X-Series)
                              </div>
                            </a>
                          </div>
                        </div>
                        <hr /> */}
                        <div>
                          {/* <p className="mb-5">Or create your account below:</p> */}
                          <div className="mb-5">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              required
                              name={"name"}
                              value={model.name ?? ""}
                              className="form-control"
                              type="text"
                              placeholder="Name"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-5">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                              required
                              name={"companyName"}
                              value={model.companyName ?? ""}
                              className="form-control"
                              type="text"
                              placeholder="Company Name"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-5">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                              required
                              name={"emailAddress"}
                              value={model.emailAddress ?? ""}
                              className="form-control"
                              type="email"
                              placeholder="Email Address"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="pb-5">
                            <ReCAPTCHA
                              sitekey="6LcBauUoAAAAAK3yfIsknW2xb8y0HPs0IXd3qSxp"
                              onChange={handleRecaptchaChange}
                            />
                          </div>
                          <div>
                            <Button
                              className="w-full"
                              variant="primary"
                              type="submit"
                              disabled={
                                isSubmitting || !model.recaptchaResponse
                              }
                            >
                              {createAccountButtonText}
                            </Button>
                          </div>
                        </div>
                        <div className="acceptterms">
                          <small>
                            By creating an account, you accept our{" "}
                            <a
                              href="https://wrappedgiftcards.com/terms-conditions"
                              target="_blank"
                            >
                              Terms
                            </a>{" "}
                            and{" "}
                            <a
                              href="https://wrappedgiftcards.com/privacy-policy"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                          </small>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="position-lg-absolute bottom-0 end-0 my-8 mx-12 text-center text-lg-end">
                <small>Already have an account?</small>
                <a href="/account/login" className="text-sm font-semibold ml-5">
                  Log in
                </a>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default CreateAccountPage;

import React, { useContext, useState, useEffect, useRef } from "react";
import TimezoneSelect from "react-timezone-select";
import { StaffBalanceCheckerApi } from "../../apiclient";
import {
  StaffBalanceCheckerGiftCardDetailViewModel,
  StaffBalanceCheckerGeneratedGiftCardViewModel,
  StaffBalanceCheckerRenewPostModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Tabs,
  Tab,
  InputGroup,
} from "react-bootstrap";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import ContentEditable from "react-contenteditable";
import * as Swal from "sweetalert2";

//import "./assets.css";
const StaffCheckBalancePage = () => {
  let { id } = useParams();

  let cardApi = new StaffBalanceCheckerApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [isLoading, setLoading] = useState(false as boolean);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showInvalidCodeError, setShowInvalidCodeError] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false as boolean);
  const [showGiftToFriendModal, setShowGiftToFriendModal] = useState(
    false as boolean
  );
  const [giftCardModel, setGiftCardModel] = useState(
    {} as StaffBalanceCheckerGiftCardDetailViewModel
  );
  const [showTab, setShowTab] = useState("main");
  const [searchParams, setSearchParams] = useSearchParams();
  const text = useRef("");
  const [giftCardCode, setGiftCardCode] = useState(
    searchParams.get("code") ?? ""
  );

  useEffect(() => {
    getGiftCardByCode(giftCardCode);
  }, []);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      getGiftCardByCode(giftCardCode);
    }
  };

  const getGiftCardByCode = (code: string) => {
    if (code) {
      cardApi
        .staffBalanceCheckerGetByCodeCodeGet(code, id)
        .then((response) => {
          if (response.data) {
            setGiftCardModel(response.data);
          } else {
            toast.error("Invalid code.");
            setShowInvalidCodeError(true);
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred check code.");
        });
    }
  };

  const logout = () => {
    setGiftCardModel({});
    setGiftCardCode("");
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;
    setGiftCardCode(value);
  };

  const renewExpiredGiftcard = () => {
    // do stuff

    Swal.default
      .fire({
        title: "Are you sure?",
        text: `A new gift card (code: ${giftCardModel.code}-2) will be generated set to expire in 7 days`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, create gift card",
      })
      .then((result) => {
        if (result.isConfirmed) {
          cardApi
            .staffBalanceCheckerRegenerateExpiredCardPost("1", {
              accountId: id,
              giftCardId: giftCardModel.id,
              recipientEmailAddress: "", // not currently used
            } as StaffBalanceCheckerRenewPostModel)
            .then((response) => {
              var data =
                response.data as StaffBalanceCheckerGeneratedGiftCardViewModel;
              if (data && data.isSuccessful) {
                toast.success("New gift card was generated");
                setGiftCardCode(data.newCode ?? giftCardCode);
                getGiftCardByCode(data.newCode ?? giftCardCode);
              } else {
                toast.error("Failed to create gift card");
              }
            })
            .catch((error: any) => {
              toast.error("Failed to create gift card");
            });
        }
      });
  };

  const getStatusBadge = (status: string) => {
    if (status == "Active") {
      return "badge bg-success";
    } else if (status == "Expired") {
      return "badge bg-danger";
    } else {
      return "badge bg-secondary";
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <LoaderIcon />
        </>
      ) : (
        <div
          className="d-xl-flex row"
          style={{ backgroundColor: "rgb(245, 249, 252)" }}
        >
          <div className="col-md-12 flex-xl-none border-start-xl h-xl-calc position-sticky top-xl-18">
            <aside className="p-8 h-full overflow-y-xl-auto">
              <div className="vstack gap-6">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="">
                    {giftCardModel && giftCardModel.id ? (
                      <div className="">
                        <div className="container py-10">
                          <div className="row mb-10 text-center">
                            <div className="d-grid gap-2 mx-auto">
                              <div className="card mb-10">
                                <div className="card-header d-flex align-items-center">
                                  <div className="ms-auto">
                                    <button
                                      onClick={logout}
                                      className="btn btn-sm btn-neutral ms-2"
                                    >
                                      Check another card
                                    </button>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="row g-5">
                                    <div className="col-12">
                                      <h5 className="font-semibold">
                                        Gift card balance:
                                      </h5>
                                      <h2 className="font-semibold">
                                        {giftCardModel.formattedBalance}
                                      </h2>

                                      <h3 className="font-semibold mt-10">
                                        Code: {giftCardCode}{" "}
                                      </h3>
                                      <h5 className="font-semibold mt-2">
                                        Status:{" "}
                                        <span
                                          className={getStatusBadge(
                                            giftCardModel.status ?? ""
                                          )}
                                        >
                                          {giftCardModel.status}
                                        </span>
                                      </h5>
                                      <h5 className="font-semibold mt-2">
                                        Expires:{" "}
                                        {giftCardModel.expiresOn
                                          ? giftCardModel.expiresOnFormatted
                                          : "never"}
                                      </h5>
                                    </div>

                                    {giftCardModel.showGenerateNewCodeButton && (
                                      <div className="d-grid gap-2 mx-auto">
                                        <button
                                          className="btn btn-neutral"
                                          onClick={() => renewExpiredGiftcard()}
                                        >
                                          Renew expired gift card
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="container py-10">
                        <div className="row mb-10 text-center">
                          <div className="mx-auto">
                            <div className="card mb-10">
                              <div className="card-body">
                                <div className="row g-5">
                                  <div className="col-12">
                                    <h2 className="font-semibold">
                                      Check a gift card balance
                                    </h2>
                                    <div className="" style={{textAlign:"left"}}>
                                      <label className="form-label mt-5">
                                        <p>
                                          Enter the gift card code below to
                                          verify its balance and expiration
                                          date. This will also automatically
                                          enable older online gift cards (bought
                                          before November 22, 2023) to be
                                          redeemed in-store.
                                        </p>
                                        <p>
                                          For physical Lightspeed POS gift cards
                                          with remaining but expired value, this
                                          service allows a one-time extension of
                                          7 days, and the newly extended code
                                          can then be redeemed in-store or
                                          online. It's advisable for the
                                          customer to use this extended balance
                                          on the day.
                                        </p>
                                        <p className="pt-5">
                                          <strong>Important:</strong>
                                          <ul>
                                            <li>
                                              Accuracy is crucial when entering
                                              the gift card code. Please ensure
                                              it exactly matches the code on
                                              your card, including case
                                              sensitivity and character
                                              specifics, do not include spaces.
                                            </li>
                                            <li>
                                              Gift cards cannot be used to
                                              purchase additional gift cards.
                                            </li>
                                          </ul>
                                        </p>
                                      </label>
                                      <Form.Control
                                        name={"code"}
                                        className="form-control mt-5"
                                        type="text"
                                        placeholder="Enter code"
                                        value={giftCardCode}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="d-grid gap-2 mx-auto">
                                    <button
                                      className="btn btn-primary"
                                      type="submit"
                                    >
                                      Find gift card
                                    </button>
                                  </div>
                                  {showInvalidCodeError && (
                                    <div className="font-semibold text-danger">
                                      Invalid code
                                    </div>
                                  )}

                                  {giftCardModel.infoMessage && (
                                    <div className="font-semibold text-info">
                                      {giftCardModel.infoMessage}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </aside>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default StaffCheckBalancePage;

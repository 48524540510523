import React, { useContext, useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { GiftcardApi, RedeemAppApi } from "../../apiclient";
import {
  RedeemAppSettingsViewModel,
  RedeemAppSaveAdvancedSettingsPostModel,
} from "../../apiclient/models";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Modal,
  InputGroup,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";

import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import Util from "../../js/util";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SettingsNavMenu from "../../components/SettingsNavMenu";
import ReedemAppToggleEnabledBanner from "./RedeemAppToggleEnabledBanner";
import { CopyBlock, dracula } from "react-code-blocks";

const RedeemAppSettingsPage = () => {
  let redeemAppApi = new RedeemAppApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState({} as RedeemAppSettingsViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [isLoading, setLoading] = useState(false as boolean);

  const dayjs = require("dayjs");
  dayjs.extend(require("dayjs/plugin/utc"));
  dayjs.extend(require("dayjs/plugin/timezone"));
  dayjs.extend(require("dayjs/plugin/relativeTime"));

  const copyToClipboard = (textToCopy: string, successMessage: string) => {
    navigator.clipboard.writeText(textToCopy);
    toast.success(successMessage);
  };

  useEffect(() => {
    fetchSettings();

    // const redirectType = searchParams.get("redirectType") ?? "";
    // if (redirectType && redirectType === "return") {
    //   toast.error("Return");
    // }
  }, []);

  const fetchSettings = () => {
    setLoading(true);
    redeemAppApi
      .redeemAppGetSettingsGet()
      .then((response) => {
        var data = response.data;
        setModel(data);
      })
      .catch((error: any) => {
        console.log("get error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: any) => {
    const target = event.target;
    console.log(
      event.target.type,
      event.target.name,
      event.currentTarget.value
    );
    var value =
      target.type === "checkbox" ||
      target.type === "radio" ||
      target.type === "switch"
        ? target.checked
        : target.value;
    const name = event.target.name;
    console.log("handleChange", name, value);
    setUnsavedChanges(true);
    setModel((values: RedeemAppSettingsViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);

      let saveModel = {
        shouldDisableAdvancedSearch: model.shouldDisableAdvancedSearch,
        shouldHideGiftCardHistory: model.shouldHideGiftCardHistory,
        shouldRedeemExpiredGiftCards: model.shouldRedeemExpiredGiftCards,
      } as RedeemAppSaveAdvancedSettingsPostModel;

      redeemAppApi
        .redeemAppSaveAdvancedSettingsPost("1", saveModel)
        .then((response: any) => {
          toast.success("Settings saved");
          setUnsavedChanges(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("An error occurred when saving settings.");
          setUnsavedChanges(false);
        });
    }
  };

  const refreshQrCode = () => {
    redeemAppApi
      .redeemAppRegenerateLoginQrCodePost()
      .then((response) => {
        fetchSettings();
        toast.success("Login QR code has been updated");
      })
      .catch((error: any) => {
        console.log("get error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <Spinner animation="border" />
        </>
      ) : (
        <>
          <header>
            <div className="container">
              <div className="border-bottom pt-6">
                <div className="row align-items-center">
                  <div className="col-sm-6 col-12">
                    <h1 className="h2 ls-tight">Redeem app settings</h1>
                  </div>
                  <div className="col-sm-6 col-12">
                    {/* <a
                  href="https://help.wrappedgiftcard.com/en/collections/3830625-account#general"
                  target={"_blank"}
                  className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block float-end"
                >
                  <i className="bi bi-question-circle-fill"></i>
                  <span className="d-none d-sm-inline ms-2">Help articles</span>
                </a> */}
                  </div>
                </div>
                <SettingsNavMenu />
              </div>
            </div>
          </header>

          <div className="bg-surface-secondary">
            <div className="container py-10">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {model && (
                  <div className="row mb-10">
                    <div className="col-lg-4 mb-5 mb-lg-0 pe-lg-16">
                      <h4 className="font-semibold mb-2">Redeem App</h4>
                      <p className="text-sm">
                        Redeem gift cards using our in-person app
                      </p>
                    </div>
                    <div className="col-lg-8">
                      <div className="card mb-10">
                        <ReedemAppToggleEnabledBanner />

                        <div className="card-header d-flex align-items-center">
                          <h5>
                            Step 1: Scan code below, or visit:{" "}
                            <a
                              href={`https://redeem.wrappedgiftcards.com`}
                              target="_blank"
                            >
                              https://redeem.wrappedgiftcards.com
                            </a>
                          </h5>
                          <div className="ms-auto">
                            <a
                              href={`https://redeem.wrappedgiftcards.com`}
                              target="_blank"
                              className="btn btn-sm btn-neutral ms-auto"
                            >
                              View <i className="bi bi-box-arrow-up-right"></i>
                            </a>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row g-5">
                            <div className="col-6">
                              <div className="">
                                <h5>Step 2: Scan QR Code to log in</h5>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>
                                {model.loginQrCode && (
                                  <img
                                    src={`/RedeemApp/LoginQrCode/${model.loginQrCode}`}
                                    height={300}
                                  />
                                )}
                              </div>
                              <div className="mt-5">
                                <button
                                  type="button"
                                  onClick={refreshQrCode}
                                  className="btn btn-neutral"
                                >
                                  <i className="bi bi-arrow-repeat"></i>{" "}
                                  Generate new QR code
                                </button>
                                <p>
                                  <small className="text-xs text-muted">
                                    Last updated:{" "}
                                    {dayjs(model.lastUpdatedAt).fromNow()}
                                  </small>
                                </p>
                              </div>
                            </div>

                            {/* <hr className="py-2" />
                            <h5>Advanced settings</h5>

                            <div className="col-12">
                              <div className="d-flex mt-3">

                                <div className="me-3">
                                  <Form.Check
                                    name="shouldDisableAdvancedSearch"
                                    id="shouldDisableAdvancedSearch"
                                    type={"checkbox"}
                                    checked={model.shouldDisableAdvancedSearch}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="shouldDisableAdvancedSearch"
                                  >
                                    Should disable advanced search (e.g. search
                                    by customer name, email address)
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="d-flex mt-3">
                                <div className="me-3">
                                  <Form.Check
                                    name="shouldRedeemExpiredGiftCards"
                                    id="shouldRedeemExpiredGiftCards"
                                    type={"checkbox"}
                                    checked={model.shouldRedeemExpiredGiftCards}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="shouldRedeemExpiredGiftCards"
                                  >
                                    Should allow redemption of expired gift
                                    cards
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="d-flex mt-3">
                                <div className="me-3">
                                  <Form.Check
                                    name="shouldHideGiftCardHistory"
                                    id="shouldHideGiftCardHistory"
                                    type={"checkbox"}
                                    checked={model.shouldHideGiftCardHistory}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="shouldHideGiftCardHistory"
                                  >
                                    Should hide gift card history
                                  </label>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        {/* <div className="card-footer text-end py-4">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                          >
                            Save
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default RedeemAppSettingsPage;

import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { FlowsApi } from "../../apiclient";
import {
  WorkflowListItemViewModel,
} from "../../apiclient/models";
import _ from "lodash";
import GridTable from "../../components/GridTable";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const WorkflowsListPage = () => {
  let flowsApi = new FlowsApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [loading, setLoading] = useState(true as boolean);
  const authContext = useContext(AuthContext);
  //const dayjs = require("dayjs");
  const [data, setData] = useState([] as WorkflowListItemViewModel[]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setfilterStatus] = useState("");
  const [showUploadGiftCardsCsvModal, setShowUploadGiftCardsCsvModal] =
    useState(false as boolean);

  const tableColumns = [
    {
      Header: "Name",
      accessor: "title",
      Cell: ({ value, row }: any) => {
        var item = row.original as WorkflowListItemViewModel;
        return (
          <React.Fragment>
            <div>
              <Link
                className="text-link font-semibold row"
                to={`/workflows/design/${item.id}`}
              >
                <div className="d-flex align-items-center col">
                  <i className="bi bi-lightning me-2 text-lg"></i>
                  {item.title}
                </div>
              </Link>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Trigger",
      accessor: "trigger",
      Cell: ({ value, row }: any) => {
        var item = row.original as WorkflowListItemViewModel;
        return (
          <React.Fragment>
            {item.trigger}
          </React.Fragment>
        );
      },
    },
    {
      Header: "Credit issued",
      accessor: "totalCreditIssued",
      Cell: ({ value, row }: any) => {
        var item = row.original as WorkflowListItemViewModel;
        return (
          <React.Fragment>
            {item.totalCreditIssued}
          </React.Fragment>
        );
      },
    },
    {
      Header: "Redemption rate",
      accessor: "redemptionRate",
      Cell: ({ value, row }: any) => {
        var item = row.original as WorkflowListItemViewModel;
        return (
          <React.Fragment>
            {item.redemptionRate}
          </React.Fragment>
        );
      },
    },
    {
      Header: "Revenue",
      accessor: "revenue",
      Cell: ({ value, row }: any) => {
        var item = row.original as WorkflowListItemViewModel;
        return (
          <React.Fragment>
            {item.revenue}
          </React.Fragment>
        );
      },
    },
    {
      Header: "Created",
      accessor: "createdAt",
      Cell: ({ value, row }: any) => {
        var item = row.original as WorkflowListItemViewModel;
        return (
          <React.Fragment>
            <span>
              {dayjs(item.createdAt)
                .utc()
                .tz(authContext?.account?.ianaTimezone)
                .format("YYYY-MM-DD hh:mm a")}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Status",
      accessor: "isEnabled",
      Cell: ({ value, row }: any) => {
        var item = row.original as WorkflowListItemViewModel;
        var badgeColor = "bg-success text-success";
        var cardStatus = "Active";
        if (!item.isEnabled) {
          cardStatus = "Inactive";
          badgeColor = "bg-secondary text-dark";
        } 
        return (
          <React.Fragment>
            <span
              className={`badge text-uppercase bg-opacity-30 ${badgeColor}`}
            >
              {cardStatus}
            </span>
          </React.Fragment>
        );
      },
    },
  ];

  const fetchData = ({ pageSize, pageIndex, search, sortBy, status }: any) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      var isDesc = sortBy[0] ? sortBy[0].desc ?? "true" : "true";
      var sortField = sortBy[0] ? sortBy[0].id ?? "createdAt" : "createdAt";

      fetchAPIData({
        limit: pageSize,
        pageIndex: pageIndex,
        skip: pageSize * pageIndex,
        search: search,
        sortField: sortField,
        isDesc: isDesc,
        status,
      });
    }
  };

  const fetchAPIData = async ({
    limit,
    pageIndex,
    skip,
    search,
    sortField,
    isDesc,
    status,
  }: any) => {
    try {
      setLoading(true);
      if (!sortField) {
        sortField = "createdAt";
      }
      const serverSortField = sortField[0].toUpperCase() + sortField.slice(1); //capitalize first letter to match server field name

      flowsApi
        .flowsListGet(
          status,
          pageIndex,
          skip,
          limit,
          serverSortField,
          isDesc,
          search
        )
        .then((response) => {
          const list = response.data.result;
          var pageSize = limit;
          var TotalCount = response.data.totalCount ?? 0;
          var pageCount = ((TotalCount + pageSize - 1) / pageSize) | 0;

          setData(list ?? ([] as WorkflowListItemViewModel[]));
          setPageCount(pageCount);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  const _handleSearch = _.debounce(
    (search: string) => {
      setSearchTerm(search);
    },
    1500,
    {
      maxWait: 1500,
    }
  );

  return (
    <React.Fragment>
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  {(authContext.account?.userRole == "Admin" || authContext.account?.userRole == "Owner") && <>
                  
                    <Link className="btn btn-primary float-end ms-2 btn-sm" to={`/Flows/Create`}>
                      Create workflow
                    </Link>
                  </>}
                  <h1 className="h2 ls-tight">Workflows</h1>
                </div>
                <p className="text-sm pb-5">
                  Create automated workflows to enhance your customer's gifting experience on auto-pilot. 
                </p>
              </div>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid vstack gap-3">
            <div className="card">
            <div className="col-sm-12 col-12">
                <div className="input-group input-group-sm input-group-inline float-end py-3 pr-20 width-350">
                  <span className="input-group-text pe-2">
                    <i className="bi bi-search"></i>{" "}
                  </span>
                  <input
                    id="searchTerm"
                    name="searchTerm"
                    type="text"
                    className="form-control"
                    placeholder="Search workflows"
                    aria-label="Search"
                    onChange={(e) => _handleSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="p-5 pt-0">
                <GridTable
                  columns={tableColumns}
                  data={data}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  search={searchTerm}
                  goToFirstPage={undefined}
                  status={filterStatus}
                />
              </div>
              <div></div>
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default WorkflowsListPage;
